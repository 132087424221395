import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Card, ListGroup, Spinner } from 'react-bootstrap';

import { get } from '../api2';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWifi, faSignal } from '@fortawesome/free-solid-svg-icons';
import CountryFlag from '../Components/CountryFlag';

export default function AnalyticsIPs({ profile, device, timeRange }) {
  const { t, i18n } = useTranslation();

  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);

    return get(
      `/profiles/${profile}/analytics/ips`,
      {
        device,
        ...timeRange,
        limit: 4,
        lang: i18n.language,
      },
      ({ data }) => {
        setData(data);
        setLoading(false);
      }
    );
  }, [profile, device, timeRange, i18n.language]);

  return (
    <Col lg="6">
      <Card className="mb-4">
        <ListGroup variant="flush">
          <ListGroup.Item className="py-3">
            <h5>{t('analytics.ips.name')}</h5>
            <div style={{ opacity: 0.6, fontSize: '0.9em' }}>{t('analytics.ips.description')}</div>
          </ListGroup.Item>

          {loading ? (
            <ListGroup.Item className="text-center">
              <Spinner animation="border" style={{ width: '3rem', height: '3rem', opacity: 0.15 }} className="my-5" />
            </ListGroup.Item>
          ) : data.length === 0 ? (
            <ListGroup.Item className="text-center my-2" style={{ opacity: 0.4 }}>
              {t('analytics.ips.empty')}
            </ListGroup.Item>
          ) : (
            data.map((ip) => (
              <ListGroup.Item key={ip.ip}>
                <div className="d-flex">
                  <div
                    className="flex-grow-1 notranslate"
                    style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                  >
                    {ip.ip}
                  </div>
                  <div className="ms-3" style={{ opacity: 0.6 }}>
                    {i18n.format(ip.queries, 'number')}
                  </div>
                </div>
                {!!(ip.network || ip.geo) && (
                  <div style={{ marginTop: 5, fontSize: '0.8em' }}>
                    {!!ip.network && (
                      <div className="d-md-inline">
                        <FontAwesomeIcon
                          icon={ip.network.cellular ? faSignal : faWifi}
                          style={{ fontSize: '0.85em', opacity: 0.5, marginRight: 2 }}
                        />
                        <span className="notranslate" style={{ marginLeft: 4, opacity: 0.5 }}>
                          {ip.network.isp}
                        </span>
                      </div>
                    )}

                    {!!ip.geo && (
                      <div className="d-md-inline ms-md-3 mt-1 mt-md-0" style={{ marginLeft: -2 }}>
                        <CountryFlag code={ip.geo.countryCode} />
                        <span style={{ opacity: 0.5 }}>
                          {' '}
                          <span className="notranslate">{ip.geo.city}</span>, {ip.geo.country}
                        </span>
                      </div>
                    )}
                  </div>
                )}
              </ListGroup.Item>
            ))
          )}
        </ListGroup>
      </Card>
    </Col>
  );
}
