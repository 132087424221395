import React, { useState, useEffect } from 'react';
import { Trans } from 'react-i18next';
import { Col, Card, Spinner } from 'react-bootstrap';
import MediaQuery from 'react-responsive';

import { get } from '../api2';

function getQueriesByStatus(data, status) {
  const item = data.find((item) => item.status === status);
  return item ? item.queries : 0;
}

function getQueriesTotal(data) {
  return data.reduce((total, item) => total + item.queries, 0);
}

export default function AnalyticsCounters({ profile, device, timeRange }) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);

    return get(
      `/profiles/${profile}/analytics/status`,
      {
        device,
        ...timeRange,
      },
      ({ data }) => {
        setData(data);
        setLoading(false);
      }
    );
  }, [profile, device, timeRange]);

  return (
    <>
      <MediaQuery minWidth={768}>
        <Col md="4">
          <Card body className="text-center py-2 mb-4">
            {loading ? (
              <Spinner animation="border" style={{ width: '2rem', height: '2rem', opacity: 0.15 }} className="my-3" />
            ) : (
              <Trans i18nKey="analytics.counters.all" count={getQueriesTotal(data)}>
                <div style={{ fontSize: '2em', lineHeight: '1.1em', fontWeight: 500 }}>{{ count: null }}</div>
                <div style={{ fontSize: '0.9em', opacity: 0.5 }}>queries_</div>
              </Trans>
            )}
          </Card>
        </Col>
        <Col md="4">
          <Card body className="text-center py-2 mb-4">
            {loading ? (
              <Spinner animation="border" style={{ width: '2rem', height: '2rem', opacity: 0.15 }} className="my-3" />
            ) : (
              <Trans i18nKey="analytics.counters.blocked" count={getQueriesByStatus(data, 'blocked')}>
                <div style={{ fontSize: '2em', lineHeight: '1.1em', fontWeight: 500, color: '#ff4136' }}>
                  {{ count: null }}
                </div>
                <div style={{ fontSize: '0.9em', opacity: 0.5 }}>blocked queries_</div>
              </Trans>
            )}
          </Card>
        </Col>
        <Col md="4">
          <Card body className="text-center py-2 mb-4">
            {loading ? (
              <Spinner animation="border" style={{ width: '2rem', height: '2rem', opacity: 0.15 }} className="my-3" />
            ) : (
              <Trans i18nKey="analytics.counters.percentBlocked">
                <div style={{ fontSize: '2em', lineHeight: '1.1em', fontWeight: 500, opacity: 0.5 }}>
                  {{ percent: getQueriesByStatus(data, 'blocked') / getQueriesTotal(data) || 0 }}
                </div>
                <div style={{ fontSize: '0.9em', opacity: 0.5 }}>
                  <span className="d-none"> </span>
                  <span>of blocked queries_</span>
                </div>
              </Trans>
            )}
          </Card>
        </Col>
      </MediaQuery>

      <MediaQuery maxWidth={768 - 1}>
        <Col>
          <Card body className="text-center p-2 mb-4">
            {loading ? (
              <Spinner animation="border" style={{ width: '2rem', height: '2rem', opacity: 0.15 }} className="my-3" />
            ) : (
              <>
                <div>
                  <div className="d-inline-block" style={{ width: '45%' }}>
                    <Trans i18nKey="analytics.counters.all" count={getQueriesTotal(data)}>
                      <div style={{ fontSize: '1.6em', lineHeight: '1.1em', fontWeight: 500 }}>{{ count: null }}</div>
                      <div style={{ fontSize: '0.9em', opacity: 0.5 }}>queries_</div>
                    </Trans>
                  </div>
                  <div className="d-inline-block" style={{ width: '45%', marginLeft: '10%' }}>
                    <Trans i18nKey="analytics.counters.blocked" count={getQueriesByStatus(data, 'blocked')}>
                      <div style={{ fontSize: '1.6em', lineHeight: '1.1em', fontWeight: 500, color: '#ff4136' }}>
                        {{ count: null }}
                      </div>
                      <div style={{ fontSize: '0.9em', opacity: 0.5 }}>blocked queries_</div>
                    </Trans>
                  </div>
                </div>
                <div className="mt-4">
                  <Trans i18nKey="analytics.counters.percentBlocked">
                    <span style={{ fontWeight: 500, opacity: 0.8 }}>
                      {{ percent: getQueriesByStatus(data, 'blocked') / getQueriesTotal(data) || 0 }}
                    </span>
                    <span style={{ fontSize: '0.9em', opacity: 0.5 }}>
                      <span> </span>
                      <span>of blocked queries_</span>
                    </span>
                  </Trans>
                </div>
              </>
            )}
          </Card>
        </Col>
      </MediaQuery>
    </>
  );
}
