import React, { useEffect, useRef, useState } from "react";
import { Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

export default function SearchInput({ value, className, active, onChange }) {
    const inputRef = useRef();
    const [newValue, setNewValue] = useState(value);

    useEffect(() => {
        if (active) {
            const handleKeyDown = (event) => {
                if (event.key.match(/^[0-9a-zA-Z]$/)) {
                    inputRef.current.focus();
                }
            }
            window.addEventListener('keydown', handleKeyDown);
            return () => {
                window.removeEventListener('keydown', handleKeyDown);
            }
        } else {
            inputRef.current.blur();
        }
    }, [active]);

    useEffect(() => {
        setNewValue(value);
    }, [value]);

    const handleChange = (event) => {
        setNewValue(event.target.value);
        onChange?.(event.target.value);
    }

    const handleClear = (event) => {
        event.preventDefault();
        setNewValue('');
        onChange?.('');
    }

    return (
        <div className={className}>
            <div className="d-flex align-items-center justify-content-between" style={{ height: '30px' }}>
                <FontAwesomeIcon icon={faSearch} style={{
                    width: '35px',
                    fontSize: '0.9em',
                    opacity: 0.5,
                }} />
                {!!newValue && (
                    <a
                        href="#clear"
                        style={{
                            fontSize: '0.8em',
                            opacity: 0.3,
                            lineHeight: 0,
                            color: 'black',
                            zIndex: 2,
                        }}
                        onClick={handleClear}
                    >
                        <FontAwesomeIcon icon={faTimesCircle} style={{ margin: 9 }} />
                    </a>
                )}
            </div>
            <Form.Control
                type="text"
                ref={inputRef}
                value={newValue}
                onChange={handleChange}
                spellCheck="false"
                autoCorrect="off"
                autoComplete="off"
                autoCapitalize="off"
                className="form-control-sm flex-shrink-1 flex-grow-1"
                style={{
                    marginTop: '-30px',
                    borderRadius: "50px",
                    paddingLeft: "34px",
                    zIndex: 1,
                }}
            />
        </div >
    );
}