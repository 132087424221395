import React, { useState, useCallback } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Card, ListGroup, Modal, Form, Button } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import dayjs from 'dayjs';
import { ReactSVG } from 'react-svg';

import { patch } from '../api2';
import { timezone, capitalize } from '../helpers';

import RecreationIcon from '../images/recreation.svg';

const DAYS = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

const TIMES = Array.from({ length: 24 * 2 }, (_, index) =>
  dayjs()
    .startOf('day')
    .add(index * 30, 'minutes')
    .format('HH:mm:ss')
);

const DEFAULT_TIMES = {
  monday: {
    start: '18:00:00',
    end: '20:30:00',
  },
  tuesday: {
    start: '18:00:00',
    end: '20:30:00',
  },
  wednesday: {
    start: '18:00:00',
    end: '20:30:00',
  },
  thursday: {
    start: '18:00:00',
    end: '20:30:00',
  },
  friday: {
    start: '18:00:00',
    end: '20:30:00',
  },
  saturday: {
    start: '09:00:00',
    end: '20:30:00',
  },
  sunday: {
    start: '09:00:00',
    end: '20:30:00',
  },
};

export default function ParentalControlRecreationTime({ profile, role, recreation, onUpdated }) {
  const { t, i18n } = useTranslation();

  const isMobile = useMediaQuery({ query: '(max-width: 840px)' });

  const [newTimes, setNewTimes] = useState({});

  const [showModal, setShowModal] = useState(false);

  const openModal = useCallback(() => {
    setNewTimes(Object.keys(recreation.times).length > 0 ? { ...recreation.times } : DEFAULT_TIMES);
    setShowModal(true);
  }, [recreation]);

  const closeModal = useCallback(() => {
    setShowModal(false);
    setNewTimes({ ...recreation.times });
  }, [recreation]);

  const onEnabledChange = useCallback((day, enabled) => {
    if (enabled) {
      setNewTimes((newTimes) => {
        return {
          ...newTimes,
          [day]: { ...DEFAULT_TIMES[day] },
        };
      });
    } else {
      setNewTimes((newTimes) => {
        let { [day]: omit, ..._newTimes } = newTimes;
        return _newTimes;
      });
    }
  }, []);

  const onTimeChange = useCallback((day, extremity, time) => {
    if (extremity === 'start') {
      setNewTimes((newTimes) => {
        return {
          ...newTimes,
          [day]: {
            start: time,
            end:
              newTimes[day].end === '00:00:00' || time < newTimes[day].end
                ? newTimes[day].end
                : TIMES[TIMES.indexOf(time) + 1],
          },
        };
      });
    } else {
      setNewTimes((newTimes) => {
        return {
          ...newTimes,
          [day]: {
            ...newTimes[day],
            end: time,
          },
        };
      });
    }
  }, []);

  const save = useCallback(() => {
    closeModal();

    patch(`/profiles/${profile}/parentalControl`, {
      recreation: {
        times: newTimes,
        timezone,
      },
    });

    onUpdated({
      times: newTimes,
      timezone,
    });
  }, [profile, newTimes, closeModal, onUpdated]);

  return (
    <>
      <Card style={{ borderLeftX: '4px solid #c9de17' }}>
        <ListGroup variant="flush">
          <ListGroup.Item className="py-3">
            <h5>{t('parentalControl.recreation.name')}</h5>
            <div style={{ opacity: 0.6, fontSize: '0.9em' }}>{t('parentalControl.recreation.description')}</div>
          </ListGroup.Item>
          {Object.keys(recreation.times).length > 0 && (
            <ListGroup.Item>
              <div>
                {DAYS.filter((day) => day in recreation.times).map((day) => (
                  <div key={day} className="my-1" style={{ fontSize: '0.9em' }}>
                    <span className="me-2" style={{ fontWeight: 500 }}>
                      {capitalize(
                        i18n.language,
                        dayjs()
                          .isoWeekday((DAYS.indexOf(day) + 1) % 7)
                          .format('dddd')
                      )}
                    </span>
                    <span style={{ opacity: 0.6 }}>
                      {i18n.format([dayjs(recreation.times[day].start, 'HH:mm:ss').toISOString(), 'LT'], 'date')}
                    </span>
                    <span style={{ opacity: 0.4 }}> → </span>
                    <span style={{ opacity: 0.6 }}>
                      {i18n.format([dayjs(recreation.times[day].end, 'HH:mm:ss').toISOString(), 'LT'], 'date')}
                    </span>
                  </div>
                ))}
              </div>
              <div className="mt-4 mb-1" style={{ fontSize: '0.8em', opacity: 0.6 }}>
                <Trans i18nKey="parentalControl.recreation.howto">
                  _Use the <ReactSVG src={RecreationIcon} className="recreation-icon" /> button in the sections above to
                  enable Recreation Time.
                </Trans>
              </div>
            </ListGroup.Item>
          )}
        </ListGroup>
        <Card.Footer className="text-muted" style={{ borderTop: 'none' }}>
          <Button
            className="btn-recreation"
            size="sm"
            style={{ fontWeight: 'bold', fontSize: 12, textTransform: 'uppercase' }}
            onClick={openModal}
            disabled={role === 'viewer'}
          >
            {t('parentalControl.recreation.set')}
          </Button>
        </Card.Footer>
      </Card>

      <Modal show={showModal} animation={false} onHide={closeModal}>
        <Form
          onSubmit={(event) => {
            event.preventDefault();
            save();
          }}
          action="#submit"
          noValidate
        >
          <Modal.Header closeButton>
            <Modal.Title as="h5">Recreation Time</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="px-3">
              {DAYS.map((day) => (
                <React.Fragment key={day}>
                  <div className="my-3 d-flex align-items-center">
                    <div className="me-1">
                      <Form.Check
                        custom
                        type="checkbox"
                        checked={day in newTimes}
                        id={`checkbox-${day}`}
                        label=""
                        onChange={(event) => onEnabledChange(day, event.target.checked)}
                      />
                    </div>
                    <div className="me-2 flex-grow-1" style={{ fontWeight: 500 }}>
                      {capitalize(
                        i18n.language,
                        dayjs()
                          .isoWeekday((DAYS.indexOf(day) + 1) % 7)
                          .format('dddd')
                      )}
                    </div>
                    {!isMobile && (
                      <>
                        <div style={{ visibility: day in newTimes ? 'visible' : 'hidden' }}>
                          <Form.Select
                            size="sm"
                            custom
                            style={{ width: 'auto' }}
                            value={newTimes[day] && newTimes[day].start}
                            onChange={(event) => onTimeChange(day, 'start', event.target.value)}
                          >
                            {TIMES.slice(0, -1).map((time, i) => (
                              <option key={i} value={time}>
                                {i18n.format([dayjs(time, 'HH:mm:ss').toISOString(), 'LT'], 'date')}
                              </option>
                            ))}
                          </Form.Select>
                        </div>
                        <div
                          className="mx-2"
                          style={{ opacity: 0.4, visibility: day in newTimes ? 'visible' : 'hidden' }}
                        >
                          →{' '}
                        </div>
                        <div style={{ visibility: day in newTimes ? 'visible' : 'hidden' }}>
                          <Form.Select
                            size="sm"
                            custom
                            style={{ width: 'auto' }}
                            value={newTimes[day] && newTimes[day].end}
                            onChange={(event) => onTimeChange(day, 'end', event.target.value)}
                          >
                            {TIMES.slice(1)
                              .filter((time) => newTimes[day] && (time === '00:00:00' || time > newTimes[day].start))
                              .map((time, i) => (
                                <option key={i} value={time}>
                                  {i18n.format([dayjs(time, 'HH:mm:ss').toISOString(), 'LT'], 'date')}
                                </option>
                              ))}
                          </Form.Select>
                        </div>
                      </>
                    )}
                  </div>
                  {isMobile && day in newTimes && (
                    <div
                      className="d-flex align-items-center"
                      style={{ marginTop: -10, marginLeft: 27, marginBottom: 16 }}
                    >
                      <div style={{ visibility: day in newTimes ? 'visible' : 'hidden' }}>
                        <Form.Select
                          size="sm"
                          custom
                          style={{ width: 'auto' }}
                          value={newTimes[day] && newTimes[day].start}
                          onChange={(event) => onTimeChange(day, 'start', event.target.value)}
                        >
                          {TIMES.slice(0, -1).map((time, i) => (
                            <option key={i} value={time}>
                              {i18n.format([dayjs(time, 'HH:mm:ss').toISOString(), 'LT'], 'date')}
                            </option>
                          ))}
                        </Form.Select>
                      </div>
                      <div
                        className="mx-2"
                        style={{ opacity: 0.4, visibility: day in newTimes ? 'visible' : 'hidden' }}
                      >
                        →{' '}
                      </div>
                      <div style={{ visibility: day in newTimes ? 'visible' : 'hidden' }}>
                        <Form.Select
                          size="sm"
                          custom
                          style={{ width: 'auto' }}
                          value={newTimes[day] && newTimes[day].end}
                          onChange={(event) => onTimeChange(day, 'end', event.target.value)}
                        >
                          {TIMES.slice(1)
                            .filter((time) => newTimes[day] && (time === '00:00:00' || time > newTimes[day].start))
                            .map((time, i) => (
                              <option key={i} value={time}>
                                {i18n.format([dayjs(time, 'HH:mm:ss').toISOString(), 'LT'], 'date')}
                              </option>
                            ))}
                        </Form.Select>
                      </div>
                    </div>
                  )}
                </React.Fragment>
              ))}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-secondary" onClick={closeModal}>
              {t('global.cancel')}
            </Button>
            <Button type="submit" className="btn-recreation">
              {t('global.save')}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
