import { useEffect, useRef, useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const usePrevious = (value) => {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};

export function useCallbackWithCancel(cb, deps) {
  const cleanupRef = useRef(null);
  useEffect(() => {
    return () => {
      const maybeCancel = cleanupRef.current;
      if (typeof maybeCancel === 'function') {
        maybeCancel();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
  return useCallback((...args) => {
    const returnValue = cb(...args);
    cleanupRef.current = returnValue;
    return returnValue;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}

// TODO: not need anymore when switching to react-router 6
export function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}
