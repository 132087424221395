import { useState, useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

function getDisplayDate(date, during, suffix, i18n, absoluteFormatter) {
  // TODO: cleanup, date should be ISO string all the time on <RelativeTime />
  let day;
  if (dayjs.isDayjs(date)) {
    day = date;
  } else if (typeof date === 'number' && date <= 2147483647) {
    day = dayjs.unix(date);
  } else {
    day = dayjs(date);
  }

  if (during < Infinity && Math.abs(day.diff(dayjs())) >= during) {
    return absoluteFormatter.format(new Date(date));
  }

  return day.locale(i18n.language).fromNow(!suffix);
}

export default function RelativeTime({ date, during = Infinity, suffix = false, refreshEvery = 60 * 1000 }) {
  const { i18n } = useTranslation();

  const [displayDate, setDisplayDate] = useState(null);

  const absoluteFormatter = useMemo(() => {
    return new Intl.DateTimeFormat(i18n.language, {
      dateStyle: 'long',
      timeStyle: 'medium',
    });
  }, [i18n.language]);

  useEffect(() => {
    setDisplayDate(getDisplayDate(date, during, suffix, i18n, absoluteFormatter));
    const interval = setInterval(() => {
      setDisplayDate(() => getDisplayDate(date, during, suffix, i18n, absoluteFormatter));
    }, refreshEvery);

    return () => {
      clearInterval(interval);
    };
  }, [date, during, suffix, refreshEvery, i18n, absoluteFormatter]);

  return displayDate;
}
