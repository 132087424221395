import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from 'react-bootstrap';
import { useIdleTimer } from 'react-idle-timer';

import * as Request from '../Request';
import { get } from '../api2';

const TYPES = {
  loading: '#eeeeee',
  error: '#111111',
  ok: '#2ecc40',
  mismatch: '#ff851b',
  unconfigured: '#ff4136',

  'apr-ok': '#2ecc40',
  'apr-blockpage': '#ff851b',
};

export default function SetupStatus({ profile, linkedIpDNSServers }) {
  const { t } = useTranslation();

  const [status, setStatus] = useState({
    type: 'loading',
    primary: null,
    secondary: null,
  });

  const [idle, setIdle] = useState(false);
  const [visible, setVisible] = useState(true);

  const timeout = useRef();

  const source = useRef();

  useEffect(() => {
    source.current = new Set();

    return () => {
      source.current.forEach((func) => func());
    };
  }, [profile]);

  const onIdle = useCallback(() => {
    setIdle(true);
  }, []);

  const onActive = useCallback(() => {
    setIdle(false);
  }, []);

  useIdleTimer({
    timeout: 1000 * 60,
    onIdle: onIdle,
    onActive: onActive,
  });

  const handleVisibilityChange = useCallback(() => {
    setVisible(document.visibilityState === 'visible');
  }, []);

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [handleVisibilityChange]);

  const fetch = useCallback(async () => {
    const randomStr = Math.random().toString(36).slice(2, 15);

    try {
      const data = await new Promise((resolve, reject) => {
        const cancel = Request.make(
          `https://${randomStr}-${profile}.test.nextdns.io`,
          {
            withCredentials: false,
          },
          ({ ok, response }) => {
            if (ok) {
              resolve(response);
            } else {
              reject('Network');
            }
          }
        );
        source.current.add(cancel);
      });

      let type, primary, secondary;

      if (data.status === 'ok') {
        if (data.apr) {
          const settings = await new Promise((resolve) => get(`/profiles/${profile}/settings`, ({ data }) => resolve(data)));
          if (settings && settings.blockPage && settings.blockPage.enabled) {
            type = 'apr-blockpage';
            primary = t('setup.status.apr.blockpage.primary');
            secondary = t('setup.status.apr.blockpage.secondary');
          } else {
            type = 'apr-ok';
            primary = t('setup.status.apr.ok.primary');
            secondary = t('setup.status.apr.ok.secondary');
          }
        } else {
          type = 'ok';
          primary = t('setup.status.ok.primary');
          secondary = t('setup.status.ok.secondary');
        }
      } else if (data.status === 'mismatch') {
        type = 'mismatch';

        if (!data.profile) {
          primary = t('setup.status.mismatch.withoutConfiguration.primary');

          if (data.clientName && data.clientName.startsWith('nextdns-')) {
            secondary = t('setup.status.mismatch.withoutConfiguration.secondary.clients');
          } else if (data.clientName === 'firefox') {
            secondary = t('setup.status.mismatch.withoutConfiguration.secondary.firefox');
          } else if (data.protocol === 'DOH' || data.protocol === 'DOH3') {
            secondary = t('setup.status.mismatch.withoutConfiguration.secondary.doh');
          } else if (data.protocol === 'DOT') {
            secondary = t('setup.status.mismatch.withoutConfiguration.secondary.dot');
          } else if (data.protocol === 'UDP' || data.protocol === 'TCP') {
            if (data.client.includes(':')) {
              secondary = t('setup.status.mismatch.withoutConfiguration.secondary.ipv6');
            } else if (!linkedIpDNSServers.includes(data.destIP)) {
              secondary = t('setup.status.mismatch.withoutConfiguration.secondary.servers');
            } else {
              secondary = t('setup.status.mismatch.withoutConfiguration.secondary.link');
            }
          } else {
            throw new Error('Unhandled "mismatch" case.');
          }
        } else {
          primary = t('setup.status.mismatch.withConfiguration.primary');

          const dataFingerprint = await new Promise((resolve, reject) => {
            const cancel = Request.make(
              `https://api.nextdns.io/profiles/${profile}/@getFingerprintInfo?fingerprint=${data.profile}`,
              {
                method: 'GET',
              },
              ({ ok, response }) => {
                if (ok) {
                  resolve(response.data);
                } else {
                  reject('Network');
                }
              }
            );
            source.current.add(cancel);
          });

          if (dataFingerprint.name) {
            secondary = t('setup.status.mismatch.withConfiguration.secondary.sameAccount', {
              name: dataFingerprint.name,
            });
          } else {
            secondary = t('setup.status.mismatch.withConfiguration.secondary.otherAccount');
          }
        }
      } else if (data.status === 'unconfigured') {
        type = 'unconfigured';
        primary = t('setup.status.unconfigured.primary');

        if (!data.resolver) {
          throw new Error('Unhandled case where "resolver" is not present.');
        }

        const dataResolver = await new Promise((resolve, reject) => {
          const cancel = Request.make(
            `https://api.nextdns.io/resolver/${data.resolver}`,
            {
              withCredentials: false,
            },
            ({ ok, response }) => {
              if (ok) {
                resolve(response);
              } else {
                reject('Network');
              }
            }
          );
          source.current.add(cancel);
        });

        secondary = t('setup.status.unconfigured.secondary', { resolver: dataResolver.name });
      } else {
        throw new Error('Unhandled "status" case.');
      }

      setStatus({
        type: type,
        primary: primary,
        secondary: secondary,
      });
    } catch (error) {
      if (error === 'Network') {
        setStatus({
          type: 'error',
          primary: t('setup.status.error.primary'),
          secondary: t('setup.status.error.secondary'),
        });

        return;
      }

      setStatus({
        type: 'error',
        primary: 'Error',
        secondary: error.message,
      });
    }
  }, [t, profile, linkedIpDNSServers]);

  const check = useCallback(async () => {
    await fetch();

    if (idle || !visible) {
      return;
    }

    timeout.current = setTimeout(() => {
      check();
    }, 2000);
  }, [idle, visible, fetch]);

  useEffect(() => {
    setStatus({
      type: 'loading',
      primary: null,
      secondary: null,
    });
  }, [profile]);

  useEffect(() => {
    if (!idle && visible) {
      check();
    } else {
      clearTimeout(timeout.current);
    }
    return () => clearTimeout(timeout.current);
  }, [idle, visible, check]);

  return (
    <Card body>
      <div className="d-flex">
        <div className="d-flex align-items-center me-3">
          <span style={{ userSelect: 'none', color: TYPES[status.type] }}>●</span>
        </div>
        <div className="flex-grow-1 d-flex align-items-center">
          <div>
            <div>{status.primary || '　'}</div>
            <div className="mt-1 mt-md-0" style={{ fontSize: '0.85em', opacity: 0.7 }}>
              {status.secondary || '　'}
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
}
