import React, { useContext } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Card, ListGroup } from 'react-bootstrap';

import { AccountContext } from '../contexts';

import AccountAffiliationPayOutButton from './AccountAffiliationPayOutButton';

export default function AccountAffiliation({ affiliation, onChange }) {
  const { t, i18n } = useTranslation();

  const account = useContext(AccountContext);

  return (
    <Card>
      <ListGroup variant="flush">
        <ListGroup.Item className="py-3">
          <h5>{t('account.affiliation.name')}</h5>
          <div style={{ opacity: 0.8, fontSize: '0.9em' }}>
            <Trans i18nKey="account.affiliation.description">
              _Help us spread the word by sharing
              <a
                href={`https://nextdns.io/?from=${affiliation.contract.code}`}
                target="_blank"
                rel="noopener noreferrer"
                className="notranslate"
              >
                {{ link: `https://nextdns.io/?from=${affiliation.contract.code}` }}
              </a>
              and receive <b>{{ share: affiliation.contract.share }}</b> of all subscriptions for the first
              <b>{{ months: affiliation.contract.months }} months</b>.
            </Trans>
          </div>
        </ListGroup.Item>
        <ListGroup.Item className="p-0">
          <div className="d-flex" style={{ fontSize: '0.95em', padding: '0.75rem 1.25rem' }}>
            <div className="flex-grow-1" style={{ fontWeight: 500 }}>
              {t('account.affiliation.balance.name')}
            </div>
            <div className="text-end" style={{ fontWeight: 500, opacity: 0.6 }}>
              {i18n.format([affiliation.balance, 'USD'], 'price')}
            </div>
          </div>
        </ListGroup.Item>
        <ListGroup.Item className="p-0">
          <div className="d-flex" style={{ fontSize: '0.95em', padding: '0.75rem 1.25rem' }}>
            <div className="flex-grow-1 me-5">
              <div style={{ fontWeight: 500 }}>{t('account.affiliation.availableBalance.name')}</div>
              <div style={{ fontSize: '0.85em', opacity: 0.6, marginTop: 3 }}>
                {t('account.affiliation.availableBalance.description')}
              </div>
            </div>
            <div className="text-end" style={{ fontWeight: 500 }}>
              {i18n.format([affiliation.availableBalance, 'USD'], 'price')}
            </div>
          </div>
        </ListGroup.Item>
        <ListGroup.Item className="text-end" style={{ padding: '0.75rem 1rem' }}>
          <AccountAffiliationPayOutButton
            email={account.account.email}
            availableBalance={affiliation.availableBalance}
            onPaidOut={onChange}
          />
        </ListGroup.Item>
      </ListGroup>
    </Card>
  );
}
