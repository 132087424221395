import React, { memo, useCallback } from 'react';
import { ListGroup, Form, Button } from 'react-bootstrap';

import { hex, unpuny, classNames } from '../helpers';
import { patch, del } from '../api2';

import { XIcon } from '@heroicons/react/solid';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import Favicon from '../Components/Favicon';

function XlistDomain({ profile, role, listType, domain, onChange, onRemove }) {
  const toggleActive = useCallback(
    (event) => {
      const active = event.target.checked;
      onChange({ ...domain, active });
      patch(`/profiles/${profile}/${listType}/${hex(domain.id)}`, { active });
    },
    [profile, listType, domain, onChange]
  );

  const remove = useCallback(() => {
    onRemove(domain);
    del(`/profiles/${profile}/${listType}/${hex(domain.id)}`);
  }, [profile, listType, domain, onRemove]);

  if (process.env.REACT_APP_TAILWIND) {
    return (
      <div
        className={classNames(
          domain.active
            ? listType === 'allowlist'
              ? 'tw-border-l-green-500'
              : 'tw-border-l-red-500'
            : 'tw-border-l-slate-700',
          'tw-flex tw-items-center tw-border-t tw-border-l-2 tw-px-3 tw-text-sm first:tw-border-t-0 dark:tw-border-t-slate-800'
        )}
      >
        <div className="tw-grow tw-py-3">
          <Favicon domain={domain.id} className="tw-me-2 tw-align-middle" />
          <span className="notranslate tw-break-all tw-align-middle tw-font-mono tw-text-[13px] dark:tw-text-slate-50">
            {unpuny(domain.id)}
          </span>
        </div>
        <button
          className="mouse:hover:tw-text-slate-400 mouse:hover:dark:tw-text-slate-400 tw-block tw-h-10 tw-cursor-pointer tw-px-4 tw-text-slate-300 dark:tw-text-slate-500"
          onClick={remove}
        >
          <XIcon className="tw-h-4 tw-w-4" />
        </button>
      </div>
    );
  }

  return (
    <ListGroup.Item
      className='pe-1'
      style={{
        borderLeft: '4px solid #ccc',
        borderLeftColor: !domain.active ? '' : listType === 'allowlist' ? '#2ecc40' : '#ff4136',
      }}
    >
      <div className="d-flex">
        <div className="flex-grow-1 d-flex gap-2 align-items-center" style={{ opacity: domain.active ? 1 : 0.4 }}>
          <Favicon domain={domain.id} style={{ marginTop: -1 }} />
          <span className="notranslate" style={{ wordBreak: 'break-all' }}>
            <span style={{ opacity: 0.3 }}>*.</span>
            {unpuny(domain.id)}
          </span>
        </div>
        <div className="d-flex align-items-center">
          <div style={{ transform: 'translate(25%, 0%) scale(0.8)' }}>
            <Form.Switch
              id={`active-switch-${domain.id}`}
              label=""
              checked={domain.active}
              onChange={toggleActive}
              disabled={role === 'viewer'}
            />
          </div>
          {role !== 'viewer' && (
            <div>
              <Button variant="link" style={{ opacity: 0.3, color: '#222' }} onClick={remove}>
                <FontAwesomeIcon icon={faTimes} />
              </Button>
            </div>
          )}
        </div>
      </div>
    </ListGroup.Item>
  );
}

export default memo(XlistDomain);
