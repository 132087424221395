import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Spinner } from 'react-bootstrap';

import { get } from '../api2';
import { useCallbackWithCancel } from '../hooks';

export default function SettingsDownloadLogsButton({ profile }) {
  const { t } = useTranslation();

  const [generating, setGenerating] = useState(false);

  const download = useCallbackWithCancel(
    (event) => {
      event.preventDefault();

      setGenerating(true);

      get(`/profiles/${profile}/logs/download`, { redirect: 0 }, ({ data }) => {
        setGenerating(false);
        window.location = data.url;
      });
    },
    [profile]
  );

  return (
    <Button variant="outline-primary" size="sm" disabled={generating} onClick={download}>
      {generating && <Spinner as="span" animation="border" size="sm" className="me-2" />}
      {t('settings.logs.download.button')}
    </Button>
  );
}
