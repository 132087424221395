import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Card, Modal, Form, Button } from 'react-bootstrap';
import axios from 'axios';

import { API_BASE_URL } from '../config';

export default function AccountChangePassword({ email }) {
  const [showModal, setShowModal] = useState(false);
  const [currentPasswordValue, setCurrentPasswordValue] = useState('');
  const [currentPasswordError, setCurrentPasswordError] = useState(null);
  const [passwordValue, setPasswordValue] = useState('');
  const [passwordError, setPasswordError] = useState(null);
  const { t } = useTranslation();
  const source = useRef(null);

  useEffect(() => {
    source.current = axios.CancelToken.source();
    return () => {
      source.current.cancel();
    };
  }, []);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setCurrentPasswordValue('');
    setCurrentPasswordError(null);
    setPasswordValue('');
    setPasswordError(null);
  };

  const onCurrentPasswordValueChange = (event) => {
    setCurrentPasswordValue(event.target.value);
    setCurrentPasswordError(null);
  };

  const onPasswordValueChange = (event) => {
    setPasswordValue(event.target.value);
    setPasswordError(null);
  };

  const changePassword = async (event) => {
    event.preventDefault();

    const { data } = await axios.patch(
      `${API_BASE_URL}/accounts/@me`,
      {
        currentPassword: currentPasswordValue,
        password: passwordValue,
      },
      {
        cancelToken: source.current.token,
      }
    );

    if (data.errors) {
      setCurrentPasswordValue('');
      setCurrentPasswordError(data.errors.currentPassword);
      setPasswordValue('');
      setPasswordError(data.errors.password);
      return;
    }

    closeModal();
  };

  return (
    <>
      <Card body>
        <h5>{t('account.changePassword.name')}</h5>
        <div style={{ opacity: 0.6, fontSize: '0.9em' }}>{t('account.changePassword.description')}</div>
        <div className="mt-4">
          <Button variant="outline-primary" size="sm" onClick={openModal}>
            {t('account.changePassword.button')}
          </Button>
        </div>
      </Card>

      <Modal show={showModal} animation={false} onHide={closeModal}>
        <Form onSubmit={changePassword} action="#submit" noValidate>
          <Modal.Header closeButton>
            <Modal.Title as="h5">{t('account.changePassword.button')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.Label>{t('account.currentPassword')}</Form.Label>
              <Form.Control
                type="password"
                autoFocus
                autoComplete="current-password"
                isInvalid={!!currentPasswordError}
                value={currentPasswordValue}
                onChange={onCurrentPasswordValueChange}
              />
              {!!currentPasswordError && (
                <Form.Control.Feedback type="invalid">
                  {t(`account.errors.currentPassword.${currentPasswordError}`)}
                </Form.Control.Feedback>
              )}
              <div className="mt-1 text-end" style={{ fontSize: '0.8em' }}>
                <Link to={`/reset-password?email=${encodeURIComponent(email)}`}>
                  {t('account.forgotPassword')}
                </Link>
              </div>
            </Form.Group>
            <Form.Group>
              <Form.Label>{t('account.newPassword')}</Form.Label>
              <Form.Control
                type="password"
                autoComplete="new-password"
                isInvalid={!!passwordError}
                value={passwordValue}
                onChange={onPasswordValueChange}
              />
              {!!passwordError && (
                <Form.Control.Feedback type="invalid">
                  {t(`account.errors.password.${passwordError}`)}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-secondary" onClick={closeModal}>
              {t('global.cancel')}
            </Button>
            <Button type="submit">{t('global.change')}</Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
