import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Card, ListGroup, Spinner } from 'react-bootstrap';

import { get } from '../api2';

export default function AnalyticsDevices({ profile, device, timeRange }) {
  const { t, i18n } = useTranslation();

  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);

    return get(
      `/profiles/${profile}/analytics/devices`,
      {
        device,
        ...timeRange,
        limit: 4,
      },
      ({ data }) => {
        setData(data);
        setLoading(false);
      }
    );
  }, [profile, device, timeRange]);

  return (
    <Col lg="6">
      <Card className="mb-4">
        <ListGroup variant="flush">
          <ListGroup.Item className="py-3">
            <h5>{t('analytics.devices.name')}</h5>
            <div style={{ opacity: 0.6, fontSize: '0.9em' }}>{t('analytics.devices.description')}</div>
          </ListGroup.Item>

          {loading ? (
            <ListGroup.Item className="text-center">
              <Spinner animation="border" style={{ width: '3rem', height: '3rem', opacity: 0.15 }} className="my-5" />
            </ListGroup.Item>
          ) : data.length === 0 ? (
            <ListGroup.Item className="text-center my-2" style={{ opacity: 0.4 }}>
              {t('analytics.devices.empty')}
            </ListGroup.Item>
          ) : (
            data.map((device) => (
              <ListGroup.Item key={device.id}>
                {device.id === '__UNIDENTIFIED__' ? (
                  <>
                    <div className="d-flex">
                      <div className="flex-grow-1 text-truncate">{t('analytics.devices.unidentified.name')}</div>
                      <div className="ms-3" style={{ opacity: 0.6 }}>
                        {i18n.format(device.queries, 'number')}
                      </div>
                    </div>
                    <div style={{ opacity: 0.5, fontSize: '0.8em', marginTop: 2 }}>{t('deviceSelector.howto')}</div>
                  </>
                ) : (
                  <>
                    <div className="d-flex">
                      <div className="flex-grow-1 text-truncate notranslate">
                        {device.name || t('global.unnamedDevice', { id: device.id })}
                      </div>
                      <div className="ms-3" style={{ opacity: 0.6 }}>
                        {i18n.format(device.queries, 'number')}
                      </div>
                    </div>
                    {!!device.model && (
                      <div className="notranslate" style={{ opacity: 0.5, fontSize: '0.8em', marginTop: 2 }}>
                        {device.model}
                      </div>
                    )}
                  </>
                )}
              </ListGroup.Item>
            ))
          )}
        </ListGroup>
      </Card>
    </Col>
  );
}
