import React, { lazy, useState, useEffect, Suspense } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Col, Card, Spinner } from 'react-bootstrap';

import { get } from '../api';
import { timezone } from '../helpers';

const AnalyticsQueriesAreaChart = lazy(() => import('./AnalyticsQueriesAreaChart'));

export default function AnalyticsQueries({ profile, device, timeRange }) {
  const { t } = useTranslation();

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);

    return get(
      `/profiles/${profile}/analytics/status;series`,
      {
        device,
        ...timeRange,
        alignment: 'start',
        timezone,
      },
      (data) => {
        setData(data);
        setLoading(false);
      }
    );
  }, [profile, device, timeRange]);

  return (
    <Col>
      <Card body className="mb-4">
        <h5>{t('analytics.queries.name')}</h5>
        <div style={{ opacity: 0.6, fontSize: '0.9em' }}>{t('analytics.queries.description')}</div>
        <div className="mt-3" style={{ margin: '-1.25rem' }}>
          {loading ? (
            <div className="text-center">
              <Spinner animation="border" style={{ width: '3rem', height: '3rem', opacity: 0.15 }} className="my-5" />
            </div>
          ) : (
            <Suspense fallback={<div style={{ height: 120 }} />}>
              <AnalyticsQueriesAreaChart data={data} tooltip={<CustomTooltip />} />
            </Suspense>
          )}
        </div>
      </Card>
    </Col>
  );
}

function CustomTooltip({ active, payload }) {
  const { i18n } = useTranslation();

  if (!payload || payload.length === 0) {
    return null;
  }

  if (active) {
    return (
      <Card body style={{ fontSize: '0.9em' }}>
        <div style={{ fontWeight: 500, marginTop: -4 }}>
          {payload[0].payload.dayOnly && i18n.format([payload[0].payload.name, 'LL'], 'date')}
          {!payload[0].payload.dayOnly && i18n.format([payload[0].payload.name, 'LLL'], 'date')}
        </div>
        <div className="mt-2">
          <Trans i18nKey="analytics.queries.queries.all" count={payload[0].payload.queries}>
            <span style={{ fontWeight: 500, color: '#3b576a' }}>{{ count: null }}</span>
            <span style={{ opacity: 0.7 }}> queries_</span>
          </Trans>
        </div>
        <div className="mt-1">
          <Trans i18nKey="analytics.queries.queries.blocked" count={payload[0].payload.blockedQueries}>
            <span style={{ fontWeight: 500, color: '#ff4136' }}>{{ count: null }}</span>
            <span style={{ opacity: 0.7 }}> blocked queries_</span>
          </Trans>
        </div>
      </Card>
    );
  }

  return null;
}
