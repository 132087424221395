import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';

import { get } from '../api2';
import * as Request from '../Request';

import Page from '../Page';
import TranslationsFooter from '../Components/TranslationsFooter';
import SetupStatus from './SetupStatus';
import SetupEndpoints from './SetupEndpoints';
import SetupLinkedIp from './SetupLinkedIp';
import SetupIdentifyDevices from './SetupIdentifyDevices';
import SetupSetupGuide from './SetupSetupGuide';

import './Setup.scss';

export default function Setup({ profile, role }) {
  const { t } = useTranslation();

  const [ipv6, setIpv6] = useState(false);
  const [state, setState] = useState();

  useEffect(() => {
    return Request.make(
      `https://test-ipv6.nextdns.io`,
      {
        method: 'GET',
        withCredentials: false,
      },
      ({ ok }) => {
        setIpv6(ok);
      }
    );
  }, []);

  useEffect(() => {
    setState();

    return get(`/profiles/${profile}/setup`, ({ data }) => {
      setState(data);
    });
  }, [profile]);

  return (
    <Page title={t('pages.setup')} className="Setup" profile={profile}>
      {!!state && (
        <>
          <Row>
            <Col className="mb-4">
              <SetupStatus profile={profile} linkedIpDNSServers={state.linkedIp.servers} />
            </Col>
          </Row>
          <Row>
            <Col lg="6" className="mb-4">
              <SetupEndpoints profile={profile} ipv4={state.ipv4} ipv6={state.ipv6} />
            </Col>
            <Col lg="6" className="mb-4">
              <SetupLinkedIp
                profile={profile}
                role={role}
                linkedIpDNSServers={state.linkedIp.servers}
                initialLinkedIp={state.linkedIp.ip}
                ddnsHostname={state.linkedIp.ddns}
                linkedIpUpdateToken={state.linkedIp.updateToken}
              />
            </Col>
          </Row>
          <Row>
            <Col className="mb-4">
              <SetupSetupGuide
                profile={profile}
                ipv6={state.ipv6}
                linkedIpDNSServers={state.linkedIp.servers}
                dnsStamp={state.dnscrypt}
                ipv6Support={ipv6}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <SetupIdentifyDevices profile={profile} />
            </Col>
          </Row>
          <TranslationsFooter />
        </>
      )}
    </Page>
  );
}
