import React, { useState, useContext, useEffect, useRef } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Card, Modal, Form, Button, Spinner } from 'react-bootstrap';
import { del } from '../api';
import { AccountContext } from '../contexts';

export default function SettingsLeaveProfile({ profile, name }) {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const isModalClosable = useRef(true);
  const source = useRef();
  const accountContext = useContext(AccountContext);

  useEffect(() => {
    source.current = new Set();
  
    return () => {
      source.current.forEach(cancel => cancel());
    };
  }, []);

  const openModal = () => {
    setShowModal(true);
    setIsDeleting(false);
  };

  const closeModal = () => {
    if (!isModalClosable.current) {
      return;
    }
    setShowModal(false);
    setIsDeleting(false);
    isModalClosable.current = true;  // Reset closable state when closing modal
  };

  const leaveProfile = () => {
    isModalClosable.current = false;
    setIsDeleting(true);
    const cancel = del(`/profiles/${profile}`, () => {
      accountContext.onProfileDeleted(profile);
      closeModal();
    });
    source.current.add(cancel);
  };

  return (
    <>
      <Card body border="danger">
        <Button variant="danger" onClick={openModal}>
          <Trans i18nKey="settings.leave.button">
            _Leave
            <span className="notranslate" style={{ fontWeight: 'bold' }}>
              {{ name }}
            </span>
          </Trans>
        </Button>
        <Form.Text className="mt-2">{t('settings.leave.warning')}</Form.Text>
      </Card>

      <Modal show={showModal} animation={false} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title as="h5">
            <Trans i18nKey="settings.leave.button">
              _Leave
              <span className="notranslate" style={{ fontWeight: 'bold' }}>
                {{ name }}
              </span>
            </Trans>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Trans i18nKey="settings.leave.confirm.text">
            _Are you sure you want to leave
            <span className="notranslate" style={{ fontWeight: 'bold' }}>
              {{ name }}
            </span>
            ? This action cannot be undone.
          </Trans>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" disabled={isDeleting} onClick={closeModal}>
            {t('global.cancel')}
          </Button>

          {!isDeleting ? (
            <Button variant="danger" onClick={leaveProfile}>
              {t('global.leave')}
            </Button>
          ) : (
            <Button variant="danger" disabled>
              <Spinner as="span" animation="border" size="sm" className="me-2" />
              {t('global.leave')}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}