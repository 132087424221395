const canUseEmoji = process.env.REACT_APP_USE_SVG_FLAGS
  ? false
  : navigator.platform === 'Android' ||
    navigator.platform === 'MacIntel' ||
    navigator.platform === 'iPhone' ||
    navigator.platform === 'iPad';

var UNICODE_OFFSET = 127462 - 65;

export const countryCodeToFlagPath = (code) => {
  return (
    '/misc/flags/' +
    (UNICODE_OFFSET + code.charCodeAt(0)).toString(16) +
    '-' +
    (UNICODE_OFFSET + code.charCodeAt(1)).toString(16) +
    '.svg'
  );
};

export default function CountryFlag({ code }) {
  if (canUseEmoji) {
    return String.fromCodePoint(
      ...code
        .toUpperCase()
        .split('')
        .map((c) => c.codePointAt(0) + UNICODE_OFFSET)
    );
  }
  return (
    <img
      src={countryCodeToFlagPath(code)}
      className="d-inline"
      style={{ width: 16, height: 16, marginTop: '-0.2em' }}
      alt=""
    />
  );
}
