import React from 'react';

import Icon from '../images/translations.svg';

export default function TranslationsFooter() {
  return (
    <div className="px-3 text-center" style={{ marginTop: 70, marginBottom: 70, fontSize: 13, flexGrow: 0 }}>
      <img src={Icon} alt="" className="d-inline" style={{ height: 18, marginRight: 6, marginBottom: 4 }} />
      <span style={{ opacity: 0.7 }}>
        Help us translate or improve NextDNS in your language.{' '}
        <a href="https://help.nextdns.io/t/q6hmv0s/translations">Learn more</a>
      </span>
    </div>
  );
}
