import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Card, ListGroup } from 'react-bootstrap';

export default function SetupEndpoints({ profile, ipv4, ipv6 }) {
  const { t } = useTranslation();

  const scrollToSetupGuide = () => {
    const offset = document.querySelector('#setup-guide').offsetTop;

    window.scroll({
      top: offset - 20,
      behavior: 'smooth',
    });
  };

  if (process.env.REACT_APP_TAILWIND) {
    return (
      <div className="tw-overflow-hidden tw-rounded-md tw-border dark:tw-border-slate-800">
        <div className="tw-px-6 tw-py-5">
          <h3 className="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900 dark:tw-text-slate-200">
            {t('setup.endpoints.name')}
          </h3>
          <div className="tw-mt-2 tw-text-sm tw-text-gray-500">{t('setup.endpoints.description')}</div>
        </div>
        <div className="tw-border-t tw-px-6 tw-py-3 tw-text-sm dark:tw-border-slate-800 sm:tw-flex">
          <div className="notranslate tw-mb-1 tw-grow tw-font-medium dark:tw-text-slate-50 sm:tw-mb-0">ID</div>
          <div className="notranslate tw-font-mono tw-text-slate-600 dark:tw-text-slate-300">{profile}</div>
        </div>
        <div className="tw-border-t tw-px-6 tw-py-3 tw-text-sm dark:tw-border-slate-800 sm:tw-block sm:tw-flex">
          <div className="notranslate tw-mb-1 tw-grow tw-font-medium dark:tw-text-slate-50 sm:tw-mb-0">
            DNS-over-TLS/QUIC
          </div>
          <div className="notranslate tw-font-mono tw-text-slate-600 dark:tw-text-slate-300">
            {profile}.dns.nextdns.io
          </div>
        </div>
        <div className="tw-border-t tw-px-6 tw-py-3 tw-text-sm dark:tw-border-slate-800 sm:tw-flex">
          <div className="notranslate tw-mb-1 tw-grow tw-font-medium dark:tw-text-slate-50 sm:tw-mb-0">
            DNS-over-HTTPS
          </div>
          <div className="notranslate tw-font-mono tw-text-slate-600 dark:tw-text-slate-300">
            https://dns.nextdns.io/{profile}
          </div>
        </div>
        <div className="tw-border-t tw-px-6 tw-py-3 tw-text-sm dark:tw-border-slate-800 sm:tw-flex">
          <div className="notranslate tw-mb-1 tw-grow tw-font-medium dark:tw-text-slate-50 sm:tw-mb-0">IPv6</div>
          <div className="tw-font-mono tw-text-slate-600 dark:tw-text-slate-300">
            {ipv6.map((ip) => (
              <div key={ip} className="notranslate">
                {ip}
              </div>
            ))}
          </div>
        </div>
        <div className="tw-border-t tw-bg-slate-50 tw-px-6 tw-py-4 tw-text-sm tw-text-slate-800 dark:tw-border-slate-800 dark:tw-bg-slate-800 dark:tw-text-slate-100">
          <Trans i18nKey="setup.endpoints.help">
            _Not sure how to use those? Follow the
            <a
              href="#setup-guide"
              onClick={(event) => {
                event.preventDefault();
                scrollToSetupGuide();
              }}
            >
              {t('setup.guide.name')}
            </a>
            .
          </Trans>
        </div>
      </div>
    );
  }

  return (
    <Card>
      <ListGroup variant="flush">
        <ListGroup.Item className="py-3">
          <h5>{t('setup.endpoints.name')}</h5>
          <div style={{ opacity: 0.6, fontSize: '0.9em' }}>{t('setup.endpoints.description')}</div>
        </ListGroup.Item>
        <ListGroup.Item>
          <div className="d-sm-flex">
            <div className="endpoint-name flex-grow-1 notranslate">ID</div>
            <div className="notranslate" style={{ opacity: 0.6 }}>
              {profile}
            </div>
          </div>
        </ListGroup.Item>
        <ListGroup.Item>
          <div className="d-sm-flex">
            <div className="endpoint-name flex-grow-1 notranslate">DNS-over-TLS/QUIC</div>
            <div className="notranslate" style={{ opacity: 0.6 }}>
              {profile}.dns.nextdns.io
            </div>
          </div>
        </ListGroup.Item>
        <ListGroup.Item>
          <div className="d-sm-flex">
            <div className="endpoint-name flex-grow-1 notranslate">DNS-over-HTTPS</div>
            <div className="notranslate" style={{ opacity: 0.6 }}>
              https://dns.nextdns.io/{profile}
            </div>
          </div>
        </ListGroup.Item>
        {ipv4.length > 0 && (
          <ListGroup.Item>
            <div className="d-sm-flex">
              <div className="endpoint-name flex-grow-1 notranslate">IPv4</div>
              <div style={{ opacity: 0.6, fontVariantNumeric: 'tabular-nums' }}>
                {ipv4.map((ip) => (
                  <div className="text-md-right notranslate" key={ip}>
                    {ip}
                  </div>
                ))}
              </div>
            </div>
          </ListGroup.Item>
        )}
        <ListGroup.Item>
          <div className="d-sm-flex">
            <div className="endpoint-name flex-grow-1 notranslate">IPv6</div>
            <div style={{ opacity: 0.6, fontVariantNumeric: 'tabular-nums' }}>
              {ipv6.map((ip) => (
                <div className="text-md-right notranslate" key={ip}>
                  {ip}
                </div>
              ))}
            </div>
          </div>
        </ListGroup.Item>
        <ListGroup.Item className="bg-2" style={{ fontSize: '0.9em' }}>
          <Trans i18nKey="setup.endpoints.help">
            _Not sure how to use those? Follow the
            <a
              href="#setup-guide"
              onClick={(event) => {
                event.preventDefault();
                scrollToSetupGuide();
              }}
            >
              {t('setup.guide.name')}
            </a>
            .
          </Trans>
        </ListGroup.Item>
      </ListGroup>
    </Card>
  );
}
