import React, { useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { Container, Row, Col, Table } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import { API_BASE_URL } from './config';

import logo from './images/logo@2x.png';

export default function Invoice() {
  const { t, i18n } = useTranslation();
  const { code } = useParams();
  const [invoice, setInvoice] = useState(null);

  useEffect(() => {
    const fetchInvoice = async () => {
      const { data } = await axios.get(`${API_BASE_URL}/account/invoice/${code}`);
      setInvoice(data);
    };

    fetchInvoice();
  }, [code]);

  return (
    <>
      {!!invoice && (
          <>
          <Helmet>
          <html lang={i18n.language} dir={i18n.dir(i18n.language)} data-bs-theme="light" />
            <title>
              {t('account.invoices.invoice.title', { code: invoice.code.toUpperCase() })}
            </title>
          </Helmet>

          <Container className="py-5 px-4" style={{ maxWidth: 800 }}>
            <Row>
              <Col>
                <div className="d-flex">
                  <div className="mb-2 flex-grow-1">
                    <img className="d-inline" src={logo} alt="" style={{ width: 137, height: 38 }} />
                  </div>
                  <div className="mb-4 text-end" style={{ fontSize: '0.9em' }}>
                    <div style={{ fontWeight: 600 }}>NextDNS Inc.</div>
                    <div style={{ opacity: 0.8 }}>
                      2810 N Church St PMB 73778
                      <br />
                      Wilmington DE 19802-4447
                      <br />
                      United States
                    </div>
                  </div>
                </div>

                <div className="mt-4" style={{ fontSize: '1.4em', fontWeight: 500, color: '#007bff' }}>
                  {t('account.invoices.invoice.title', { code: invoice.code.toUpperCase() })}
                </div>
                <div className="mb-4" style={{ opacity: 0.7 }}>
                  {i18n.format([invoice.createdOn, 'LL'], 'date')}
                </div>

                <div>
                  <Trans i18nKey="account.invoices.invoice.to">
                    _To: <span style={{ fontWeight: 600 }}>{{ email: invoice.account.email }}</span>
                  </Trans>
                </div>

                {!!invoice.business.name && (
                  <div className="mt-2">
                    <div style={{ fontWeight: 600 }}>{invoice.business.name}</div>
                    <div style={{ opacity: 0.8 }}>
                      {invoice.business.address.split('\n').map((line, i) => (
                        <span key={i}>
                          {line}
                          <br />
                        </span>
                      ))}
                    </div>
                    {!!invoice.business.vatNumber && (
                      <div className="mt-2" style={{ fontSize: '0.9em', opacity: 0.8 }}>
                        {t('account.invoices.invoice.vatNumber', {
                          number: invoice.business.vatNumber,
                        })}
                      </div>
                    )}
                  </div>
                )}

                <Table className="mt-5 mb-5">
                  <thead>
                    <tr>
                      <th className="ps-0">{t('account.invoices.invoice.columns.plan')}</th>
                      <th>{t('account.invoices.invoice.columns.period')}</th>
                      <th className="pe-0 text-end">{t('account.invoices.invoice.columns.amount')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr style={{ borderBottom: '2px solid #dee2e6' }}>
                      <td className="ps-0">
                        {!invoice.units &&
                          t('account.subscription.planFull', {
                            plan: t(`account.subscription.plans.${invoice.plan}`),
                            interval: t(
                              `account.subscription.intervals.${invoice.billingInterval}`
                            ),
                          })}
                        {!!invoice.units &&
                          t('account.subscription.planFullWithUnits', {
                            plan: t(`account.subscription.plans.${invoice.plan}`),
                            units: t(`account.subscription.units.${invoice.plan}`, {
                              count: invoice.units,
                            }),
                            interval: t(
                              `account.subscription.intervals.${invoice.billingInterval}`
                            ),
                          })}
                      </td>
                      <td style={{ opacity: 0.8 }}>
                        {i18n.format([invoice.period.from, 'LL'], 'date')}
                        <span style={{ opacity: 0.4 }}> → </span>
                        {i18n.format([invoice.period.to, 'LL'], 'date')}
                      </td>
                      <td className="pe-0 text-end">
                        {i18n.format(
                          [invoice.amountPreTax, invoice.currency],
                          'price'
                        )}
                      </td>
                    </tr>
                    {!invoice.vat.reverseCharged && invoice.vat.amount > 0 && (
                      <tr>
                        <td colSpan="2" className="ps-0">
                          {t('account.invoices.invoice.vat', { rate: invoice.vat.rate })}
                        </td>
                        <td className="pe-0 text-end">
                          {i18n.format(
                            [invoice.vat.amount, invoice.currency],
                            'price'
                          )}
                        </td>
                      </tr>
                    )}
                    <tr style={{ fontWeight: 500 }}>
                      <td colSpan="2" className="ps-0" style={{ borderBottom: 0 }}>
                        {t('account.invoices.invoice.total')}
                      </td>
                      <td className="pe-0 text-end" style={{ borderBottom: 0 }}>
                        {i18n.format([invoice.amount, invoice.currency], 'price')}
                      </td>
                    </tr>
                  </tbody>
                </Table>

                {invoice.vat.reverseCharged && (
                  <div className="text-center mb-1" style={{ fontWeight: 500, fontSize: '0.8em', opacity: 0.7 }}>
                    {t('account.invoices.invoice.footer.reverseCharged')}
                  </div>
                )}

                <div className="text-center" style={{ fontSize: '0.8em', opacity: 0.5 }}>
                  {t('account.invoices.invoice.footer.currency', { code: invoice.currency })}
                </div>
              </Col>
            </Row>
          </Container>
        </>
      )}
    </>
);
};