import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';

import './Analytics.scss';

import Page from '../Page';
import TranslationsFooter from '../Components/TranslationsFooter';
import DeviceSelector from '../Components/DeviceSelector';
import AnalyticsTimeRangeSelector from './AnalyticsTimeRangeSelector';
import AnalyticsCounters from './AnalyticsCounters';
import AnalyticsQueries from './AnalyticsQueries';
import AnalyticsDomains from './AnalyticsDomains';
import AnalyticsRootDomains from './AnalyticsRootDomains';
import AnalyticsReasons from './AnalyticsReasons';
import AnalyticsIPs from './AnalyticsIPs';
import AnalyticsDevices from './AnalyticsDevices';
import AnalyticsDNSSEC from './AnalyticsDNSSEC';
import AnalyticsEncryptedDNS from './AnalyticsEncryptedDNS';
import AnalyticsTrafficDestinationMap from './AnalyticsTrafficDestinationMap';
import AnalyticsGAFAM from './AnalyticsGAFAM';

const defaultTimeRange = {
  from: '-30d',
  to: null,
};

export default function Analytics({ profile }) {
  const { t } = useTranslation();

  const [device, setDevice] = useState(null);

  const [timeRange, setTimeRange] = useState(defaultTimeRange);

  useEffect(() => {
    setDevice(null);
    setTimeRange(defaultTimeRange);
  }, [profile]);

  return (
    <Page title={t('pages.analytics')} className="Analytics" profile={profile}>
      <Row>
        <Col className="mb-4 d-flex">
          <div style={{ minWidth: 0 }}>
            <DeviceSelector profile={profile} onDeviceChange={setDevice} />
          </div>
          <div className="flex-grow-1 ms-3"></div>
          <div>
            <AnalyticsTimeRangeSelector profile={profile} onTimeRangeChange={setTimeRange} />
          </div>
        </Col>
      </Row>

      <Row>
        <AnalyticsCounters profile={profile} device={device} timeRange={timeRange} />
      </Row>
      <Row>
        <AnalyticsQueries profile={profile} device={device} timeRange={timeRange} />
      </Row>
      <Row>
        <AnalyticsDomains type="resolved" profile={profile} device={device} timeRange={timeRange} />
        <AnalyticsDomains type="blocked" profile={profile} device={device} timeRange={timeRange} />
      </Row>
      <Row>
        <AnalyticsReasons profile={profile} device={device} timeRange={timeRange} />
        <AnalyticsDevices profile={profile} device={device} timeRange={timeRange} />
      </Row>
      <Row>
        <AnalyticsIPs profile={profile} device={device} timeRange={timeRange} />
        <AnalyticsRootDomains profile={profile} device={device} timeRange={timeRange} />
      </Row>
      <Row>
        <AnalyticsGAFAM profile={profile} device={device} timeRange={timeRange} />
        <Col lg="6">
          <AnalyticsEncryptedDNS profile={profile} device={device} timeRange={timeRange} />
          <AnalyticsDNSSEC profile={profile} device={device} timeRange={timeRange} />
        </Col>
      </Row>
      <Row>
        <AnalyticsTrafficDestinationMap profile={profile} device={device} timeRange={timeRange} />
      </Row>
      <TranslationsFooter />
    </Page>
  );
}
