import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, ProgressBar, Spinner } from 'react-bootstrap';

import { get } from '../api2';

function getQueriesByValidationStatus(data, status) {
  const item = data.find((item) => item.validated === status);
  return item ? item.queries : 0;
}

export default function AnalyticsDNSSEC({ profile, device, timeRange }) {
  const { t, i18n } = useTranslation();

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);

    return get(
      `/profiles/${profile}/analytics/dnssec`,
      {
        device,
        ...timeRange,
      },
      ({ data }) => {
        setData(data);
        setLoading(false);
      }
    );
  }, [profile, device, timeRange]);

  return (
    <Card body className="mb-4">
      <h5>{t('analytics.dnssec.name')}</h5>
      <div style={{ opacity: 0.6, fontSize: '0.9em' }}>{t('analytics.dnssec.description')}</div>
      <div className="mt-3">
        {loading ? (
          <div className="text-center">
            <Spinner animation="border" style={{ width: '2rem', height: '2rem', opacity: 0.15 }} className="my-2" />
          </div>
        ) : data.reduce((acc, item) => acc + item.queries, 0) === 0 ? (
          <div className="text-center my-2" style={{ opacity: 0.4 }}>
            {t('analytics.dnssec.empty')}
          </div>
        ) : (
          <>
            <ProgressBar
              className="dnssec"
              now={Math.max(
                (getQueriesByValidationStatus(data, true) /
                  (getQueriesByValidationStatus(data, true) + getQueriesByValidationStatus(data, false))) *
                  100,
                0.5
              )}
            />
            <div style={{ color: '#4085c2', fontSize: '0.9em', fontWeight: 500, marginTop: 4 }}>
              {i18n.format(
                getQueriesByValidationStatus(data, true) /
                  (getQueriesByValidationStatus(data, true) + getQueriesByValidationStatus(data, false)),
                'percentage'
              )}
            </div>
          </>
        )}
      </div>
    </Card>
  );
}
