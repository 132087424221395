import punycode from 'punycode';

export const isMobile = !!('ontouchstart' in window || 'msMaxTouchPoints' in navigator);

export const hex = (str) => {
  const s = unescape(encodeURIComponent(str));

  let h = '';
  for (let i = 0; i < s.length; i++) {
    h += s.charCodeAt(i).toString(16);
  }

  return 'hex:' + h;
};

export const removeProtocolFromURL = (url) => {
  return url.replace(/https?:\/\//, '');
};

export const statusColors = {
  error: '#111111',
  blocked: '#ff4136',
  allowed: '#2ecc40',
  rewritten: '#000000',
  relayed: '#ae00ff',
};

export const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const unpuny = (domain) => {
  if (!domain.includes('xn--')) {
    return domain;
  }

  return punycode.toUnicode(domain);
};

export const puny = (domain) => {
  return punycode.toASCII(domain);
};

export const humanizeList = (elements, opts = {}) => {
  let s = '';

  for (const [i, element] of elements.entries()) {
    if (i === 0) {
      s += element;
    } else if (i === elements.length - 1) {
      s += opts.conjunction + element;
    } else {
      s += opts.separator + element;
    }
  }

  return s;
};

export const htmlSpaces = (count) => {
  let s = '';

  for (let i = 0; i < count; i++) {
    s += '\xa0';
  }

  return s;
};

export const expandIpv6 = (ipv6) => {
  const blocks = ipv6.split(':');

  for (let i = 0; i < blocks.length; i++) {
    if (blocks[i].length === 0) {
      blocks.splice(i, 1);
      while (blocks.length < 8) {
        blocks.splice(i, 0, '0000');
        i++;
      }
    }

    if (blocks[i].length < 4) {
      blocks[i] = '0000'.slice(0, 4 - blocks[i].length) + blocks[i];
    }
  }

  return blocks.join(':');
};

export const capitalize = (locale, [first, ...rest]) => {
  return [first.toLocaleUpperCase(locale), ...rest].join('');
};

export function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}
