import React, { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Card, ListGroup, Button, Spinner } from 'react-bootstrap';

import { post, del } from '../api2';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCopy } from '@fortawesome/free-solid-svg-icons';

export default function AccountApi({ keys: initialKeys }) {
  const { t } = useTranslation();

  const [keys, setKeys] = useState(initialKeys);
  const [isCreating, setIsCreating] = useState(false);

  const createKey = () => {
    setIsCreating(true);
    post('/account/apiKeys', ({ apiKey }) => {
      setKeys(current => [...current, { apiKey }]);
      setIsCreating(false);
    });
  };

  const removeKey = (apiKey) => {
    setKeys(current => current.filter(k => k.apiKey !== apiKey));
    del(`/account/apiKeys/${apiKey}`);
  }

  return (
    <Card>
      <ListGroup variant="flush">
        <ListGroup.Item className="py-3">
          <div className="d-flex align-items-center">
            <h5>API</h5>
          </div>
          <div style={{ opacity: 0.6, fontSize: '0.9em' }}>
            <Trans i18nKey="account.api.description">
              _Create and manage your profiles programmatically. Read the documentation
              at <a href="https://nextdns.io/api" rel="noopener noreferrer" className="notranslate">
                {{ link: "https://nextdns.io/api" }}
              </a>
              .
            </Trans>
          </div>
        </ListGroup.Item>

        {keys.map((key, index) => (
          <ListGroup.Item
            key={index}
            style={{
              fontSize: '0.9em',
              fontWeight: 500,
              boxSizing: 'border-box',
              width: '100%',
              maxWidth: '100%',
            }}
            className="p-0 d-flex align-items-center"
          >
            <div
              className="font-monospace flex-shrink-1 ms-3 no-scrollbars"
              style={{
                overflowX: 'auto',
                minWidth: 0,
                flexBasis: 'auto',
                whiteSpace: 'nowrap',
              }}>
              {key.apiKey}
            </div>
            {navigator.clipboard && (
              <Button variant="link" >
                <FontAwesomeIcon icon={faCopy} onClick={async () => {
                  if (navigator.clipboard) {
                    try {
                      await navigator.clipboard.writeText(key.apiKey)
                    } catch (e) {
                      console.error('Failed to copy to clipboard', e)
                    }
                  }
                }} />
              </Button>
            )}
            <div className="flex-grow-1"></div>
            <Button
              variant="link"
              className="me-n3 my-n1"
              style={{ opacity: 0.3, color: '#222' }}
              onClick={() => removeKey(key.apiKey)}
            >
              <FontAwesomeIcon icon={faTimes} />
            </Button>
          </ListGroup.Item>
        ))}

        <Card.Footer style={{ borderTop: 'none' }}>
          <Button
            disabled={isCreating}
            size="sm"
            style={{ fontWeight: 'bold', fontSize: 12, textTransform: 'uppercase' }}
            onClick={createKey}
          >
            {t('account.api.create.button')}
            {isCreating && <Spinner animation="border" size="sm" className="ms-2" />}
          </Button>
        </Card.Footer>
      </ListGroup>
    </Card>
  );
}
