import React, { useState, useCallback, useContext } from 'react';
import { Navigate, Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { Container, Row, Col, Card, Alert, Form, Button } from 'react-bootstrap';
import axios from 'axios';
import { useQuery } from '../hooks';
import { useTheme } from '../theme';

import { isMobile } from '../helpers';

import { AccountContext } from '../contexts';

import { API_BASE_URL } from '../config';

import logoLarge from '../images/logo-large@2x.png';
import logoLargeDark from '../images/logo-large-dark@2x.png';

export default function SignUp() {
  const { t } = useTranslation();
  const theme = useTheme();

  const account = useContext(AccountContext);

  const query = useQuery();

  const [email, setEmail] = useState({
    value: '',
    error: null,
  });

  const [password, setPassword] = useState({
    value: '',
    error: null,
  });

  const onEmailChange = useCallback((event) => {
    const value = event.target.value.trim();

    setEmail((email) => {
      return { ...email, value: value, error: null };
    });
  }, []);

  const onPasswordChange = useCallback((event) => {
    const value = event.target.value;

    setPassword((password) => {
      return { ...password, value: value, error: null };
    });
  }, []);

  const signUp = useCallback(
    async (event) => {
      event.preventDefault();

      const { data } = await axios.post(`${API_BASE_URL}/accounts`, {
        email: email.value,
        password: password.value,
        profile: {
          name: t('global.firstConfiguration'),
          security: {
            threatIntelligenceFeeds: true,
            googleSafeBrowsing: true,
            cryptojacking: true,
            idnHomographs: true,
            typosquatting: true,
            dga: true,
            csam: true,
          },
          privacy: {
            blocklists: [
              {
                id: 'nextdns-recommended',
              },
            ],
            disguisedTrackers: true,
          },
          settings: {
            logs: {
              enabled: true,
            },
            performance: {
              ecs: true,
            },
          },
        },
      });

      if (data.errors) {
        if (data.errors.email) {
          setEmail((email) => ({ ...email, error: data.errors.email }));
        }

        if (data.errors.password) {
          setPassword((password) => ({ ...password, error: data.errors.password }));
        }

        return;
      }

      if (query.get('next')) {
        window.location.href = window.location.protocol + '//' + window.location.host + query.get('next');
        return;
      }

      await account.load();
    },
    [account, email.value, password.value, t, query]
  );

  if (account.account && account.account.email) {
    return <Navigate to="/" replace />;
  }

  const loginpath = query.has('next') ? '/login?next=' + encodeURIComponent(query.get('next')) : '/login';

  return (
    <>
      <Helmet>
        <title>{t('pages.signup')}</title>
      </Helmet>

      <Container style={{ marginTop: '5vw' }}>
        <Row className="justify-content-center">
          <Col sm="9" md="7" lg="5" xl="4">
            <div className="mt-2 mb-4 text-center">
              <img
                className="d-inline"
                src={theme === 'dark' ? logoLargeDark : logoLarge}
                style={{ width: 200, marginRight: 20 }}
                alt=""
              />
            </div>

            <Card>
              <Card.Body>
                {account.account && !account.account.email && (
                  <Alert variant="primary" style={{ fontSize: '0.8em' }}>
                    {t('account.convertingNotice')}
                  </Alert>
                )}
                <Form onSubmit={signUp} action="#submit" noValidate className="d-grid gap-3">
                  <Form.Group>
                    <Form.Label>{t('account.email')}</Form.Label>
                    <Form.Control
                      type="email"
                      autoFocus={!isMobile}
                      autoCorrect="off"
                      autoCapitalize="none"
                      autoComplete="username"
                      isInvalid={!!email.error}
                      value={email.value}
                      onChange={onEmailChange}
                    />
                    {!!email.error && (
                      <Form.Control.Feedback type="invalid">
                        {t(`account.errors.email.${email.error}`)}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>{t('account.password')}</Form.Label>
                    <Form.Control
                      type="password"
                      autoComplete="new-password"
                      isInvalid={!!password.error}
                      value={password.value}
                      onChange={onPasswordChange}
                    />
                    {!!password.error && (
                      <Form.Control.Feedback type="invalid">
                        {t(`account.errors.password.${password.error}`)}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>

                  <Button className="mt-2" type="submit" size="lg">
                    {t('account.signup')}
                  </Button>
                </Form>
              </Card.Body>
            </Card>

            <div className="mt-3 mb-4 text-center">
              <Trans i18nKey="account.alreadyHaveAnAccount">
                _Already have an account? <Link to={account ? '/logout' : loginpath}>Log in</Link>
              </Trans>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
