import React, { useMemo, useContext } from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import classnames from 'classnames';

import { AccountContext } from './contexts';

export default function Page({ profile, title, className, children }) {
  const account = useContext(AccountContext);

  const fullTitle = useMemo(() => {
    let fullProfile = profile ? account.profiles.find((item) => item.id === profile) : null;
    return [title, fullProfile ? fullProfile.name : null].filter((x) => x).join(' - ');
  }, [account, profile, title]);

  return (
    <div className={classnames('mt-4', className)} style={{ flexGrow: 1 }}>
      <Helmet>
        <title> {fullTitle} </title>
      </Helmet>
      <Container>{children}</Container>
    </div>
  );
}
