import React, { useState } from 'react';
import { hex, classNames } from '../helpers';
import { useTheme } from '../theme';
import defaultFaviconLight from '../images/default-favicon.svg';
import defaultFaviconDark from '../images/default-favicon-dark.svg';
export default function Favicon({domain, url, width, height, size, alt, className, ...attributes}) {
  const theme = useTheme();
  if (url) {
    domain = url.replace(/^https?:\/\/|\/.*$/, '');
  }

  const pixelDensity = Math.min(Math.ceil(window.devicePixelRatio || 1), 3);
  const defaultFavicon = theme === 'dark' ? defaultFaviconDark : defaultFaviconLight;

  let favicon;
  if (domain) {
    favicon = `https://favicons.nextdns.io/${hex(domain)}@${pixelDensity}x.png`;
  } else {
    favicon = defaultFavicon;
  }

  const [fallbackFavicon, setFallbackFavicon] = useState(null);

  const onError = () => {
    setFallbackFavicon(defaultFavicon);
  };

  if (process.env.REACT_APP_TAILWIND) {
    if (fallbackFavicon) {
      return (
        <img
          key="fallback"
          {...attributes}
          className={classNames('tw-inline dark:tw-invert', className)}
          src={fallbackFavicon}
          alt=""
          style={{ width: size || 16, height: size || 16 }}
        />
      );
    }

    return (
      <img
        key={favicon}
        {...attributes}
        src={favicon}
        alt=""
        style={{ width: size || 16, height: size || 16 }}
        className={classNames('tw-inline', className)}
        onError={onError}
      />
    );
  }

  if (fallbackFavicon) {
    return (
      <img
        key="fallback"
        {...attributes}
        className={classNames(className, 'd-inline-block')}
        src={fallbackFavicon}
        alt=""
        style={{ width: size || 16, height: size || 16 }}
      />
    );
  }

  return (
    <img
      key={favicon}
      {...attributes}
      src={favicon}
      alt=""
      style={{ width: size || 16, height: size || 16 }}
      className={classNames(className, 'd-inline-block')}
      onError={onError}
    />
  );
}
