import React, { useState, useCallback, useRef } from 'react';
import { Form } from 'react-bootstrap';

import { SearchIcon } from '@heroicons/react/solid';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

export default function LogsSearch({ onSearch }) {
  const input = useRef();

  const [value, setValue] = useState('');

  const onChange = useCallback((event) => {
    const value = event.target.value
      .trim()
      .toLowerCase()
      .replace(/[^a-z0-9._-]+/g, '');
    setValue(value);
  }, []);

  const onSubmit = useCallback(
    (event) => {
      event.preventDefault();

      input.current.blur();

      onSearch(value || null);
    },
    [value, onSearch]
  );

  const onClear = useCallback(
    (event) => {
      event.preventDefault();

      input.current.blur();

      setValue('');
      onSearch(null);
    },
    [onSearch]
  );

  if (process.env.REACT_APP_TAILWIND) {
    return (
      <form action="#submit" noValidate className="tw-relative" onSubmit={onSubmit}>
        <input
          ref={input}
          type="search"
          spellCheck="false"
          autoCorrect="off"
          autoComplete="off"
          autoCapitalize="off"
          className="mouse:hover:tw-bg-slate-800/60 focus:tw-border-nextdnsblue tw-block tw-w-full tw-rounded-full tw-border tw-border-slate-800/50 tw-bg-slate-800/50 tw-p-2.5 tw-ps-9 tw-font-mono tw-text-[13px] tw-text-slate-200 focus:tw-outline-none"
          value={value}
          onChange={onChange}
        />
        <div className="tw-absolute tw-inset-y-0 tw-left-0 tw-top-0 tw-bottom-0 tw-flex tw-items-center tw-ps-3 tw-text-slate-600">
          <SearchIcon className="tw-h-4 tw-w-4" />
        </div>
      </form>
    );
  }

  return (
    <div className="flex-grow-1" style={{ position: 'relative' }}>
      <FontAwesomeIcon
        icon={faSearch}
        style={{ position: 'absolute', top: 9, left: 9, fontSize: '0.8em', opacity: 0.2 }}
      />
      {!!value && (
        <a
          href="#clear"
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            fontSize: '0.8em',
            opacity: 0.3,
            lineHeight: 0,
            color: 'black',
          }}
          onClick={onClear}
        >
          <FontAwesomeIcon icon={faTimesCircle} style={{ margin: 9 }} />
        </a>
      )}
      <Form onSubmit={onSubmit} action="#submit" noValidate>
        <Form.Control
          type="search"
          ref={input}
          spellCheck="false"
          autoCorrect="off"
          autoComplete="off"
          autoCapitalize="off"
          size="sm"
          style={{ borderRadius: 16, paddingLeft: 30 }}
          maxLength="255"
          value={value}
          onChange={onChange}
        />
      </Form>
    </div>
  );
}
