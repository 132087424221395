import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { post } from './api2';

export default function ProfileInvite({ match }) {
  const { token } = useParams();
  useEffect(() => {
    post('/accounts/@me/invites', { token }, ({ data }) => {
      if (data.profile) {
        window.location.href = `https://my.nextdns.io/${data.profile}`;
      } else {
        window.location.href = `https://my.nextdns.io`;
      }
    });
  }, [token]);

  return null;
}
