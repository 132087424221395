import React, { useState, useCallback, useEffect, useContext } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Row, Col, Card, ListGroup, Form } from 'react-bootstrap';
import { get, patch } from '../api2';
import { useTheme } from '../theme';

import merge from 'deepmerge';

import RootCA from '../images/root-ca.svg';

import EnsLogoImage from '../images/web3/ens-image.svg';
import EnsLogoTextLight from '../images/web3/ens-text.svg';
import EnsLogoTextDark from '../images/web3/ens-text-dark.svg';
import UnstoppableDomainsLogoLight from '../images/web3/unstoppabledomains.svg';
import UnstoppableDomainsLogoDark from '../images/web3/unstoppabledomains-dark.svg';
import HandshakeLogoLight from '../images/web3/handshake.svg';
import HandshakeLogoDark from '../images/web3/handshake-dark.svg';
import IpfsLogoLight from '../images/web3/ipfs.svg';
import IpfsLogoDark from '../images/web3/ipfs-dark.svg';

import { AccountContext } from '../contexts';

import Page from '../Page';
import TranslationsFooter from '../Components/TranslationsFooter';
import SettingsName from './SettingsName';
import SettingsLogsLocationDropdown from './SettingsLogsLocationDropdown';
import SettingsDownloadLogsButton from './SettingsDownloadLogsButton';
import SettingsClearLogsButton from './SettingsClearLogsButton';
import SettingsRewrites from './SettingsRewrites';
import SettingsAccess from './SettingsAccess';
import SettingsDuplicateProfile from './SettingsDuplicateProfile';
import SettingsDeleteProfile from './SettingsDeleteProfile';
import SettingsLeaveProfile from './SettingsLeaveProfile';

const RETENTIONS = {
  '1h': 3600,
  '6h': 3600 * 6,
  '1d': 3600 * 24,
  '1w': 3600 * 24 * 7,
  '1m': 3600 * 24 * 30,
  '3m': 3600 * 24 * 30 * 3,
  '6m': 3600 * 24 * 30 * 6,
  '1y': 3600 * 24 * 365,
  '2y': 3600 * 24 * 365 * 2,
};

export default function Settings({ profile, role }) {
  const { t } = useTranslation();
  const theme = useTheme();

  const EnsLogoText = theme === 'light' ? EnsLogoTextLight : EnsLogoTextDark;
  const UnstoppableDomainsLogo = theme === 'light' ? UnstoppableDomainsLogoLight : UnstoppableDomainsLogoDark;
  const HandshakeLogo = theme === 'light' ? HandshakeLogoLight : HandshakeLogoDark;
  const account = useContext(AccountContext);
  const IpfsLogo = theme === 'light' ? IpfsLogoLight : IpfsLogoDark;

  const [state, setState] = useState();

  useEffect(() => {
    setState();

    return get(`/profiles/${profile}/settings`, ({ data }) => {
      setState(data);
    });
  }, [profile]);

  const update = useCallback(
    (data) => {
      setState((state) => {
        return merge(state, data);
      });

      patch(`/profiles/${profile}/settings`, data);
    },
    [profile]
  );

  return (
    <Page title={t('pages.settings')} profile={profile}>
      {!!state && (
        <>
          <Row className="mb-3">
            <Col>
              <SettingsName profile={profile} role={role} />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Card>
                <ListGroup variant="flush">
                  <ListGroup.Item className="py-3">
                    <h5>{t('settings.logs.name')}</h5>
                    <div style={{ opacity: 0.6, fontSize: '0.9em' }}>{t('settings.logs.description')}</div>
                    <div className="d-flex mt-3">
                      <div className="d-flex align-items-center">
                        <Form.Switch
                          id="settings-logging"
                          label=""
                          checked={state.logs.enabled}
                          onChange={(event) =>
                            update({
                              logs: {
                                enabled: event.target.checked,
                              },
                            })
                          }
                          disabled={role === 'viewer'}
                        />
                      </div>
                      <div className="flex-grow-1 d-flex align-items-center">
                        <div style={{ fontSize: '0.9em', opacity: 0.9 }}>{t('settings.logs.enable')}</div>
                      </div>
                    </div>
                  </ListGroup.Item>
                  {state.logs.enabled && (
                    <>
                      <ListGroup.Item className="py-3">
                        <h6>{t('settings.logs.privacy.name')}</h6>
                        <div className="d-flex mt-1">
                          <div className="d-flex align-items-center">
                            <Form.Switch
                              id="settings-loggingdisableclient"
                              label=""
                              checked={!state.logs.drop.ip}
                              onChange={(event) =>
                                update({
                                  logs: {
                                    drop: {
                                      ip: !event.target.checked,
                                    },
                                  },
                                })
                              }
                              disabled={role === 'viewer'}
                            />
                          </div>
                          <div className="flex-grow-1 d-flex align-items-center">
                            <div style={{ fontSize: '0.9em', opacity: 0.9 }}>{t('settings.logs.privacy.ip')}</div>
                          </div>
                        </div>
                        <div className="d-flex mt-1">
                          <div className="d-flex align-items-center">
                            <Form.Switch
                              id="settings-loggingdisablequery"
                              label=""
                              checked={!state.logs.drop.domain}
                              onChange={(event) =>
                                update({
                                  logs: {
                                    drop: {
                                      domain: !event.target.checked,
                                    },
                                  },
                                })
                              }
                              disabled={role === 'viewer'}
                            />
                          </div>
                          <div className="flex-grow-1 d-flex align-items-center">
                            <div style={{ fontSize: '0.9em', opacity: 0.9 }}>{t('settings.logs.privacy.domains')}</div>
                          </div>
                        </div>
                      </ListGroup.Item>
                      <ListGroup.Item className="py-3">
                        <h6>{t('settings.logs.retention.name')}</h6>
                        <Form.Group className="mb-0" controlId="settings_loggingretention">
                          <Form.Select
                            size="sm"
                            style={{ width: 'auto' }}
                            value={state.logs.retention}
                            onChange={(event) =>
                              update({
                                logs: {
                                  retention: parseInt(event.target.value),
                                },
                              })
                            }
                            disabled={role === 'viewer'}
                          >
                            {Object.keys(RETENTIONS).map((retention) => (
                              <option key={retention} value={RETENTIONS[retention]}>
                                {t(`settings.logs.retention.options.${retention}`)}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                      </ListGroup.Item>
                      <ListGroup.Item className="py-3">
                        <h6>{t('settings.logs.location.name')}</h6>
                        <SettingsLogsLocationDropdown profile={profile} role={role} location={state.logs.location} />
                      </ListGroup.Item>
                    </>
                  )}
                  <ListGroup.Item className="py-3">
                    <div className="d-flex gap-3">
                      <SettingsDownloadLogsButton profile={profile} />
                      <SettingsClearLogsButton profile={profile} role={role} />
                    </div>
                  </ListGroup.Item>
                </ListGroup>
              </Card>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Card>
                <ListGroup variant="flush">
                  <ListGroup.Item className="py-3">
                    <h5>{t('settings.blockpage.name')}</h5>
                    <div style={{ opacity: 0.6, fontSize: '0.9em' }}>{t('settings.blockpage.description')}</div>
                    <div className="d-flex mt-3">
                      <div className="d-flex align-items-center">
                        <Form.Switch
                          id="settings-blockPage"
                          label=""
                          checked={state.blockPage.enabled}
                          onChange={(event) =>
                            update({
                              blockPage: {
                                enabled: event.target.checked,
                              },
                            })
                          }
                          disabled={role === 'viewer'}
                        />
                      </div>
                      <div className="flex-grow-1 d-flex align-items-center">
                        <div style={{ fontSize: '0.9em', opacity: 0.9 }}>{t('settings.blockpage.enable')}</div>
                      </div>
                    </div>
                  </ListGroup.Item>
                  {state.blockPage.enabled && (
                    <ListGroup.Item className="py-3">
                      <h6 className="d-flex align-items-center" style={{ marginBottom: 7 }}>
                        <img
                          src={RootCA}
                          alt=""
                          className="d-inline-block me-1"
                          style={{ height: 14, marginTop: -1 }}
                        />
                        <span>{t('settings.blockpage.ca.name')}</span>
                      </h6>
                      <div style={{ opacity: 0.6, fontSize: '0.85em' }}>
                        <Trans i18nKey="settings.blockpage.ca.description">
                          _Remove the HTTPS warning when loading the block page by installing and trusting our root CA
                          at
                          <a className="notranslate" href="https://nextdns.io/ca">
                            {{ link: 'https://nextdns.io/ca' }}
                          </a>
                          . Read instructions on how to do this
                          <a
                            href="https://help.nextdns.io/t/g9hmv0a/how-to-install-and-trust-nextdns-root-ca"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            here
                          </a>
                          .
                        </Trans>
                      </div>
                    </ListGroup.Item>
                  )}
                </ListGroup>
              </Card>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Card>
                <ListGroup variant="flush">
                  <ListGroup.Item className="py-3">
                    <h5>{t('settings.performance.name')}</h5>
                    <div style={{ opacity: 0.6, fontSize: '0.9em' }}>{t('settings.performance.description')}</div>
                  </ListGroup.Item>
                  <ListGroup.Item className="py-3">
                    <h6 style={{ marginBottom: 5 }}>{t('settings.performance.ecs.name')}</h6>
                    <div style={{ opacity: 0.6, fontSize: '0.85em' }}>{t('settings.performance.ecs.description')}</div>
                    <div className="d-flex" style={{ marginTop: 7 }}>
                      <div className="d-flex align-items-center">
                        <Form.Switch
                          id="settings-ecs"
                          label=""
                          checked={state.performance.ecs}
                          onChange={(event) =>
                            update({
                              performance: {
                                ecs: event.target.checked,
                              },
                            })
                          }
                          disabled={role === 'viewer'}
                        />
                      </div>
                      <div className="flex-grow-1 d-flex align-items-center">
                        <div style={{ fontSize: '0.9em', opacity: 0.9 }}>{t('settings.performance.ecs.enable')}</div>
                      </div>
                    </div>
                  </ListGroup.Item>
                  <ListGroup.Item className="py-3">
                    <h6 style={{ marginBottom: 5 }}>{t('settings.performance.cache.name')}</h6>
                    <div style={{ opacity: 0.6, fontSize: '0.85em' }}>
                      {t('settings.performance.cache.description')}
                    </div>
                    <div className="d-flex" style={{ marginTop: 7 }}>
                      <div className="d-flex align-items-center">
                        <Form.Switch
                          id="settings-cacheBoost"
                          label=""
                          checked={state.performance.cacheBoost}
                          onChange={(event) =>
                            update({
                              performance: {
                                cacheBoost: event.target.checked,
                              },
                            })
                          }
                          disabled={role === 'viewer'}
                        />
                      </div>
                      <div className="flex-grow-1 d-flex align-items-center">
                        <div style={{ fontSize: '0.9em', opacity: 0.9 }}>{t('settings.performance.cache.enable')}</div>
                      </div>
                    </div>
                  </ListGroup.Item>
                  <ListGroup.Item className="py-3">
                    <h6 style={{ marginBottom: 5 }}>{t('settings.performance.flatten.name')}</h6>
                    <div style={{ opacity: 0.6, fontSize: '0.85em' }}>
                      {t('settings.performance.flatten.description')}
                    </div>
                    <div className="d-flex" style={{ marginTop: 7 }}>
                      <div className="d-flex align-items-center">
                        <Form.Switch
                          id="settings-cnameFlattening"
                          label=""
                          checked={state.performance.cnameFlattening}
                          onChange={(event) =>
                            update({
                              performance: {
                                cnameFlattening: event.target.checked,
                              },
                            })
                          }
                          disabled={role === 'viewer'}
                        />
                      </div>
                      <div className="flex-grow-1 d-flex align-items-center">
                        <div style={{ fontSize: '0.9em', opacity: 0.9 }}>
                          {t('settings.performance.flatten.enable')}
                        </div>
                      </div>
                    </div>
                  </ListGroup.Item>
                </ListGroup>
              </Card>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Card>
                <Card.Body>
                  <div className="d-flex align-items-center">
                    <h5>{t('settings.web3.name')}</h5>
                    <div className='beta-badge'>
                      {t('global.beta')}
                    </div>
                  </div>
                  <div style={{ opacity: 0.6, fontSize: '0.9em' }}>
                    <Trans i18nKey="settings.web3.description">
                      _Web3 refers to a decentralized and censorship-resistant online ecosystem comprised of innovative
                      technologies such as blockchain-based domain registries (e.g. Ethereum Name Service) and
                      distributed content storage and delivery networks (e.g. IPFS). When enabled, NextDNS will act as
                      an unfiltered gateway to this new Web, letting you experience it firsthand without the need to
                      install anything.
                    </Trans>
                  </div>
                  <div className="mt-2" style={{ opacity: 0.4, fontSize: '0.9em' }}>
                    <Trans i18nKey="settings.web3.tips">
                      _As most browsers only support traditional top-level domains at the moment, you should add a
                      trailing slash ("<span className="notranslate">/</span>") when trying to access a Web3 domain
                      directly (e.g. "<span className="notranslate">vitalik.eth/</span>" instead of "
                      <span className="notranslate">vitalik.eth</span>").
                    </Trans>
                  </div>
                  <div className="d-flex align-items-center flex-wrap" style={{ marginBottom: 4 }}>
                    <a
                      href="https://ens.domains"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ marginRight: 30, marginTop: 20 }}
                    >
                      <img
                        src={EnsLogoImage}
                        alt="ENS"
                        className="d-inline-block"
                        style={{ height: 26, marginRight: 9 }}
                      />
                      <img src={EnsLogoText} alt="ENS" className="d-inline" style={{ height: 12 }} />
                    </a>
                    <a
                      href="https://unstoppabledomains.com"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ marginRight: 30, marginTop: 20 }}
                    >
                      <img
                        src={UnstoppableDomainsLogo}
                        alt="Unstoppable Domains"
                        className="d-inline-block"
                        style={{ height: 26 }}
                      />
                    </a>
                    <a
                      href="https://handshake.org"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ marginRight: 30, marginTop: 20 }}
                    >
                      <img src={HandshakeLogo} alt="Handshake" className="d-inline" style={{ height: 26 }} />
                    </a>
                    <a href="https://ipfs.io" target="_blank" rel="noopener noreferrer" style={{ marginTop: 20 }}>
                      <img src={IpfsLogo} alt="IPFS" className="d-inline" style={{ height: 26 }} />
                    </a>
                  </div>
                </Card.Body>
                <Card.Footer className="text-muted">
                  <div className="d-flex">
                    <div className="me-1">
                      <Form.Switch
                        id="settings-web3"
                        label=""
                        checked={state.web3}
                        onChange={(event) => update({ web3: event.target.checked })}
                        disabled={role === 'viewer'}
                      />
                    </div>
                    <div className="flex-grow-1 d-flex align-items-center" style={{ fontSize: '0.9em' }}>
                      <div>{t('settings.web3.enable')}</div>
                    </div>
                  </div>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <SettingsRewrites profile={profile} role={role} />
            </Col>
          </Row>
          {role === 'owner' && (
            <Row className="mb-3">
              <Col>
                <SettingsAccess profile={profile} />
              </Col>
            </Row>
          )}
          <Row className="mb-3">
            <Col>
              <SettingsDuplicateProfile profile={profile} name={account.getProfileName(profile)} />
            </Col>
          </Row>
          <Row>
            <Col>
              {role === 'owner' ? (
                <SettingsDeleteProfile profile={profile} name={account.getProfileName(profile)} />
              ) : (
                <SettingsLeaveProfile profile={profile} name={account.getProfileName(profile)} />
              )}
            </Col>
          </Row>
          <TranslationsFooter />
        </>
      )}
    </Page>
  );
}
