import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Row, Col, Modal, Form, Button, Nav, Alert, Spinner } from 'react-bootstrap';
import {
  Elements,
  ElementsConsumer,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import axios from 'axios';
import classnames from 'classnames';

import { API_BASE_URL, STRIPE_PUBLISHABLE_KEY } from '../config';

import { isMobile } from '../helpers';
import { useTheme } from '../theme';

import Visa from '../images/payments/visa.svg';
import Mastercard from '../images/payments/mastercard.svg';
import Amex from '../images/payments/amex.svg';
import UnionPay from '../images/payments/unionpay.svg';
import UnknownCard from '../images/payments/unknown.svg';

import Lock from '../images/payments/secure.svg';

import Paypal from '../images/payments/paypal.svg';
import PaypalDark from '../images/payments/paypal-dark.svg';
import PaypalIcon from '../images/payments/paypal-icon.svg';

import Bitcoin from '../images/payments/bitcoin.svg';
import Ethereum from '../images/payments/ethereum.svg';
import Ripple from '../images/payments/ripple.svg';

import PaypalWhite from '../images/payments/paypal-white.svg';
import Bitpay from '../images/payments/bitpay.svg';

import './AccountSubscriptionModal.scss';
import { useLocation } from 'react-router-dom';

const stripeStyleLight = {
  base: {
    iconColor: '#666ee8',
    color: '#495057',
    lineHeight: '24px',
    fontWeight: 400,
    fontFamily:
      '-apple-system, system-ui, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
    fontSize: '16px',
    fontSmoothing: 'antialiased',

    '::placeholder': {
      color: '#CFD7E0',
    },
  },
};

const stripeStyleDark = {
  base: {
    ...stripeStyleLight.base,
    color: '#dee2e6',
    '::placeholder': {
      color: '#495057',
    },
  },
};


export default function AccountSubscriptionModal({ prices, subscription, action, onSubscriptionUpdated, onCloseModal }) {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const stripeStyle = useMemo(() => (theme === 'dark' ? stripeStyleDark : stripeStyleLight), [theme]);

  const stripePromiseRef = useRef();
  const isClosableRef = useRef(true);

  const location = useLocation();
  // eslint-disable-next-line no-unused-vars
  const [_, plan, billingInterval] = location.hash.split('/');

  const [state, setState] = useState({
    stripeLoaded: false,

    showModal: false,

    action: null,
    step: null,

    plan,
    units: null,
    billingInterval,

    unitsValue: '',

    paymentMethodType: 'card',

    cardNumberError: null,
    cardExpiryError: null,
    cardCvcError: null,
    savingCard: false,

    continuingToPaypal: false,

    continuingToBitpay: false,
  });

  const openModal = useCallback(() => {
    if (!stripePromiseRef.current) {
      import('@stripe/stripe-js').then((stripe) => {
        stripePromiseRef.current = stripe.loadStripe(STRIPE_PUBLISHABLE_KEY, {
          locale: i18n.language, // https://stripe.com/docs/js/appendix/supported_locales
        });
        setState(oldState => ({ ...oldState, stripeLoaded: true }));
      });
    }

    setState(oldState => ({ ...oldState, showModal: true }));
  }, [i18n.language]);

  useEffect(() => {
    setState(oldState => ({ ...oldState, action }));
    if (action) {
      openModal();
    }
  }, [action, openModal]);

  const closeModal = () => {
    if (!isClosableRef.current) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      showModal: false,

      action: null,
      step: null,

      plan: null,
      units: null,
      billingInterval: null,

      unitsValue: '',

      paymentMethodType: 'card',

      cardNumberError: null,
      cardExpiryError: null,
      cardCvcError: null,
      savingCard: false,

      continuingToPaypal: false,

      continuingToBitpay: false,
    }));

    onCloseModal();
  };

  const back = () => {
    setState(prevState => {
      const newState = { ...prevState };

      if (prevState.step === 'add-payment-method') {
        if (prevState.plan === 'pro') {
          newState.step = null;
        } else {
          newState.step = 'set-billing-interval';
        }
      } else if (prevState.step === 'set-billing-interval') {
        newState.billingInterval = null;
        newState.step = 'set-units';
      } else {
        newState.unitsValue = '';
        newState.units = null;
        newState.step = null;
      }

      return newState;
    });
  };

  const selectPlan = (plan, billingInterval) => {
    setState(prevState => ({ ...prevState, plan, billingInterval }));
  };

  const onUnitsValueChange = (event) => {
    const units = event.target.value.trim().replace(/[^0-9]+/g, '');
    setState(prevState => ({ ...prevState, unitsValue: units }));
  };

  const continueWithPlan = () => {
    if (
      state.action === 'change-plan' &&
      (state.plan === 'pro' || state.step === 'set-billing-interval')
    ) {
      changePlan();
      return;
    }

    setState(prevState => {
      const newState = { ...prevState };

      if (prevState.plan === 'pro' || prevState.step === 'set-billing-interval') {
        newState.step = 'add-payment-method';
      } else if (prevState.step === 'set-units') {
        newState.units = parseInt(prevState.unitsValue);
        newState.step = 'set-billing-interval';
      } else {
        newState.step = 'set-units';
      }

      return newState;
    });
  };

  const changePlan = async () => {
    openModal();

    const body = {
      plan: state.plan,
      units: state.units,
      billingInterval: state.billingInterval,
    };

    await axios.post(`${API_BASE_URL}/subscription/change-plan`, body);

    onSubscriptionUpdated();
    closeModal();
  };

  const changePaymentMethodType = (event, type) => {
    event.preventDefault();

    setState(prevState => ({
      ...prevState,
      paymentMethodType: type,
      savingCard: false,
      cardNumberError: null,
      cardExpiryError: null,
      cardCvcError: null,
    }));
  };

  const onCardFieldChange = (event) => {
    setState(prevState => ({
      ...prevState,
      [`${event.elementType}Error`]: event.error ? event.error.message : null,
    }));
  };

  const onStripeSubmit = async (event, stripe, elements) => {
    event.preventDefault();

    isClosableRef.current = false;

    setState(prevState => ({ ...prevState, savingCard: true }));

    const { data } = await axios.get(`${API_BASE_URL}/subscription/stripe/intent`);

    const result = await stripe.confirmCardSetup(data.clientSecret, {
      payment_method: {
        card: elements.getElement(CardNumberElement),
      },
    });

    if (result.error) {
      isClosableRef.current = true;

      setState(prevState => ({ ...prevState, savingCard: false }));

      if (result.error.type !== 'validation_error') {
        setState(prevState => ({
          ...prevState,
          cardNumberError: result.error.message,
        }));
      }
    } else {
      const body = {
        paymentMethod: result.setupIntent.payment_method,
        currency: prices.currency,
      };

      if (state.plan) {
        body.plan = state.plan;
        body.units = state.units;
        body.billingInterval = state.billingInterval;
      }

      await axios.post(`${API_BASE_URL}/subscription/stripe/finalize`, body);

      isClosableRef.current = true;

      closeModal();

      onSubscriptionUpdated();
    }
  };

  const continueToPaypal = async () => {
    isClosableRef.current = false;

    setState(prevState => ({ ...prevState, continuingToPaypal: true }));

    const body = {
      currency: prices.currency,
    };

    if (state.plan) {
      body.plan = state.plan;
      body.units = state.units;
      body.billingInterval = state.billingInterval;
    }

    const { data } = await axios.post(`${API_BASE_URL}/subscription/paypal`, body);

    window.location.href = data.redirectURL;
  };

  const continueToBitpay = async () => {
    isClosableRef.current = false;

    setState(prevState => ({
      ...prevState,
      continuingToBitpay: true,
    }));

    const body = {
      currency: prices.currency,
      billingInterval: 'year',
    };

    if (subscription) {
      body.plan = subscription.plan;
      body.units = subscription.units;
    }

    if (state.plan) {
      body.plan = state.plan;
      body.units = state.units;
      body.subscribe = true;
    }

    const { data } = await axios.post(`${API_BASE_URL}/subscription/bitpay`, body);

    window.location.href = data.redirectURL;
  };

  const getPrice = (plan, units, billingInterval) => {
    const trancheSize = plan === 'education' ? 250 : 50;

    const tranches = Math.max(1, Math.ceil(units / trancheSize));

    return prices.plans[plan][billingInterval] * tranches;
  };

  return (
    <Modal
      show={state.showModal}
      animation={false}
      scrollable={(state.action === 'subscribe' && !state.step) || state.action === 'change-plan'}
      onHide={closeModal}
    >
      {((state.action === 'subscribe' && state.step !== 'add-payment-method') ||
        state.action === 'change-plan') && (
          <>
            <Modal.Header closeButton>
              <Modal.Title as="h5">
                {state.action === 'change-plan'
                  ? t('account.subscription.changePlan.button')
                  : t('account.subscription.subscribe.choosePlan')}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {!state.step && (
                <>
                  <div className="mb-3 p-3 text-center" style={{ opacity: 0.8 }}>
                    <Trans i18nKey="account.subscription.subscribe.included">
                      _All plans include <span style={{ fontWeight: 500 }}>access to all features</span>,
                      <span style={{ fontWeight: 500 }}>unlimited devices</span> and
                      <span style={{ fontWeight: 500 }}>unlimited queries</span>.
                    </Trans>
                  </div>

                  <div
                    className={classnames('p-3 mb-2 planCard d-flex', {
                      selected: state.plan === 'pro' && state.billingInterval === 'month',
                    })}
                    onClick={() => selectPlan('pro', 'month')}
                  >
                    <div className="flex-grow-1">
                      <div style={{ fontSize: '1.3em', lineHeight: '1.1em', fontWeight: 500 }}>
                        {t('account.subscription.plans.pro')}
                      </div>
                      <div style={{ opacity: 0.5, marginTop: 3, textTransform: 'capitalize' }}>
                        {t('account.subscription.intervals.month')}
                      </div>
                    </div>
                    <div className="text-end">
                      <div style={{ fontSize: '1.3em', lineHeight: '1.35em', fontWeight: 600, color: '#007bff' }}>
                        {i18n.format(
                          [prices.plans.pro.month, prices.currency],
                          'price'
                        )}
                      </div>
                      <div style={{ fontSize: '0.85em', opacity: 0.5 }}>
                        {t('account.subscription.per.month')}
                      </div>
                    </div>
                  </div>

                  <div
                    className={classnames('p-3 planCard', {
                      selected: state.plan === 'pro' && state.billingInterval === 'year',
                    })}
                    onClick={() => selectPlan('pro', 'year')}
                  >
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <div style={{ fontSize: '1.3em', lineHeight: '1.1em', fontWeight: 500 }}>
                          {t('account.subscription.plans.pro')}
                        </div>
                        <div style={{ opacity: 0.5, marginTop: 3, textTransform: 'capitalize' }}>
                          {t('account.subscription.intervals.year')}
                        </div>
                      </div>
                      <div className="text-end">
                        <div style={{ fontSize: '1.3em', lineHeight: '1.35em', fontWeight: 600, color: '#007bff' }}>
                          {i18n.format(
                            [prices.plans.pro.year, prices.currency],
                            'price'
                          )}
                        </div>
                        <div style={{ fontSize: '0.85em', opacity: 0.5 }}>
                          {t('account.subscription.per.year')}
                        </div>
                      </div>
                    </div>
                    <div
                      className="mt-2 d-inline-block"
                      style={{
                        fontSize: 12,
                        color: 'green',
                        border: '1px solid green',
                        borderRadius: 4,
                        padding: '2px 5px',
                      }}
                    >
                      {t('account.subscription.subscribe.savings', { percent: 0.17 })}
                    </div>
                  </div>

                  <div className="p-2" style={{ fontSize: '0.8em', opacity: 0.6 }}>
                    {t('account.subscription.subscribe.familyOnly')}
                  </div>

                  <div
                    className={classnames('mt-3 p-3 mb-2 planCard', { selected: state.plan === 'business' })}
                    onClick={() => selectPlan('business')}
                  >
                    <div style={{ fontSize: '1.1em', lineHeight: '1.1em', fontWeight: 500 }}>
                      {t('account.subscription.plans.business')}
                    </div>
                    <div style={{ fontSize: '0.85em', marginTop: 8 }}>
                      <Trans i18nKey="account.subscription.subscribe.starting.business">
                        <span style={{ opacity: 0.5 }}>_Starting at </span>
                        <span style={{ fontWeight: 600, color: '#007bff' }}>
                          {{ price: [prices.plans.business.month, prices.currency] }}
                        </span>
                        <span style={{ opacity: 0.5 }}> per month for {{ units: 50 }} employees</span>
                      </Trans>
                    </div>
                  </div>

                  <div
                    className={classnames('mt-3 p-3 mb-2 planCard', { selected: state.plan === 'education' })}
                    onClick={() => selectPlan('education')}
                  >
                    <div style={{ fontSize: '1.1em', lineHeight: '1.1em', fontWeight: 500 }}>
                      {t('account.subscription.plans.education')}
                    </div>
                    <div style={{ fontSize: '0.85em', marginTop: 8 }}>
                      <Trans i18nKey="account.subscription.subscribe.starting.education">
                        <span style={{ opacity: 0.5 }}>_Starting at </span>
                        <span style={{ fontWeight: 600, color: '#007bff' }}>
                          {{ price: [prices.plans.education.month, prices.currency] }}
                        </span>
                        <span style={{ opacity: 0.5 }}> per month for {{ units: 250 }} students</span>
                      </Trans>
                    </div>
                  </div>
                </>
              )}
              {state.step === 'set-units' && (
                <>
                  <div className="mb-1 p-3 text-center" style={{ opacity: 0.8 }}>
                    <Trans i18nKey={`account.subscription.subscribe.unitsQuestion.${state.plan}`}>
                      _How many <span style={{ fontWeight: 500 }}>_</span> does your _ _?
                    </Trans>
                  </div>
                  <Form onSubmit={continueWithPlan} action="#submit" noValidate className="mb-3">
                    <Form.Control
                      inputMode="numeric"
                      autoFocus={!isMobile}
                      pattern="[0-9]*"
                      autoComplete="off"
                      maxLength="6"
                      value={state.unitsValue}
                      onChange={onUnitsValueChange}
                      style={{ width: '50%', margin: '0 auto', fontWeight: 500, fontSize: '1.2em' }}
                    />
                  </Form>
                </>
              )}
              {state.step === 'set-billing-interval' && (
                <>
                  <div className="mb-3 p-3 text-center" style={{ opacity: 0.8 }}>
                    <Trans i18nKey="account.subscription.subscribe.included">
                      _All plans include <span style={{ fontWeight: 500 }}>access to all features</span>,
                      <span style={{ fontWeight: 500 }}>unlimited devices</span> and
                      <span style={{ fontWeight: 500 }}>unlimited queries</span>.
                    </Trans>
                  </div>

                  <div
                    className={classnames('p-3 mb-2 planCard d-flex', {
                      selected: state.billingInterval === 'month',
                    })}
                    onClick={() => selectPlan(state.plan, 'month')}
                  >
                    <div className="flex-grow-1">
                      <div style={{ fontSize: '1.3em', lineHeight: '1.1em', fontWeight: 500 }}>
                        {t(`account.subscription.plans.${state.plan}`)}
                      </div>
                      <div style={{ fontSize: '0.9em', fontWeight: 500, opacity: 0.7, marginTop: 4 }}>
                        {t(`account.subscription.units.${state.plan}`, { count: state.units })}
                      </div>
                      <div style={{ opacity: 0.5, marginTop: 3, textTransform: 'capitalize' }}>
                        {t('account.subscription.intervals.month')}
                      </div>
                    </div>
                    <div className="text-end">
                      <div style={{ fontSize: '1.3em', lineHeight: '1.35em', fontWeight: 600, color: '#007bff' }}>
                        {i18n.format(
                          [getPrice(state.plan, state.units, 'month'), prices.currency],
                          'price'
                        )}
                      </div>
                      <div style={{ fontSize: '0.85em', opacity: 0.5 }}>
                        {t('account.subscription.per.month')}
                      </div>
                    </div>
                  </div>

                  <div
                    className={classnames('p-3 planCard', { selected: state.billingInterval === 'year' })}
                    onClick={() => selectPlan(state.plan, 'year')}
                  >
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <div style={{ fontSize: '1.3em', lineHeight: '1.1em', fontWeight: 500 }}>
                          {t(`account.subscription.plans.${state.plan}`)}
                        </div>
                        <div style={{ fontSize: '0.9em', fontWeight: 500, opacity: 0.7, marginTop: 4 }}>
                          {t(`account.subscription.units.${state.plan}`, { count: state.units })}
                        </div>
                        <div style={{ opacity: 0.5, marginTop: 3, textTransform: 'capitalize' }}>
                          {t('account.subscription.intervals.year')}
                        </div>
                      </div>
                      <div className="text-end">
                        <div style={{ fontSize: '1.3em', lineHeight: '1.35em', fontWeight: 600, color: '#007bff' }}>
                          {i18n.format(
                            [getPrice(state.plan, state.units, 'year'), prices.currency],
                            'price'
                          )}
                        </div>
                        <div style={{ fontSize: '0.85em', opacity: 0.5 }}>
                          {t('account.subscription.per.year')}
                        </div>
                      </div>
                    </div>
                    <div
                      className="mt-2 d-inline-block"
                      style={{
                        fontSize: 12,
                        color: 'green',
                        border: '1px solid green',
                        borderRadius: 4,
                        padding: '2px 5px',
                      }}
                    >
                      {t('account.subscription.subscribe.savings', { percent: 0.17 })}
                    </div>
                  </div>

                  <div className="p-2" style={{ fontSize: '0.8em', opacity: 0.6 }}>
                    {t(`account.subscription.subscribe.asYouGrow.${state.plan}`)}
                  </div>
                </>
              )}
            </Modal.Body>
            <Modal.Footer>
              {!state.step && (
                <Button variant="outline-secondary" onClick={closeModal}>
                  {t('global.cancel')}
                </Button>
              )}
              {!!state.step && (
                <Button variant="outline-secondary" onClick={back}>
                  {t('global.back')}
                </Button>
              )}

              {state.action === 'subscribe' && (
                <Button
                  disabled={
                    !state.plan ||
                    (state.step === 'set-units' && !state.unitsValue) ||
                    (state.step === 'set-billing-interval' && !state.billingInterval)
                  }
                  onClick={continueWithPlan}
                >
                  {t('global.continue')}
                </Button>
              )}
              {state.action === 'change-plan' && (
                <Button
                  disabled={
                    !state.plan ||
                    (state.step === 'set-units' && !state.unitsValue) ||
                    (state.step === 'set-billing-interval' && !state.billingInterval)
                  }
                  onClick={continueWithPlan}
                >
                  {!state.plan || state.plan === 'pro' || state.step === 'set-billing-interval'
                    ? t('global.save')
                    : t('global.continue')}
                </Button>
              )}
            </Modal.Footer>
          </>
        )}

      {state.stripeLoaded &&
        ((state.action === 'subscribe' && state.step === 'add-payment-method') ||
          state.action === 'change-payment-method') && (
          <Elements stripe={stripePromiseRef.current}>
            <ElementsConsumer>
              {({ stripe, elements }) => (
                <Form onSubmit={(event) => onStripeSubmit(event, stripe, elements)} action="#submit" noValidate>
                  <Modal.Header closeButton>
                    <Modal.Title as="h5">
                      {state.action === 'change-payment-method'
                        ? t('account.subscription.changePaymentMethod.button')
                        : t('account.subscription.subscribe.addPaymentMethod')}
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Nav variant="pills" className="PaymentMethodNav mb-4 mt-2">
                      <Nav.Item>
                        <Nav.Link
                          href="#"
                          active={state.paymentMethodType === 'card'}
                          onClick={(event) => changePaymentMethodType(event, 'card')}
                        >
                          <img className="d-inline d-sm-none" src={UnknownCard} alt="" style={{ height: 14 }} />
                          <img className="d-none d-sm-inline" src={Visa} alt="" style={{ height: 14 }} />
                          <img className="ms-1 d-none d-sm-inline" src={Mastercard} alt="" style={{ height: 14 }} />
                          <img className="ms-1 d-none d-sm-inline" src={Amex} alt="" style={{ height: 14 }} />
                          <img className="ms-1 d-none d-sm-inline" src={UnionPay} alt="" style={{ height: 14 }} />
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="ms-3">
                        <Nav.Link
                          href="#"
                          active={state.paymentMethodType === 'paypal'}
                          onClick={(event) => changePaymentMethodType(event, 'paypal')}
                        >
                          <img className="d-none d-sm-inline" src={theme === 'dark' ? PaypalDark : Paypal} alt="" style={{ height: 14 }} />
                          <img className="d-inline d-sm-none" src={PaypalIcon} alt="" style={{ height: 14 }} />
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="ms-3">
                        <Nav.Link
                          href="#"
                          active={state.paymentMethodType === 'crypto'}
                          onClick={(event) => changePaymentMethodType(event, 'crypto')}
                        >
                          <img className="d-inline" src={Bitcoin} alt="" style={{ height: 16 }} />
                          <img className="ms-1 d-none d-sm-inline" src={Ethereum} alt="" style={{ height: 14 }} />
                          <img className="ms-1 d-none d-sm-inline" src={Ripple} alt="" style={{ height: 14 }} />
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>

                    {state.paymentMethodType === 'card' && (
                      <>
                        <div className="mx-sm-3 d-flex flex-column gap-3">
                          <Form.Group>
                            <Form.Label style={{ fontSize: '0.9em' }}>
                              {t('account.subscription.subscribe.card.number')}
                            </Form.Label>
                            <CardNumberElement
                              autoFocus
                              className={classnames('form-control', { 'is-invalid': !!state.cardNumberError })}
                              options={{ style: stripeStyle, showIcon: true, iconStyle: 'solid' }}
                              onChange={onCardFieldChange}
                            />
                            {!!state.cardNumberError && (
                              <Form.Control.Feedback type="invalid">
                                {state.cardNumberError}
                              </Form.Control.Feedback>
                            )}
                          </Form.Group>
                          <Row>
                            <Col sm="6">
                              <Form.Group>
                                <Form.Label style={{ fontSize: '0.9em' }}>
                                  {t('account.subscription.subscribe.card.expiration')}
                                </Form.Label>
                                <CardExpiryElement
                                  className={classnames('form-control', {
                                    'is-invalid': !!state.cardExpiryError,
                                  })}
                                  options={{ style: stripeStyle }}
                                  onChange={onCardFieldChange}
                                />
                                {!!state.cardExpiryError && (
                                  <Form.Control.Feedback type="invalid">
                                    {state.cardExpiryError}
                                  </Form.Control.Feedback>
                                )}
                              </Form.Group>
                            </Col>
                            <Col sm="6">
                              <Form.Group>
                                <Form.Label style={{ fontSize: '0.9em' }}>
                                  {t('account.subscription.subscribe.card.code')}
                                </Form.Label>
                                <CardCvcElement
                                  className={classnames('form-control', { 'is-invalid': !!state.cardCvcError })}
                                  options={{ style: stripeStyle }}
                                  onChange={onCardFieldChange}
                                />
                                {!!state.cardCvcError && (
                                  <Form.Control.Feedback type="invalid">
                                    {state.cardCvcError}
                                  </Form.Control.Feedback>
                                )}
                              </Form.Group>
                            </Col>
                          </Row>
                          <div className="mt-2" style={{ fontSize: 13, color: '#aaa' }}>
                            <img className="d-inline me-1" src={Lock} alt="" style={{ height: 13 }} />
                            {t('account.subscription.subscribe.card.stripe')}
                          </div>
                        </div>
                      </>
                    )}

                    {state.paymentMethodType === 'paypal' && (
                      <div className="mx-sm-3" style={{ fontSize: '0.9em' }}>
                        {t('account.subscription.subscribe.paypal.text')}
                      </div>
                    )}

                    {state.paymentMethodType === 'crypto' && (
                      <div className="mx-sm-3" style={{ fontSize: '0.9em' }}>
                        {((state.action === 'subscribe' && state.billingInterval !== 'year') ||
                          (state.action === 'change-payment-method' &&
                            subscription.billingInterval !== 'year')) && (
                            <Alert className="mb-3" variant="warning">
                              <Trans i18nKey="account.subscription.subscribe.bitpay.toYearly">
                                _We do not accept cryptocurrency payments for monthly plans.
                                <span style={{ fontWeight: 500 }}>
                                  By continuing, you will automatically be switched to the yearly plan.
                                </span>
                              </Trans>
                            </Alert>
                          )}

                        {!!subscription && (
                          <Alert className="mb-3" variant="success">
                            {t('account.subscription.subscribe.bitpay.remainder', {
                              date: [subscription.validUntil, 'LL'],
                            })}
                          </Alert>
                        )}

                        <div>{t('account.subscription.subscribe.bitpay.text')}</div>
                      </div>
                    )}
                  </Modal.Body>
                  <Modal.Footer>
                    {state.action === 'subscribe' && (
                      <Button
                        variant="outline-secondary"
                        disabled={
                          state.savingCard || state.continuingToPaypal || state.continuingToBitpay
                        }
                        onClick={back}
                      >
                        {t('global.back')}
                      </Button>
                    )}
                    {action !== 'subscribe' && (
                      <Button
                        variant="outline-secondary"
                        disabled={
                          state.savingCard || state.continuingToPaypal || state.continuingToBitpay
                        }
                        onClick={closeModal}
                      >
                        {t('global.cancel')}
                      </Button>
                    )}

                    {state.paymentMethodType === 'card' && (
                      <>
                        {!state.savingCard && (
                          <Button type="submit" disabled={!stripe}>
                            {!subscription || !subscription.paymentMethod
                              ? t('global.subscribe')
                              : t('global.save')}
                          </Button>
                        )}
                        {state.savingCard && (
                          <Button type="submit" disabled>
                            <Spinner as="span" animation="border" size="sm" className="me-2" />
                            {!subscription || !subscription.paymentMethod
                              ? t('global.subscribe')
                              : t('global.save')}
                          </Button>
                        )}
                      </>
                    )}

                    {state.paymentMethodType === 'paypal' && (
                      <>
                        {!state.continuingToPaypal && (
                          <Button onClick={continueToPaypal}>
                            <Trans i18nKey="account.subscription.subscribe.continueTo">
                              _Continue to
                              <img className="d-inline" src={PaypalWhite} alt="" style={{ height: 14 }} />
                            </Trans>
                          </Button>
                        )}
                        {state.continuingToPaypal && (
                          <Button disabled>
                            <Spinner as="span" animation="border" size="sm" className="me-2" />
                            <Trans i18nKey="account.subscription.subscribe.continueTo">
                              _Continue to
                              <img className="d-inline" src={PaypalWhite} alt="" style={{ height: 14 }} />
                              ...
                            </Trans>
                          </Button>
                        )}
                      </>
                    )}

                    {state.paymentMethodType === 'crypto' && (
                      <>
                        {!state.continuingToBitpay && (
                          <Button onClick={continueToBitpay}>
                            <Trans i18nKey="account.subscription.subscribe.continueTo">
                              _Continue to <img className="d-inline" src={Bitpay} alt="" style={{ height: 16 }} />
                            </Trans>
                          </Button>
                        )}
                        {state.continuingToBitpay && (
                          <Button disabled>
                            <Spinner as="span" animation="border" size="sm" className="me-2" />
                            <Trans i18nKey="account.subscription.subscribe.continueTo">
                              _Continue to <img className="d-inline" src={Bitpay} alt="" style={{ height: 16 }} />
                              ...
                            </Trans>
                          </Button>
                        )}
                      </>
                    )}
                  </Modal.Footer>
                </Form>
              )}
            </ElementsConsumer>
          </Elements>
        )}
    </Modal>
  );
}