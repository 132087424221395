import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card, Collapse, ListGroup, Table } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoice } from '@fortawesome/free-solid-svg-icons';

export default function AccountInvoices({ invoices }) {
  const { t } = useTranslation();

  const [showMore, setShowMore] = React.useState(false);
  const maxItems = 5;

  return (
    <Card id="invoices">
      <ListGroup variant="flush">
        <ListGroup.Item className="py-3">
          <h5>{t('account.invoices.name')}</h5>
          <div style={{ opacity: 0.6, fontSize: '0.9em' }}>{t('account.invoices.description')}</div>
        </ListGroup.Item>

        <ListGroup.Item className="p-0" style={{ border: 'none' }}>
          <Table responsive className="mb-0 text-nowrap">
            <tbody>
              {invoices.slice(0, maxItems).map((invoice, index) => (
                <InvoiceRow key={index} invoice={invoice} />
              ))}
            </tbody>
          </Table>
          <Collapse in={showMore}>
            <div id="more-invoices">
              <Table responsive className="mb-0 text-nowrap" >
                <tbody>
                  {invoices.slice(maxItems, -1).map((invoice, index) => (
                    <InvoiceRow key={maxItems+index} invoice={invoice} />
                  ))}
                </tbody>
              </Table>
            </div>
          </Collapse>
        </ListGroup.Item>
        {invoices.length > maxItems && (
          <ListGroup.Item className="text-center">
            <Button
              size="sm"
              variant="link"
              aria-controls="more-invoices"
              aria-expanded={showMore}
              href="#invoices"
              className="py-0"
              onClick={() => setShowMore(!showMore)}
            >
              {!showMore ?
                t("account.invoices.show-more")
                :
                t("account.invoices.show-less")
              }
            </Button>
          </ListGroup.Item>
        )}
      </ListGroup>
    </Card>
  );
}

function InvoiceRow({ invoice }) {
  const { t, i18n } = useTranslation();

  return (
    <tr key={invoice.code} style={{ fontSize: '0.9em' }}>
      <td className="d-none d-sm-table-cell" style={{ paddingLeft: '1.25rem', fontWeight: 500 }}>
        {i18n.format([invoice.createdOn, 'LL'], 'date')}
      </td>

      <td className="d-sm-none" style={{ paddingLeft: '1.25rem' }}>
        {i18n.format([invoice.createdOn, 'L'], 'date')}
      </td>

      <td className="text-truncate d-none d-md-table-cell">
        {!invoice.units &&
          t('account.subscription.planFull', {
            plan: t(`account.subscription.plans.${invoice.plan}`),
            interval: t(`account.subscription.intervals.${invoice.billingInterval}`),
          })}
        {!!invoice.units &&
          t('account.subscription.planFullWithUnits', {
            plan: t(`account.subscription.plans.${invoice.plan}`),
            units: t(`account.subscription.units.${invoice.plan}`, { count: invoice.units }),
            interval: t(`account.subscription.intervals.${invoice.billingInterval}`),
          })}
      </td>

      <td className="d-none d-lg-table-cell" style={{ opacity: 0.6 }}>
        {i18n.format([invoice.period.from, 'LL'], 'date')}
        <span style={{ opacity: 0.4 }}> → </span>
        {i18n.format([invoice.period.to, 'LL'], 'date')}
      </td>

      {invoice.status === 'pending' && (
        <td className="text-end" style={{ fontWeight: 500, width: '100%', opacity: 0.25 }}>
          {t('account.invoices.pending', { amount: [invoice.amount, invoice.currency] })}
        </td>
      )}
      {invoice.status === 'paid' && (
        <td className="text-end" style={{ fontWeight: 500, width: '100%' }}>
          {i18n.format([invoice.amount, invoice.currency], 'price')}
        </td>
      )}
      {invoice.status === 'refunded' && (
        <td
          className="text-end"
          style={{ fontWeight: 500, width: '100%', opacity: 0.5, textDecoration: 'line-through' }}
        >
          {i18n.format([invoice.amount, invoice.currency], 'price')}
        </td>
      )}

      <td style={{ paddingRight: '1.25rem' }}>
        {invoice.status !== 'pending' && !!invoice.hasInvoice && (
          <a href={`/invoice/${invoice.code}`} target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faFileInvoice} style={{ color: '#007bff' }} />
          </a>
        )}
      </td>
    </tr>
  );
}