import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { Modal, Form, Button } from 'react-bootstrap';
import axios from 'axios';
import QRCode from 'qrcode.react';

import { API_BASE_URL } from '../config';

export default function Account2FAEnableButton({ secretCandidate, email, onEnabled }) {
  const [showModal, setShowModal] = useState(false);
  const [currentPasswordValue, setCurrentPasswordValue] = useState('');
  const [currentPasswordError, setCurrentPasswordError] = useState(null);
  const [codeValue, setCodeValue] = useState('');
  const [codeError, setCodeError] = useState(null);
  const { t } = useTranslation();
  const source = useRef(null);

  useEffect(() => {
    source.current = axios.CancelToken.source();
    return () => {
      source.current.cancel();
    };
  }, []);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setCurrentPasswordValue('');
    setCurrentPasswordError(null);
    setCodeValue('');
    setCodeError(null);
  };

  const onCurrentPasswordValueChange = (event) => {
    setCurrentPasswordValue(event.target.value);
    setCurrentPasswordError(null);
  };

  const onCodeValueChange = (event) => {
    const value = event.target.value.replace(/[^\d]+/g, '');
    setCodeValue(value);
    setCodeError(null);
  };

  const enable = async (event) => {
    event.preventDefault();

    const { data } = await axios.patch(
      `${API_BASE_URL}/accounts/@me`,
      {
        '2faSecret': secretCandidate,
        currentPassword: currentPasswordValue,
        code: codeValue,
      },
      {
        cancelToken: source.current.token,
      }
    );

    if (data.errors) {
      setCurrentPasswordValue('');
      setCurrentPasswordError(data.errors.currentPassword);
      setCodeValue('');
      setCodeError(data.errors.code);
      return;
    }

    closeModal();
    onEnabled();
  };

  return (
    <>
      <Button variant="outline-primary" size="sm" onClick={openModal}>
        {t('account.2fa.enable.button')}
      </Button>

      <Modal show={showModal} animation={false} onHide={closeModal}>
        <Form onSubmit={enable} action="#submit" noValidate>
          <Modal.Header closeButton>
            <Modal.Title as="h5">{t('account.2fa.enable.button')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-4" style={{ opacity: 0.6, fontSize: '0.9em' }}>
              <Trans i18nKey="account.2fa.enable.modal.howto">
                _Use an authenticator app like
                <a
                  href="https://support.google.com/accounts/answer/1066447"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Google Authenticator
                </a>
                or a password manager supporting 2FA. You can either scan the QR code, tap on it to open the
                <span className="notranslate">{{ otpauth: 'otpauth://' }}</span> link or copy & paste your secret
                manually.
              </Trans>
            </div>
            <div className="text-center mb-4 pb-4" style={{ borderBottom: '2px solid var(--bs-border-color)' }}>
              <div style={{ width: 200, maxWidth: '80%', margin: '0 auto' }}>
                <a
                  href={`otpauth://totp/NextDNS:${email}?secret=${secretCandidate}&issuer=NextDNS`}
                  className="d-block position-relative"
                  style={{ paddingTop: '100%' }}
                >
                  <QRCode
                    value={`otpauth://totp/NextDNS:${email}?secret=${secretCandidate}&issuer=NextDNS`}
                    size={800}
                    className="position-absolute"
                    style={{ width: '100%', height: '100%', top: 0, left: 0 }}
                  />
                </a>
              </div>
              <Form.Control
                size="sm"
                className="mt-2 text-center text-monospace"
                plaintext
                readOnly
                style={{ fontsize: '0.8em' }}
                value={secretCandidate}
              />
            </div>
            <Form.Group>
              <Form.Label>{t('account.currentPassword')}</Form.Label>
              <Form.Control
                type="password"
                autoComplete="current-password"
                isInvalid={!!currentPasswordError}
                value={currentPasswordValue}
                onChange={onCurrentPasswordValueChange}
              />
              {!!currentPasswordError && (
                <Form.Control.Feedback type="invalid">
                  {t(`account.errors.currentPassword.${currentPasswordError}`)}
                </Form.Control.Feedback>
              )}
              <div className="mt-1 text-end" style={{ fontSize: '0.8em' }}>
                <Link to={`/reset-password?email=${encodeURIComponent(email)}`}>
                  {t('account.forgotPassword')}
                </Link>
              </div>
            </Form.Group>
            <Form.Group>
              <Form.Label>{t('account.code')}</Form.Label>
              <Form.Control
                className="text-center text-monospace"
                inputMode="numeric"
                pattern="[0-9]*"
                autoComplete="one-time-code"
                maxLength="6"
                size="lg"
                isInvalid={!!codeError}
                value={codeValue}
                onChange={onCodeValueChange}
                style={{ fontWeight: 500, fontSize: '1.6em', letterSpacing: '0.35em', paddingRight: '1rem' }}
              />
              {!!codeError && (
                <Form.Control.Feedback type="invalid">
                  {t(`account.errors.code.${codeError}`)}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-secondary" onClick={closeModal}>
              {t('global.cancel')}
            </Button>
            <Button type="submit">{t('global.enable')}</Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}