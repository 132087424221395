import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Button, Spinner } from 'react-bootstrap';
import axios from 'axios';

import { API_BASE_URL } from '../config';

export default function AccountSubscriptionCancelSubscriptionButton({ subscription, onSubscriptionCanceled }) {
  const { t } = useTranslation();

  const [isCanceling, setIsCanceling] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const isModalClosableRef = useRef(true);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    if (!isModalClosableRef.current) {
      return;
    }

    setShowModal(false);
    setIsCanceling(false);
  };

  const cancelSubscription = async () => {
    isModalClosableRef.current = false;

    setIsCanceling(true);

    await axios.get(`${API_BASE_URL}/subscription/cancel`);

    isModalClosableRef.current = true;

    closeModal();
    onSubscriptionCanceled();
  };

    return (
      <>
        <Button variant="outline-danger" size="sm" onClick={openModal}>
          {t('account.subscription.cancel.button')}
        </Button>

        <Modal show={showModal} animation={false} onHide={closeModal}>
          <Modal.Header closeButton>
            <Modal.Title as="h5">{t('account.subscription.cancel.button')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {t('account.subscription.cancel.confirm.text', {
              date: [subscription.validUntil, 'LL'],
            })}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-secondary" disabled={isCanceling} onClick={closeModal}>
              {t('global.cancel')}
            </Button>

            {!isCanceling && (
              <Button variant="danger" onClick={cancelSubscription}>
                {t('account.subscription.cancel.confirm.submit')}
              </Button>
            )}
            {isCanceling && (
              <Button variant="danger" disabled>
                <Spinner as="span" animation="border" size="sm" className="me-2" />
                {t('account.subscription.cancel.confirm.submit')}
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      </>
    );
}