import React, { useState, useEffect, useRef } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Card, ListGroup, Modal, Button, ButtonToolbar, Dropdown, Spinner } from 'react-bootstrap';
import { del, post, get } from '../api2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faTimes } from '@fortawesome/free-solid-svg-icons';
import RelativeTime from '../Components/RelativeTime';
import SearchInput from '../Components/SearchInput';
import { hex } from '../helpers';

function upperFirst(s) {
  return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
}

export default function PrivacyBlocklists({ blocklists: initialBlocklists, profile, role }) {
  const { t } = useTranslation();
  const [allBlocklistsLoaded, setAllBlocklistsLoaded] = useState(false);
  const [blocklists, setBlocklists] = useState(initialBlocklists.map((blocklist, popIndex) => ({
    ...blocklist,
    added: true,
    deprecated: blocklist.deprecated || blocklist.entries === 0,
    popIndex,
  })));
  const [addedOrder, setAddedOrder] = useState(initialBlocklists.map(blocklist => blocklist.id));
  const [blocklistsSortBy, setBlocklistsSortBy] = useState('popularity');
  const [blocklistsSearch, setBlocklistsSearch] = useState('');
  const [showModal, setShowModal] = useState(false);
  const source = useRef();

  useEffect(() => {
    source.current = new Set();
    return () => {
      source.current.forEach(func => func());
    }
  }, []);

  const loadAllBlocklists = () => {
    if (!allBlocklistsLoaded) {
      const cancel = get(`/privacy/blocklists`, ({ data }) => {
        setBlocklists(prevBlocklists => data.map((blocklist, popIndex) => ({
          ...blocklist,
          ...prevBlocklists.filter(b => b.id === blocklist.id)[0],
          popIndex,
        })));
        setAllBlocklistsLoaded(true);
      });
      source.current.add(cancel);
    }
  };

  const openModal = () => {
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };

  const add = (id) => {
    setBlocklists(current =>
      current.map(b => b.id === id ? { ...b, added: true } : b)
    );
    setAddedOrder(current => [...current, id]);
    const cancel = post(`/profiles/${profile}/privacy/blocklists`, { id });
    source.current.add(cancel);
  };

  const remove = (id) => {
    setBlocklists(current =>
      current.map(b => ({
        ...b,
        added: b.id === id ? false : b.added
      }))
    );
    setAddedOrder(current => current.filter(i => i !== id));
    const cancel = del(`/profiles/${profile}/privacy/blocklists/${hex(id)}`);
    source.current.add(cancel);
  };

  const blocklistsTransformed = () => {
    let filteredBlocklists = blocklists.filter(b =>
      !b.deprecated || b.added);

    if (blocklistsSearch) {
      const search = blocklistsSearch.toLowerCase();
      filteredBlocklists = filteredBlocklists.filter(blocklist =>
        t(`privacy.blocklists.blocklists.${blocklist.id}.name`, blocklist.name).toLowerCase().includes(search)
      );
    }

    return [...filteredBlocklists].sort((a, b) => {
      switch (blocklistsSortBy) {
        case 'name':
          return t(`privacy.blocklists.blocklists.${a.id}.name`, a.name).localeCompare(
            t(`privacy.blocklists.blocklists.${b.id}.name`, b.name)
          );
        case 'entries':
          return b.entries - a.entries;
        case 'updated':
          return new Date(b.updatedOn) - new Date(a.updatedOn);
        default:
          return a.popIndex - b.popIndex;
      }
    });
  };

  const addedBlocklists = () => {
    return blocklists.filter(blocklist => blocklist.added).sort((a, b) => {
      return addedOrder.indexOf(a.id) - addedOrder.indexOf(b.id);
    });
  }

  useEffect(() => {
    source.current = new Set();
    return () => {
      source.current.forEach(func => func());
    };
  }, []); return (
    <>
      <Card>
        <ListGroup variant="flush">
          <ListGroup.Item className="py-3">
            <h5>{t('privacy.blocklists.name')}</h5>
            <div style={{ opacity: 0.6, fontSize: '0.9em' }}>{t('privacy.blocklists.description')}</div>
          </ListGroup.Item>
          {addedBlocklists().map((blocklist, index) => (
            <ListGroup.Item key={blocklist.id} style={{ borderLeft: '4px solid #007bff' }}>
              <div className="d-flex">
                <div className="flex-grow-1" style={{ marginLeft: -4 }}>
                  <div className="notranslate" style={{ fontWeight: 500 }}>
                    {t(`privacy.blocklists.blocklists.${blocklist.id}.name`, blocklist.name)}
                  </div>
                  <div className="mt-1" style={{ fontSize: '0.9em', opacity: 0.5 }}>
                    {t(`privacy.blocklists.blocklists.${blocklist.id}.description`, blocklist.description)}
                  </div>
                  <div className="mt-1" style={{ fontSize: '0.8em' }}>
                    {blocklist.website !== null && (
                      <div className="d-sm-inline">
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={blocklist.website}
                          className="notranslate"
                          style={{ wordBreak: 'break-all' }}
                        >
                          {blocklist.website.replace(/^https?:\/\//, '')}
                        </a>
                        <span className="d-none d-sm-inline" style={{ opacity: 0.4 }}>
                          {' '}
                          •{' '}
                        </span>
                      </div>
                    )}
                    <div className="d-sm-inline mt-1 mt-sm-0">
                      <span style={{ opacity: 0.4 }}>
                        {t('privacy.blocklists.entries', { count: blocklist.entries })}
                      </span>
                      <span style={{ opacity: 0.4 }}> • </span>
                      <span style={{ opacity: 0.4 }}>
                        <Trans i18nKey="privacy.blocklists.updated">
                          _Updated
                          <RelativeTime date={blocklist.updatedOn} suffix />
                        </Trans>
                      </span>
                    </div>
                  </div>
                </div>
                {role !== 'viewer' && (
                  <div className="ms-4">
                    <Button
                      variant="link"
                      className="me-n3 mt-n1"
                      style={{ opacity: 0.3, color: '#222' }}
                      onClick={() => remove(blocklist.id)}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </Button>
                  </div>
                )}
              </div>
            </ListGroup.Item>
          ))}
        </ListGroup>
        <Card.Footer className="text-muted" style={{ borderTop: 'none' }}>
          <Button
            size="sm"
            style={{ fontWeight: 'bold', fontSize: 12, textTransform: 'uppercase' }}
            onClick={openModal}
            disabled={role === 'viewer'}
          >
            {t('privacy.blocklists.add')}
          </Button>
        </Card.Footer>
      </Card>

      <Modal
        show={showModal}
        animation={false}
        size="lg"
        scrollable={true}
        onHide={closeModal}
        onShow={loadAllBlocklists}
      >
        <Modal.Header closeButton>
          <Modal.Title as="h5">{t('privacy.blocklists.add')}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          {!allBlocklistsLoaded ? (
            <div className="text-center py-3">
              <Spinner animation="border" style={{ width: '2rem', height: '2rem', opacity: 0.15 }} className="my-4" />
            </div>
          ) : (
            <>
              <ButtonToolbar className="m-2 me-3 ms-3 d-flex gap-2 flex-nowrap justify-content-between">
                <SearchInput active className="me-2 flex-grow-1" onChange={setBlocklistsSearch} />
                <Dropdown className="flex-shrink-0">
                  <Dropdown.Toggle variant="light" size="sm">
                    <FontAwesomeIcon icon={faSort} style={{ marginRight: "8px" }} />
                    <Trans i18nKey={`privacy.blocklists.sortBy.${blocklistsSortBy}`}>
                      _By {upperFirst(blocklistsSortBy)}
                    </Trans>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {['popularity', 'name', 'entries', 'updated'].map((sortBy) => (
                      <Dropdown.Item
                        onClick={() => setBlocklistsSortBy(sortBy)}
                        size="sm"
                        active={blocklistsSortBy === sortBy}
                      >
                        <Trans i18nKey={`privacy.blocklists.sortBy.${sortBy}`}>
                          _By {upperFirst(sortBy)}
                        </Trans>
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </ButtonToolbar>
              <ListGroup variant="flush">
                {blocklistsTransformed().map((blocklist) => (
                  <ListGroup.Item
                    key={blocklist.id}
                    style={{ borderLeft: '4px solid #ccc', borderLeftColor: blocklist.added ? '#007bff' : '' }}
                  >
                    <div className="d-lg-flex gap-4" style={{ marginLeft: -4 }}>
                      <div className="flex-grow-1">
                        <div className="notranslate" style={{ fontWeight: 500 }}>
                          {t(`privacy.blocklists.blocklists.${blocklist.id}.name`, blocklist.name)}
                        </div>
                        <div className="mt-1" style={{ fontSize: '0.9em', opacity: 0.5 }}>
                          {t(
                            `privacy.blocklists.blocklists.${blocklist.id}.description`,
                            blocklist.description
                          )}
                        </div>
                        <div className="mt-1" style={{ fontSize: '0.8em' }}>
                          {blocklist.website !== null && (
                            <div className="d-sm-inline">
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={blocklist.website}
                                className="notranslate"
                                style={{ wordBreak: 'break-all' }}
                              >
                                {blocklist.website.replace(/^https?:\/\//, '')}
                              </a>
                              <span className="d-none d-sm-inline" style={{ opacity: 0.4 }}>
                                {' '}
                                •{' '}
                              </span>
                            </div>
                          )}
                          <div className="d-sm-inline mt-1 mt-sm-0">
                            <span style={{ opacity: 0.4 }}>
                              {t('privacy.blocklists.entries', { count: blocklist.entries })}
                            </span>
                            <span style={{ opacity: 0.4 }}> • </span>
                            <span style={{ opacity: 0.4 }}>
                              <Trans i18nKey="privacy.blocklists.updated">
                                _Updated
                                <RelativeTime date={blocklist.updatedOn} suffix />
                              </Trans>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex align-items-center my-2 my-lg-0">
                        {blocklist.added && (
                          <Button
                            variant="danger"
                            size="sm"
                            style={{
                              fontWeight: 'bold',
                              fontSize: 12,
                              textTransform: 'uppercase',
                              whiteSpace: 'nowrap',
                            }}
                            onClick={() => remove(blocklist.id)}
                          >
                            {t('global.remove')}
                          </Button>
                        )}
                        {!blocklist.added && (
                          <Button
                            size="sm"
                            style={{
                              fontWeight: 'bold',
                              fontSize: 12,
                              textTransform: 'uppercase',
                              whiteSpace: 'nowrap',
                            }}
                            onClick={() => add(blocklist.id)}
                          >
                            {t('global.add')}
                          </Button>
                        )}
                      </div>
                    </div>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
