import React from 'react';
import { createRoot } from 'react-dom/client';
import axios from 'axios';
import smoothscroll from 'smoothscroll-polyfill';
import ResizeObserver from 'resize-observer-polyfill';

import './i18n';

import AppWrapper from './AppWrapper';

import './index.scss';

axios.defaults.withCredentials = true; // TODO: delete later when we have fully migrated to api helper

smoothscroll.polyfill();

window.ResizeObserver = ResizeObserver;

if (process.env.REACT_APP_TAILWIND) {
  document.documentElement.classList.add('tw');
}

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <AppWrapper />
  </React.StrictMode>
);
