import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Card, Form } from 'react-bootstrap';
import { Switch } from '@headlessui/react';

import { classNames } from '../helpers';
import { get, patch } from '../api2';

import Page from '../Page';
import TranslationsFooter from '../Components/TranslationsFooter';
import SecurityTlds from './SecurityTlds';

function Setting({ name, description, extra, action, beta, checked, onChange, children }) {
  const { t } = useTranslation();

  return (
    <div className="tw-overflow-hidden tw-rounded-md tw-border dark:tw-border-slate-800">
      <div className="tw-px-6 tw-py-5">
        <h3 className="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900 dark:tw-text-slate-200">
          <span className="tw-align-middle">{name}</span>
          {beta && (
            <span className="tw-ml-2 tw-inline-flex tw-items-center tw-rounded-full tw-bg-green-100 tw-px-2.5 tw-align-middle tw-text-[11px] tw-font-medium tw-uppercase tw-leading-5 tw-text-green-800">
              {t('global.beta')}
            </span>
          )}
        </h3>
        <div className="tw-mt-2 tw-text-sm tw-text-gray-500">{description}</div>
        {extra}
      </div>
      <div className="tw-border-t tw-bg-slate-50 tw-px-6 tw-py-4 dark:tw-border-slate-800 dark:tw-bg-slate-800/30">
        <Switch.Group as="div" className="tw-items-top tw-flex">
          <Switch
            checked={checked}
            disabled={!onChange}
            onChange={onChange}
            className={classNames(
              checked ? 'tw-bg-nextdnsblue' : 'tw-bg-slate-200 dark:tw-bg-slate-600',
              'tw-relative tw-inline-flex tw-h-5 tw-w-9 tw-flex-shrink-0 tw-cursor-pointer tw-rounded-full tw-border-2 tw-border-transparent tw-transition-colors tw-duration-200 tw-ease-in-out'
            )}
          >
            <span
              aria-hidden="true"
              className={classNames(
                checked ? 'tw-translate-x-4' : 'tw-translate-x-0',
                'tw-pointer-events-none tw-inline-block tw-h-4 tw-w-4 tw-transform tw-rounded-full tw-bg-white tw-shadow tw-ring-0 tw-transition tw-duration-200 tw-ease-in-out'
              )}
            />
          </Switch>
          <Switch.Label as="span" className="tw-ml-3 tw-text-sm tw-text-gray-500">
            {action}
          </Switch.Label>
        </Switch.Group>
      </div>
    </div>
  );
}

export default function Security({ profile, role }) {
  const { t } = useTranslation();

  const [state, setState] = useState();

  useEffect(() => {
    setState();

    return get(`/profiles/${profile}/security`, ({ data }) => {
      setState(data);
    });
  }, [profile]);

  const toggle = useCallback(
    (setting, enabled) => {
      setState((state) => {
        return { ...state, [setting]: enabled };
      });

      patch(`/profiles/${profile}/security`, {
        [setting]: enabled,
      });
    },
    [profile]
  );

  if (process.env.REACT_APP_TAILWIND) {
    return (
      <Page title={t('pages.security')} profile={profile}>
        {!!state && (
          <>
            <div>
              <Setting
                name={t('security.feeds.name')}
                description={t('security.feeds.description')}
                action={t('security.feeds.enable')}
                checked={state.threatIntelligenceFeeds}
                onChange={role === 'viewer' ? null : (enabled) => toggle('threatIntelligenceFeeds', enabled)}
              />
            </div>
            <div className="tw-mt-4">
              <SecurityTlds profile={profile} role={role} blockedTLDs={state.tlds} />
            </div>
            <div className="tw-mt-4">
              <Setting
                beta
                name={t('security.ai.name')}
                description={t('security.ai.description')}
                action={t('security.ai.enable')}
                checked={state.aiThreatDetection}
                onChange={role === 'viewer' ? null : (enabled) => toggle('aiThreatDetection', enabled)}
              />
            </div>
            <div className="tw-mt-4">
              <Setting
                name={t('security.googleSafeBrowsing.name')}
                description={t('security.googleSafeBrowsing.description')}
                action={t('security.googleSafeBrowsing.enable')}
                checked={state.googleSafeBrowsing}
                onChange={role === 'viewer' ? null : (enabled) => toggle('googleSafeBrowsing', enabled)}
              />
            </div>
            <div className="tw-mt-4">
              <Setting
                name={t('security.cryptojacking.name')}
                description={t('security.cryptojacking.description')}
                action={t('security.cryptojacking.enable')}
                checked={state.cryptojacking}
                onChange={role === 'viewer' ? null : (enabled) => toggle('cryptojacking', enabled)}
              />
            </div>
            <div className="tw-mt-4">
              <Setting
                name={t('security.dnsRebinding.name')}
                description={t('security.dnsRebinding.description')}
                action={t('security.dnsRebinding.enable')}
                checked={state.dnsRebinding}
                onChange={role === 'viewer' ? null : (enabled) => toggle('dnsRebinding', enabled)}
              />
            </div>
            <div className="tw-mt-4">
              <SecurityTlds profile={profile} role={role} blockedTLDs={state.tlds} />
            </div>
            <TranslationsFooter />
          </>
        )}
      </Page>
    );
  }

  return (
    <Page title={t('pages.security')} profile={profile}>
      {!!state && (
        <>
          <Row className="mb-3">
            <Col>
              <Card>
                <Card.Body>
                  <h5>{t('security.feeds.name')}</h5>
                  <div style={{ opacity: 0.6, fontSize: '0.9em' }}>{t('security.feeds.description')}</div>
                </Card.Body>
                <Card.Footer className="text-muted">
                  <div className="d-flex">
                    <div className="me-1">
                      <Form.Switch
                        id="security-threatIntelligenceFeeds"
                        label=""
                        checked={state.threatIntelligenceFeeds}
                        onChange={(event) => toggle('threatIntelligenceFeeds', event.target.checked)}
                        disabled={role === 'viewer'}
                      />
                    </div>
                    <div className="flex-grow-1 d-flex align-items-center" style={{ fontSize: '0.9em' }}>
                      <div>{t('security.feeds.enable')}</div>
                    </div>
                  </div>
                </Card.Footer>
              </Card>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <Card>
                <Card.Body>
                  <div className="d-flex align-items-center">
                    <h5>{t('security.ai.name')}</h5>
                    <div
                      style={{
                        background: 'orange',
                        borderRadius: 3,
                        padding: '1px 3px',
                        marginTop: -7,
                        marginLeft: 8,
                        fontSize: 10,
                        color: 'white',
                        fontWeight: 500,
                        textTransform: 'uppercase',
                      }}
                    >
                      {t('global.beta')}
                    </div>
                  </div>
                  <div style={{ opacity: 0.6, fontSize: '0.9em' }}>{t('security.ai.description')}</div>
                </Card.Body>
                <Card.Footer className="text-muted">
                  <div className="d-flex">
                    <div className="me-1">
                      <Form.Switch
                        id="security-aithreatdetection"
                        label=""
                        checked={state.aiThreatDetection}
                        onChange={(event) => toggle('aiThreatDetection', event.target.checked)}
                        disabled={role === 'viewer'}
                      />
                    </div>
                    <div className="flex-grow-1 d-flex align-items-center" style={{ fontSize: '0.9em' }}>
                      <div>{t('security.ai.enable')}</div>
                    </div>
                  </div>
                </Card.Footer>
              </Card>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <Card>
                <Card.Body>
                  <h5>{t('security.googleSafeBrowsing.name')}</h5>
                  <div style={{ opacity: 0.6, fontSize: '0.9em' }}>{t('security.googleSafeBrowsing.description')}</div>
                </Card.Body>
                <Card.Footer className="text-muted">
                  <div className="d-flex">
                    <div className="me-1">
                      <Form.Switch
                        id="security-googlesafebrowsing"
                        label=""
                        checked={state.googleSafeBrowsing}
                        onChange={(event) => toggle('googleSafeBrowsing', event.target.checked)}
                        disabled={role === 'viewer'}
                      />
                    </div>
                    <div className="flex-grow-1 d-flex align-items-center" style={{ fontSize: '0.9em' }}>
                      <div>{t('security.googleSafeBrowsing.enable')}</div>
                    </div>
                  </div>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Card>
                <Card.Body>
                  <h5>{t('security.cryptojacking.name')}</h5>
                  <div style={{ opacity: 0.6, fontSize: '0.9em' }}>{t('security.cryptojacking.description')}</div>
                </Card.Body>
                <Card.Footer className="text-muted">
                  <div className="d-flex">
                    <div className="me-1">
                      <Form.Switch
                        id="security-cryptojacking"
                        label=""
                        checked={state.cryptojacking}
                        onChange={(event) => toggle('cryptojacking', event.target.checked)}
                        disabled={role === 'viewer'}
                      />
                    </div>
                    <div className="flex-grow-1 d-flex align-items-center" style={{ fontSize: '0.9em' }}>
                      <div>{t('security.cryptojacking.enable')}</div>
                    </div>
                  </div>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Card>
                <Card.Body>
                  <h5>{t('security.dnsRebinding.name')}</h5>
                  <div style={{ opacity: 0.6, fontSize: '0.9em' }}>{t('security.dnsRebinding.description')}</div>
                </Card.Body>
                <Card.Footer className="text-muted">
                  <div className="d-flex">
                    <div className="me-1">
                      <Form.Switch
                        id="security-dnsrebinding"
                        label=""
                        checked={state.dnsRebinding}
                        onChange={(event) => toggle('dnsRebinding', event.target.checked)}
                        disabled={role === 'viewer'}
                      />
                    </div>
                    <div className="flex-grow-1 d-flex align-items-center" style={{ fontSize: '0.9em' }}>
                      <div>{t('security.dnsRebinding.enable')}</div>
                    </div>
                  </div>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Card>
                <Card.Body>
                  <h5>{t('security.homograph.name')}</h5>
                  <div style={{ opacity: 0.6, fontSize: '0.9em' }}>{t('security.homograph.description')}</div>
                </Card.Body>
                <Card.Footer className="text-muted">
                  <div className="d-flex">
                    <div className="me-1">
                      <Form.Switch
                        id="security-homograph"
                        label=""
                        checked={state.idnHomographs}
                        onChange={(event) => toggle('idnHomographs', event.target.checked)}
                        disabled={role === 'viewer'}
                      />
                    </div>
                    <div className="flex-grow-1 d-flex align-items-center" style={{ fontSize: '0.9em' }}>
                      <div>{t('security.homograph.enable')}</div>
                    </div>
                  </div>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Card>
                <Card.Body>
                  <h5>{t('security.typosquatting.name')}</h5>
                  <div style={{ opacity: 0.6, fontSize: '0.9em' }}>{t('security.typosquatting.description')}</div>
                </Card.Body>
                <Card.Footer className="text-muted">
                  <div className="d-flex">
                    <div className="me-1">
                      <Form.Switch
                        id="security-typosquatting"
                        label=""
                        checked={state.typosquatting}
                        onChange={(event) => toggle('typosquatting', event.target.checked)}
                        disabled={role === 'viewer'}
                      />
                    </div>
                    <div className="flex-grow-1 d-flex align-items-center" style={{ fontSize: '0.9em' }}>
                      <div>{t('security.typosquatting.enable')}</div>
                    </div>
                  </div>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Card>
                <Card.Body>
                  <h5>{t('security.dga.name')}</h5>
                  <div style={{ opacity: 0.6, fontSize: '0.9em' }}>{t('security.dga.description')}</div>
                </Card.Body>
                <Card.Footer className="text-muted">
                  <div className="d-flex">
                    <div className="me-1">
                      <Form.Switch
                        id="security-dga"
                        label=""
                        checked={state.dga}
                        onChange={(event) => toggle('dga', event.target.checked)}
                        disabled={role === 'viewer'}
                      />
                    </div>
                    <div className="flex-grow-1 d-flex align-items-center" style={{ fontSize: '0.9em' }}>
                      <div>{t('security.dga.enable')}</div>
                    </div>
                  </div>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Card>
                <Card.Body>
                  <h5>{t('security.nrd.name')}</h5>
                  <div style={{ opacity: 0.6, fontSize: '0.9em' }}>{t('security.nrd.description')}</div>
                </Card.Body>
                <Card.Footer className="text-muted">
                  <div className="d-flex">
                    <div className="me-1">
                      <Form.Switch
                        id="security-nrd"
                        label=""
                        checked={state.nrd}
                        onChange={(event) => toggle('nrd', event.target.checked)}
                        disabled={role === 'viewer'}
                      />
                    </div>
                    <div className="flex-grow-1 d-flex align-items-center" style={{ fontSize: '0.9em' }}>
                      <div>{t('security.nrd.enable')}</div>
                    </div>
                  </div>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Card>
                <Card.Body>
                  <div className="d-flex align-items-center">
                    <h5>{t('security.ddns.name')}</h5>
                    <div
                      style={{
                        background: 'orange',
                        borderRadius: 3,
                        padding: '1px 3px',
                        marginTop: -7,
                        marginLeft: 8,
                        fontSize: 10,
                        color: 'white',
                        fontWeight: 500,
                        textTransform: 'uppercase',
                      }}
                    >
                      {t('global.beta')}
                    </div>
                  </div>
                  <div style={{ opacity: 0.6, fontSize: '0.9em' }}>{t('security.ddns.description')}</div>
                  <div className="mt-2" style={{ opacity: 0.4, fontSize: '0.9em' }}>
                    {t('security.ddns.disclaimer')}
                  </div>
                </Card.Body>
                <Card.Footer className="text-muted">
                  <div className="d-flex">
                    <div className="me-1">
                      <Form.Switch
                        id="security-ddns"
                        label=""
                        checked={state.ddns}
                        onChange={(event) => toggle('ddns', event.target.checked)}
                        disabled={role === 'viewer'}
                      />
                    </div>
                    <div className="flex-grow-1 d-flex align-items-center" style={{ fontSize: '0.9em' }}>
                      <div>{t('security.ddns.enable')}</div>
                    </div>
                  </div>
                </Card.Footer>
              </Card>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <Card>
                <Card.Body>
                  <h5>{t('security.parked.name')}</h5>
                  <div style={{ opacity: 0.6, fontSize: '0.9em' }}>{t('security.parked.description')}</div>
                </Card.Body>
                <Card.Footer className="text-muted">
                  <div className="d-flex">
                    <div className="me-1">
                      <Form.Switch
                        id="security-parked"
                        label=""
                        checked={state.parking}
                        onChange={(event) => toggle('parking', event.target.checked)}
                        disabled={role === 'viewer'}
                      />
                    </div>
                    <div className="flex-grow-1 d-flex align-items-center" style={{ fontSize: '0.9em' }}>
                      <div>{t('security.parked.enable')}</div>
                    </div>
                  </div>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <SecurityTlds profile={profile} role={role} blockedTLDs={state.tlds} />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Card>
                <Card.Body>
                  <h5>{t('security.csam.name')}</h5>
                  <div style={{ opacity: 0.6, fontSize: '0.9em' }}>{t('security.csam.description')}</div>
                </Card.Body>
                <Card.Footer className="text-muted">
                  <div className="d-flex">
                    <div className="me-1">
                      <Form.Switch
                        id="security-csam"
                        label=""
                        checked={state.csam}
                        onChange={(event) => toggle('csam', event.target.checked)}
                        disabled={role === 'viewer'}
                      />
                    </div>
                    <div className="flex-grow-1 d-flex align-items-center" style={{ fontSize: '0.9em' }}>
                      <div>{t('security.csam.enable')}</div>
                    </div>
                  </div>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
          <TranslationsFooter />
        </>
      )}
    </Page>
  );
}
