import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Card, Form } from 'react-bootstrap';
import { get, patch } from '../api2';

import Page from '../Page';
import TranslationsFooter from '../Components/TranslationsFooter';
import PrivacyBlocklists from './PrivacyBlocklists';
import PrivacyNatives from './PrivacyNatives';

import Incognito from '../images/incognito.svg';

export default function Privacy({ profile, role }) {
  const { t } = useTranslation();

  const [state, setState] = useState();

  useEffect(() => {
    setState();

    return get(`/profiles/${profile}/privacy`, ({ data }) => {
      setState(data);
    });
  }, [profile]);

  const toggle = useCallback(
    (setting, enabled) => {
      setState((state) => {
        return { ...state, [setting]: enabled };
      });

      patch(`/profiles/${profile}/privacy`, {
        [setting]: enabled,
      });
    },
    [profile]
  );

  return (
    <Page title={t('pages.privacy')} className="Privacy" profile={profile}>
      {!!state && (
        <>
          <Row className="mb-3">
            <Col>
              <PrivacyBlocklists profile={profile} role={role} blocklists={state.blocklists} />
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <PrivacyNatives profile={profile} role={role} natives={state.natives} />
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <Card>
                <Card.Body>
                  <h5>{t('privacy.disguised.name')}</h5>
                  <div style={{ opacity: 0.6, fontSize: '0.9em' }}>{t('privacy.disguised.description')}</div>
                </Card.Body>
                <Card.Footer className="text-muted">
                  <div className="d-flex">
                    <div className="me-1">
                      <Form.Switch
                        id="privacy-blockDisguised"
                        label=""
                        checked={state.disguisedTrackers}
                        onChange={(event) => toggle('disguisedTrackers', event.target.checked)}
                        disabled={role === 'viewer'}
                      />
                    </div>
                    <div className="flex-grow-1 d-flex align-items-center" style={{ fontSize: '0.9em' }}>
                      <div>{t('privacy.disguised.enable')}</div>
                    </div>
                  </div>
                </Card.Footer>
              </Card>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <Card>
                <Card.Body>
                  <h5>{t('privacy.affiliate.name')}</h5>
                  <div style={{ opacity: 0.6, fontSize: '0.9em' }}>{t('privacy.affiliate.description')}</div>
                  <div className="d-flex mt-3">
                    <img
                      src={Incognito}
                      alt=""
                      className="d-inline-block"
                      style={{ width: 18, height: 18, marginRight: 7 }}
                    />
                    <div style={{ opacity: 0.7, fontSize: 13, fontWeight: 500 }}>{t('privacy.affiliate.relay')}</div>
                  </div>
                </Card.Body>
                <Card.Footer className="text-muted">
                  <div className="d-flex">
                    <div className="me-1">
                      <Form.Switch
                        id="privacy-allowaffiliate"
                        label=""
                        checked={state.allowAffiliate}
                        onChange={(event) => toggle('allowAffiliate', event.target.checked)}
                        disabled={role === 'viewer'}
                      />
                    </div>
                    <div className="flex-grow-1 d-flex align-items-center" style={{ fontSize: '0.9em' }}>
                      <div>{t('privacy.affiliate.enable')}</div>
                    </div>
                  </div>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
          <TranslationsFooter />
        </>
      )}
    </Page>
  );
}
