import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

export default function NoProfile() {
  return (
    <>
      <Container>
        <Row>
          <Col className="p-5 text-center text-muted">No profiles yet.</Col>
        </Row>
      </Container>
    </>
  );
}
