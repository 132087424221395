import React, { useEffect, useCallback, useMemo } from 'react';
import axios from 'axios';

import { AppContext } from './contexts';
import * as NotificationCenter from './NotificationCenter';

export default function AppContextProvider({ children }) {
  const handleNetworkError = useCallback((error) => {
    if (axios.isCancel(error)) {
      return;
    }

    let message = error.message;
    let details = error.stack;

    if (error.isAxiosError) {
      const enhanced = error.toJSON();
      message = enhanced.message;
      details = enhanced.config.method.toUpperCase() + ' ' + enhanced.config.url;
    }

    NotificationCenter.push(
      NotificationCenter.error({
        title: message,
        description: details,
      })
    );
  }, []);

  const value = useMemo(() => {
    return {
      handleNetworkError,
    };
  }, [handleNetworkError]);

  // TODO: remove when all axios calls catch and call handleNetworkError themselves
  useEffect(() => {
    const handler = (event) => {
      handleNetworkError(event.detail);
    };

    window.addEventListener('axioserror', handler);

    return () => {
      window.removeEventListener('axioserror', handler);
    };
  }, [handleNetworkError]);

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}
