import React, { useState, useEffect, useRef, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, ListGroup, Modal, Button, Spinner } from 'react-bootstrap';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/solid';
import { del, post, get } from '../api2';
import { hex, unpuny, classNames } from '../helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

export default function SecurityTlds({ blockedTLDs: initialBlockedTLDs, profile, role }) {
  const { t } = useTranslation();
  const [blockedTLDs, setBlockedTLDs] = useState(initialBlockedTLDs);
  const [tlds, setTlds] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const source = useRef();

  const fetchModalContent = () => {
    if (tlds === null) {
      const cancel = get(`/security/tlds`, ({ data }) => {
        setTlds({
          spamhaus: data.filter((item) => item.spamhaus > 0).sort((a, b) => (b.spamhaus < a.spamhaus ? -1 : 1)),
          all: data,
        });
      });
      source.current.add(cancel);
    }
  };

  const add = (tld) => {
    setBlockedTLDs(currentBlockedTLDs => [...currentBlockedTLDs, { id: tld }]);
    const cancel = post(`/profiles/${profile}/security/tlds`, { id: tld });
    source.current.add(cancel);
  };

  const remove = (tld) => {
    setBlockedTLDs(currentBlockedTLDs => currentBlockedTLDs.filter(({ id: _tld }) => _tld !== tld));
    const cancel = del(`/profiles/${profile}/security/tlds/${hex(tld)}`);
    source.current.add(cancel);
  };

  const openModal = () => {
    fetchModalContent();
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    source.current = new Set();
    return () => {
      source.current.forEach(func => func());
    };
  }, []);

  if (process.env.REACT_APP_TAILWIND) {
    return (
      <>
        <div className="tw-overflow-hidden tw-rounded-md tw-border dark:tw-border-slate-800">
          <div className="tw-px-6 tw-py-5">
            <h3 className="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900 dark:tw-text-slate-200">
              {t('security.tld.name')}
            </h3>
            <div className="tw-mt-2 tw-text-sm tw-text-gray-500">{t('security.tld.description')}</div>
          </div>
          {blockedTLDs.map(({ id: tld }) => (
            <div
              key={tld}
              className="tw-flex tw-items-center tw-border-l-2 tw-border-t tw-border-l-red-500 tw-text-sm dark:tw-border-t-slate-800"
            >
              <div className="tw-grow tw-px-6 tw-py-3 tw-font-mono tw-text-sm dark:tw-text-slate-200">
                <span className="tw--ml-0.5">.{unpuny(tld)}</span>
              </div>
              <button
                className="mouse:hover:tw-text-slate-400 mouse:hover:dark:tw-text-slate-400 tw-block tw-h-10 tw-cursor-pointer tw-px-4 tw-text-slate-300 dark:tw-text-slate-500"
                onClick={() => remove(tld)}
              >
                <XIcon className="tw-h-4 tw-w-4" />
              </button>
            </div>
          ))}
          <div className="tw-border-t tw-bg-slate-50 tw-px-6 tw-py-4 dark:tw-border-slate-800 dark:tw-bg-slate-800/30">
            <button
              type="button"
              className="tw-bg-nextdnsblue tw-w-full tw-rounded-md tw-px-3 tw-py-2 tw-text-sm tw-font-medium tw-leading-4 tw-text-white hover:tw-text-opacity-80 focus:tw-outline-none sm:tw-w-fit"
              onClick={openModal}
            >
              {t('security.tld.add')}
            </button>
          </div>
        </div>

        <Transition.Root show={showModal} as={Fragment}>
          <Dialog as="div" className="tw-relative tw-z-10" onClose={closeModal}>
            <Transition.Child
              as={Fragment}
              enter="tw-ease-out tw-duration-300"
              enterFrom="tw-opacity-0"
              enterTo="tw-opacity-100"
              leave="tw-ease-in tw-duration-200"
              leaveFrom="tw-opacity-100"
              leaveTo="tw-opacity-0"
            >
              <div className="tw-fixed tw-inset-0 tw-bg-gray-500/75 tw-transition-opacity dark:tw-bg-slate-900/75" />
            </Transition.Child>

            <div className="tw-fixed tw-inset-0 tw-z-10 tw-overflow-y-auto">
              <div className="tw-flex tw-min-h-full tw-items-end tw-justify-center tw-p-4 sm:tw-items-start sm:tw-p-0">
                <Transition.Child
                  as={Fragment}
                  enter="tw-ease-out tw-duration-300"
                  enterFrom="tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
                  enterTo="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
                  leave="tw-ease-in tw-duration-200"
                  leaveFrom="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
                  leaveTo="tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
                >
                  <Dialog.Panel className="tw-relative tw-w-full tw-transform tw-overflow-hidden tw-rounded-md tw-bg-white tw-shadow-xl tw-transition-all dark:tw-bg-slate-900 sm:tw-my-8 sm:tw-w-full sm:tw-max-w-md">
                    {tlds !== null ? (
                      <>
                        {tlds.all.map(({ id: tld }) => {
                          const isBlocked = blockedTLDs.some(({ id }) => id === tld);
                          return (
                            <div
                              key={tld}
                              className={classNames(
                                isBlocked ? 'tw-border-l-red-500' : 'tw-border-l-transparent',
                                'tw-flex tw-items-center tw-border-l-2 tw-border-t tw-text-sm dark:tw-border-t-slate-800'
                              )}
                            >
                              <div className="tw-grow tw-px-6 tw-py-3 tw-font-mono tw-text-sm dark:tw-text-slate-200">
                                <span className="tw--ml-1">.{unpuny(tld)}</span>
                              </div>
                              <button
                                className="mouse:hover:tw-text-slate-400 mouse:hover:dark:tw-text-slate-400 tw-block tw-h-10 tw-cursor-pointer tw-px-4 tw-text-slate-300 dark:tw-text-slate-500"
                                onClick={() => remove(tld)}
                              >
                                <XIcon className="tw-h-4 tw-w-4" />
                              </button>
                            </div>
                          );
                        })}
                      </>
                    ) : (
                      <div className="text-center py-3">
                        <Spinner
                          animation="border"
                          style={{ width: '2rem', height: '2rem', opacity: 0.15 }}
                          className="my-4"
                        />
                      </div>
                    )}
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    );
  }

  return (
    <>
      <Card>
        <ListGroup variant="flush">
          <ListGroup.Item className="py-3">
            <h5>{t('security.tld.name')}</h5>
            <div style={{ opacity: 0.6, fontSize: '0.9em' }}>{t('security.tld.description')}</div>
          </ListGroup.Item>
          {blockedTLDs.map(({ id: tld }) => (
            <ListGroup.Item key={tld} style={{ borderLeft: '4px solid #ff4136' }}>
              <div className="d-flex">
                <div className="flex-grow-1 d-flex align-items-center">
                  <div>
                    <span className="notranslate" style={{ fontWeight: 500, marginLeft: -4 }}>
                      .{unpuny(tld)}
                    </span>
                  </div>
                </div>
                {role !== 'viewer' && (
                  <div className="d-flex align-items-center">
                    <Button
                      variant="link"
                      className="me-n3 my-n1"
                      style={{ opacity: 0.3, color: '#222' }}
                      onClick={() => remove(tld)}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </Button>
                  </div>
                )}
              </div>
            </ListGroup.Item>
          ))}
        </ListGroup>
        <Card.Footer className="text-muted" style={{ borderTop: 'none' }}>
          <Button
            size="sm"
            style={{ fontWeight: 'bold', fontSize: 12, textTransform: 'uppercase' }}
            onClick={openModal}
            disabled={role === 'viewer'}
          >
            {t('security.tld.add')}
          </Button>
        </Card.Footer>
      </Card>

      <Modal
        show={showModal}
        animation={false}
        size="lg"
        scrollable={true}
        onHide={closeModal}
        onShow={fetchModalContent}
      >
        <Modal.Header closeButton>
          <Modal.Title as="h5">{t('security.tld.add')}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          {tlds === null && (
            <div className="text-center py-3">
              <Spinner animation="border" style={{ width: '2rem', height: '2rem', opacity: 0.15 }} className="my-4" />
            </div>
          )}
          {tlds !== null && (
            <ListGroup variant="flush">
              <ListGroup.Item className="p-2 ps-3 pt-4">
                <span
                  style={{ textTransform: 'uppercase', fontSize: 13, fontWeight: 600, opacity: 0.4, marginLeft: 4 }}
                >
                  {t('security.tld.sections.spamhaus')}
                </span>
              </ListGroup.Item>
              {tlds.spamhaus.map(({ id: tld }) => {
                const isBlocked = blockedTLDs.some(({ id }) => id === tld);
                return (
                  <ListGroup.Item
                    key={tld}
                    style={{
                      borderLeft: '4px solid #ccc',
                      borderLeftColor: isBlocked ? '#ff4136' : '',
                    }}
                  >
                    <div className="d-flex">
                      <div className="flex-grow-1 d-flex align-items-center">
                        <div>
                          <span className="notranslate" style={{ fontWeight: 500, marginLeft: -4 }}>
                            .{unpuny(tld)}
                          </span>
                        </div>
                      </div>
                      <div className="d-flex align-items-center ms-4">
                        {isBlocked && (
                          <Button
                            variant="danger"
                            size="sm"
                            style={{ fontWeight: 'bold', fontSize: 12, textTransform: 'uppercase' }}
                            onClick={() => remove(tld)}
                          >
                            {t('global.remove')}
                          </Button>
                        )}
                        {!isBlocked && (
                          <Button
                            size="sm"
                            style={{ fontWeight: 'bold', fontSize: 12, textTransform: 'uppercase' }}
                            onClick={() => add(tld)}
                          >
                            {t('global.add')}
                          </Button>
                        )}
                      </div>
                    </div>
                  </ListGroup.Item>
                );
              })}

              <ListGroup.Item className="p-2 ps-3 pt-4">
                <span
                  style={{ textTransform: 'uppercase', fontSize: 13, fontWeight: 600, opacity: 0.4, marginLeft: 4 }}
                >
                  {t('security.tld.sections.all')}
                </span>
              </ListGroup.Item>
              {tlds.all.map(({ id: tld }) => {
                const isBlocked = blockedTLDs.some(({ id }) => id === tld);
                return (
                  <ListGroup.Item
                    key={tld}
                    style={{
                      borderLeft: '4px solid #ccc',
                      borderLeftColor: isBlocked ? '#ff4136' : '',
                    }}
                  >
                    <div className="d-flex">
                      <div className="flex-grow-1 d-flex align-items-center">
                        <div>
                          <span className="notranslate" style={{ fontWeight: 500, marginLeft: -4 }}>
                            .{unpuny(tld)}
                          </span>
                        </div>
                      </div>
                      <div className="d-flex align-items-center ms-4">
                        {isBlocked && (
                          <Button
                            variant="danger"
                            size="sm"
                            style={{
                              fontWeight: 'bold',
                              fontSize: 12,
                              textTransform: 'uppercase',
                              whiteSpace: 'nowrap',
                            }}
                            onClick={() => remove(tld)}
                          >
                            {t('global.remove')}
                          </Button>
                        )}
                        {!isBlocked && (
                          <Button
                            size="sm"
                            style={{
                              fontWeight: 'bold',
                              fontSize: 12,
                              textTransform: 'uppercase',
                              whiteSpace: 'nowrap',
                            }}
                            onClick={() => add(tld)}
                          >
                            {t('global.add')}
                          </Button>
                        )}
                      </div>
                    </div>
                  </ListGroup.Item>
                );
              })}
            </ListGroup>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}