import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, ListGroup, Button } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

import AccountSubscriptionCancelSubscriptionButton from './AccountSubscriptionCancelSubscriptionButton';

import PaypalIcon from '../images/payments/paypal-icon.svg';

import Visa from '../images/payments/visa.svg';
import Mastercard from '../images/payments/mastercard.svg';
import Amex from '../images/payments/amex.svg';
import UnionPay from '../images/payments/unionpay.svg';
import Diners from '../images/payments/diners.svg';
import Discover from '../images/payments/discover.svg';
import Jcb from '../images/payments/jcb.svg';
import UnknownCard from '../images/payments/unknown.svg';

const CardImages = {
  amex: Amex,
  diners: Diners,
  discover: Discover,
  jcb: Jcb,
  mastercard: Mastercard,
  unionpay: UnionPay,
  visa: Visa,
  unknown: UnknownCard,
};

export default function AccountSubscription(props) {
  const { t } = useTranslation();

  return (
    <Card>
      <ListGroup variant="flush">
        <ListGroup.Item className="py-3">
          <h5>{t('account.subscription.name')}</h5>

          {props.subscription.plan && (
            <div className="mt-3 mb-2">
              {!!props.subscription.paymentMethod && (
                <>
                  <FontAwesomeIcon icon={faCheckCircle} style={{ color: '#2ecc40', marginRight: 6 }} />
                  <span style={{ opacity: 0.8 }}>
                    {!props.subscription.units &&
                      t('account.subscription.planFull', {
                        plan: t(`account.subscription.plans.${props.subscription.plan}`),
                        interval: t(`account.subscription.intervals.${props.subscription.billingInterval}`),
                      })}
                    {!!props.subscription.units &&
                      t('account.subscription.planFullWithUnits', {
                        plan: t(`account.subscription.plans.${props.subscription.plan}`),
                        units: t(`account.subscription.units.${props.subscription.plan}`, {
                          count: props.subscription.units,
                        }),
                        interval: t(`account.subscription.intervals.${props.subscription.billingInterval}`),
                      })}
                  </span>
                </>
              )}
              {!props.subscription.paymentMethod && (
                <>
                  <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'orange', marginRight: 6 }} />
                  <span style={{ opacity: 0.8 }}>
                    {t('account.subscription.expires', { date: [props.subscription.expiresOn, 'LL'] })}
                  </span>
                </>
              )}
            </div>
          )}

          {!props.subscription.paymentMethod && (
            <Button className="mt-2" onClick={props.onSubscribeClicked}>
              {t('account.subscription.restart.button')}
            </Button>
          )}

          {!!props.subscription.paymentMethod && (
            <Button variant="link" className="mt-2 p-0" size="sm" onClick={props.onChangePlanClicked}>
              {t('account.subscription.changePlan.button')}
            </Button>
          )}
        </ListGroup.Item>
        {!!props.subscription.paymentMethod && (
          <ListGroup.Item className="py-3">
            {props.subscription.paymentMethod.type === 'card' && (
              <div>
                <img
                  src={CardImages[props.subscription.paymentMethod.card.brand]}
                  alt=""
                  className="d-inline-block me-2"
                />
                <span className="notranslate" style={{ fontSize: '0.9em', opacity: 0.8, fontWeight: 500 }}>
                  {props.subscription.paymentMethod.card.number}
                </span>
              </div>
            )}
            {props.subscription.paymentMethod.type === 'paypal' && (
              <div>
                <img src={PaypalIcon} alt="" className="d-inline-block me-2" style={{ height: 18 }} />
                <span className="notranslate" style={{ fontSize: '0.9em', opacity: 0.8, fontWeight: 500 }}>
                  {props.subscription.paymentMethod.paypal.email}
                </span>
              </div>
            )}
            <div className="mt-2" style={{ fontSize: '0.9em', opacity: 0.6 }}>
              {t('account.subscription.renewal', {
                amount: [props.subscription.paymentMethod.renewsFor, props.subscription.paymentMethod.currency],
                date: [props.subscription.validUntil, 'LL'],
              })}
            </div>
            <Button variant="link" className="mt-3 p-0" size="sm" onClick={props.onChangePaymentMethodClicked}>
              {t('account.subscription.changePaymentMethod.button')}
            </Button>
          </ListGroup.Item>
        )}
        {!!props.subscription.paymentMethod && (
          <ListGroup.Item className="py-3">
            <AccountSubscriptionCancelSubscriptionButton
              subscription={props.subscription}
              onSubscriptionCanceled={props.onSubscriptionUpdated}
            />
          </ListGroup.Item>
        )}
      </ListGroup>
    </Card>
  );
}
