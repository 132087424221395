import React, { useContext } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useTheme } from './theme';

import { AccountContext } from './contexts';

import Account from './Account/Account';
import Start from './Start';
import SignUp from './Auth/SignUp';
import LogIn from './Auth/LogIn';
import LogOut from './Auth/LogOut';
import ResetPassword from './Auth/ResetPassword';
import ProfileInvite from './ProfileInvite';
import Invoice from './Invoice';
import Profile from './Profile';
import NoProfile from './NoProfile';
import Header from './Header/Header';

import lastProfileSaver from './lastProfileSaver';

function getValidProfile(profiles, id) {
  const ownedProfile = profiles.find((profile) => profile.id === id);
  if (ownedProfile) {
    return ownedProfile.id;
  }
  const firstProfile = profiles[0];
  if (firstProfile) {
    return firstProfile.id;
  }
  return null;
}

export default function App() {
  const account = useContext(AccountContext);
  const { i18n } = useTranslation();
  const theme = useTheme();

  if (account.ready) {
    return (
      <>
        <Helmet>
          <html lang={i18n.language} dir={i18n.dir(i18n.language)} data-bs-theme={theme} />
        </Helmet>
        <Router>
          <Routes>
            <Route path="/signup" element={<SignUp />} />
            <Route path="/reset-password/:token?" element={<ResetPassword />} />
            {account.account ? (
              <>
                <Route path="/logout" element={<LogOut />} />
                <Route path="/invite/:token" element={<ProfileInvite />} />
                <Route path="/invoice/:code" element={<Invoice />} />
                <Route path="/account" element={
                  <>
                    <Header simple />
                    <Account />
                  </>
                }
                />
                <Route path="/:profile/:menu?" element={<ProfileRoute />} />
                <Route path="/*" element={<ProfileRedirect />} />
              </>
            ) : (
              <>
                <Route path="/start" element={<Start />} />
                <Route path="/login" element={<LogIn />} />
                <Route path="/*" element={
                  <Navigate to={'/login' + getLoginNextQuery()} replace />
                } />
              </>
            )}
          </Routes>
        </Router>
      </>
    );
  }
  return null;
}

function getLoginNextQuery() {
  const path = window.location.pathname;
  if (path.startsWith('/invite/') || path.startsWith('/account')) {
    return `?next=${encodeURIComponent(path+window.location.search+window.location.hash)}`;
  }
  return '';
}

function ProfileRoute() {
  const params = useParams();
  const account = useContext(AccountContext);
  let profile = getValidProfile(account.profiles, params.profile);
  // Required profile doesn't belong to the viewer
  if (profile !== params.profile) {
    // Redirect to some profile (last saved or first in list)
    if (profile) {
      return <Navigate to={`/${profile}`} replace />;
    } else {
      // Wasn't able to default on any profile, redirect to the "No Profile" screen
      return <Navigate to="/" replace />;
    }
  } else {
    return (
      <>
        <Header profile={profile} />
        <Profile profile={profile} />
      </>
    );
  }
}

function ProfileRedirect() {
  const account = useContext(AccountContext);
  // Redirect to some profile (last saved or first in list)
  const nextProfile = getValidProfile(account.profiles, lastProfileSaver.get());
  if (nextProfile) {
    return <Navigate to={`/${nextProfile}`} replace />;
  }
  // Wasn't able to default on any profile
  return (
    <>
      <Header />
      <NoProfile />
    </>
  );
}

