import React, { useState, useEffect, useRef } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Card, Modal, Form, Button, Spinner } from 'react-bootstrap';
import axios from 'axios';

import { API_BASE_URL } from '../config';

export default function AccountDeleteAccount({ email }) {
  const [showModal, setShowModal] = useState(false);
  const [currentPasswordValue, setCurrentPasswordValue] = useState('');
  const [currentPasswordError, setCurrentPasswordError] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [accountDeleted, setAccountDeleted] = useState(false);
  const { t } = useTranslation();
  const source = useRef(null);
  const isModalClosable = useRef(true);

  useEffect(() => {
    source.current = axios.CancelToken.source();
    return () => {
      source.current.cancel();
    };
  }, []);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setCurrentPasswordValue('');
    setCurrentPasswordError(null);
    setIsDeleting(false);
  };

  const onCurrentPasswordValueChange = (event) => {
    setCurrentPasswordValue(event.target.value);
    setCurrentPasswordError(null);
  };

  const deleteAccount = async (event) => {
    event.preventDefault();

    isModalClosable.current = false;
    setIsDeleting(true);

    const { data } = await axios.delete(`${API_BASE_URL}/accounts/@me`, {
      data: {
        password: currentPasswordValue,
      },
      cancelToken: source.current.token,
    });

    if (data.error) {
      isModalClosable.current = true;
      setCurrentPasswordValue('');
      setCurrentPasswordError(data.error);
      setIsDeleting(false);
      return;
    }

    setAccountDeleted(true);
  };

  return (
    <>
      {accountDeleted && <Navigate to="/logout" replace />}

      <Card body border="danger">
        <Button variant="danger" onClick={openModal}>
          {t('account.delete.button')}
        </Button>
        <Form.Text className="mt-2" as="div">
          {t('account.delete.warning')}
        </Form.Text>
      </Card>

      <Modal show={showModal} animation={false} onHide={closeModal}>
        <Form onSubmit={deleteAccount} action="#submit" noValidate>
          <Modal.Header closeButton>
            <Modal.Title as="h5">{t('account.delete.button')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-4">{t('account.delete.confirm.text')}</div>
            <Form.Group>
              <Form.Label>{t('account.currentPassword')}</Form.Label>
              <Form.Control
                type="password"
                autoFocus
                autoComplete="current-password"
                isInvalid={!!currentPasswordError}
                value={currentPasswordValue}
                onChange={onCurrentPasswordValueChange}
              />
              {!!currentPasswordError && (
                <Form.Control.Feedback type="invalid">
                  {t(`account.errors.currentPassword.${currentPasswordError}`)}
                </Form.Control.Feedback>
              )}
              <div className="mt-1 text-end" style={{ fontSize: '0.8em' }}>
                <Link to={`/reset-password?email=${encodeURIComponent(email)}`}>
                  {t('account.forgotPassword')}
                </Link>
              </div>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-secondary" disabled={isDeleting} onClick={closeModal}>
              {t('global.cancel')}
            </Button>

            {!isDeleting && (
              <Button type="submit" variant="danger">
                {t('global.delete')}
              </Button>
            )}
            {isDeleting && (
              <Button variant="danger" disabled>
                <Spinner as="span" animation="border" size="sm" className="me-2" />
                {t('global.delete')}
              </Button>
            )}
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
