import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, Modal, Button, Spinner } from 'react-bootstrap';
import { patch } from '../api2';

import CountryFlag from '../Components/CountryFlag';

const LOCATIONS = ['us', 'eu', 'gb', 'ch'];

const FLAGS = {
  us: 'US',
  eu: 'EU',
  gb: 'GB',
  ch: 'CH',
};

export default function SettingsLogsLocationDropdown({ location: initialLocation, profile, role }) {
  const { t } = useTranslation();
  const [location, setLocation] = useState(initialLocation);
  const [newLocation, setNewLocation] = useState(null);
  const [isChanging, setIsChanging] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const isModalClosable = useRef(true);
  const source = useRef();

  useEffect(() => {
    source.current = new Set();

    return () => {
      source.current.forEach(cancel => cancel());
    };
  }, []);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    if (!isModalClosable.current) {
      return;
    }

    setShowModal(false);
    setNewLocation(null);
    setIsChanging(false);
  };

  const onNewLocationSelected = (selectedLocation) => {
    if (selectedLocation === location) {
      return;
    }

    setNewLocation(selectedLocation);
    openModal();
  };

  const changeLocation = () => {
    isModalClosable.current = false;
    setIsChanging(true);

    const cancel = patch(`/profiles/${profile}/settings/logs`, { location: newLocation }, () => {
      setLocation(newLocation);
      setNewLocation(null);
      isModalClosable.current = true;
      closeModal();
    });

    source.current.add(cancel);
  };

  return (
    <>
      <Dropdown>
        <Dropdown.Toggle variant="light" size="sm" disabled={role === 'viewer'}>
          {FLAGS[location] && (
            <>
              <CountryFlag code={FLAGS[location]} />{' '}
              {t(`settings.logs.location.locations.${location}.name`)}
            </>
          )}
        </Dropdown.Toggle>
        <Dropdown.Menu style={{ maxWidth: '80vw' }}>
          {LOCATIONS.filter(loc => loc !== 'gb').map(loc => (
            <Dropdown.Item
              key={loc}
              active={loc === location}
              onClick={() => onNewLocationSelected(loc)}
            >
              <div>
                <CountryFlag code={FLAGS[loc]} />{' '}
                {t(`settings.logs.location.locations.${loc}.name`)}
              </div>
              <div style={{ fontSize: '0.8em', opacity: 0.5, marginTop: 3, whiteSpace: 'normal' }}>
                {t(`settings.logs.location.locations.${loc}.description`)}
              </div>
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>

      <Modal show={showModal} animation={false} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title as="h5">{t('settings.logs.location.confirm.title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t('settings.logs.location.confirm.text')}</Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" disabled={isChanging} onClick={closeModal}>
            {t('global.cancel')}
          </Button>

          {!isChanging ? (
            <Button variant="danger" onClick={changeLocation}>
              {t('global.change')}
            </Button>
          ) : (
            <Button variant="danger" disabled>
              <Spinner as="span" animation="border" size="sm" className="me-2" />
              {t('global.change')}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}