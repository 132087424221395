import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Card, ListGroup, Spinner } from 'react-bootstrap';

import { get } from '../api2';

export default function AnalyticsReasons({ profile, device, timeRange }) {
  const { t, i18n } = useTranslation();

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);

    return get(
      `/profiles/${profile}/analytics/reasons`,
      {
        device,
        ...timeRange,
        limit: 6,
        lang: i18n.language,
      },
      ({ data }) => {
        setData(data);
        setLoading(false);
      }
    );
  }, [profile, device, timeRange, i18n.language]);

  return (
    <Col lg="6">
      <Card className="mb-4">
        <ListGroup variant="flush">
          <ListGroup.Item className="py-3">
            <h5>{t('analytics.reasons.name')}</h5>
            <div style={{ opacity: 0.6, fontSize: '0.9em' }}>{t('analytics.reasons.description')}</div>
          </ListGroup.Item>
          {loading ? (
            <ListGroup.Item className="text-center">
              <Spinner animation="border" style={{ width: '3rem', height: '3rem', opacity: 0.15 }} className="my-5" />
            </ListGroup.Item>
          ) : data.length === 0 ? (
            <ListGroup.Item className="text-center my-2" style={{ opacity: 0.4 }}>
              {t('analytics.reasons.empty')}
            </ListGroup.Item>
          ) : (
            data.map((list) => (
              <ListGroup.Item key={list.id}>
                <div className="d-flex">
                  <div className="flex-grow-1 text-truncate notranslate">{list.name}</div>
                  <div className="ms-3" style={{ opacity: 0.6 }}>
                    {i18n.format(list.queries, 'number')}
                  </div>
                </div>
              </ListGroup.Item>
            ))
          )}
        </ListGroup>
      </Card>
    </Col>
  );
}
