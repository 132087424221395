import React, { lazy, useState, useEffect, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Card, Spinner } from 'react-bootstrap';

import { get } from '../api2';
import { useTheme } from '../theme';

const AnalyticsGAFAMPieChart = lazy(() => import('./AnalyticsGAFAMPieChart'));

const companyColorsLight = {
  google: '#4285f4',
  amazon: '#ff9900',
  facebook: '#3b5998',
  apple: '#323232',
  microsoft: '#7cbb00',
  others: '#f3f5f7',
};

const companyColorsDark = {
  google: '#4285f4',
  amazon: '#ff9900',
  facebook: '#3b5998',
  apple: '#ffffff',
  microsoft: '#7cbb00',
  others: '#494B4D',
};


export default function AnalyticsGAFAM({ profile, device, timeRange }) {
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  const [data, setData] = useState();
  const [empty, setEmpty] = useState(true);
  const [loading, setLoading] = useState(true);

  const companyColors = theme === 'dark' ? companyColorsDark : companyColorsLight;

  useEffect(() => {
    setLoading(true);

    return get(
      `/profiles/${profile}/analytics/destinations?type=gafam`,
      {
        device,
        ...timeRange,
      },
      ({ data }) => {
        if (data.length > 0) {
          const othersIndex = data.findIndex((item) => item.company === 'others');

          if (othersIndex > -1) {
            setData(
              data
                .slice(0, othersIndex)
                .concat(data.slice(othersIndex + 1))
                .concat([data[othersIndex]])
            );
          } else {
            setData(data);
          }
        } else {
          setData([]);
        }

        setEmpty(true);

        for (const entry of data) {
          if (entry.queries > 0) {
            setEmpty(false);
          }
        }

        setLoading(false);
      }
    );
  }, [profile, device, timeRange]);

  return (
    <Col lg="6">
      <Card body className="mb-4">
        <h5>{t('analytics.gafam.name')}</h5>
        <div style={{ opacity: 0.6, fontSize: '0.9em' }}>{t('analytics.gafam.description')}</div>
        <div className="mt-3">
          {loading ? (
            <div className="text-center">
              <Spinner animation="border" style={{ width: '3rem', height: '3rem', opacity: 0.15 }} className="my-5" />
            </div>
          ) : (
            <div className="d-flex mt-4 ps-xl-5 pb-md-2">
              <div className='flex-grow-1'>
                <Suspense fallback={<canvas width={160} height={160} style={{ width: '100%', height: 'auto' }} />}>
                  <AnalyticsGAFAMPieChart empty={empty} data={data} companyColors={companyColors} />
                </Suspense>
              </div>
              <div className="ms-4">
                <div className="d-flex flex-wrap">
                  {data.map((entry, index) => (
                    <div key={index} className="d-flex" style={{ width: '100%', marginBottom: 3 }}>
                      <div
                        style={{
                          width: 7,
                          height: 7,
                          marginTop: 10,
                          marginRight: 4,
                          borderRadius: '50%',
                          backgroundColor: companyColors[entry.company],
                        }}
                      ></div>
                      <div>
                        <small>
                          <span style={{ fontWeight: 500 }}>
                            {t(`analytics.gafam.companies.${entry.company}.name`)}
                          </span>
                          <span> </span>
                          <span style={{ opacity: 0.7 }}>
                            {i18n.format(
                              entry.queries / data.reduce((acc, item) => acc + item.queries, 0),
                              'percentage'
                            )}
                            <span className="d-none d-md-inline" style={{ opacity: 0.6 }}>
                              {' '}
                              ({t('analytics.gafam.queries', { count: entry.queries })})
                            </span>
                          </span>
                        </small>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </Card>
    </Col>
  );
}
