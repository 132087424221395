import React, { Fragment, useState, useCallback, useRef, useContext } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Dropdown, Form, Modal, Button, Spinner } from 'react-bootstrap';
import { Listbox, Transition } from '@headlessui/react';
import { SelectorIcon } from '@heroicons/react/solid';

import { classNames } from '../helpers';
import { post } from '../api2';
import { AccountContext } from '../contexts';

export default function HeaderProfileSelector({ profile: currentProfile }) {
  const { t } = useTranslation();

  const params = useParams();
  const navigate = useNavigate();

  const account = useContext(AccountContext);

  const [name, setName] = useState({
    value: '',
    error: null,
  });

  const [showModal, setShowModal] = useState(false);
  const [creating, setCreating] = useState(false);

  const isModalClosable = useRef(true);

  const openModal = useCallback(() => {
    setShowModal(true);
  }, []);

  const closeModal = useCallback(() => {
    if (!isModalClosable.current) {
      return;
    }

    setShowModal(false);
    setCreating(false);

    setName((name) => {
      return { ...name, value: '', error: null };
    });
  }, []);

  const onNameChange = useCallback((event) => {
    const value = event.target.value;

    setName((name) => {
      return { ...name, value: value, error: null };
    });
  }, []);

  const createProfile = useCallback(() => {
    if (!name.value.trim()) {
      setName((name) => {
        return { ...name, error: { code: 'required' } };
      });

      return;
    }

    isModalClosable.current = false;
    setCreating(true);

    setName((name) => {
      return { ...name, value: name.value.trim().normalize(), error: null };
    });

    post(
      '/profiles',
      {
        name: name.value,
        security: {
          threatIntelligenceFeeds: true,
          googleSafeBrowsing: true,
          cryptojacking: true,
          idnHomographs: true,
          typosquatting: true,
          dga: true,
          csam: true,
        },
        privacy: {
          blocklists: [
            {
              id: 'nextdns-recommended',
            },
          ],
          disguisedTrackers: true,
        },
        settings: {
          logs: {
            enabled: true,
          },
          performance: {
            ecs: true,
          },
        },
      },
      ({ data, errors }) => {
        isModalClosable.current = true;

        if (errors) {
          setName((name) => {
            return { ...name, error: errors[0] };
          });

          setCreating(false);

          return;
        }

        closeModal();

        account.onProfileCreated(data);

        navigate(`/${data.id}`);
      }
    );
  }, [navigate, account, closeModal, name.value]);

  if (process.env.REACT_APP_TAILWIND) {
    return (
      <Listbox value={currentProfile} onChange={(value) => navigate(`/${value}/${params.menu}`)}>
        {({ open }) => (
          <>
            <div className="tw-relative tw-mt-1">
              {account.profiles
                .filter((profile) => profile.id === currentProfile)
                .map((profile) => (
                  <Listbox.Button
                    key={profile.id}
                    className={classNames(
                      'tw-relative tw-w-fit tw-cursor-default tw-rounded-md tw-border tw-border-transparent tw-py-2 tw-ps-3 tw-pe-9 hover:tw-border-gray-300 hover:tw-bg-white focus:tw-outline-none sm:tw-text-sm',
                      open ? 'tw-border-gray-300 tw-bg-white' : 'tw-border-transparent'
                    )}
                  >
                    <span className="tw-block tw-truncate tw-text-base tw-font-medium">
                      <span className="tw-align-middle dark:tw-text-gray-100">{profile.name || profile.id}</span>
                      {profile.role !== 'owner' && (
                        <span className="tw-ml-2 tw-inline-flex tw-items-center tw-rounded-full tw-bg-slate-100 tw-px-2.5 tw-align-middle tw-text-[11px] tw-font-medium tw-uppercase tw-leading-5 tw-text-slate-600 dark:tw-bg-slate-800 dark:tw-text-slate-400">
                          {t(`settings.access.roles.${profile.role}`)}
                        </span>
                      )}
                    </span>
                    <span className="tw-pointer-events-none tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pe-2">
                      <SelectorIcon className="tw-h-5 tw-w-5 tw-text-gray-400" aria-hidden="true" />
                    </span>
                  </Listbox.Button>
                ))}

              <Transition
                show={open}
                as={Fragment}
                leave="tw-transition tw-ease-in tw-duration-100"
                leaveFrom="tw-opacity-100"
                leaveTo="tw-opacity-0"
              >
                <Listbox.Options className="tw-absolute tw-z-10 tw-mt-1 tw-max-h-96 tw-w-fit tw-overflow-auto tw-rounded-md tw-bg-white tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5 focus:tw-outline-none">
                  {account.profiles.map((profile) => (
                    <Listbox.Option
                      key={profile.id}
                      className={({ active }) =>
                        classNames(
                          active ? 'tw-bg-gray-50' : 'tw-text-gray-900',
                          'tw-relative tw-cursor-pointer tw-py-2 tw-ps-3 tw-pe-9'
                        )
                      }
                      value={profile.id}
                    >
                      {({ selected, active }) => (
                        <span
                          className={classNames(
                            selected ? 'tw-font-medium' : 'tw-font-normal',
                            'tw-block tw-truncate tw-text-base'
                          )}
                        >
                          <span className="tw-align-middle">{profile.name || profile.id}</span>
                          {profile.role !== 'owner' && (
                            <span className="tw-ml-2 tw-inline-flex tw-items-center tw-rounded-full tw-bg-gray-100 tw-px-2.5 tw-align-middle tw-text-[11px] tw-font-medium tw-uppercase tw-leading-5 tw-text-gray-600">
                              {t(`settings.access.roles.${profile.role}`)}
                            </span>
                          )}
                        </span>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    );
  }

  return (
    <>
      {account.profiles.length > 0 && (
        <Dropdown>
          {account.profiles
            .filter((profile) => profile.id === currentProfile)
            .map((profile) => (
              <Dropdown.Toggle key={profile.id} variant="light" className="notranslate d-flex align-items-center">
                <div>{profile.name || profile.id}</div>
                {profile.role !== 'owner' && (
                  <div className="access-badge">
                    {profile.role === 'editor' ? 'Editor' : 'Viewer'}
                  </div>
                )}
              </Dropdown.Toggle>
            ))}
          <Dropdown.Menu>
            {account.profiles.map((profile) => (
              <Dropdown.Item
                key={profile.id}
                as={Link}
                to={`/${profile.id}/${params.menu}`}
                active={profile.id === currentProfile}
                className="notranslate d-flex align-items-center"
              >
                <div>{profile.name || profile.id}</div>
                {profile.role !== 'owner' && (
                  <div className="access-badge">
                    {t(`settings.access.roles.${profile.role}`)}
                  </div>
                )}
              </Dropdown.Item>
            ))}
            <Dropdown.Divider />
            <Dropdown.Item
              onClick={() => {
                document.activeElement.blur();
                openModal();
              }}
              className="text-primary"
            >
              {t('configurationSelector.new')}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      )}

      {account.profiles.length === 0 && (
        <Button className='button-variant-auto' onClick={openModal}>
          {t('configurationSelector.new')}
        </Button>
      )}

      <Modal show={showModal} animation={false} onHide={closeModal}>
        <Form
          onSubmit={(event) => {
            event.preventDefault();
            createProfile();
          }}
          action="#submit"
          noValidate
        >
          <Modal.Header closeButton>
            <Modal.Title as="h5">{t('configurationSelector.form.title')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.Label>{t('configurationSelector.form.name.label')}</Form.Label>
              <Form.Control
                autoFocus
                autoComplete="off"
                data-1p-ignore
                data-lpignore
                maxLength="255"
                placeholder={t('configurationSelector.form.name.placeholder')}
                isInvalid={!!name.error}
                value={name.value}
                onChange={onNameChange}
              />
              {!!name.error && (
                <Form.Control.Feedback type="invalid">
                  {t(
                    `configurationSelector.form.name.errors.${
                      name.error.code === 'duplicate' ? 'taken' : name.error.code
                    }`
                  )}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-secondary" disabled={creating} onClick={closeModal}>
              {t('global.cancel')}
            </Button>

            {!creating && <Button type="submit">{t('global.create')}</Button>}
            {creating && (
              <Button disabled>
                <Spinner as="span" animation="border" size="sm" className="me-2" />
                {t('global.create')}
              </Button>
            )}
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
