import React, { memo, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Card, Dropdown, ListGroup, OverlayTrigger, Tooltip, Popover } from 'react-bootstrap';
import MediaQuery from 'react-responsive';
import classnames from 'classnames';
import { classNames } from '../helpers';
import { useNavigate, useParams } from 'react-router-dom';

import Favicon from '../Components/Favicon';
import RelativeTime from '../Components/RelativeTime';

import { ChevronDownIcon, LockClosedIcon } from '@heroicons/react/solid';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEllipsisV,
  faInfoCircle,
  faLock,
  faAngleDown,
  faAngleUp,
  faEye,
  faNetworkWired,
  faGlobe,
} from '@fortawesome/free-solid-svg-icons';

import { statusColors, unpuny, humanizeList, htmlSpaces } from '../helpers';

import LogsLogTrackerInsights from './LogsLogTrackerInsights';

export default memo(({ log, streamed, relativeTime, toggleRelativeTime }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { profile } = useParams();

  const [expanded, setExpanded] = useState(false);
  const [showTrackerInsights, setShowTrackerInsights] = useState(false);

  const toggleExpanded = () => {
    if (expanded) {
      setShowTrackerInsights(false);
    }

    setExpanded((expanded) => !expanded);
  };

  const toggleShowTrackerInsights = () => {
    setShowTrackerInsights((showTrackerInsights) => !showTrackerInsights);
  };

  const relativeTimeCutoff = relativeTime ? 29 * 60 * 1000 : 0;

  if (process.env.REACT_APP_TAILWIND) {
    return (
      <div
        className={classNames(
          log.status === 'blocked' ? 'tw-border-l-red-500' : 'tw-border-l-transparent',
          'tw-flex tw-items-center tw-border-t tw-border-l-2 tw-px-3 tw-text-sm first:tw-border-t-0 dark:tw-border-t-slate-800'
        )}
      >
        <div className="tw-grow tw-py-3">
          <Favicon domain={log.domain} className="tw-me-2 tw-align-middle" />
          <span className="notranslate tw-break-all tw-align-middle tw-font-mono tw-text-[13px] dark:tw-text-slate-50">
            <span className="tw-opacity-70">{unpuny(log.domain.slice(0, log.domain.length - log.root.length))}</span>
            {unpuny(log.root)}
          </span>
          {!!log.type && (
            <span className="notranslate tw-ml-2 tw-align-middle tw-font-mono tw-text-[11px] tw-text-slate-700">
              {log.type}
            </span>
          )}
        </div>
        <div className="tw-ml-3 tw-text-slate-300 dark:tw-text-slate-700 sm:tw-hidden">
          <ChevronDownIcon className="tw-h-5 tw-w-5" />
        </div>
        <div className="tw-ml-3 tw-hidden tw-py-3 tw-text-end tw-text-slate-300 dark:tw-text-slate-700 sm:tw-block">
          <div className="tw-text-[13px] tw-text-slate-400">
            <LockClosedIcon className="tw-me-1 tw-inline tw-h-3 tw-w-3 tw-align-middle" />
            <span className="tw-align-middle">{log.device.name}</span>
          </div>
          <div className="tw-mt-1 tw-text-xs tw-text-slate-600" style={{ cursor: 'pointer' }} onClick={toggleRelativeTime}>
            <RelativeTime date={log.timestamp} suffix during={relativeTimeCutoff} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <MediaQuery minWidth={768}>
        <ListGroup.Item
          className="log"
          style={{ borderLeft: `4px solid ${statusColors[log.status] || 'transparent'}` }}
        >
          <div className={classnames('d-flex', { fadein: streamed })}>
            <div className="flex-grow-1 d-flex align-items-center text-break" style={{ marginLeft: -2 }}>
              <div>
                {!!log.domain && (
                  <>
                    <Favicon domain={log.domain} className="me-2" />

                    {!log.tracker && (
                      <span className="notranslate domain">
                        <span style={{ opacity: 0.6 }}>
                          {unpuny(log.domain.substr(0, log.domain.indexOf(log.root)))}
                        </span>
                        {unpuny(log.domain.substr(log.domain.indexOf(log.root)))}
                      </span>
                    )}

                    {!!log.tracker && (
                      <OverlayTrigger
                        trigger="click"
                        rootClose
                        placement="bottom"
                        overlay={
                          <Popover style={{ maxWidth: 400 }}>
                            <LogsLogTrackerInsights tracker={log.tracker} />
                          </Popover>
                        }
                      >
                        <span className="notranslate domain has-tracker-insights">
                          <span style={{ opacity: 0.6 }}>
                            {unpuny(log.domain.substr(0, log.domain.indexOf(log.root)))}
                          </span>
                          {unpuny(log.domain.substr(log.domain.indexOf(log.root)))}
                        </span>
                      </OverlayTrigger>
                    )}

                    {!!log.dnssec && (
                      <OverlayTrigger
                        placement="bottom"
                        overlay={
                          <Tooltip>
                            <small>{t('logs.log.dnssec')}</small>
                          </Tooltip>
                        }
                      >
                        <FontAwesomeIcon
                          icon={faLock}
                          style={{ fontSize: '0.75em', color: '#4085c2', marginLeft: 4 }}
                        />
                      </OverlayTrigger>
                    )}
                  </>
                )}
                {!log.domain && (
                  <>
                    <Favicon className="me-2" />
                    <span className="notranslate" style={{ background: '#eee', borderRadius: 2, fontSize: '0.85em' }}>
                      {htmlSpaces(50)}
                    </span>
                  </>
                )}
                {!!log.type && (
                  <span className="d-inline-block notranslate log-type-badge">
                    {log.type}
                  </span>
                )}
                {!!(log.domain && log.status === 'blocked') && (
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip>
                        <div className="pb-1">
                          {!!log.matched && (
                            <div className="mt-2 mb-2 notranslate" style={{ fontSize: '0.9em' }}>
                              <b>→ {unpuny(log.matched)}</b>
                            </div>
                          )}

                          {log.reasons.length > 0 && (
                            <small>
                              <Trans i18nKey="logs.log.blockedBy">
                                _Blocked by
                                <span className="notranslate">
                                  {{
                                    lists: humanizeList(
                                      log.reasons.map((reason) => reason.name),
                                      {
                                        separator: t('global.list.separator'),
                                        conjunction: t('global.list.conjunction'),
                                      }
                                    ),
                                  }}
                                </span>
                                .
                              </Trans>
                            </small>
                          )}
                          {log.reasons.length === 0 && <small>{t('logs.log.manual.deny')}</small>}
                        </div>
                      </Tooltip>
                    }
                  >
                    <FontAwesomeIcon
                      className="reason-icon"
                      icon={faInfoCircle}
                      style={{ fontSize: '0.8em', color: statusColors[log.status], marginLeft: 10 }}
                    />
                  </OverlayTrigger>
                )}
                {!!(log.domain && ['allowed', 'relayed'].includes(log.status)) && (
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip>
                        <div className="pb-1">
                          {!!log.matched && (
                            <div className="mt-2 mb-2 notranslate" style={{ fontSize: '0.9em' }}>
                              <b>→ {unpuny(log.matched)}</b>
                            </div>
                          )}

                          {log.reasons.length > 0 && (
                            <small>
                              <Trans i18nKey="logs.log.allowedBy">
                                _Allowed by
                                <span className="notranslate">
                                  {{
                                    lists: humanizeList(
                                      log.reasons.map((reason) => reason.name),
                                      {
                                        separator: t('global.list.separator'),
                                        conjunction: t('global.list.conjunction'),
                                      }
                                    ),
                                  }}
                                </span>
                                .
                              </Trans>
                            </small>
                          )}
                          {log.reasons.length === 0 && <small>{t('logs.log.manual.allow')}</small>}
                        </div>
                      </Tooltip>
                    }
                  >
                    <FontAwesomeIcon
                      className="reason-icon"
                      icon={faInfoCircle}
                      style={{ fontSize: '0.8em', color: statusColors[log.status], marginLeft: 10 }}
                    />
                  </OverlayTrigger>
                )}
              </div>
            </div>
            <div style={{ fontSize: '0.9em' }}>
              <div className="text-end">
                {log.encrypted && (
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip>
                        <div className="p-2" style={{ fontSize: '0.9em' }}>
                          <div style={{ fontWeight: 500 }}>{log.protocol}</div>

                          {!!log.client && (
                            <div className="notranslate" style={{ fontSize: '0.9em', opacity: 0.8 }}>
                              {t(`logs.log.clients.${log.client}`)}
                            </div>
                          )}
                        </div>
                      </Tooltip>
                    }
                  >
                    <FontAwesomeIcon
                      icon={faLock}
                      style={{ fontSize: '0.8em', color: 'var(--bs-text)', opacity: 0.7, marginRight: 6 }}
                    />
                  </OverlayTrigger>
                )}

                {!!log.device && (
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip className="tooltip-device">
                        <div className="p-2" style={{ fontSize: '0.9em' }}>
                          <div style={{ fontWeight: 500 }}>
                            {log.device.name || t('global.unnamedDevice', { id: log.device.id })}
                          </div>

                          {!!log.device.model && (
                            <div style={{ fontSize: '0.9em', opacity: 0.8 }}>{log.device.model}</div>
                          )}

                          {!!log.device.localIp && (
                            <div className="mt-2 mb-n1" style={{ fontSize: '0.9em', opacity: 0.8 }}>
                              <FontAwesomeIcon icon={faNetworkWired} style={{ marginRight: 4, opacity: 0.8 }} />
                              <span>{log.device.localIp}</span>
                            </div>
                          )}

                          <div className="mt-2" style={{ fontSize: '0.9em', opacity: 0.8 }}>
                            <FontAwesomeIcon icon={faGlobe} style={{ marginRight: 4, opacity: 0.8 }} />
                            {!!log.clientIp && <span>{log.clientIp}</span>}
                            {!log.clientIp && (
                              <span style={{ background: '#eee', borderRadius: 2 }}>{htmlSpaces(30)}</span>
                            )}
                          </div>
                        </div>
                      </Tooltip>
                    }
                  >
                    <span className="notranslate" style={{ cursor: 'default' }}>
                      {log.device.name || t('global.unnamedDevice', { id: log.device.id })}
                    </span>
                  </OverlayTrigger>
                )}
                {!log.device && (
                  <>
                    {!!log.clientIp && <span className="notranslate">{log.clientIp}</span>}
                    {!log.clientIp && (
                      <span className="notranslate" style={{ background: '#eee', borderRadius: 2, fontSize: '0.8em' }}>
                        {htmlSpaces(30)}
                      </span>
                    )}
                  </>
                )}
              </div>
              <div
                className="text-end"
                style={{ fontSize: '0.8em', opacity: 0.3, marginTop: 2, cursor: 'pointer' }}
                onClick={toggleRelativeTime}
              >
                <RelativeTime date={log.timestamp} suffix during={relativeTimeCutoff} />
              </div>
            </div>
            {!!log.domain && (
              <Dropdown align="end" >
                <Dropdown.Toggle variant="secondary" size="sm" className="no-caret ms-2" style={{
                  background: "none",
                  border: "none",
                  alignSelf: "center",
                  height: "100%",
                }}>
                  <FontAwesomeIcon icon={faEllipsisV} style={{ color: '#ccc' }} />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item disabled={log.status === 'blocked'} onClick={(e) => {
                    e.preventDefault();
                    navigate(`/${profile}/denylist`, {state: {domain: log.domain}});
                  }}>
                    <Trans i18nKey="logs.log.actionBlock">
                      _Block
                    </Trans>
                  </Dropdown.Item>
                  <Dropdown.Item disabled={log.status === 'allowed'} onClick={(e) => {
                    e.preventDefault();
                    navigate(`/${profile}/allowlist`, {state: {domain: log.domain}});
                  }}>
                    <Trans i18nKey="logs.log.actionAllow">
                      _Allow
                    </Trans>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}
          </div>
        </ListGroup.Item>
      </MediaQuery>

      <MediaQuery maxWidth={768 - 1}>
        <ListGroup.Item
          className="log"
          style={{ borderLeft: `4px solid ${statusColors[log.status] || 'transparent'}` }}
        >
          <div className={classnames('d-flex', { fadein: streamed })}>
            <div className="flex-grow-1 d-flex align-items-center text-break" style={{ marginLeft: -7 }}>
              <div>
                {!!log.domain && (
                  <>
                    <Favicon domain={log.domain} className="me-2" />
                    <span className="notranslate" style={{ opacity: 0.6 }}>
                      {unpuny(log.domain.substr(0, log.domain.indexOf(log.root)))}
                    </span>
                    <span className="notranslate">{unpuny(log.domain.substr(log.domain.indexOf(log.root)))}</span>
                  </>
                )}
                {!log.domain && (
                  <>
                    <Favicon className="me-2" />
                    <span className="notranslate" style={{ background: '#eee', borderRadius: 2, fontSize: '0.85em' }}>
                      {htmlSpaces(50)}
                    </span>
                  </>
                )}
                {!!log.domain && !!log.type && (
                  <span
                    className="notranslate"
                    style={{ fontWeight: 'bold', fontSize: '0.8em', opacity: 0.3, marginLeft: 10 }}
                  >
                    {log.type}
                  </span>
                )}
              </div>
            </div>
            <a href="#show-more" className="d-block ps-3 pe-2 pt-1 my-n2" onClick={toggleExpanded}>
              <FontAwesomeIcon icon={expanded ? faAngleUp : faAngleDown} style={{ color: '#ccc' }} />
            </a>
          </div>
          {expanded && (
            <div className="d-flex justify-content-between">
              <div className="mt-3" style={{ fontSize: '0.9em', marginLeft: -7 }}>
                {log.domain && log.status === 'blocked' && (
                  <div className="mb-2">
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      className="me-1"
                      style={{ color: statusColors[log.status], transform: 'scale(0.9)' }}
                    />

                    {!!log.matched && (
                      <span className="notranslate">
                        <b>→ {unpuny(log.matched)}</b> |{' '}
                      </span>
                    )}

                    {log.reasons.length > 0 && (
                      <span>
                        <Trans i18nKey="logs.log.blockedBy">
                          _Blocked by
                          <span className="notranslate">
                            {{
                              lists: humanizeList(
                                log.reasons.map((reason) => reason.name),
                                {
                                  separator: t('global.list.separator'),
                                  conjunction: t('global.list.conjunction'),
                                }
                              ),
                            }}
                          </span>
                          .
                        </Trans>
                      </span>
                    )}
                    {log.reasons.length === 0 && <span>{t('logs.log.manual.deny')}</span>}
                  </div>
                )}
                {!!log.domain && ['allowed', 'relayed'].includes(log.status) && (
                  <div className="mb-2">
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      className="me-1"
                      style={{ color: statusColors[log.status], transform: 'scale(0.9)' }}
                    />

                    {!!log.matched && (
                      <span className="notranslate">
                        <b>→ {unpuny(log.matched)}</b> |{' '}
                      </span>
                    )}

                    {log.reasons.length > 0 && (
                      <span>
                        <Trans i18nKey="logs.log.allowedBy">
                          _Allowed by
                          <span className="notranslate">
                            {{
                              lists: humanizeList(
                                log.reasons.map((reason) => reason.name),
                                {
                                  separator: t('global.list.separator'),
                                  conjunction: t('global.list.conjunction'),
                                }
                              ),
                            }}
                          </span>
                          .
                        </Trans>
                      </span>
                    )}
                    {log.reasons.length === 0 && <small>{t('logs.log.manual.allow')}</small>}
                  </div>
                )}
                {log.domain && log.dnssec && (
                  <div className="mb-2">
                    <FontAwesomeIcon
                      icon={faLock}
                      className="me-1"
                      style={{ color: '#4085c2', transform: 'scale(0.9)' }}
                    />
                    <span>{t('logs.log.dnssec')}</span>
                  </div>
                )}

                {!!log.device && (
                  <>
                    <div className="notranslate">
                      <span>{log.device.name || t('global.unnamedDevice', { id: log.device.id })}</span>
                      {!!log.clientIp && <span style={{ opacity: 0.5 }}> ({log.clientIp})</span>}
                      {!log.clientIp && (
                        <>
                          <span style={{ opacity: 0.5 }}> (</span>
                          <span style={{ background: '#eee', borderRadius: 2, fontSize: '0.8em' }}>{htmlSpaces(30)}</span>
                          <span style={{ opacity: 0.5 }}>)</span>
                        </>
                      )}
                    </div>
                    {!!log.device.model && (
                      <div className="notranslate" style={{ opacity: 0.4, fontSize: '0.9em' }}>
                        {log.deviceModel}
                      </div>
                    )}
                    {!!log.device.localIp && (
                      <div className="mt-1 notranslate" style={{ fontSize: '0.8em', opacity: 0.8 }}>
                        <FontAwesomeIcon icon={faNetworkWired} style={{ marginRight: 4, opacity: 0.6 }} />
                        {log.device.localIp}
                      </div>
                    )}
                  </>
                )}
                {!log.device && (
                  <div className="notranslate">
                    {!!log.clientIp && <span>{log.clientIp}</span>}
                    {!log.clientIp && (
                      <span style={{ background: '#eee', borderRadius: 2, fontSize: '0.8em' }}>{htmlSpaces(30)}</span>
                    )}
                  </div>
                )}

                {log.encrypted && (
                  <div className="mt-2" style={{ fontSize: '0.8em' }}>
                    <FontAwesomeIcon icon={faLock} style={{ color: 'var(--bs-text)', opacity: 0.7, marginRight: 4 }} />
                    <span style={{ opacity: 0.8 }}>{log.protocol}</span>
                    {!!log.client && <span style={{ opacity: 0.5 }}> ({t(`logs.log.clients.${log.client}`)})</span>}
                  </div>
                )}

                <div
                  className="mt-2"
                  style={{ fontSize: '0.8em', opacity: 0.3, cursor: 'pointer' }}
                  onClick={toggleRelativeTime}
                >
                  <RelativeTime date={log.timestamp} suffix during={relativeTimeCutoff} />
                </div>
                {!!(log.domain && log.tracker) && (
                  <>
                    <a
                      href="#show-tracker-data"
                      className="d-block mt-2 pt-2 pb-1"
                      style={{ textDecoration: 'none' }}
                      onClick={toggleShowTrackerInsights}
                    >
                      <FontAwesomeIcon icon={faEye} className="me-1" style={{ color: '#00aef0' }} />
                      <span style={{ color: '#00aef0' }}>
                        {!showTrackerInsights && t('logs.log.insights.show')}
                        {showTrackerInsights && t('logs.log.insights.hide')}
                      </span>
                    </a>
                    {showTrackerInsights && (
                      <Card className="mb-2 mt-1">
                        <Card.Body className="p-0">
                          <LogsLogTrackerInsights tracker={log.tracker} />
                        </Card.Body>
                      </Card>
                    )}
                  </>
                )}
              </div>
              {!!log.domain && (
                <Dropdown className="d-flex align-items-end">
                  <Dropdown.Toggle variant="secondary" size="sm" className="no-caret me-n1" style={{
                    background: "none",
                    border: "none",
                  }}>
                    <FontAwesomeIcon icon={faEllipsisV} style={{ color: '#ccc' }} />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item disabled={log.status === 'blocked'} onClick={(e) => {
                      e.preventDefault();
                      navigate(`/${profile}/denylist`, {state: {domain: log.domain}});
                    }}>
                      <Trans i18nKey="logs.log.actionBlock">
                        _Block
                      </Trans>
                    </Dropdown.Item>
                    <Dropdown.Item disabled={log.status === 'allowed'} onClick={(e) => {
                      e.preventDefault();
                      navigate(`/${profile}/allowlist`, {state: {domain: log.domain}});
                    }}>
                      <Trans i18nKey="logs.log.actionAllow">
                        _Allow
                      </Trans>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </div>
          )}
        </ListGroup.Item>
      </MediaQuery>
    </>
  );
});
