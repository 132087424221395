import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, ListGroup, Modal, Form, Button, Spinner } from 'react-bootstrap';
import { patch, del, post, get } from '../api2';
import classnames from 'classnames';
import { ReactSVG } from 'react-svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Favicon from '../Components/Favicon';
import RecreationIcon from '../images/recreation.svg';
import { hex } from '../helpers';

export default function ParentalControlServices({ services: initialServices, profile, hasRecreation, role }) {
  const { t } = useTranslation();
  const [allServicesLoaded, setAllServicesLoaded] = useState(false);
  const [services, setServices] = useState(initialServices.map(service => ({
    ...service,
    added: true,
  })));
  const [addedOrder, setAddedOrder] = useState(initialServices.map(service => service.id));
  const [showModal, setShowModal] = useState(false);
  const source = useRef();

  useEffect(() => {
    source.current = new Set();
    return () => {
      source.current.forEach(cancel => cancel());
    };
  }, []);

  const loadAllServices = () => {
    if (!allServicesLoaded) {
      const cancel = get('/parentalControl/services', ({ data }) => {
        setServices(prevServices => data.map(service => ({
          ...service,
          active: true,
          recreation: false,
          ...prevServices.filter(s => s.id === service.id)[0],
        })));
        setAllServicesLoaded(true);
      });
      source.current.add(cancel);
    }
  }

  const setRecreation = (id, recreation) => {
    setServices(prevServices => prevServices.map(service =>
      service.id === id ? { ...service, recreation } : service
    ));
    const cancel = patch(`/profiles/${profile}/parentalControl/services/${hex(id)}`, { recreation });
    source.current.add(cancel);
  };

  const setActive = (id, active) => {
    setServices(prevServices => prevServices.map(service =>
      service.id === id ? { ...service, active } : service
    ));
    const cancel = patch(`/profiles/${profile}/parentalControl/services/${hex(id)}`, { active });
    source.current.add(cancel);
  };

  const remove = (id) => {
    setServices(prevAllServices => prevAllServices.map(s =>
      s.id === id ? { ...s, added: false } : s
    ));
    setAddedOrder(current => current.filter(i => i !== id));
    const cancel = del(`/profiles/${profile}/parentalControl/services/${hex(id)}`);
    source.current.add(cancel);
  };

  const add = (id) => {
    setServices(prevAllServices => prevAllServices.map(s =>
      s.id === id ? { ...s, added: true } : s
    ));
    setAddedOrder(current => [...current, id]);
    const cancel = post(`/profiles/${profile}/parentalControl/services`, { id });
    source.current.add(cancel);
  };

  const addedServices = () => {
    return services.filter(service => service.added).sort((a, b) => {
      return addedOrder.indexOf(a.id) - addedOrder.indexOf(b.id);
    });
  }


  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <Card>
        <ListGroup variant="flush">
          <ListGroup.Item className="py-3">
            <h5>{t('parentalControl.services.name')}</h5>
            <div style={{ opacity: 0.6, fontSize: '0.9em' }}>
              {t('parentalControl.services.description')}
            </div>
          </ListGroup.Item>
          {addedServices().map(service => (
            <ListGroup.Item
              key={service.id}
              className='py-2 pe-1'
              style={{ borderLeft: '4px solid #ccc', borderLeftColor: service.active ? '#ff4136' : '' }}
            >
              <div className="d-flex">
                <div className="flex-grow-1 d-flex align-items-center" style={{ opacity: service.active ? 1 : 0.4 }}>
                  <div style={{ marginLeft: -4 }} className="d-flex align-items-center gap-2">
                    <Favicon url={service.website} />
                    <span className="notranslate" style={{ fontWeight: 500 }}>
                      {t(`parentalControl.services.services.${service.id}`)}
                    </span>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  {hasRecreation && (
                    <ReactSVG
                      src={RecreationIcon}
                      className={classnames('recreation-button', {
                        active: service.recreation,
                        readOnly: role === 'viewer',
                      })}
                      onClick={() => role !== 'viewer' && setRecreation(service.id, !service.recreation)}
                    />
                  )}
                  <div style={{ transform: 'translate(25%, 3%) scale(0.8)' }}>
                    <Form.Switch
                      id={`active-switch-${service.id}`}
                      label=""
                      checked={service.active}
                      onChange={(event) => setActive(service.id, event.target.checked)}
                      disabled={role === 'viewer'}
                    />
                  </div>
                  {role !== 'viewer' && (
                    <Button
                      variant="link"
                      style={{ opacity: 0.3, color: '#222' }}
                      onClick={() => remove(service.id)}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </Button>
                  )}
                </div>
              </div>
            </ListGroup.Item>
          ))}
        </ListGroup>
        <Card.Footer className="text-muted" style={{ borderTop: 'none' }}>
          <Button
            size="sm"
            style={{ fontWeight: 'bold', fontSize: 12, textTransform: 'uppercase' }}
            onClick={openModal}
            disabled={role === 'viewer'}
          >
            {t('parentalControl.services.add')}
          </Button>
        </Card.Footer>
      </Card>

      <Modal
        show={showModal}
        animation={false}
        size="lg"
        scrollable={true}
        onHide={closeModal}
        onShow={loadAllServices}
      >
        <Modal.Header closeButton>
          <Modal.Title as="h5">{t('parentalControl.services.add')}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          {!allServicesLoaded ? (
            <div className="text-center py-3">
              <Spinner animation="border" style={{ width: '2rem', height: '2rem', opacity: 0.15 }} className="my-4" />
            </div>
          ) : (
            <ListGroup variant="flush">
              {services.map(service => (
                <ListGroup.Item
                  key={service.id}
                  style={{ borderLeft: '4px solid #ccc', borderLeftColor: service.added ? '#ff4136' : '' }}
                >
                  <div className="d-flex">
                    <div className="flex-grow-1 d-flex align-items-center">
                      <div style={{ marginLeft: -4 }}>
                        <Favicon url={service.website} className="me-2" style={{ marginTop: -3 }} />
                        <span className="notranslate" style={{ fontWeight: 500 }}>
                          {t(`parentalControl.services.services.${service.id}`)}
                        </span>
                      </div>
                    </div>
                    <div className="d-flex align-items-center ms-4">
                      <Button
                        variant={service.added ? "danger" : "primary"}
                        size="sm"
                        style={{
                          fontWeight: 'bold',
                          fontSize: 12,
                          textTransform: 'uppercase',
                          whiteSpace: 'nowrap',
                        }}
                        onClick={() => service.added ? remove(service.id) : add(service.id)}
                      >{t(service.added ? 'global.remove' : 'global.add')}</Button>
                    </div>
                  </div>
                </ListGroup.Item>
              ))}
            </ListGroup>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}