import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, ListGroup, Modal, Form, Button } from 'react-bootstrap';
import { del, patch, post } from '../api2';
import classnames from 'classnames';
import { ReactSVG } from 'react-svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import RecreationIcon from '../images/recreation.svg';
import { hex } from '../helpers';

const CATEGORIES = ['porn', 'gambling', 'dating', 'piracy', 'social-networks', 'gaming', 'video-streaming'];

export default function ParentalControlCategories({ categories: initialCategories, profile, hasRecreation, role }) {
  const { t } = useTranslation();
  const [categories, setCategories] = useState(CATEGORIES.map(category => (
    initialCategories.some(c => c.id === category)
      ? { ...initialCategories.filter(c => c.id === category)[0], added: true }
      : { id: category, active: true, recreation: false, added: false }
  )));
  const [showModal, setShowModal] = useState(false);
  const source = useRef();

  useEffect(() => {
    source.current = new Set();
    return () => {
      source.current.forEach(cancel => cancel());
    };
  }, []);

  const setActive = (id, active) => {
    setCategories(prevCategories => prevCategories.map(category => {
      if (category.id === id) {
        return { ...category, active };
      }
      return category;
    }));
    const cancel = patch(`/profiles/${profile}/parentalControl/categories/${hex(id)}`, { active });
    source.current.add(cancel);
  };

  const setRecreation = (id, recreation) => {
    setCategories(prevCategories => prevCategories.map(category => {
      if (category.id === id) {
        return { ...category, recreation };
      }
      return category;
    }));
    const cancel = patch(`/profiles/${profile}/parentalControl/categories/${hex(id)}`, { recreation });
    source.current.add(cancel);
  };

  const remove = (id) => {
    setCategories(prevAllCategories => prevAllCategories.map(_category => ({
      ..._category,
      added: _category.id !== id ? _category.added : false
    })));
    const cancel = del(`/profiles/${profile}/parentalControl/categories/${hex(id)}`);
    source.current.add(cancel);
  };

  const add = (category) => {
    setCategories(prevAllCategories => prevAllCategories.map(_category => {
      if (_category.id === category.id) {
        return { ..._category, added: true };
      }
      return _category;
    }));
    const cancel = post(`/profiles/${profile}/parentalControl/categories`, { id: category.id });
    source.current.add(cancel);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <Card>
        <ListGroup variant="flush">
          <ListGroup.Item className="py-3">
            <h5>{t('parentalControl.categories.name')}</h5>
            <div style={{ opacity: 0.6, fontSize: '0.9em' }}>
              {t('parentalControl.categories.description')}
            </div>
          </ListGroup.Item>
          {categories.filter(category => category.added).map((category, index) => (
            <ListGroup.Item
              key={category.id}
              className='pe-1'
              style={{
                borderLeft: '4px solid #ccc',
                borderLeftColor: category.active ? '#ff4136' : '',
              }}
            >
              <div className="d-flex gap-4">
                <div className="flex-grow-1" style={{ opacity: category.active ? 1 : 0.4 }}>
                  <div style={{ marginLeft: -4 }}>
                    <div style={{ fontWeight: 500 }}>
                      {t(`parentalControl.categories.categories.${category.id}.name`)}
                    </div>
                    <div className="mt-1" style={{ fontSize: '0.9em', opacity: 0.5 }}>
                      {t(`parentalControl.categories.categories.${category.id}.description`)}
                    </div>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="d-flex align-items-center" style={{ height: "25px" }}>
                    {hasRecreation && (
                      <ReactSVG
                        src={RecreationIcon}
                        className={classnames('recreation-button', {
                          active: category.recreation,
                          readOnly: role === 'viewer'
                        })}
                        onClick={() => role !== 'viewer' ? setRecreation(category.id, !category.recreation) : null}
                      />
                    )}
                    <div style={{ transform: 'translate(25%, 3%) scale(0.8)' }}>
                      <Form.Switch
                        id={`active-switch-${category.id}`}
                        label=""
                        checked={category.active}
                        onChange={(event) => setActive(category.id, event.target.checked)}
                        disabled={role === 'viewer'}
                      />
                    </div>
                    {role !== 'viewer' && (
                      <Button
                        variant="link"
                        style={{ opacity: 0.3, color: '#222' }}
                        onClick={() => remove(category.id)}
                      >
                        <FontAwesomeIcon icon={faTimes} />
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </ListGroup.Item>
          ))}
        </ListGroup>
        <Card.Footer className="text-muted" style={{ borderTop: 'none' }}>
          <Button
            size="sm"
            style={{ fontWeight: 'bold', fontSize: 12, textTransform: 'uppercase' }}
            onClick={openModal}
            disabled={role === 'viewer'}
          >
            {t('parentalControl.categories.add')}
          </Button>
        </Card.Footer>
      </Card>

      <Modal
        show={showModal}
        animation={false}
        size="lg"
        scrollable={true}
        onHide={closeModal}
      >
        <Modal.Header closeButton>
          <Modal.Title as="h5">{t('parentalControl.categories.add')}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <ListGroup variant="flush">
            {categories.map((category) => (
              <ListGroup.Item
                key={category.id}
                style={{ borderLeft: '4px solid #ccc', borderLeftColor: category.added ? '#ff4136' : '' }}
              >
                <div className="d-lg-flex" style={{ marginLeft: -4 }}>
                  <div className="flex-grow-1 d-flex align-items-center">
                    <div>
                      <div style={{ fontWeight: 500 }}>
                        {t(`parentalControl.categories.categories.${category.id}.name`)}
                      </div>
                      <div className="mt-1" style={{ fontSize: '0.9em', opacity: 0.5 }}>
                        {t(`parentalControl.categories.categories.${category.id}.description`)}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center my-2 my-lg-0 ms-lg-4">
                    {category.added && (
                      <Button
                        variant="danger"
                        size="sm"
                        style={{
                          fontWeight: 'bold',
                          fontSize: 12,
                          textTransform: 'uppercase',
                          whiteSpace: 'nowrap',
                        }}
                        onClick={() => remove(category.id)}
                      >
                        {t('global.remove')}
                      </Button>
                    )}
                    {!category.added && (
                      <Button
                        size="sm"
                        style={{
                          fontWeight: 'bold',
                          fontSize: 12,
                          textTransform: 'uppercase',
                          whiteSpace: 'nowrap',
                        }}
                        onClick={() => add(category)}
                      >
                        {t('global.add')}
                      </Button>
                    )}
                  </div>
                </div>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Modal.Body>
      </Modal>
    </>
  );
}