import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, ListGroup, Modal, Form, Button } from 'react-bootstrap';
import axios from 'axios';

import { API_BASE_URL } from '../config';

export default function AccountBusinessInfo({ business, onBusinessUpdated }) {
  const [showModal, setShowModal] = useState(false);
  const [nameValue, setNameValue] = useState('');
  const [nameError, setNameError] = useState(null);
  const [addressValue, setAddressValue] = useState('');
  const [addressError, setAddressError] = useState(null);
  const [vatNumberValue, setVatNumberValue] = useState('');
  const [vatNumberError, setVatNumberError] = useState(null);

  const { t } = useTranslation();

  const openModal = () => {
    setShowModal(true);
    setNameValue(business.name || '');
    setAddressValue(business.address || '');
    setVatNumberValue(business.vatNumber || '');
  };

  const closeModal = () => {
    setShowModal(false);
    setNameError(null);
    setAddressError(null);
    setVatNumberError(null);
  };

  const onNameValueChange = (event) => {
    setNameValue(event.target.value);
    setNameError(null);
  };

  const onAddressValueChange = (event) => {
    setAddressValue(event.target.value);
    setAddressError(null);
  };

  const onVatNumberValueChange = (event) => {
    setVatNumberValue(event.target.value);
    setVatNumberError(null);
  };

  const save = async (event) => {
    event.preventDefault();

    try {
      const { data } = await axios.put(`${API_BASE_URL}/accounts/@me/business`, {
        name: nameValue,
        address: addressValue,
        vatNumber: vatNumberValue,
      });

      if (data.errors) {
        if (data.errors.name) {
          setNameError(data.errors.name);
        }
        if (data.errors.address) {
          setAddressError(data.errors.address);
        }
        if (data.errors.vatNumber) {
          setVatNumberError(data.errors.vatNumber);
        }

        return;
      }

      closeModal();
      onBusinessUpdated();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Card>
        <ListGroup variant="flush">
          <ListGroup.Item className="py-3">
            <h5>{t('account.business.name')}</h5>
            <div style={{ opacity: 0.6, fontSize: '0.9em' }}>{t('account.business.description')}</div>
          </ListGroup.Item>

          <ListGroup.Item className="py-3">
            {!!business.name && (
              <>
                <div style={{ fontWeight: 600 }}>{business.name}</div>
                <div style={{ opacity: 0.8 }}>
                  {business.address.split('\n').map((line, i) => (
                    <span key={i}>
                      {line}
                      <br />
                    </span>
                  ))}
                </div>
                {!!business.vatNumber && (
                  <div className="mt-2" style={{ fontSize: '0.9em', opacity: 0.6 }}>
                    {t('account.invoices.invoice.vatNumber', { number: business.vatNumber })}
                  </div>
                )}
                <div className="mt-3" style={{ fontSize: 14 }}>
                  <Button variant="link" className="p-0" size="sm" onClick={openModal}>
                    {t('account.business.button.update')}
                  </Button>
                </div>
              </>
            )}
            {!business.name && (
              <div className="mt-0">
                <Button variant="outline-primary" size="sm" onClick={openModal}>
                  {t('account.business.button.add')}
                </Button>
              </div>
            )}
          </ListGroup.Item>
        </ListGroup>
      </Card>

      <Modal show={showModal} animation={false} onHide={closeModal}>
        <Form onSubmit={save} action="#submit" noValidate>
          <Modal.Header closeButton>
            <Modal.Title as="h5">{t('account.business.name')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.Label>{t('account.business.form.name.label')}</Form.Label>
              <Form.Control
                autoFocus={!nameValue}
                maxLength="255"
                spellCheck="false"
                autoCorrect="off"
                autoComplete="off"
                autoCapitalize="off"
                placeholder={t('account.business.form.name.placeholder')}
                value={nameValue}
                isInvalid={!!nameError}
                onChange={onNameValueChange}
              />
              {!!nameError && (
                <Form.Control.Feedback type="invalid">
                  {t(`account.business.form.name.errors.${nameError}`)}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group>
              <Form.Label>{t('account.business.form.address.label')}</Form.Label>
              <Form.Control
                as="textarea"
                rows="3"
                maxLength="255"
                spellCheck="false"
                autoCorrect="off"
                autoComplete="off"
                autoCapitalize="off"
                placeholder={t('account.business.form.address.placeholder')}
                value={addressValue}
                isInvalid={!!addressError}
                onChange={onAddressValueChange}
              />
              {!!addressError && (
                <Form.Control.Feedback type="invalid">
                  {t(`account.business.form.address.errors.${addressError}`)}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group>
              <Form.Label>{t('account.business.form.vatNumber.label')}</Form.Label>
              <Form.Control
                maxLength="255"
                spellCheck="false"
                autoCorrect="off"
                autoComplete="off"
                autoCapitalize="off"
                placeholder="123456789"
                value={vatNumberValue}
                isInvalid={!!vatNumberError}
                onChange={onVatNumberValueChange}
              />
              {!!vatNumberError && (
                <Form.Control.Feedback type="invalid">
                  {t(`account.business.form.vatNumber.errors.${vatNumberError}`)}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-secondary" onClick={closeModal}>
              {t('global.cancel')}
            </Button>
            <Button type="submit">{t('global.save')}</Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}