import React, { useState, useCallback, useRef, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Card, Modal, Form, Button } from 'react-bootstrap';
import { CancelToken } from 'axios';

import { api } from '../api';
import { AppContext, AccountContext } from '../contexts';

export default function AccountChangeEmail({ onEmailChanged }) {
  const { t } = useTranslation();

  const app = useContext(AppContext);
  const account = useContext(AccountContext);

  const source = useRef();

  useEffect(() => {
    source.current = CancelToken.source();

    return () => {
      source.current.cancel();
    };
  }, []);

  const [newEmail, setNewEmail] = useState({
    value: '',
    error: null,
  });

  const [currentPassword, setCurrentPassword] = useState({
    value: '',
    error: null,
  });

  const [showModal, setShowModal] = useState(false);

  const openModal = useCallback(() => {
    setShowModal(true);
  }, []);

  const closeModal = useCallback(() => {
    setShowModal(false);

    setNewEmail((newEmail) => {
      return { ...newEmail, value: '', error: null };
    });

    setCurrentPassword((currentPassword) => {
      return { ...currentPassword, value: '', error: null };
    });
  }, []);

  const onNewEmailChange = useCallback((event) => {
    const value = event.target.value.trim();

    setNewEmail((newEmail) => {
      return { ...newEmail, value: value, error: null };
    });
  }, []);

  const onCurrentPasswordChange = useCallback((event) => {
    const value = event.target.value;

    setCurrentPassword((currentPassword) => {
      return { ...currentPassword, value: value, error: null };
    });
  }, []);

  const change = useCallback(async () => {
    let data;
    try {
      ({ data } = await api.patch(
        '/accounts/@me',
        {
          email: newEmail.value,
          currentPassword: currentPassword.value,
        },
        {
          cancelToken: source.current.token,
        }
      ));
    } catch (error) {
      if (error.isAxiosError) {
        app.handleNetworkError(error);
        return;
      }
      throw error;
    }

    if (data.errors) {
      if (data.errors.email) {
        setNewEmail((newEmail) => {
          return { ...newEmail, error: data.errors.email };
        });

        setCurrentPassword((currentPassword) => {
          return { ...currentPassword, value: '', error: null };
        });
      }

      if (data.errors.currentPassword) {
        setCurrentPassword((currentPassword) => {
          return { ...currentPassword, value: '', error: data.errors.currentPassword };
        });
      }

      return;
    }

    closeModal();

    onEmailChanged(data.email);

    account.onEmailChanged(data.email);
  }, [app, account, newEmail.value, currentPassword.value, closeModal, onEmailChanged]);

  return (
    <>
      <Card body>
        <h5>{t('account.changeEmail.name')}</h5>
        <div style={{ opacity: 0.6, fontSize: '0.9em' }}>{t('account.changeEmail.description')}</div>
        <div className="mt-4">
          <Button variant="outline-primary" size="sm" onClick={openModal}>
            {t('account.changeEmail.button')}
          </Button>
        </div>
      </Card>

      <Modal show={showModal} animation={false} onHide={closeModal}>
        <Form
          onSubmit={(event) => {
            event.preventDefault();
            change();
          }}
          action="#submit"
          noValidate
        >
          <Modal.Header closeButton>
            <Modal.Title as="h5">{t('account.changeEmail.button')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.Label>{t('account.newEmail')}</Form.Label>
              <Form.Control
                type="email"
                autoFocus
                autoCorrect="off"
                autoCapitalize="none"
                autoComplete="username"
                isInvalid={!!newEmail.error}
                value={newEmail.value}
                onChange={onNewEmailChange}
              />
              {!!newEmail.error && (
                <Form.Control.Feedback type="invalid">
                  {t(`account.errors.email.${newEmail.error}`)}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="mt-3">
              <Form.Label>{t('account.currentPassword')}</Form.Label>
              <Form.Control
                type="password"
                autoComplete="current-password"
                isInvalid={!!currentPassword.error}
                value={currentPassword.value}
                onChange={onCurrentPasswordChange}
              />
              {!!currentPassword.error && (
                <Form.Control.Feedback type="invalid">
                  {t(`account.errors.currentPassword.${currentPassword.error}`)}
                </Form.Control.Feedback>
              )}
              <div className="mt-1 text-end" style={{ fontSize: '0.8em' }}>
                <Link to={`/reset-password?email=${encodeURIComponent(account.account.email)}`}>
                  {t('account.forgotPassword')}
                </Link>
              </div>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-secondary" onClick={closeModal}>
              {t('global.cancel')}
            </Button>
            <Button type="submit">{t('global.change')}</Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
