import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import classnames from 'classnames';
import SearchInput from '../Components/SearchInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faNetworkWired } from '@fortawesome/free-solid-svg-icons';

import { get } from '../api2';

export default function DeviceSelector({ profile, onDeviceChange }) {
  const { t } = useTranslation();

  const [show, setShow] = useState(false);
  const [devices, setDevices] = useState(null);
  const [selected, setSelected] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [search, setSearch] = useState('');

  const deviceName = useCallback((device) => device.id === '__UNIDENTIFIED__'
    ? t('analytics.devices.unidentified.name')
    : device.name || t('global.unnamedDevice', { id: device.id }), [t]);

  const filteredDevices = useMemo(() => {
    if (!search) {
      return devices;
    }

    return devices.filter((device) => {
      if (deviceName(device).toLowerCase().includes(search)) {
        return true;
      }
      if (device.model?.toLowerCase().includes(search)) {
        return true;
      }
      if (device.localIp?.toLowerCase().includes(search)) {
        return true;
      }
      return false;
    });
  }, [deviceName, devices, search]);

  const toggleShow = () => {
    setSearch('');
    setShow(prevShow => !prevShow);
  };

  const handleSearch = (value) => {
    setSearch(value.toLowerCase());
  };

  const searchHighlight = useCallback((text) => {
    if (!search || !text) {
      return text;
    }
  
    const index = text.toLowerCase().indexOf(search);
    if (index === -1) {
      return text;
    }
  
    return (
      <>
        {text.substring(0, index)}
        <span className="search-highlight">{text.substring(index, index + search.length)}</span>
        {text.substring(index + search.length)}
      </>
    );
  }, [search]); 

  const handleSelect = useCallback((id) => {
    setSelected(id);
    onDeviceChange(id);
    setTimeout(() => {
      setSearch('');
      setShow(false)
    }, 300);
  }, [onDeviceChange]);

  useEffect(() => {
    setLoading(true);

    return get(
      `/profiles/${profile}/analytics/devices`,
      {
        from: '-3M',
        to: null,
        limit: 200,
      },
      ({ data }) => {
        setDevices(data);
        setSelected(null);
        setLoading(false);
      }
    );
  }, [profile]);

  if (isLoading) {
    return (
      <Dropdown show={show} onToggle={toggleShow}>
        <Dropdown.Toggle
          disabled={isLoading}
          variant="light"
          className="text-truncate"
          style={{ maxWidth: '100%', pointerEvents: 'none' }}
        >
          {t('deviceSelector.all')}
        </Dropdown.Toggle>
      </Dropdown>
    );
  }

  if (devices.length === 0) {
    return (
      <OverlayTrigger
        placement="bottom"
        overlay={
          <Tooltip>
            <div className="py-2" style={{ fontSize: '0.9em', lineHeight: '1.5em', opacity: 0.95 }}>
              {t('deviceSelector.howto')}
            </div>
          </Tooltip>
        }
      >
        <Dropdown show={show} onToggle={toggleShow}>
          <Dropdown.Toggle
            disabled
            variant="light"
            className="text-truncate"
            style={{ maxWidth: '100%', pointerEvents: 'none' }}
          >
            {t('deviceSelector.all')}
          </Dropdown.Toggle>
        </Dropdown>
      </OverlayTrigger>
    );
  }

  const selectedDevice = devices.find((device) => device.id === selected);
  const nameOcurrences = devices.reduce((acc, device) => {
    if (!device.name) {
      return acc;
    }
    acc[device.name] = (acc[device.name] || 0) + 1;
    return acc;
  }, {});

  return (
    <Dropdown show={show} onToggle={toggleShow}>
      {selectedDevice ? (
        <Dropdown.Toggle
          variant="light"
          className={classnames('text-truncate', { notranslate: selectedDevice.id && selectedDevice.name })}
          style={{ maxWidth: '100%' }}
        >
          {deviceName(selectedDevice)}
        </Dropdown.Toggle>
      ) : (
        <Dropdown.Toggle variant="light" className="text-truncate" style={{ maxWidth: '100%' }}>
          {t('deviceSelector.all')}
        </Dropdown.Toggle>
      )}
      <Dropdown.Menu style={{ maxWidth: '84vw' }}>
        {devices.length > 10 && (
          <div className="bg-2" style={{
            transform: 'translate(0, -8px)',
            borderRadius: '5px 5px 0 0',
          }}>
            <SearchInput active={show} value={search} className="ps-2 pe-2 pt-3 pb-1" onChange={handleSearch} />
            <Dropdown.Divider />
          </div>
        )}
        <Dropdown.Item
          active={!selectedDevice}
          style={{ maxWidth: '100%' }}
          variant="light"
          className="text-truncate"
          onClick={() => handleSelect(null)}
        >
          {t('deviceSelector.all')}
        </Dropdown.Item>
        {filteredDevices.map((device, index) => (
          <Dropdown.Item
            key={device.id}
            active={device.id === selected}
            onClick={() => handleSelect(device.id)}
          >
            <div className={classnames('text-truncate', { notranslate: device.id && device.name })}>
              {searchHighlight(deviceName(device))}
            </div>
            <div style={{
              opacity: 0.5,
              fontSize: '0.8em',
            }}>
              {device.model && (
                <div className="text-truncate notranslate">
                  {searchHighlight(device.model)}
                </div>
              )}
              {device.localIp && (!device.name || nameOcurrences[device.name] > 1 || (search && device.localIp.toLowerCase().includes(search))) && (
                <div className="text-truncate notranslate mt-1">
                  <FontAwesomeIcon icon={faNetworkWired} style={{ marginRight: 4, opacity: 0.8 }} />
                  <span>{searchHighlight(device.localIp)}</span>
                </div>
              )}
            </div>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown >
  );
}
