const defaultIsOk = (status) => status >= 200 && status < 300;

const ARTIFICIAL_DELAY = null;

export function make(
  url,
  { headers, method = 'GET', payload = null, responseType = 'json', withCredentials = true, isOk = defaultIsOk } = {},
  onResponse
) {
  const xhr = new XMLHttpRequest();
  xhr.open(method, url, true);
  xhr.responseType = responseType;
  if (headers != null) {
    Object.entries(headers).forEach(([key, value]) => xhr.setRequestHeader(key, value));
  }
  const onLoad = () => {
    onResponse({
      status: xhr.status,
      ok: isOk(xhr.status),
      response: xhr.response,
    });
  };
  const onError = () => {
    onResponse({
      status: xhr.status,
      ok: isOk(xhr.status),
      response: xhr.response,
    });
  };
  xhr.addEventListener('load', onLoad);
  xhr.addEventListener('error', onError);
  xhr.withCredentials = withCredentials;
  let timeoutId;
  if (ARTIFICIAL_DELAY) {
    timeoutId = setTimeout(() => {
      xhr.send(payload);
    }, ARTIFICIAL_DELAY);
  } else {
    xhr.send(payload);
  }
  return () => {
    clearTimeout(timeoutId);
    xhr.abort();
    xhr.removeEventListener('load', onLoad);
    xhr.removeEventListener('error', onError);
  };
}
