import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { AccountContext } from '../contexts';
import { getStoredTheme, setStoredTheme } from '../theme';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faDisplay, faSun, faMoon } from '@fortawesome/free-solid-svg-icons';

export default function HeaderAccount() {
  const { t } = useTranslation();
  const [themePreference, setThemePreference] = useState(getStoredTheme() || 'system');

  const themeMenu = [
    { name: t('theme.system'), icon: faDisplay, id: 'system' },
    { name: t('theme.light'), icon: faSun, id: 'light' },
    { name: t('theme.dark'), icon: faMoon, id: 'dark' },
  ];

  const handleThemeChange = (theme) => {
    setStoredTheme(theme.id);
    setThemePreference(theme.id);
  }

  const account = useContext(AccountContext);

  return (
    <Dropdown>
      <Dropdown.Toggle variant="light">
        <span className="d-none d-lg-inline notranslate">{account.account.email}</span>
        <FontAwesomeIcon className="d-inline d-lg-none" icon={faUser} />
      </Dropdown.Toggle>
      <Dropdown.Menu align="end">
        <Dropdown.Item as={Link} to="/account">
          {t('pages.account')}
        </Dropdown.Item>
        <div>
          <div className='d-flex justify-space-between align-items-center px-3'>
            {t('theme.label')}
            <Dropdown>
              {themeMenu.filter(theme => theme.id === themePreference).map(theme => (
                <Dropdown.Toggle key={theme.id} variant="light" size="sm" className="ms-2">
                  <FontAwesomeIcon icon={theme.icon} className="me-2" />
                  {theme.name}
                </Dropdown.Toggle>
              ))}
              <Dropdown.Menu align="end">
                {themeMenu.map(theme => (
                  <Dropdown.Item 
                    key={theme.id}
                    active={theme.id === themePreference}
                    value={theme.id}
                    onClick={() => handleThemeChange(theme)}
                  >
                    <FontAwesomeIcon icon={theme.icon} className="me-2" style={{ width: 17}} />
                    {theme.name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <Dropdown.Item href="https://help.nextdns.io" target="_blank">
          {t('pages.help')}
        </Dropdown.Item>
        <Dropdown.Item as={Link} to="/logout" className="text-danger">
          {t('account.logout')}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown >
  );
}
