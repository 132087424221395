import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Card, ProgressBar, Alert, Button } from 'react-bootstrap';

export default function AccountFreeUsage(props) {
  const { t } = useTranslation();

  return (
    <Card body>
      <h5>{t('account.usage.name')}</h5>
      <div style={{ opacity: 0.6, fontSize: '0.9em' }}>{t('account.usage.description', { queries: 300000 })}</div>
      <div className="mt-4">
        <ProgressBar>
          <ProgressBar
            variant={props.monthlyQueries > 300000 ? 'danger' : props.monthlyQueries >= 250000 ? 'warning' : ''}
            now={100 * Math.min(1, props.monthlyQueries / 300000)}
          />
        </ProgressBar>
        <div className="mt-1">
          <Trans i18nKey="account.usage.queries">
            <span
              style={{
                fontWeight: 500,
                color:
                  props.monthlyQueries > 300000 ? '#dc3545' : props.monthlyQueries >= 250000 ? '#ffc107' : '#007bff',
              }}
            >
              {{ current: props.monthlyQueries }}
            </span>
            <span style={{ fontSize: '0.8em', opacity: 0.5 }}> / {{ quota: 300000 }} queries</span>
          </Trans>
        </div>

        {props.monthlyQueries > 300000 && (
          <Alert className="mt-3" variant="warning">
            <div style={{ fontWeight: 500 }}>{t('account.usage.overQuota.message', { queries: 300000 })}</div>
            <div className="mt-1" style={{ fontSize: '0.9em' }}>
              {t('account.usage.overQuota.info')}
            </div>
          </Alert>
        )}
        <div className="mt-4 d-flex">
          <Button onClick={props.onSubscribeClicked}>{t('global.subscribe')}</Button>
          <div className="flex-grow-1 d-flex align-items-center" style={{ marginLeft: 12 }}>
            <div style={{ fontSize: '0.9em', opacity: 0.7 }}>
              {t('account.usage.subscribe.upsell', {
                amount: [props.prices.plans.pro.month, props.prices.currency],
              })}
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
}
