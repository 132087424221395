import { useState, useEffect } from 'react';

export function getStoredTheme() {
    const storedTheme = localStorage.getItem('theme');
    if (storedTheme === 'light' || storedTheme === 'dark') {
        return storedTheme
    }
    return 'system';
}

export function setStoredTheme(theme) {
    localStorage.setItem('theme', theme);
    const preverredTheme = getPreferredTheme();
    handlers.forEach(fn => fn(preverredTheme));
}

export function getPreferredTheme() {
    const storedTheme = getStoredTheme()
    if (storedTheme === 'light' || storedTheme === 'dark') {
        return storedTheme
    }

    // auto
    return window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'
}

export function useTheme() {
    const [theme, setTheme] = useState(getPreferredTheme());

    useEffect(() => {
        const handleThemeChange = (theme) => {
            setTheme(theme);
        };
        onThemeChange(handleThemeChange);
        return () => {
            offThemeChange(handleThemeChange);
        };
    }, []);

    return theme;
}

const handlers = [];

export function onThemeChange(fn = (theme) => { }) {
    handlers.push(fn);
}

export function offThemeChange(fn = (theme) => { }) {
    const index = handlers.indexOf(fn);
    if (index !== -1) {
        handlers.splice(index, 1);
    }
}

window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', () => {
    const theme = getPreferredTheme();
    handlers.forEach(fn => fn(theme));
})