import { useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AppContext, AccountContext } from './contexts';
import { api } from './api';

export default function Start() {
  const { t } = useTranslation();

  const app = useContext(AppContext);
  const account = useContext(AccountContext);

  useEffect(() => {
    (async () => {
      try {
        await api.post('/accounts', {
          profile: {
            name: t('global.firstConfiguration'),
            security: {
              threatIntelligenceFeeds: true,
              googleSafeBrowsing: true,
              cryptojacking: true,
              idnHomographs: true,
              typosquatting: true,
              dga: true,
              csam: true,
            },
            privacy: {
              blocklists: [
                {
                  id: 'nextdns-recommended',
                },
              ],
              disguisedTrackers: true,
            },
            settings: {
              logs: {
                enabled: true,
              },
              performance: {
                ecs: true,
              },
            },
          },
        });
      } catch (error) {
        if (error.isAxiosError) {
          app.handleNetworkError(error);
          return;
        }
        throw error;
      }

      await account.load();
    })();
  }, [t, app, account]);

  return null;
}
