import React, { memo, useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'react-bootstrap';

const RANGES = ['30m', '6h', '24h', '7d', '30d', '3M'];

function AnalyticsTimeRangeSelector({ profile, onTimeRangeChange }) {
  const { t } = useTranslation();

  const [from, setFrom] = useState('30d');

  useEffect(() => {
    setFrom('30d');
  }, [profile]);

  const onFromChange = useCallback(
    (range) => {
      setFrom(range);

      onTimeRangeChange({
        from: `-${range}`,
        to: null,
      });
    },
    [onTimeRangeChange]
  );

  return (
    <Dropdown>
      <Dropdown.Toggle variant="light">{t(`timeRangeSelector.ranges.${from}`)}</Dropdown.Toggle>
      <Dropdown.Menu align="end" >
        {RANGES.map((range) => (
          <Dropdown.Item key={range} active={range === from} onClick={() => onFromChange(range)}>
            {t(`timeRangeSelector.ranges.${range}`)}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

// TODO: is memo really needed here?
export default memo(AnalyticsTimeRangeSelector);
