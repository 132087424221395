import React, { useState, useEffect, useCallback, useRef, useContext } from 'react';
import { Navigate, Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { Container, Row, Col, Card, Form, Button, Spinner } from 'react-bootstrap';

import { api } from '../api';
import { usePrevious, useQuery } from '../hooks';
import { isMobile } from '../helpers';
import { AppContext, AccountContext } from '../contexts';
import { useTheme } from '../theme';

import logoLarge from '../images/logo-large@2x.png';
import logoLargeDark from '../images/logo-large-dark@2x.png';

export default function LogIn() {
  const { t } = useTranslation();
  const theme = useTheme();

  const app = useContext(AppContext);
  const account = useContext(AccountContext);

  const query = useQuery();

  const [email, setEmail] = useState({
    value: '',
    error: null,
  });

  const [password, setPassword] = useState({
    value: '',
    error: null,
  });

  const [code, setCode] = useState({
    required: false,
    value: '',
    error: null,
  });

  const [shouldFocusCodeInput, setShouldFocusCodeInput] = useState(false);

  const prevCode = usePrevious(code);

  const codeInput = useRef();

  const [loggingIn, setLoggingIn] = useState(false);

  const onEmailChange = useCallback((event) => {
    const value = event.target.value.trim();

    setEmail((email) => {
      return { ...email, value: value, error: null };
    });
  }, []);

  const onPasswordChange = useCallback((event) => {
    const value = event.target.value;

    setPassword((password) => {
      return { ...password, value: value, error: null };
    });
  }, []);

  const onCodeChange = useCallback((event) => {
    const value = event.target.value.replace(/[^\d]+/g, '');

    setCode((code) => {
      return { ...code, value: value, error: null };
    });
  }, []);

  const logIn = useCallback(async () => {
    setLoggingIn(true);

    const body = {
      email: email.value,
      password: password.value,
    };

    if (code.required) {
      body.code = code.value;
    }

    let data;
    try {
      ({ data } = await api.post('/accounts/@login', body));
    } catch (error) {
      if (error.isAxiosError) {
        app.handleNetworkError(error);
        return;
      }
      throw error;
    }

    if (data.errors) {
      setLoggingIn(false);

      if (data.errors.email) {
        setEmail((email) => {
          return { ...email, error: data.errors.email };
        });
      }

      if (data.errors.password) {
        setPassword((password) => {
          return { ...password, value: '', error: data.errors.password };
        });
      }

      if (data.errors.code) {
        setCode((code) => {
          return { ...code, value: '', error: data.errors.code };
        });

        setShouldFocusCodeInput(true)
      }

      return;
    }

    if (data.requiresCode) {
      setLoggingIn(false);

      setCode((code) => {
        return { ...code, required: true };
      });

      setShouldFocusCodeInput(true);

      return;
    }

    if (query.get('next')) {
      window.location.href = window.location.protocol + '//' + window.location.host + query.get('next');
      return;
    }

    await account.load();
  }, [app, account, email.value, password.value, code.required, code.value, query]);

  useEffect(() => {
    if (!prevCode || prevCode.value === code.value) {
      return;
    }

    if (code.value.length === 6) {
      logIn();
    }
  });

  useEffect(() => {
    if (shouldFocusCodeInput && codeInput.current) {
      codeInput.current.focus();
      setShouldFocusCodeInput(false);  // reset for the next time
    }
  }, [shouldFocusCodeInput]);


  if (account.account) {
    return <Navigate to="/" replace />;
  }

  const signUpPath = query.has('next') ? '/signup?next=' + encodeURIComponent(query.get('next')) : '/signup';

  return (
    <>
      <Helmet>
        <title>{t('pages.login')}</title>
      </Helmet>

      <Container style={{ marginTop: '5vw' }}>
        <Row className="justify-content-center">
          <Col sm="9" md="7" lg="5" xl="4">
            <div className="mt-2 mb-4 text-center">
              <img
                className="d-inline"
                src={theme === 'dark' ? logoLargeDark : logoLarge}
                style={{ width: 200, marginRight: 20 }}
                alt=""
              />
            </div>

            <Card>
              <Card.Body>
                <Form
                  onSubmit={(event) => {
                    event.preventDefault();
                    logIn();
                  }}
                  action="#submit"
                  noValidate
                  className="d-grid gap-3"
                >
                  <Form.Group>
                    <Form.Label>{t('account.email')}</Form.Label>
                    <Form.Control
                      type="email"
                      disabled={code.required}
                      autoFocus={!isMobile}
                      autoCorrect="off"
                      autoCapitalize="none"
                      autoComplete="username"
                      isInvalid={!!email.error}
                      value={email.value}
                      onChange={onEmailChange}
                    />
                    {!!email.error && (
                      <Form.Control.Feedback type="invalid">
                        {t(`account.errors.email.${email.error}`)}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>{t('account.password')}</Form.Label>
                    <Form.Control
                      type="password"
                      disabled={code.required}
                      autoComplete="current-password"
                      isInvalid={!!password.error}
                      value={password.value}
                      onChange={onPasswordChange}
                    />
                    {!!password.error && (
                      <Form.Control.Feedback type="invalid">
                        {t(`account.errors.password.${password.error}`)}
                      </Form.Control.Feedback>
                    )}
                    {!code.required && (
                      <div className="mt-1 text-end" style={{ fontSize: '0.8em' }}>
                        <Link to={`/reset-password` + (email.value ? `?email=${encodeURIComponent(email.value)}` : '')}>
                          {t('account.forgotPassword')}
                        </Link>
                      </div>
                    )}
                  </Form.Group>

                  {code.required && (
                    <Form.Group>
                      <Form.Label>{t('account.code')}</Form.Label>
                      <Form.Control
                        className="text-center text-monospace"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        autoComplete="one-time-code"
                        ref={codeInput}
                        maxLength="6"
                        size="lg"
                        isInvalid={!!code.error}
                        value={code.value}
                        onChange={onCodeChange}
                        style={{ fontWeight: 500, fontSize: '1.6em', letterSpacing: '0.35em', paddingRight: '1rem' }}
                      />
                      {!!code.error && (
                        <Form.Control.Feedback type="invalid">
                          {t(`account.errors.code.${code.error}`)}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  )}

                  <Button type="submit" size="lg" disabled={loggingIn}>
                    {!loggingIn && t('account.login')}
                    {loggingIn && (
                      <Spinner
                        animation="border"
                        style={{ width: '1.4rem', height: '1.4rem', borderWidth: '0.2rem' }}
                      />
                    )}
                  </Button>
                </Form>
              </Card.Body>
            </Card>

            <div className="mt-3 mb-4 text-center">
              <Trans i18nKey="account.noAccountYet">
                _No account yet? <Link to={signUpPath}>Sign up</Link>
              </Trans>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
