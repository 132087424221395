import * as Request from './Request';
import * as NotificationCenter from './NotificationCenter';

const apiUrl = process.env.REACT_APP_API_ENDPOINT || 'https://api.nextdns.io';

// TODO: why X-Api-Key??
//const apiKey = process.env.REACT_APP_API_KEY;
//const defaultHeaders = { 'X-Api-Key': apiKey };
const defaultHeaders = {};

function addQueryString(url, qs) {
  if (qs.length === 0) {
    return url;
  }
  if (url.includes('?')) {
    return `${url}&${qs}`;
  }
  return `${url}?${qs}`;
}

let noop = () => {};

const createUrl = (apiPath) => {
  if (apiPath.startsWith('https://') || apiPath.startsWith('http://')) {
    return apiPath;
  }
  return `${apiUrl}${apiPath}`;
};

const getCallbacks = (method, apiPath, callback) => {
  let defaultOnFailure = () =>
    NotificationCenter.push(
      NotificationCenter.error({
        title: 'Network Error',
        description: `${method} ${apiPath}`,
      })
    );

  if (typeof callback == 'function') {
    return {
      onResponse: callback,
      onFailure: defaultOnFailure,
    };
  }
  if (callback != null && typeof callback == 'object') {
    return {
      onResponse: callback.onResponse || noop,
      onFailure: callback.onFailure || defaultOnFailure,
    };
  }
  return {
    onResponse: noop,
    onFailure: defaultOnFailure,
  };
};

export function ignoreStatus(callback) {
  return {
    onResponse: callback,
    onFailure: (data) => {
      callback(data.response);
    }
  };
}

export const get = (apiPath, params, callback) => {
  if (typeof callback == 'undefined' && typeof params == 'function') {
    callback = params;
    params = null;
  }
  const { onResponse, onFailure } = getCallbacks('GET', apiPath, callback);
  const queryString = params
    ? Object.entries(params)
        .reduce((acc, [key, value]) => {
          if (value != null) {
            acc.append(key, value);
          }
          return acc;
        }, new URLSearchParams())
        .toString()
    : '';
  return Request.make(
    addQueryString(createUrl(apiPath), queryString),
    { method: 'GET', headers: defaultHeaders },
    (response) => {
      if (response.ok) {
        onResponse(response.response);
      } else {
        onFailure(response);
      }
    }
  );
};

export const put = (apiPath, payload, callback) => {
  if (typeof callback == 'undefined' && typeof payload == 'function') {
    callback = payload;
    payload = null;
  }
  const { onResponse, onFailure } = getCallbacks('PUT', apiPath, callback);
  return Request.make(
    createUrl(apiPath),
    {
      method: 'PUT',
      headers: { ...defaultHeaders, ...(payload ? { 'Content-Type': 'application/json' } : null) },
      payload: payload ? JSON.stringify(payload) : null,
    },
    (response) => {
      if (response.ok) {
        onResponse(response.response);
      } else {
        onFailure(response);
      }
    }
  );
};

export const post = (apiPath, payload, callback) => {
  if (typeof callback == 'undefined' && typeof payload == 'function') {
    callback = payload;
    payload = null;
  }
  const { onResponse, onFailure } = getCallbacks('POST', apiPath, callback);
  return Request.make(
    createUrl(apiPath),
    {
      method: 'POST',
      headers: { ...defaultHeaders, ...(payload ? { 'Content-Type': 'application/json' } : null) },
      payload: payload ? JSON.stringify(payload) : null,
    },
    (response) => {
      if (response.ok) {
        onResponse(response.response);
      } else {
        onFailure(response);
      }
    }
  );
};

export const patch = (apiPath, payload, callback) => {
  if (typeof callback == 'undefined' && typeof payload == 'function') {
    callback = payload;
    payload = null;
  }
  const { onResponse, onFailure } = getCallbacks('PATCH', apiPath, callback);
  return Request.make(
    createUrl(apiPath),
    {
      method: 'PATCH',
      headers: { ...defaultHeaders, 'Content-Type': 'application/json' },
      payload: payload ? JSON.stringify(payload) : null,
    },
    (response) => {
      if (response.ok) {
        onResponse(response.response);
      } else {
        onFailure(response);
      }
    }
  );
};

export const del = (apiPath, payload, callback) => {
  if (typeof callback == 'undefined' && typeof payload == 'function') {
    callback = payload;
    payload = null;
  }
  const { onResponse, onFailure } = getCallbacks('DELETE', apiPath, callback);
  return Request.make(
    createUrl(apiPath),
    { method: 'DELETE', headers: defaultHeaders, payload: payload ? JSON.stringify(payload) : null },
    (response) => {
      if (response.ok) {
        onResponse(response.response);
      } else {
        onFailure(response);
      }
    }
  );
};

export const request = (apiPath, options, callback) => {
  const { onResponse, onFailure } = getCallbacks(options.method || 'GET', apiPath, callback);
  return Request.make(
    createUrl(apiPath),
    { ...options, headers: { ...defaultHeaders, ...(options ? options.headers : null) } },
    (response) => {
      if (response.ok) {
        onResponse(response);
      } else {
        onFailure(response);
      }
    }
  );
};

export const getAsStream = (apiPath, params, callback) => {
  if (typeof callback == 'undefined' && typeof params == 'function') {
    callback = params;
    params = null;
  }
  const { onResponse, onFailure } = getCallbacks('GET', apiPath, callback);
  const queryString = params
    ? Object.entries(params)
        .reduce((acc, [key, value]) => {
          if (value != null) {
            acc.append(key, value);
          }
          return acc;
        }, new URLSearchParams())
        .toString()
    : '';

  const source = new EventSource(addQueryString(createUrl(apiPath), queryString), { withCredentials: true });
  let onMessage = ({ data, lastEventId }) => {
    onResponse({ data: [JSON.parse(data)], meta: { stream: { id: lastEventId } } });
  };
  source.addEventListener('message', onMessage);
  source.addEventListener('error', (event) => {
    if (source.readyState === 0) {
      return; // reconnecting
    }

    onFailure(event);
  });
  return () => {
    source.removeEventListener('message', onMessage);
    source.removeEventListener('error', onFailure);
    source.close();
  };
};
