import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Card, ListGroup } from 'react-bootstrap';

import { get } from '../api2';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import Page from '../Page';
import TranslationsFooter from '../Components/TranslationsFooter';
import XlistAdd from './XlistAdd';
import XlistDomain from './XlistDomain';

export default function Xlist({ listType, profile, role }) {
  const { t } = useTranslation();

  const [domains, setDomains] = useState();

  useEffect(() => {
    setDomains();

    return get(`/profiles/${profile}/${listType}`, ({ data }) => setDomains(data));
  }, [profile, listType]);

  const onDomainAdd = useCallback((domain) => {
    setDomains((domains) => (domains ? [domain].concat(domains) : [domain]));
  }, []);

  const onDomainChange = useCallback((domain) => {
    setDomains((domains) => (domains ? domains.map((item) => (item.id === domain.id ? domain : item)) : domains));
  }, []);

  const onDomainRemove = useCallback((domain) => {
    setDomains((domains) => (domains ? domains.filter((item) => item.id !== domain.id) : domains));
  }, []);

  if (process.env.REACT_APP_TAILWIND) {
    return (
      <Page title={t(`pages.${listType}`)} profile={profile}>
        <XlistAdd listType={listType} profile={profile} role={role} onAdded={onDomainAdd} />
        {!!domains && (
          <div className="tw-mt-3 tw-overflow-hidden tw-rounded-md tw-border dark:tw-border-slate-800">
            {domains.length > 0 ? (
              domains.map((domain) => (
                <XlistDomain
                  key={domain.id}
                  listType={listType}
                  profile={profile}
                  role={role}
                  domain={domain}
                  onChange={onDomainChange}
                  onRemove={onDomainRemove}
                />
              ))
            ) : (
              <div>empty</div>
            )}
          </div>
        )}
      </Page>
    );
  }

  return (
    <Page title={t(`pages.${listType}`)} profile={profile}>
      <Row className="mb-3">
        <Col>
          <Card>
            <ListGroup variant="flush">
              <ListGroup.Item className="text-muted">
                <small>
                  <FontAwesomeIcon icon={faInfoCircle} className="me-2" style={{ opacity: 0.6 }} />
                  {t(`xlist.${listType}.info`)}
                </small>
              </ListGroup.Item>
            </ListGroup>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <XlistAdd listType={listType} profile={profile} role={role} onAdded={onDomainAdd} />
            {!!domains && (
              <>
                {domains.length > 0 && (
                  <ListGroup variant="flush">
                    {domains.map((domain) => (
                      <XlistDomain
                        key={domain.id}
                        listType={listType}
                        profile={profile}
                        role={role}
                        domain={domain}
                        onChange={onDomainChange}
                        onRemove={onDomainRemove}
                      />
                    ))}
                  </ListGroup>
                )}
                {domains.length === 0 && (
                  <Card.Body>
                    <div className="text-center my-2" style={{ opacity: 0.4 }}>
                      {t('xlist.empty')}
                    </div>
                  </Card.Body>
                )}
              </>
            )}
          </Card>
        </Col>
      </Row>
      {!!domains && <TranslationsFooter />}
    </Page>
  );
}
