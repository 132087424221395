import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, ListGroup, Modal, Button } from 'react-bootstrap';
import { del, post } from '../api2';
import { hex } from '../helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useTheme } from '../theme';

import Xiaomi from '../images/natives/xiaomi.svg';
import Alexa from '../images/natives/alexa.svg';
import Huawei from '../images/natives/huawei.svg';
import Roku from '../images/natives/roku.svg';
import RokuDark from '../images/natives/roku-dark.svg';
import Samsung from '../images/natives/samsung.svg';
import SamsungDark from '../images/natives/samsung-dark.svg';
import Windows from '../images/natives/windows.svg';
import Apple from '../images/natives/apple.svg';
import AppleDark from '../images/natives/apple-dark.svg';
import Sonos from '../images/natives/sonos.svg';
import SonosDark from '../images/natives/sonos-dark.svg';

const NATIVES = [
  { id: 'windows', logo: Windows },
  { id: 'apple', logo: Apple, logoDark: AppleDark},
  { id: 'samsung', logo: Samsung, logoDark: SamsungDark },
  { id: 'xiaomi', logo: Xiaomi },
  { id: 'huawei', logo: Huawei },
  { id: 'alexa', logo: Alexa },
  { id: 'roku', logo: Roku, logoDark: RokuDark},
  { id: 'sonos', logo: Sonos, logoDark: SonosDark},
];

export default function PrivacyNatives({ profile, role, natives }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const [allNatives, setAllNatives] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const source = useRef();

  useEffect(() => {
    source.current = new Set();
    setAllNatives(NATIVES.map(native => ({
      ...native,
      added: natives.some(n => n.id === native.id)
    })));
    return () => {
      source.current.forEach(cancel => cancel());
    };
  }, [natives]);

  const getLogo = (native) => theme === 'dark' ? (native.logoDark ?? native.logo) : native.logo;

  const openModal = () => setShowModal(true);

  const closeModal = () => setShowModal(false);

  const add = (native) => {
    const newNative = { ...native, active: true };
    updateAllNatives(newNative.id, true);
    const cancel = post(`/profiles/${profile}/privacy/natives`, { id: native.id });
    source.current.add(cancel);
  };

  const remove = (id) => {
    updateAllNatives(id, false);
    const cancel = del(`/profiles/${profile}/privacy/natives/${hex(id)}`);
    source.current.add(cancel);
  };

  const updateAllNatives = (id, added) => {
    const updatedAllNatives = allNatives.map(n => n.id === id ? { ...n, added } : n);
    setAllNatives(updatedAllNatives);
  };

  return (
    <>
      <Card>
        <ListGroup variant="flush">
          <ListGroup.Item className="py-3">
            <div className="d-flex align-items-center">
              <h5>{t('privacy.native.name')}</h5>
              <div className='beta-badge'>
                {t('global.beta')}
              </div>
            </div>
            <div style={{ opacity: 0.6, fontSize: '0.9em' }}>{t('privacy.native.description')}</div>
          </ListGroup.Item>
          {allNatives && allNatives.filter(n => n.added).map(native => (
            <ListGroup.Item key={native.id} style={{ borderLeft: '4px solid #007bff' }}>
              <div className="d-flex">
                <div
                  className="d-flex align-items-center"
                  style={{ minWidth: 40, width: 40, marginLeft: -4, marginRight: 10 }}
                >
                  <img src={getLogo(native)} style={{ width: '100%' }} alt="" />
                </div>
                <div className="flex-grow-1">
                  <div>
                    <div className="notranslate" style={{ fontWeight: 500 }}>
                      {t(`privacy.native.systems.${native.id}.name`)}
                    </div>
                    <div style={{ fontSize: '0.8em', opacity: 0.4 }}>
                      {t(`privacy.native.systems.${native.id}.devices`)}
                    </div>
                  </div>
                </div>
                {role !== 'viewer' && (
                  <Button
                    variant="link"
                    className="me-n3 mt-n1"
                    style={{ opacity: 0.3, color: '#222' }}
                    onClick={() => remove(native.id)}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </Button>
                )}
              </div>
            </ListGroup.Item>
          ))}
          <Card.Footer className="text-muted" style={{ borderTop: 'none' }}>
            <Button
              size="sm"
              style={{ fontWeight: 'bold', fontSize: 12, textTransform: 'uppercase' }}
              onClick={openModal}
              disabled={role === 'viewer'}
            >
              {t('privacy.native.add')}
            </Button>
          </Card.Footer>
        </ListGroup>
      </Card>

      <Modal
        show={showModal}
        animation={false}
        size="lg"
        scrollable={true}
        onHide={closeModal}
      >
        <Modal.Header closeButton>
          <Modal.Title as="h5">{t('privacy.native.add')}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          {allNatives && (
            <ListGroup variant="flush">
              {allNatives.map(native => (
                <ListGroup.Item
                  key={native.id}
                  style={{ borderLeft: '4px solid #ccc', borderLeftColor: native.added ? '#007bff' : '' }}
                >
                  <div className="d-flex">
                    <div
                      className="d-flex align-items-center"
                      style={{ minWidth: 40, width: 40, marginLeft: -4, marginRight: 10 }}
                    >
                      <img className="d-inline" src={getLogo(native)} style={{ width: '100%' }} alt="" />
                    </div>
                    <div className="flex-grow-1">
                      <div>
                        <div className="notranslate" style={{ fontWeight: 500 }}>
                          {t(`privacy.native.systems.${native.id}.name`)}
                        </div>
                        <div style={{ fontSize: '0.8em', opacity: 0.4 }}>
                          {t(`privacy.native.systems.${native.id}.devices`)}
                        </div>
                      </div>
                    </div>
                    <div className="d-flex align-items-center my-2 ms-4">
                      <Button
                        size="sm"
                        style={{
                          fontWeight: 'bold',
                          fontSize: 12,
                          textTransform: 'uppercase',
                          whiteSpace: 'nowrap',
                        }}
                        variant={native.added ? "danger" : "primary"}
                        onClick={() => native.added ? remove(native.id) : add(native)}
                      >
                        {native.added ? t('global.remove') : t('global.add')}
                      </Button>
                    </div>
                  </div>
                </ListGroup.Item>
              ))}
            </ListGroup>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}