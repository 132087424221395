import React, { useState, useCallback, useRef, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import { CancelToken } from 'axios';

import { api } from '../api';
import { AppContext } from '../contexts';

import Page from '../Page';
import TranslationsFooter from '../Components/TranslationsFooter';
import AccountFreeUsage from './AccountFreeUsage';
import AccountSubscription from './AccountSubscription';
import AccountBusinessInfo from './AccountBusinessInfo';
import AccountInvoices from './AccountInvoices';
import AccountAffiliation from './AccountAffiliation';
import AccountChangeEmail from './AccountChangeEmail';
import AccountChangePassword from './AccountChangePassword';
import Account2FA from './Account2FA';
import AccountApi from './AccountApi';
import AccountDeleteAccount from './AccountDeleteAccount';
import AccountSubscriptionModal from './AccountSubscriptionModal';
import { useLocation } from 'react-router-dom';

export default function Account() {
  const { t } = useTranslation();

  const location = useLocation();
  const subscriptionModalDefaultAction = location.hash.substring(1).split('/')[0] ?? null;

  const app = useContext(AppContext);
  const [state, setState] = useState();
  const [subscriptionModalAction, setSubscriptionModalAction] = useState(subscriptionModalDefaultAction);

  const source = useRef();

  useEffect(() => {
    source.current = CancelToken.source();

    return () => {
      source.current.cancel();
    };
  }, []);

  const fetch = useCallback(async () => {
    let data;
    try {
      ({ data } = await api.get('/account', {
        cancelToken: source.current.token,
      }));
    } catch (error) {
      if (error.isAxiosError) {
        app.handleNetworkError(error);
        return;
      }
      throw error;
    }

    setState(data);
  }, [app]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <Page title={t('pages.account')}>
      {!!state && (
        <>
          {!state.subscription && (
            <Row>
              <Col className="mb-4">
                <AccountFreeUsage
                  monthlyQueries={state.monthlyQueries}
                  prices={state.prices}
                  onSubscribeClicked={() => setSubscriptionModalAction('subscribe')}
                />
              </Col>
            </Row>
          )}

          {!!state.subscription && (
            <Row>
              <Col className="mb-4">
                <AccountSubscription
                  subscription={state.subscription}
                  onSubscribeClicked={() => setSubscriptionModalAction('subscribe')}
                  onChangePlanClicked={() => setSubscriptionModalAction('change-plan')}
                  onChangePaymentMethodClicked={() => setSubscriptionModalAction('change-payment-method')}
                  onSubscriptionUpdated={fetch}
                />
              </Col>
            </Row>
          )}

          <AccountSubscriptionModal
            subscription={state.subscription}
            prices={state.prices}
            action={subscriptionModalAction}
            onCloseModal={() => setSubscriptionModalAction(null)}
            onSubscriptionUpdated={fetch}
          />

          {((state.subscription && state.subscription.plan !== 'pro') || state.business.name) && (
            <Row>
              <Col className="mb-4">
                <AccountBusinessInfo business={state.business} onBusinessUpdated={fetch} />
              </Col>
            </Row>
          )}

          {state.invoices.length > 0 && (
            <Row>
              <Col className="mb-4">
                <AccountInvoices invoices={state.invoices} />
              </Col>
            </Row>
          )}

          <Row>
            <Col className="mb-4">
              <AccountAffiliation affiliation={state.affiliation} onChange={fetch} />
            </Col>
          </Row>

          <Row>
            <Col lg="6" className="mb-4">
              <AccountChangeEmail onEmailChanged={fetch} />
            </Col>

            <Col lg="6" className="mb-4">
              <AccountChangePassword email={state.email} />
            </Col>
          </Row>

          <Row>
            <Col className="mb-4">
              <Account2FA twoFactorAuthentication={state.twoFactorAuthentication} onChange={fetch} />
            </Col>
          </Row>

          {!!state.apiKeys && (
            <Row>
              <Col className="mb-4">
                <AccountApi keys={state.apiKeys} />
              </Col>
            </Row>
          )}

          <Row style={{ marginBottom: 70 }}>
            <Col>
              <AccountDeleteAccount email={state.email} />
            </Col>
          </Row>
          <TranslationsFooter />
        </>
      )}
    </Page>
  );
}
