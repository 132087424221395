import React, { useEffect, useRef } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Nav } from 'react-bootstrap';
import MediaQuery from 'react-responsive';

import { classNames } from '../helpers';

const MENUS = [
  'setup',
  'security',
  'privacy',
  'parentalcontrol',
  'denylist',
  'allowlist',
  'analytics',
  'logs',
  'settings',
];

export default function HeaderMenu({ profile }) {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const location = useLocation();

  const mobileMenuContainerRef = useRef();

  useEffect(() => {
    if (!mobileMenuContainerRef.current) {
      return;
    }

    let children = mobileMenuContainerRef.current.children[0].children;
    for (const child of children) {
      if (!child.children[0].className.includes('active')) {
        continue;
      }

      if (child.offsetLeft + child.offsetWidth > mobileMenuContainerRef.current.offsetWidth - 15) {
        mobileMenuContainerRef.current.scrollLeft = Math.round(
          child.offsetWidth / 2 +
            (child.offsetLeft + child.offsetWidth) -
            (mobileMenuContainerRef.current.offsetWidth - 15)
        );
      }

      break;
    }
  }, []);

  if (process.env.REACT_APP_TAILWIND) {
    return (
      <div className="tw-scrollbar-hide tw--mb-px tw-overflow-x-auto">
        <nav className="tw-flex tw-space-x-7">
          {MENUS.map((menu, i) => (
            <a
              key={menu}
              href="#menu"
              onClick={(e) => {
                e.preventDefault();
                navigate(`/${profile}/${menu}`);
              }}
              className={classNames(
                location.pathname.startsWith(`/${profile}/${menu}`)
                  ? 'tw-border-nextdnsblue tw-text-nextdnsblue'
                  : 'mouse:hover:tw-border-gray-300 mouse:hover:tw-text-gray-800 dark:mouse:hover:tw-border-slate-500 dark:mouse:hover:tw-text-slate-50 tw-border-transparent tw-text-gray-500 dark:tw-text-gray-400',
                'tw-whitespace-nowrap tw-border-b-2 tw-py-2.5 tw-px-1 tw-text-sm tw-font-normal hover:tw-no-underline'
              )}
            >
              {t(`pages.${menu}`)}
            </a>
          ))}
        </nav>
      </div>
    );
  }

  return (
    <>
      <MediaQuery minWidth={992}>
        <Nav variant="tabs" style={{ marginBottom: -1 }}>
          {MENUS.map((menu) => (
            <Nav.Item key={menu}>
              <Nav.Link as={NavLink} className="menu" to={`/${profile}/${menu}`}>
                {t(`pages.${menu}`)}
              </Nav.Link>
            </Nav.Item>
          ))}
        </Nav>
      </MediaQuery>
      <MediaQuery minWidth={576} maxWidth={992 - 1}>
        <div style={{ height: 52, marginLeft: -30, marginRight: -30, overflowY: 'hidden' }}>
          <div
            ref={mobileMenuContainerRef}
            style={{
              overflowX: 'auto',
              overflowScrolling: 'touch',
              WebkitOverflowScrolling: 'touch',
              overflowY: 'hidden',
            }}
          >
            <Nav variant="pills" style={{ marginBottom: 12, flexWrap: 'nowrap', paddingBottom: 100, paddingLeft: 30 }}>
              {MENUS.map((menu, i) => (
                <Nav.Item key={menu}>
                  <Nav.Link
                    as={NavLink}
                    to={`/${profile}/${menu}`}
                    style={{ whiteSpace: 'nowrap', marginRight: i === MENUS.length - 1 ? 30 : 0 }}
                  >
                    {t(`pages.${menu}`)}
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </div>
        </div>
      </MediaQuery>
      <MediaQuery maxWidth={576 - 1}>
        <div style={{ height: 52, marginLeft: -12, marginRight: -12, overflowY: 'hidden' }}>
          <div
            ref={mobileMenuContainerRef}
            style={{
              overflowX: 'auto',
              overflowScrolling: 'touch',
              WebkitOverflowScrolling: 'touch',
              overflowY: 'hidden',
            }}
          >
            <Nav variant="pills" style={{ marginBottom: 12, flexWrap: 'nowrap', paddingBottom: 100, paddingLeft: 12 }}>
              {MENUS.map((menu, i) => (
                <Nav.Item key={menu}>
                  <Nav.Link
                    as={NavLink}
                    to={`/${profile}/${menu}`}
                    style={{ whiteSpace: 'nowrap', marginRight: i === MENUS.length - 1 ? 15 : 0 }}
                  >
                    {t(`pages.${menu}`)}
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </div>
        </div>
      </MediaQuery>
    </>
  );
}
