import axios from 'axios';
import * as Request from './Request';
import * as NotificationCenter from './NotificationCenter';

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT || 'https://api.nextdns.io',
  withCredentials: true,
});

export const apiv4 = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT || 'https://ipv4.api.nextdns.io',
  withCredentials: true,
});

const apiUrl = process.env.REACT_APP_API_ENDPOINT || 'https://api.nextdns.io';

function addQueryString(url, qs) {
  if (qs.length === 0) {
    return url;
  }
  if (url.includes('?')) {
    return `${url}&${qs}`;
  }
  return `${url}?${qs}`;
}

let noop = () => {};

export const get = (apiPath, params, onResponse) => {
  if (typeof onResponse == 'undefined' && typeof params == 'function') {
    onResponse = params;
    params = null;
  }
  onResponse = onResponse || noop;
  const queryString = params
    ? Object.entries(params)
        .reduce((acc, [key, value]) => {
          if (value != null) {
            acc.append(key, value);
          }
          return acc;
        }, new URLSearchParams())
        .toString()
    : '';
  return Request.make(addQueryString(`${apiUrl}${apiPath}`, queryString), { method: 'GET' }, ({ ok, response }) => {
    if (ok) {
      onResponse(response);
    } else {
      NotificationCenter.push(
        NotificationCenter.error({
          title: 'Network Error',
          description: `GET ${apiPath}`,
        })
      );
    }
  });
};

export const put = (apiPath, payload, onResponse) => {
  if (typeof onResponse == 'undefined' && typeof payload == 'function') {
    onResponse = payload;
    payload = null;
  }
  onResponse = onResponse || noop;
  return Request.make(
    `${apiUrl}${apiPath}`,
    {
      method: 'PUT',
      headers: payload ? { 'Content-Type': 'application/json' } : null,
      payload: payload ? JSON.stringify(payload) : null,
    },
    ({ ok, response }) => {
      if (ok) {
        onResponse(response);
      } else {
        NotificationCenter.push(
          NotificationCenter.error({
            title: 'Network Error',
            description: `PUT ${apiPath}`,
          })
        );
      }
    }
  );
};

export const patch = (apiPath, payload, onResponse) => {
  if (typeof onResponse == 'undefined' && typeof payload == 'function') {
    onResponse = payload;
    payload = null;
  }
  onResponse = onResponse || noop;
  return Request.make(
    `${apiUrl}${apiPath}`,
    {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      payload: payload ? JSON.stringify(payload) : null,
    },
    ({ ok, response }) => {
      if (ok) {
        onResponse(response);
      } else {
        NotificationCenter.push(
          NotificationCenter.error({
            title: 'Network Error',
            description: `PATCH ${apiPath}`,
          })
        );
      }
    }
  );
};

export const del = (apiPath, onResponse) => {
  onResponse = onResponse || noop;
  return Request.make(`${apiUrl}${apiPath}`, { method: 'DELETE' }, ({ ok, response }) => {
    if (ok) {
      onResponse(response);
    } else {
      NotificationCenter.push(
        NotificationCenter.error({
          title: 'Network Error',
          description: `DELELE ${apiPath}`,
        })
      );
    }
  });
};
