import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, ListGroup, Modal, Form, Button, Spinner } from 'react-bootstrap';
import { get, post, del, ignoreStatus } from '../api2';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

export default function SettingsRewrites({ profile, role }) {
  const { t } = useTranslation();
  const [rewrites, setRewrites] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [isChanging, setIsChanging] = useState(false);
  const [nameValue, setNameValue] = useState('');
  const [nameError, setNameError] = useState(null);
  const [answerValue, setAnswerValue] = useState('');
  const [answerError, setAnswerError] = useState(null);

  const source = useRef();

  const fetchRewrites = useCallback(() => {
    const cancel = get(`/profiles/${profile}/rewrites`, ({ data }) => setRewrites(data));
    source.current.add(cancel);
  }, [profile]);

  useEffect(() => {
    source.current = new Set();
    fetchRewrites();
    return () => {
      source.current.forEach(cancel => cancel());
    };
  }, [fetchRewrites]);

  const openModal = () => setShowModal(true);

  const closeModal = () => {
    setShowModal(false);
    setNameValue('');
    setNameError(null);
    setAnswerValue('');
    setAnswerError(null);
    setIsChanging(false);
  };

  const removeRewrite = (id) => {
    setRewrites((currentRewrites) => currentRewrites.filter(r => r.id !== id));
    const cancel = del(`/profiles/${profile}/rewrites/${id}`);
    source.current.add(cancel);
  };

  const onNameValueChange = (event) => {
    setNameValue(event.target.value.trim().toLowerCase());
    setNameError(null);
  };

  const onAnswerValueChange = (event) => {
    setAnswerValue(event.target.value.trim().toLowerCase());
    setAnswerError(null);
  };

  const saveRewrite = async (event) => {
    event.preventDefault();
    setIsChanging(true);
    const cancel = post(`/profiles/${profile}/rewrites`,
      {
        name: nameValue.replace(/^\*\.?/, ''),
        content: answerValue,
      },
      ignoreStatus((data) => {
        if (data.response) {
          data = data.response;
        }
        if (data.errors) {
          data.errors.reduce((acc, current) => {
            // Only keey the first error for a given field.
            if (!acc.some(item => item.source.pointer === current.source.pointer)) {
              acc.push(current);
            }
            return acc;
          }, []).forEach((error) => {
            if (error.source.pointer === 'name' || error.source.pointer === '/name') {
              // eslint-disable-next-line default-case
              switch (error.code) {
                case 'conflict': error.code = 'taken'; break;
                case 'format': error.code = 'invalid'; break;
                case 'minLength': error.code = 'invalid'; break;
              }
              setNameError(error);
            }
            if (error.source.pointer === 'content' || error.source.pointer === '/content') {
              // eslint-disable-next-line default-case
              switch (error.code) {
                case 'format': error.code = 'invalid'; break;
                case 'minLength': error.code = 'invalid'; break;
              }
              setAnswerError(error);
            }
          });
          setIsChanging(false);
          return;
        }
        setRewrites((currentRewrites) => currentRewrites.concat([data.data]));
        closeModal();
      })
    );

    source.current.add(cancel);
  };


  return (
    <>
      <Card>
        <ListGroup variant="flush">
          <ListGroup.Item className="py-3">
            <h5>{t('settings.rewrites.name')}</h5>
            <div style={{ opacity: 0.6, fontSize: '0.9em' }}>{t('settings.rewrites.description')}</div>
          </ListGroup.Item>
          {rewrites ? (
            rewrites.map((rewrite) => (
              <ListGroup.Item key={rewrite.id}>
                <div className="d-flex">
                  <div className="flex-grow-1 d-flex align-items-center">
                    <div className="notranslate">
                      <span style={{ fontWeight: 500 }}>
                        <span style={{ opacity: 0.3 }}>*.</span>
                        {rewrite.name}
                      </span>
                      <span style={{ opacity: 0.3 }}> → </span>
                      <span style={{ opacity: 0.8 }}>{rewrite.content}</span>
                    </div>
                  </div>
                  {role !== 'viewer' && (
                    <div className="d-flex align-items-center">
                      <Button
                        variant="link"
                        className="me-n3 my-n1"
                        style={{ opacity: 0.3, color: '#222' }}
                        onClick={() => removeRewrite(rewrite.id)}
                      >
                        <FontAwesomeIcon icon={faTimes} />
                      </Button>
                    </div>
                  )}
                </div>
              </ListGroup.Item>
            ))
          ) : (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Spinner animation="border" style={{ width: '2rem', height: '2rem', opacity: 0.15 }} className="my-3" />
            </div>
          )}
        </ListGroup>
        <Card.Footer className="text-muted" style={{ borderTop: 'none' }}>
          <Button
            size="sm"
            style={{ fontWeight: 'bold', fontSize: '12px', textTransform: 'uppercase' }}
            onClick={openModal}
            disabled={role === 'viewer' || !rewrites}
          >
            {t('settings.rewrites.new')}
          </Button>
        </Card.Footer>
      </Card>

      <Modal show={showModal} onHide={closeModal} animation={false}>
        <Form onSubmit={saveRewrite} action="#submit" noValidate>
          <Modal.Header closeButton>
            <Modal.Title as="h5">{t('settings.rewrites.new')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.Label>{t('settings.rewrites.form.domain.label')}</Form.Label>
              <Form.Control
                autoFocus
                type="text"
                maxLength="255"
                spellCheck="false"
                autoCorrect="off"
                autoComplete="off"
                autoCapitalize="off"
                placeholder={t('settings.rewrites.form.domain.placeholder')}
                value={nameValue}
                onChange={onNameValueChange}
                isInvalid={!!nameError}
              />
              {!!nameError && (
                <Form.Control.Feedback type="invalid">
                  {nameError && t(
                    `settings.rewrites.form.domain.errors.${nameError.code}`
                  )}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="mt-3">
              <Form.Label>{t('settings.rewrites.form.answer.label')}</Form.Label>
              <Form.Control
                maxLength="255"
                spellCheck="false"
                autoCorrect="off"
                autoComplete="off"
                autoCapitalize="off"
                placeholder={t('settings.rewrites.form.answer.placeholder')}
                value={answerValue}
                onChange={onAnswerValueChange}
                isInvalid={!!answerError}
              />
              {!!answerError && (
                <Form.Control.Feedback type="invalid">
                  {answerError && t(`settings.rewrites.form.answer.errors.${answerError.code}`)}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-secondary" onClick={closeModal}>
              {t('global.cancel')}
            </Button>
            <Button type="submit" disabled={isChanging}>
              {isChanging ? (
                <Spinner as="span" animation="border" size="sm" className="me-2" />
              ) : null}
              {t('global.save')}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal >
    </>
  );
}