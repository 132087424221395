import React, { useEffect, useContext } from 'react';
import { Navigate } from 'react-router-dom';

import { api } from '../api';
import lastProfileSaver from '../lastProfileSaver';
import { AppContext, AccountContext } from '../contexts';

export default function LogOut() {
  const app = useContext(AppContext);
  const account = useContext(AccountContext);

  useEffect(() => {
    (async () => {
      try {
        await api.post('/accounts/@logout');
      } catch (error) {
        if (error.isAxiosError) {
          app.handleNetworkError(error);
          return;
        }
        throw error;
      }

      lastProfileSaver.clear();

      await account.load();
    })();
  }, [app, account]);

  if (!account.account) {
    return <Navigate to="/" replace />;
  }

  return null;
}
