import React, { useState, useEffect, useRef } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { ListGroup, Modal, Form, Button } from 'react-bootstrap';
import { patch } from '../api2';
import { API_BASE_URL_IPV4 } from '../config';

export default function SetupLinkedIpDDNS({ profile, role, hostname: initialHostname, onLinkedIpUpdate }) {
  const { t } = useTranslation();
  const [hostname, setHostname] = useState(initialHostname);
  const [hostnameValue, setHostnameValue] = useState('');
  const [hostnameError, setHostnameError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const source = useRef();

  useEffect(() => {
    source.current = new Set();
    return () => {
      source.current.forEach(cancel => cancel());
    };
  }, []);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setHostnameValue('');
    setHostnameError(null);
  };

  const onHostnameValueChange = (event) => {
    setHostnameValue(event.target.value.trim());
    setHostnameError(null);
  };

  const add = async (event) => {
    event.preventDefault();
    if (hostnameValue === '') {
      setHostnameError({ code: 'required' });
      return;
    }

    const cancel = patch(`${API_BASE_URL_IPV4}/profiles/${profile}/setup/linkedIp`,
      {
        ddns: hostnameValue,
      },
      ({ data, errors }) => {
        if (errors) {
          setHostnameError(errors[0]);
          return;
        }

        setHostname(data.ddns);
        if (data.ip) {
          onLinkedIpUpdate(data.ip);
        }
        closeModal();
      }
    );

    source.current.add(cancel);
  };

  const remove = () => {
    setHostname(null);
    patch(`${API_BASE_URL_IPV4}/profiles/${profile}/setup/linkedIp`, { ddns: null });
  };

  return (
    <ListGroup.Item style={{ fontSize: '0.8em' }}>
      {!hostname && (
        <>
          <div>{t('setup.linkedIp.ddns.explanation')}</div>
          <Button
            variant="link"
            className="p-0 mt-1"
            style={{ fontSize: '1em' }}
            onClick={openModal}
            disabled={role === 'viewer'}
          >
            {t('setup.linkedIp.ddns.set')}
          </Button>
        </>
      )}

      {!!hostname && (
        <>
          <div>
            <Trans i18nKey="setup.linkedIp.ddns.status">
              _You are currently using
              <span className="notranslate" style={{ fontWeight: 'bold' }}>
                {{ hostname }}
              </span>
              as DDNS hostname, your IP will be automatically updated every minute.
            </Trans>
          </div>
          <Button
            variant="link"
            className="p-0 mt-1"
            style={{ fontSize: '1em', color: '#dc3545' }}
            onClick={remove}
            disabled={role === 'viewer'}
          >
            {t('setup.linkedIp.ddns.unset')}
          </Button>
        </>
      )}

      <Modal show={showModal} animation={false} onHide={closeModal}>
        <Form onSubmit={add} noValidate>
          <Modal.Header closeButton>
            <Modal.Title as="h5">{t('setup.linkedIp.ddns.set')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.Label>{t('setup.linkedIp.ddns.form.hostname.label')}</Form.Label>
              <Form.Control
                autoFocus
                maxLength="255"
                spellCheck="false"
                autoCorrect="off"
                autoComplete="off"
                autoCapitalize="off"
                placeholder={t('setup.linkedIp.ddns.form.hostname.placeholder')}
                value={hostnameValue}
                isInvalid={!!hostnameError}
                onChange={onHostnameValueChange}
              />
              {!!hostnameError && (
                <Form.Control.Feedback type="invalid">
                  {t(`setup.linkedIp.ddns.form.hostname.errors.${hostnameError.code}`)}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-secondary" onClick={closeModal}>
              {t('global.cancel')}
            </Button>
            <Button type="submit">{t('global.save')}</Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </ListGroup.Item>
  );
}