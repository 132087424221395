import React, { Suspense } from 'react';

import AppContextProvider from './AppContextProvider';
import AccountContextProvider from './Account/AccountContextProvider';
import ErrorBoundary from './Components/ErrorBoundary';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import App from './App';

export default function AppWrapper() {
  return (
    <ErrorBoundary>
      <HelmetProvider>
        <Helmet titleTemplate="%s - NextDNS" defaultTitle="NextDNS" />
        <Suspense fallback>
          <AppContextProvider>
            <AccountContextProvider>
              <App />
            </AccountContextProvider>
          </AppContextProvider>
        </Suspense>
      </HelmetProvider>
    </ErrorBoundary>
  );
}
