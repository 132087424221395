import React, { useEffect, useMemo, useContext } from 'react';
import { Navigate, useParams } from 'react-router-dom';

import { AccountContext } from './contexts';

import Setup from './Setup/Setup';
import Security from './Security/Security';
import Privacy from './Privacy/Privacy';
import ParentalControl from './ParentalControl/ParentalControl';
import Xlist from './Xlist/Xlist';
import Analytics from './Analytics/Analytics';
import Logs from './Logs/Logs';
import Settings from './Settings/Settings';

import lastProfileSaver from './lastProfileSaver';

export default function Profile({ profile }) {
  const account = useContext(AccountContext);

  const { menu } = useParams();

  const role = useMemo(() => {
    return account.getProfileRole(profile);
  }, [account, profile]);

  useEffect(() => {
    lastProfileSaver.save(profile);
  }, [profile]);

  switch (menu) {
    case "setup":
      return <Setup profile={profile} role={role} />;
    case "security":
      return <Security profile={profile} role={role} />;
    case "privacy":
      return <Privacy profile={profile} role={role} />;
    case "parentalcontrol":
      return <ParentalControl profile={profile} role={role} />;
    case "denylist":
      return <Xlist listType="denylist" profile={profile} role={role} />;
    case "allowlist":
      return <Xlist listType="allowlist" profile={profile} role={role} />;
    case "analytics":
      return <Analytics profile={profile} />;
    case "logs":
      return <Logs profile={profile} />;
    case "settings":
      return <Settings profile={profile} role={role} />;
    default:
      return <Navigate to={"setup"} />;
  }
}
