import React, { Component } from 'react';
import { Container, Row, Col, Alert } from 'react-bootstrap';
import axios from 'axios';

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
    };
  }

  componentDidMount() {
    window.addEventListener('unhandledrejection', (event) => {
      // TODO: cleanup here later
      if (axios.isCancel(event.reason)) {
        return;
      }

      // TODO: cleanup here later
      if (event.reason.isAxiosError) {
        const newEvent = new CustomEvent('axioserror', {
          detail: event.reason,
        });
        window.dispatchEvent(newEvent);
        return;
      }

      if (event.reason && event.reason.stack) {
        if (
          event.reason.stack.includes('@safari-web-extension://') ||
          event.reason.stack.includes('@safari-extension://') ||
          event.reason.stack.includes('@webkit-masked-url://')
        ) {
          return;
        }
      }

      this.setState({
        error: event.reason,
      });
    });
  }

  componentDidCatch(error) {
    this.setState({ error });
  }

  render() {
    if (this.state.error) {
      return (
        <Container>
          <Row>
            <Col>
              <Alert className="my-5" variant="danger">
                <Alert.Heading as="h5">{this.state.error.message}</Alert.Heading>
                <div style={{ fontSize: '0.9em', whiteSpace: 'pre-wrap' }}>{this.state.error.stack}</div>
              </Alert>
            </Col>
          </Row>
        </Container>
      );
    }

    return this.props.children;
  }
}
