import React, { useState, useEffect } from 'react';
import { SunIcon, MoonIcon } from '@heroicons/react/solid';

export default function ThemeSwitcherButton() {
  const [dark, setDark] = useState(() => {
    return window.localStorage ? !!window.localStorage.getItem('darkTheme') : false;
  });

  useEffect(() => {
    if (dark) {
      document.documentElement.classList.add('tw-dark');
      if (window.localStorage) {
        window.localStorage.setItem('darkTheme', '1');
      }
    } else {
      document.documentElement.classList.remove('tw-dark');
      if (window.localStorage) {
        window.localStorage.removeItem('darkTheme');
      }
    }
  }, [dark]);

  const toggleDark = () => {
    setDark(!dark);
  };

  return (
    <button className="tw-p-2 tw-pe-0.5 focus:tw-outline-none" onClick={toggleDark}>
      <SunIcon className="tw-hidden tw-h-5 tw-w-5 tw-text-slate-400 hover:tw-text-slate-300 dark:tw-inline" />
      <MoonIcon className="tw-inline tw-h-5 tw-w-5 tw-text-slate-400 hover:tw-text-slate-500 dark:tw-hidden" />
    </button>
  );
}
