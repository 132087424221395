import React, { useState, useEffect, useRef, useContext } from 'react';
import { Navigate, useParams, useLocation } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { Container, Row, Col, Card, Form, Button, Alert } from 'react-bootstrap';
import axios from 'axios';
import queryString from 'query-string';

import { isMobile } from '../helpers';
import { API_BASE_URL } from '../config';
import { useTheme } from '../theme';

import { AccountContext } from '../contexts';

import logoLarge from '../images/logo-large@2x.png';
import logoLargeDark from '../images/logo-large-dark@2x.png';

export default function ResetPassword() {
  const theme = useTheme();

  const [emailValue, setEmailValue] = useState('');
  const [emailError, setEmailError] = useState(null);
  const [emailSent, setEmailSent] = useState(false);

  const [tokenChecked, setTokenChecked] = useState(false);
  const [tokenError, setTokenError] = useState(null);

  const [passwordValue, setPasswordValue] = useState('');
  const [passwordError, setPasswordError] = useState(null);
  const [passwordSaved, setPasswordSaved] = useState(false);

  const { t } = useTranslation();
  const emailInput = useRef(null);
  const { token } = useParams();
  const location = useLocation();
  const context = useContext(AccountContext);

  let source = axios.CancelToken.source();

  useEffect(() => {
    const checkToken = async () => {
      if (token) {
        const { data } = await axios.post(`${API_BASE_URL}/accounts/@login`, {
          passwordResetToken: token,
        });

        if (data.error) {
          setTokenChecked(true);
          setTokenError(data.error);
          return;
        }

        setTokenChecked(true);
        await context.load();
        return;
      }

      const queryStringArgs = queryString.parse(location.search);

      if (queryStringArgs.email) {
        setEmailValue(queryStringArgs.email);
      }
    };

    checkToken();

    return () => source.cancel();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const onPasswordValueChange = (event) => {
    setPasswordValue(event.target.value);
    setPasswordError(null);
  };

  const savePassword = async (event) => {
    event.preventDefault();

    const { data } = await axios.patch(
      `${API_BASE_URL}/accounts/@me`,
      {
        passwordResetToken: token,
        password: passwordValue,
      },
      {
        cancelToken: source.token,
      }
    );

    if (data.errors) {
      setTokenError(data.errors.passwordResetToken);
      setPasswordValue('');
      setPasswordError(data.errors.password);
      return;
    }

    setPasswordSaved(true);
  };

  const onEmailValueChange = (event) => {
    setEmailValue(event.target.value.trim());
    setEmailError(null);
    setEmailSent(false);
  };

  const sendEmail = async (event) => {
    event.preventDefault();

    if (emailSent) {
      return;
    }

    const { data } = await axios.post(`${API_BASE_URL}/accounts/@sendResetPasswordEmail`, {
      email: emailValue,
    });

    if (data.error) {
      setEmailError(data.error);
      return;
    }

    emailInput.current.blur();

    setEmailSent(true);
  };

  return (
    <>
      {passwordSaved && <Navigate to="/" replace />}
      <Helmet>
        <title>{t('pages.passwordReset')}</title>
      </Helmet>

      {(!token || tokenChecked) && (
        <Container style={{ marginTop: '5vw' }}>
          <Row className="mb-4 justify-content-center">
            <Col sm="10" md="8" lg="6" xl="5">
              <div className="mt-2 mb-4 text-center">
                <img
                  className="d-inline"
                  src={theme === 'dark' ? logoLargeDark : logoLarge}
                  style={{ width: 200, marginRight: 20 }}
                  alt=""
                />
              </div>

              <Card>
                <Card.Body>
                  {tokenChecked && !tokenError && (
                    <Form onSubmit={savePassword} action="#submit" noValidate className='d-grid'>
                      <Form.Group>
                        <Form.Label>{t('account.newPassword')}</Form.Label>
                        <Form.Control
                          type="password"
                          autoComplete="new-password"
                          autoFocus={!isMobile}
                          isInvalid={!!passwordError}
                          value={passwordValue}
                          onChange={onPasswordValueChange}
                        />
                        {!!passwordError && (
                          <Form.Control.Feedback type="invalid">
                            {t(`account.errors.password.${passwordError}`)}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                      <Button className="mt-4" type="submit" size="lg">
                        {t('account.savePassword')}
                      </Button>
                    </Form>
                  )}
                  {(!token || (tokenChecked && !!tokenError)) && (
                    <>
                      {!emailSent && !!tokenError && (
                        <Alert variant="warning" style={{ fontSize: '0.9em' }}>
                          {t(`account.errors.resetPasswordToken.${tokenError}`)}
                        </Alert>
                      )}
                      {(!token || !!tokenError) && (
                        <>
                          <h5>{t('pages.passwordReset')}</h5>
                          <div className="mb-3" style={{ opacity: 0.6, fontSize: '0.9em' }}>
                            {t('account.passwordResetInfo')}
                          </div>
                          <Form onSubmit={sendEmail} action="#submit" noValidate className='d-grid'>
                            <Form.Group>
                              <Form.Label>{t('account.email')}</Form.Label>
                              <Form.Control
                                type="email"
                                ref={emailInput}
                                autoFocus={!isMobile}
                                autoCorrect="off"
                                autoCapitalize="none"
                                autoComplete="username"
                                isInvalid={!!emailError}
                                value={emailValue}
                                onChange={onEmailValueChange}
                              />
                              {!!emailError && (
                                <Form.Control.Feedback type="invalid">
                                  {t(`account.errors.email.${emailError}`)}
                                </Form.Control.Feedback>
                              )}
                            </Form.Group>
                            {emailSent && (
                              <Alert variant="success" className="mt-4 mb-0" style={{ fontSize: '0.9em' }}>
                                <Trans i18nKey="account.passwordResetLinkSentMessage">
                                  _A link to reset your password was sent to
                                  <span style={{ fontWeight: 500 }}>{{ email: emailValue }}</span>.
                                </Trans>
                              </Alert>
                            )}
                            {!emailSent && (
                              <Button className="mt-4" type="submit" size="lg">
                                {t('account.sendEmail')}
                              </Button>
                            )}
                          </Form>
                        </>
                      )}
                    </>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
}