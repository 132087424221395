import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Card, Form } from 'react-bootstrap';
import { get, patch } from '../api2';

import Page from '../Page';
import TranslationsFooter from '../Components/TranslationsFooter';
import ParentalControlRecreationTime from './ParentalControlRecreationTime';
import ParentalControlServices from './ParentalControlServices';
import ParentalControlCategories from './ParentalControlCategories';

import './ParentalControl.scss';

export default function ParentalControl({ profile, role }) {
  const { t } = useTranslation();

  const [state, setState] = useState();

  useEffect(() => {
    setState();

    return get(`/profiles/${profile}/parentalControl`, ({ data }) => {
      setState(data);
    });
  }, [profile]);

  const toggle = useCallback(
    (setting, enabled) => {
      setState((state) => {
        return { ...state, [setting]: enabled };
      });

      patch(`/profiles/${profile}/parentalControl`, {
        [setting]: enabled,
      });
    },
    [profile]
  );

  const onRecreationUpdated = useCallback((recreation) => {
    setState((state) => ({
      ...state,
      recreation,
    }));

    if (Object.keys(recreation.times).length === 0) {
      setState((state) => ({
        ...state,
        services: state.services.map((service) => {
          service.recreation = false;
          return service;
        }),
        categories: state.categories.map((category) => {
          category.recreation = false;
          return category;
        }),
      }));
    }
  }, []);

  return (
    <Page title={t('pages.parentalcontrol')} profile={profile}>
      {!!state && (
        <>
          <Row className="mb-3">
            <Col>
              <ParentalControlServices
                profile={profile}
                role={role}
                services={state.services}
                hasRecreation={Object.keys(state.recreation.times).length > 0}
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <ParentalControlCategories
                profile={profile}
                role={role}
                categories={state.categories}
                hasRecreation={Object.keys(state.recreation.times).length > 0}
              />
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <ParentalControlRecreationTime
                profile={profile}
                role={role}
                recreation={state.recreation}
                onUpdated={onRecreationUpdated}
              />
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <Card>
                <Card.Body>
                  <h5>{t('parentalControl.safesearch.name')}</h5>
                  <div style={{ opacity: 0.6, fontSize: '0.9em' }}>{t('parentalControl.safesearch.description')}</div>
                </Card.Body>
                <Card.Footer className="text-muted">
                  <div className="d-flex">
                    <div className="me-1">
                      <Form.Switch
                        id="parentalControl-safeSearch"
                        label=""
                        checked={state.safeSearch}
                        onChange={(event) => toggle('safeSearch', event.target.checked)}
                        disabled={role === 'viewer'}
                      />
                    </div>
                    <div className="flex-grow-1 d-flex align-items-center" style={{ fontSize: '0.9em' }}>
                      <div>{t('parentalControl.safesearch.enable')}</div>
                    </div>
                  </div>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Card>
                <Card.Body>
                  <h5>{t('parentalControl.youtubeRestricted.name')}</h5>
                  <div style={{ opacity: 0.6, fontSize: '0.9em' }}>
                    {t('parentalControl.youtubeRestricted.description')}
                  </div>
                </Card.Body>
                <Card.Footer className="text-muted">
                  <div className="d-flex">
                    <div className="me-1">
                      <Form.Switch
                        id="parentalControl-youtubeRestrictedMode"
                        label=""
                        checked={state.youtubeRestrictedMode}
                        onChange={(event) => toggle('youtubeRestrictedMode', event.target.checked)}
                        disabled={role === 'viewer'}
                      />
                    </div>
                    <div className="flex-grow-1 d-flex align-items-center" style={{ fontSize: '0.9em' }}>
                      <div>{t('parentalControl.youtubeRestricted.enable')}</div>
                    </div>
                  </div>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Card>
                <Card.Body>
                  <h5>{t('parentalControl.bypass.name')}</h5>
                  <div style={{ opacity: 0.6, fontSize: '0.9em' }}>{t('parentalControl.bypass.description')}</div>
                </Card.Body>
                <Card.Footer className="text-muted">
                  <div className="d-flex">
                    <div className="me-1">
                      <Form.Switch
                        id="privacy-blockBypass"
                        label=""
                        checked={state.blockBypass}
                        onChange={(event) => toggle('blockBypass', event.target.checked)}
                        disabled={role === 'viewer'}
                      />
                    </div>
                    <div className="flex-grow-1 d-flex align-items-center" style={{ fontSize: '0.9em' }}>
                      <div>{t('parentalControl.bypass.enable')}</div>
                    </div>
                  </div>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
          <TranslationsFooter />
        </>
      )}
    </Page>
  );
}
