import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Card, ListGroup, Spinner } from 'react-bootstrap';

import { get } from '../api2';
import { unpuny } from '../helpers';

import Favicon from '../Components/Favicon';

export default function AnalyticsRootDomains({ profile, device, timeRange }) {
  const { t, i18n } = useTranslation();

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);

    return get(
      `/profiles/${profile}/analytics/domains`,
      {
        root: true,
        device,
        ...timeRange,
        limit: 6,
      },
      ({ data }) => {
        setData(data);
        setLoading(false);
      }
    );
  }, [profile, device, timeRange]);

  return (
    <Col lg="6">
      <Card className="mb-4">
        <ListGroup variant="flush">
          <ListGroup.Item className="py-3">
            <h5>{t('analytics.domains.root.name')}</h5>
            <div style={{ opacity: 0.6, fontSize: '0.9em' }}>{t('analytics.domains.root.description')}</div>
          </ListGroup.Item>

          {loading ? (
            <ListGroup.Item className="text-center">
              <Spinner animation="border" style={{ width: '3rem', height: '3rem', opacity: 0.15 }} className="my-5" />
            </ListGroup.Item>
          ) : data.length === 0 ? (
            <ListGroup.Item className="text-center my-2" style={{ opacity: 0.4 }}>
              {t('analytics.domains.empty')}
            </ListGroup.Item>
          ) : (
            data.map((domain) => (
              <ListGroup.Item key={domain.domain}>
                <div className="d-flex">
                  <div className="flex-grow-1 text-break">
                    <Favicon domain={domain.domain} className="me-2" style={{ marginTop: -1 }} />
                    <span className="notranslate" style={{ wordBreak: 'break-all' }}>
                      <span style={{ opacity: 0.3 }}>*.</span>
                      {unpuny(domain.domain)}
                    </span>
                  </div>
                  <div className="ms-3" style={{ opacity: 0.6 }}>
                    {i18n.format(domain.queries, 'number')}
                  </div>
                </div>
              </ListGroup.Item>
            ))
          )}
        </ListGroup>
      </Card>
    </Col>
  );
}
