import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, ListGroup, Card, Spinner, Form } from 'react-bootstrap';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import { useIdleTimer } from 'react-idle-timer';
import { get, getAsStream } from '../api2';
import classnames from 'classnames';
import { classNames } from '../helpers';
import { ReactSVG } from 'react-svg';

import './Logs.scss';

import Page from '../Page';
import DeviceSelector from '../Components/DeviceSelector';
import LogsSearch from './LogsSearch';
import LogsLog from './LogsLog';

import { RefreshIcon, CogIcon, AdjustmentsIcon } from '@heroicons/react/solid';

import StreamingIcon from '../images/logs/streaming.svg';
import SettingsIcon from '../images/logs/settings.svg';

const initialState = {
  data: null,
  meta: {
    pagination: {
      cursor: null,
    },
    stream: {
      id: null,
    },
  },
  isFetching: false,
};

export default function Logs({ profile }) {
  const { t, i18n } = useTranslation();

  const isLoadingNextPage = useRef(false);
  const [showSettings, setShowSettings] = useState(false);
  const [raw, setRaw] = useState(window.localStorage ? !!window.localStorage.getItem('logs.raw') : false);
  const [device, setDevice] = useState(null);
  const [streamId, setStreamId] = useState(null);
  const [search, setSearch] = useState(null);
  const [blockedQueriesOnly, setBlockedQueriesOnly] = useState(false);
  const [state, setState] = useState(initialState);
  const [relativeTime, setRelativeTime] = useState(true);

  const toggleRelativeTime = useCallback(() => {
    setRelativeTime((relativeTime) => !relativeTime);
  }, []);

  const fetchLogs = useCallback(
    ({ profile, device, cursor, search, blockedQueriesOnly, raw }) => {
      isLoadingNextPage.current = true;
      setState((state) => ({ ...state, isFetching: true }));

      return get(
        `/profiles/${profile}/logs`,
        {
          device,
          cursor: cursor || undefined,
          search: search || undefined,
          status: blockedQueriesOnly ? 'blocked' : undefined,
          raw: raw ? 1 : undefined,
          lang: i18n.language,
          limit: 50,
        },
        (response) => {
          isLoadingNextPage.current = false;
          setState((state) => {
            return {
              ...state,
              data: [
                ...(state.data || []),
                ...response.data.map((log) => {
                  log.key = log.timestamp + ':' + log.name + ':' + Math.random();
                  return log;
                }),
              ],
              meta: {
                pagination: {
                  cursor: response.meta.pagination.cursor,
                },
                stream: response.meta.stream || state.meta.stream,
              },
              isFetching: false,
            };
          });
        }
      );
    },
    [i18n.language]
  );

  const streamLogs = useCallback(
    ({ profile, device, id, search, blockedQueriesOnly, raw }) => {
      return getAsStream(
        `/profiles/${profile}/logs/stream`,
        {
          device: device,
          id: id || undefined,
          search: search || undefined,
          status: blockedQueriesOnly ? 'blocked' : undefined,
          raw: raw ? 1 : undefined,
          lang: i18n.language,
        },
        (response) => {
          isLoadingNextPage.current = false;
          setState((state) => {
            return {
              ...state,
              data: [
                ...response.data.map((log) => {
                  log.key = log.timestamp + ':' + log.name + ':' + Math.random();
                  log.streamed = true;
                  return log;
                }),
                ...(state.data || []),
              ],
              meta: {
                ...state.meta,
                stream: response.meta.stream,
              },
              isFetching: false,
            };
          });
        }
      );
    },
    [i18n.language]
  );

  const onBottom = useCallback(() => {
    if (state.meta.pagination.cursor && !isLoadingNextPage.current) {
      return fetchLogs({
        profile,
        device,
        cursor: state.meta.pagination.cursor,
        search,
        blockedQueriesOnly,
        raw,
      });
    }
  }, [fetchLogs, profile, state.meta.pagination.cursor, device, search, blockedQueriesOnly, raw]);

  useBottomScrollListener(onBottom, {
    offset: 400,
    debounce: 0,
    triggerOnNoScroll: true,
  });

  useEffect(() => {
    setState(initialState);
    return fetchLogs({
      profile,
      device,
      cursor: null,
      search,
      blockedQueriesOnly,
      raw,
    });
  }, [fetchLogs, profile, device, search, blockedQueriesOnly, raw]);

  useEffect(() => {
    if (streamId) {
      return streamLogs({
        profile,
        device,
        id: streamId,
        search,
        blockedQueriesOnly,
        raw,
      });
    }
  }, [streamLogs, streamId, profile, device, search, blockedQueriesOnly, raw]);

  useEffect(() => {
    setDevice(null);
  }, [profile]);

  useEffect(() => {
    setStreamId(null);
  }, [profile, raw, device, search, blockedQueriesOnly]);

  const onIdle = useCallback(() => {
    setStreamId(null);
  }, []);

  useIdleTimer({
    timeout: 5 * 60 * 1000, // 5m
    onIdle,
  });

  const onBlockedQueriesOnlyChange = useCallback((event) => {
    const value = event.target.checked;
    setBlockedQueriesOnly(value);
  }, []);

  const onRawChange = useCallback((event) => {
    const value = event.target.checked;

    if (window.localStorage) {
      if (value) {
        window.localStorage.setItem('logs.raw', '1');
      } else {
        window.localStorage.removeItem('logs.raw');
      }
    }

    setRaw(value);
  }, []);

  const toggleStream = useCallback(() => {
    setStreamId((streamId) => (streamId === null ? state.meta.stream.id : null));
  }, [state.meta.stream.id]);

  const toggleSettings = useCallback(() => {
    setShowSettings((showSettings) => !showSettings);
    setBlockedQueriesOnly(false);
  }, []);

  if (process.env.REACT_APP_TAILWIND) {
    return (
      <Page title={t('pages.logs')} className="Logs" profile={profile}>
        <div className="tw-mb-3 tw-flex tw-items-stretch">
          <div className="tw-me-2 tw-grow">
            <LogsSearch onSearch={setSearch} />
          </div>
          <button className="mouse:hover:tw-text-slate-400 tw-px-2 tw-text-slate-500">
            <AdjustmentsIcon className="tw-h-5 tw-w-5" />
          </button>
          <button className="mouse:hover:tw-text-slate-400 tw-px-2 tw-text-slate-500">
            <CogIcon className="tw-h-5 tw-w-5" />
          </button>
          <button className="mouse:hover:tw-text-slate-400 tw-px-2 tw-text-slate-500">
            <RefreshIcon
              className={classNames(streamId !== null ? 'tw-text-nextdnsblue tw-animate-spin' : '', 'tw-h-5 tw-w-5')}
              onClick={toggleStream}
            />
          </button>
        </div>
        <div className="tw-overflow-hidden tw-rounded-md tw-border dark:tw-border-slate-800">
          {!!state.data &&
            state.data.map(({ key, streamed, ...log }) => <LogsLog
              key={key}
              log={log}
              streamed={streamed}
              relativeTime={relativeTime}
              toggleRelativeTime={toggleRelativeTime}
            />)}
        </div>
      </Page>
    );
  }

  return (
    <Page title={t('pages.logs')} className="Logs mb-5" profile={profile}>
      <Row>
        <Col className="mb-4 d-flex">
          <div style={{ minWidth: 0 }}>
            <DeviceSelector profile={profile} onDeviceChange={(id) => setDevice(id)} />
          </div>
          <div className="flex-grow-1 ms-3"></div>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card className="mb-4">
            <ListGroup variant="flush">
              <ListGroup.Item className="px-3 bg-2">
                <div className="d-flex">
                  <LogsSearch onSearch={setSearch} />

                  <div className="d-flex align-items-center ms-3">
                    <ReactSVG
                      src={SettingsIcon}
                      className={classnames('settings-button', { active: showSettings })}
                      onClick={toggleSettings}
                    />
                  </div>

                  <div className="d-flex align-items-center ms-3">
                    <ReactSVG
                      src={StreamingIcon}
                      className={classnames('stream-button', { streaming: streamId !== null })}
                      onClick={toggleStream}
                    />
                  </div>
                </div>

                {showSettings && (
                  <div className="d-md-flex">
                    <div className="d-flex mt-3">
                      <div
                        className="d-flex align-items-center"
                        style={{ transform: 'scale(0.9)', marginTop: -10, marginBottom: -10 }}
                      >
                        <Form.Switch
                          id="blocked-queries-only"
                          label=""
                          checked={blockedQueriesOnly}
                          onChange={onBlockedQueriesOnlyChange}
                        />
                      </div>
                      <div className="d-flex align-items-center" style={{ opacity: 0.7, whiteSpace: 'nowrap' }}>
                        <small>{t('logs.blockedOnly')}</small>
                      </div>
                    </div>

                    <div className="d-flex mt-3 ms-md-5">
                      <div
                        className="d-flex align-items-center"
                        style={{ transform: 'scale(0.9)', marginTop: -10, marginBottom: -10 }}
                      >
                        <Form.Switch id="advanced-mode" label="" checked={raw} onChange={onRawChange} />
                      </div>
                      <div className="d-flex align-items-center" style={{ opacity: 0.7, whiteSpace: 'nowrap' }}>
                        <small>{t('logs.raw')}</small>
                      </div>
                    </div>
                  </div>
                )}
              </ListGroup.Item>

              {!state.data && state.isFetching && (
                <ListGroup.Item className="text-center">
                  <Spinner
                    animation="border"
                    style={{ width: '3rem', height: '3rem', opacity: 0.15 }}
                    className="my-4"
                  />
                </ListGroup.Item>
              )}
              {state.data && (
                <>
                  {state.data.map(({ key, streamed, ...log }) => (
                    <LogsLog
                      key={key}
                      log={log}
                      streamed={streamed}
                      relativeTime={relativeTime}
                      toggleRelativeTime={toggleRelativeTime}
                    />
                  ))}
                  {state.data.length === 0 && (
                    <ListGroup.Item>
                      <div className="text-center my-2" style={{ opacity: 0.4 }}>
                        {t('logs.empty')}
                      </div>
                    </ListGroup.Item>
                  )}
                </>
              )}
            </ListGroup>
          </Card>
        </Col>
      </Row>

      {!!state.data && state.meta.pagination.cursor && (
        <Row>
          <Col className="text-center">
            <Spinner animation="border" style={{ width: '2rem', height: '2rem', opacity: 0.15 }} className="my-2" />
          </Col>
        </Row>
      )}
    </Page>
  );
}
