import React, { useState, useEffect, useCallback, useMemo, useContext } from 'react';

import { api } from '../api';
import { AppContext, AccountContext } from '../contexts';

export default function AccountContextProvider({ children }) {
  const app = useContext(AppContext);

  const [state, setState] = useState({
    ready: false,
    account: null,
    profiles: [],
  });

  const load = useCallback(async () => {
    let status, data;
    try {
      ({ status, data } = await api.get('/accounts/@me', {
        params: {
          withProfiles: true,
        },
        validateStatus: (status) => {
          return (status >= 200 && status < 300) || status === 403;
        },
      }));
    } catch (error) {
      if (error.isAxiosError) {
        app.handleNetworkError(error);
        return;
      }
      throw error;
    }

    if (status === 403) {
      setState((state) => {
        return {
          ...state,
          ready: true,
          account: null,
          profiles: [],
        };
      });

      return;
    }

    setState((state) => {
      return {
        ...state,
        ready: true,
        account: {
          email: data.email,
          expired: data.expired,
          expiresOn: data.expiresOn,
        },
        profiles: data.profiles,
      };
    });
  }, [app]);

  const onEmailChanged = useCallback((email) => {
    setState((state) => {
      return {
        ...state,
        account: { ...state.account, email: email },
      };
    });
  }, []);

  const onProfileCreated = useCallback((profile) => {
    setState((state) => {
      return {
        ...state,
        profiles: [...state.profiles, profile],
      };
    });
  }, []);

  const onProfileNameChanged = useCallback((id, name) => {
    setState((state) => {
      return {
        ...state,
        profiles: state.profiles.map((profile) => {
          if (profile.id === id) {
            profile = { ...profile, name };
          }
          return profile;
        }),
      };
    });
  }, []);

  const onProfileDeleted = useCallback((id) => {
    setState((state) => {
      return {
        ...state,
        profiles: state.profiles.filter((profile) => {
          return profile.id !== id;
        }),
      };
    });
  }, []);

  const getProfileName = useCallback(
    (id) => {
      for (const profile of state.profiles) {
        if (profile.id === id) {
          return profile.name;
        }
      }
    },
    [state.profiles]
  );

  const getProfileRole = useCallback(
    (id) => {
      for (const profile of state.profiles) {
        if (profile.id === id) {
          return profile.role;
        }
      }
    },
    [state.profiles]
  );

  const value = useMemo(() => {
    return {
      ready: state.ready,
      account: state.account,
      profiles: state.profiles,
      load,
      onEmailChanged,
      onProfileCreated,
      onProfileNameChanged,
      onProfileDeleted,
      getProfileName,
      getProfileRole,
    };
  }, [
    state,
    load,
    onEmailChanged,
    onProfileCreated,
    onProfileNameChanged,
    onProfileDeleted,
    getProfileName,
    getProfileRole,
  ]);

  useEffect(() => {
    load();
  }, [load]);

  return <AccountContext.Provider value={value}>{children}</AccountContext.Provider>;
}
