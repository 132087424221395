import React, { useState, useCallback, useContext, useRef } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Card, Modal, Form, Button, Spinner } from 'react-bootstrap';
import { get, post } from '../api2';

import { AccountContext } from '../contexts';

export default function SettingsDuplicateProfile({ profile, name }) {
  const { t } = useTranslation();

  const account = useContext(AccountContext);
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const [isDuplicating, setIsDuplicating] = useState(false);
  const isModalClosable = useRef(true);
  const [newName, setNewName] = useState({
    value: '',
    error: null,
  });

  const openModal = () => {
    setShowModal(true);
    setIsDuplicating(false);
  };

  const closeModal = () => {
    if (!isModalClosable) {
      return;
    }

    setShowModal(false);
    setIsDuplicating(false);
  };

  const onNameChange = useCallback((event) => {
    const value = event.target.value;

    setNewName((name) => {
      return { ...name, value: value, error: null };
    });
  }, []);

  const duplicateProfile = useCallback(() => {
    isModalClosable.current = false;
    setShowModal(true);
    setIsDuplicating(true);

    get(
      `/profiles/${profile}`,
      ({ data }) => {
        // cleanup profile for reposting
        if (data.parentalControl && data.parentalControl.recreation) {
          delete data.parentalControl.recreation;
          if (data.parentalControl.services) {
            data.parentalControl.services = data.parentalControl.services.map((service) => {
              delete service.website;
              return service;
            });
          }
        }
        if (data.privacy && data.privacy.blocklists) {
          data.privacy.blocklists = data.privacy.blocklists.map((blocklist) => {
            delete blocklist.description;
            delete blocklist.name;
            delete blocklist.entries;
            delete blocklist.updatedOn;
            delete blocklist.website;
            return blocklist;
          });
        }
        delete data.rewrites;
        delete data.setup;
        delete data.id;
        delete data.fingerprint;
        data.name = newName.value;
        post("/profiles", data, ({ data, errors }) => {
          isModalClosable.current = true;

          if (errors) {
            setNewName((name) => {
              return { ...name, error: errors[0] };
            });
            setIsDuplicating(false);
            return;
          }

          closeModal();
          account.onProfileCreated(data);
          setIsDuplicating(true);
          navigate(`/${data.id}`);
        });
      }
    );
  }, [account, navigate, newName.value, profile]);

  return (
    <>
      <Card body>
        <Button onClick={openModal}>
          <Trans i18nKey="settings.duplicate.button">
            _Duplicate
            <span className="notranslate" style={{ fontWeight: 'bold' }}>
              &nbsp;{{ name }}
            </span>
          </Trans>
        </Button>
        <Form.Text className="mt-2" as="div">
          <Trans i18nKey="settings.duplicate.description" />
        </Form.Text>
      </Card>

      <Modal show={showModal} animation={false} onHide={closeModal}>
        <Form
          onSubmit={(event) => {
            event.preventDefault();
            duplicateProfile();
          }}
          action="#submit"
          noValidate
        >
          <Modal.Header closeButton>
            <Modal.Title as="h5">
              <Trans i18nKey="settings.duplicate.button">
                _Duplicate
                <span className="notranslate" style={{ fontWeight: 'bold' }}>
                  &nbsp;{{ name }}
                </span>
              </Trans>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.Label>
                <Trans i18nKey="settings.duplicate.form.name.label">
                  _Name
                </Trans>
              </Form.Label>
              <Form.Control
                autoFocus
                autoComplete="off"
                data-1p-ignore
                data-lpignore
                maxLength="255"
                placeholder={t('settings.duplicate.form.name.placeholder')}
                isInvalid={!!newName.error}
                value={newName.value}
                onChange={onNameChange}
              />
              {!!newName.error && (
                <Form.Control.Feedback type="invalid">
                  {t(
                    `settings.duplicate.form.name.errors.${newName.error.code === 'duplicate' ? 'taken' : newName.error.code
                    }`
                  )}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-secondary" disabled={isDuplicating} onClick={closeModal}>
              <Trans i18nKey="global.cancel">
                _Cancel
              </Trans>
            </Button>

            <Button
              type="submit"
              disabled={isDuplicating || newName.value === undefined || newName.value.length === 0}
            >
              {isDuplicating && (
                <Spinner as="span" animation="border" size="sm" className="me-2" />
              )}
              <Trans i18nKey="settings.duplicate.form.button">
                _Duplicate
              </Trans>
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}