import React, { useState, useEffect, useCallback } from 'react';
import { useCallbackWithCancel } from '../hooks';
import { useTranslation } from 'react-i18next';
import { Card, ListGroup, Button, Spinner, Modal, Form } from 'react-bootstrap';
import { get, post, patch, del } from '../api2';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

export default function SettingsAccess({ profile }) {
  const { t } = useTranslation();

  const [data, setData] = useState(null);

  const [email, setEmail] = useState({
    value: '',
    error: null,
  });

  const [role, setRole] = useState('editor');

  const [showModal, setShowModal] = useState(false);

  const openModal = useCallback(() => {
    setShowModal(true);
  }, []);

  const closeModal = useCallback(() => {
    setShowModal(false);

    setEmail({
      value: '',
      error: null,
    });

    setRole('editor');
  }, []);

  const onEmailChange = useCallback((event) => {
    const value = event.target.value.trim();

    setEmail({
      value,
      error: null,
    });
  }, []);

  const onRoleChange = useCallback((event) => {
    const value = event.target.value;

    setRole(value);
  }, []);

  const remove = useCallback(
    (email) => {
      setData((data) => data.filter((item) => item.email !== email));

      del(`/profiles/${profile}/access/${encodeURIComponent(email)}`);
    },
    [profile]
  );

  const invite = useCallbackWithCancel(
    (e) => {
      e.preventDefault();

      if (!email.value) {
        setEmail((email) => ({
          ...email,
          error: { code: 'required' },
        }));

        return;
      }

      return post(
        `/profiles/${profile}/access`,
        {
          email: email.value,
          role,
        },
        ({ data: _data, errors }) => {
          if (errors) {
            setEmail((email) => ({
              ...email,
              error: errors[0],
            }));

            return;
          }

          closeModal();

          setData((data) => [...data, _data]);
        }
      );
    },
    [profile, email, role]
  );

  const changeRole = useCallback(
    (email, newRole) => {
      setData((data) =>
        data.map((item) => {
          if (item.email === email) {
            item.role = newRole;
          }
          return item;
        })
      );

      patch(`/profiles/${profile}/access/${encodeURIComponent(email)}`, {
        role: newRole,
      });
    },
    [profile]
  );

  useEffect(() => {
    setData(null);
    closeModal();

    return get(`/profiles/${profile}/access`, ({ data }) => {
      setData(data);
    });
  }, [profile, closeModal]);

  return (
    <>
      <Card>
        <ListGroup variant="flush">
          <ListGroup.Item className="py-3">
            <div className="d-flex align-items-center">
              <h5>{t('settings.access.name')}</h5>
              <div className='beta-badge'>
                {t('global.beta')}
              </div>
            </div>
            <div style={{ opacity: 0.6, fontSize: '0.9em' }}>{t('settings.access.description')}</div>
          </ListGroup.Item>
          {data !== null ? (
            data.map((item) => (
              <ListGroup.Item key={item.email}>
                <div className="d-flex">
                  <div className="flex-grow-1 d-sm-flex align-items-center">
                    <div className="flex-grow-1 d-sm-flex align-items-center">
                      <div
                        className="notranslate text-break"
                        style={{
                          fontWeight: item.pending ? '' : 500,
                          opacity: item.pending ? 0.7 : '',
                        }}
                      >
                        {item.email}
                      </div>
                      {item.pending && (
                        <div className="d-inline-block ms-0 ms-sm-2 mb-2 mb-sm-0 access-badge">
                          {t('settings.access.pending')}
                        </div>
                      )}
                    </div>
                    <div>
                      <Form.Select
                        size="sm"
                        className="mt-2 mt-sm-0 mb-1 mb-sm-0"
                        style={{ width: 'auto' }}
                        value={item.role}
                        onChange={(event) => {
                          changeRole(item.email, event.target.value);
                        }}
                      >
                        {['editor', 'viewer'].map((role) => (
                          <option key={role} value={role}>
                            {t(`settings.access.roles.${role}`)}
                          </option>
                        ))}
                      </Form.Select>
                    </div>
                  </div>
                  <div>
                    <Button
                      variant="link"
                      className="my-n1"
                      style={{ opacity: 0.3, transform: 'translate(7px, 0)'}}
                      onClick={() => remove(item.email)}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </Button>
                  </div>
                </div>
              </ListGroup.Item>
            ))
          ) : (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Spinner animation="border" style={{ width: '2rem', height: '2rem', opacity: 0.15 }} className="my-3" />
            </div>
          )}
        </ListGroup>
        <Card.Footer className="text-muted" style={{ borderTop: 'none' }}>
          <Button
            size="sm"
            style={{ fontWeight: 'bold', fontSize: 12, textTransform: 'uppercase' }}
            onClick={openModal}
          >
            Invite
          </Button>
        </Card.Footer>
      </Card>

      <Modal show={showModal} animation={false} onHide={closeModal}>
        <Form onSubmit={invite} action="#submit" noValidate>
          <Modal.Header closeButton>
            <Modal.Title as="h5">{t('global.invite')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.Label>{t('settings.access.form.email.label')}</Form.Label>
              <Form.Control
                autoFocus
                maxLength="255"
                spellCheck="false"
                autoCorrect="off"
                autoComplete="off"
                autoCapitalize="off"
                data-1p-ignore
                data-lpignore
                value={email.value}
                isInvalid={!!email.error}
                onChange={onEmailChange}
              />
              {!!email.error && (
                <Form.Control.Feedback type="invalid">
                  {t(`settings.access.form.email.errors.${email.error.code}`)}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className='mt-3'>
              <Form.Label>{t('settings.access.form.role.label')}</Form.Label>
              <Form.Select size="sm" custom value={role} onChange={onRoleChange}>
                {['editor', 'viewer'].map((role) => (
                  <option key={role} value={role}>
                    {t(`settings.access.roles.${role}`)}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-secondary" onClick={closeModal}>
              {t('global.cancel')}
            </Button>
            <Button type="submit">{t('global.invite')}</Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
