import React, { useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { ProgressBar, Spinner } from 'react-bootstrap';
import { removeProtocolFromURL } from '../helpers';

import Favicon from '../Components/Favicon';

import { ReactComponent as WhoTracksMeLogo } from '../images/whotracksme-logo.svg';
import { get } from '../api2';

const colorFromPrevalence = (prevalence) => {
  if (prevalence >= 0.05) {
    return 'danger';
  } else if (prevalence >= 0.01) {
    return 'warning';
  } else {
    return 'info';
  }
};

const valueFromPrevalence = (prevalence) => {
  if (prevalence >= 0.2) {
    return 90;
  } else if (prevalence >= 0.1) {
    return 80;
  } else if (prevalence >= 0.05) {
    return 70;
  } else if (prevalence >= 0.01) {
    return 50;
  } else {
    return 30;
  }
};

const labelFromPrevalence = (prevalence) => {
  if (prevalence >= 0.2) {
    return 'dangerously';
  } else if (prevalence >= 0.1) {
    return 'extremely';
  } else if (prevalence >= 0.05) {
    return 'very';
  } else if (prevalence >= 0.01) {
    return 'commonly';
  } else {
    return 'relatively';
  }
};

const cacheById = {};

export default function LogsLogTrackerInsights({ tracker }) {
  const { t } = useTranslation();

  const [trackerInsight, setTrackerInsight] = useState(null);

  useEffect(() => {
    if (cacheById[tracker]) {
      setTrackerInsight(cacheById[tracker]);
      return;
    }
    return get(`/trackers/${tracker}`, (trackerInsight) => {
      cacheById[tracker] = trackerInsight;
      setTrackerInsight(trackerInsight);
    });
  }, [tracker]);

  return trackerInsight ? (
    <>
      <div className="p-3" style={{ borderBottom: '1px solid var(--bs-border-color)' }}>
        <h6 className="notranslate">{trackerInsight.name}</h6>
        {!!trackerInsight.category && (
          <div className="text-muted">{t(`logs.log.insights.categories.${trackerInsight.category}.description`)}</div>
        )}
        {!!trackerInsight.website && (
          <div className="d-flex gap-1 align-items-center mt-1">
            <Favicon url={trackerInsight.website} size={14} style={{ marginTop: -1 }} />
            <a className="notranslate" href={trackerInsight.website} target="_blank" rel="noopener noreferrer">
              {removeProtocolFromURL(trackerInsight.website)}
            </a>
          </div>
        )}
        {!!trackerInsight.prevalence && (
          <div className="mt-4 d-flex">
            <div style={{ width: 40 }}>
              <ProgressBar
                variant={colorFromPrevalence(trackerInsight.prevalence)}
                now={valueFromPrevalence(trackerInsight.prevalence)}
                style={{ height: 10 }}
              />
            </div>
            <div style={{ marginTop: -7, marginLeft: 6, opacity: 0.8 }}>
              <small>
                <Trans i18nKey="logs.log.insights.prevalence.summary">
                  _
                  {{
                    prevalence: t(
                      `logs.log.insights.prevalence.intensity.${labelFromPrevalence(trackerInsight.prevalence)}`
                    ),
                  }}
                  <span style={{ opacity: 0.6 }}>(tracks {{ percent: trackerInsight.prevalence }} of web traffic)</span>
                </Trans>
              </small>
            </div>
          </div>
        )}
      </div>
      {!!trackerInsight.company && (
        <div className="p-3 bg-2" style={{ borderBottom: '1px solid var(--bs-border-color)' }}>
          <div>
            <Trans i18nKey="logs.log.insights.ownedBy">
              <span style={{ opacity: 0.8 }}>_Owned by </span>
              <span className="notranslate" style={{ fontWeight: 500 }}>
                {{ company: trackerInsight.company.name }}
              </span>
            </Trans>
          </div>
          {!!trackerInsight.company.type && (
            <div className="mt-1" style={{ opacity: 0.9 }}>
              <small>{trackerInsight.company.type}</small>
            </div>
          )}
          {!!trackerInsight.company.description && (
            <div className="mt-1 text-muted">
              <small>{trackerInsight.company.description}</small>
            </div>
          )}
          {!!trackerInsight.company.privacyURL && (
            <div className="mt-1">
              <small>
                <a
                  className="notranslate"
                  href={trackerInsight.company.privacyURL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {removeProtocolFromURL(trackerInsight.company.privacyURL)}
                </a>
              </small>
            </div>
          )}
        </div>
      )}
      <div className="px-3 py-2 d-flex">
        <div className="flex-grow-1 d-flex align-items-center text-muted">
          <small style={{ marginLeft: 'auto', marginRight: 5 }}>{t('logs.log.insights.source')}</small>
        </div>
        <div className="d-flex align-items-center">
          <a href="https://whotracks.me" target="_blank" rel="noopener noreferrer">
            <WhoTracksMeLogo className="d-inline" style={{ width: 80, height: 16 }} />
          </a>
        </div>
      </div>
    </>
  ) : (
    <Spinner animation="border" style={{ width: '3rem', height: '3rem', opacity: 0.15 }} className="m-5" />
  );
}
