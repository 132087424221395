import React from 'react';
import { createRoot } from 'react-dom/client';
import { Alert } from 'react-bootstrap';

const notifications = { current: [] };

const container = document.createElement('div');
document.body.appendChild(container);
const root = createRoot(container);

Object.assign(container.style, {
  position: 'fixed',
  left: 0,
  bottom: 0,
  right: 0,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  alignItems: 'center',
});

function render() {
  root.render(
    <>
      {notifications.current.map(({ key, notification: { variant, title, description } }) => {
        return (
          <Alert
            key={key}
            className="text-truncate"
            variant={variant}
            style={{ maxWidth: '90vw' }}
            onClose={() => {
              notifications.current = notifications.current.filter((item) => item.key !== key);
              render();
            }}
            dismissible
          >
            <div style={{ fontWeight: 500 }}> {title || 'Error'} </div>
            {description && (
              <div className="mt-1" style={{ fontSize: '0.8em' }}>
                {description}
              </div>
            )}
          </Alert>
        );
      })}
    </>
  );
}

let index = -1;

export const push = (notification, duration = 2000) => {
  const key = String(++index);
  notifications.current = notifications.current.concat([
    {
      key,
      expires: Date.now() + duration,
      notification,
    },
  ]);
  setTimeout(() => {
    notifications.current = notifications.current.filter((item) => item.key !== key);
    render();
  }, duration);
  render();
};

export let error = ({ title, description }) => {
  return {
    variant: 'danger',
    title,
    description,
  };
};
