import React, { useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Card, ListGroup, Dropdown, Nav } from 'react-bootstrap';
import MediaQuery from 'react-responsive';
import classnames from 'classnames';

import { expandIpv6 } from '../helpers';
import { useTheme } from '../theme';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHome,
  faCheckCircle,
  faTimesCircle,
  faExclamationTriangle,
  faWindowMaximize,
} from '@fortawesome/free-solid-svg-icons';
import { faWindows, faApple, faAndroid, faLinux, faChrome } from '@fortawesome/free-brands-svg-icons';

import appStore from '../images/setup-guide/app-store.svg';
import macAppStore from '../images/setup-guide/mac-app-store.svg';

import GoogleChrome from '../images/setup-guide/google-chrome.svg';
import Firefox from '../images/setup-guide/firefox.svg';
import Edge from '../images/setup-guide/edge.svg';
import Brave from '../images/setup-guide/brave.svg';

import Tailscale from '../images/setup-guide/tailscale.svg';
import TailscaleDark from '../images/setup-guide/tailscale-dark.svg';

const PLATFORMS = {
  android: {
    icon: faAndroid,
  },
  ios: {
    icon: faApple,
  },
  windows: {
    icon: faWindows,
  },
  macos: {
    icon: faApple,
  },
  linux: {
    icon: faLinux,
  },
  chromeos: {
    icon: faChrome,
  },
  browsers: {
    icon: faWindowMaximize,
  },
  routers: {
    icon: faHome,
  },
};

const Or = () => {
  const { t } = useTranslation();

  return (
    <div style={{ marginTop: '-3.95rem', marginBottom: '2.4rem' }}>
      <div
        className="d-inline-block"
        style={{
          background: 'var(--bs-body-bg)',
          borderRadius: 4,
          border: '1px solid',
          borderColor: 'var(--bs-list-group-border-color)',
          padding: '3px 5px',
          fontSize: '0.7em',
          fontWeight: 600,
          opacity: 0.7,
          textTransform: 'uppercase',
        }}
      >
        {t('setup.guide.or')}
      </div>
    </div>
  );
};

const Tag = ({ recommended, advanced, children }) => {
  const { t } = useTranslation();

  return (
    <div
      className="d-inline-block mb-2"
      style={{
        fontWeight: 600,
        fontSize: 10,
        opacity: 0.9,
        background: recommended ? '#007bff' : '#777',
        color: 'white',
        borderRadius: 3,
        padding: '2px 5px',
        textTransform: 'uppercase',
      }}
    >
      {!!recommended && t('setup.guide.tags.recommended')}
      {!!advanced && t('setup.guide.tags.advanced')}
    </div>
  );
};

const Title = ({ noTranslate, children }) => {
  return (
    <div
      className={classnames({ notranslate: noTranslate })}
      style={{ fontSize: '1.3em', lineHeight: '1.3em', fontWeight: 500 }}
    >
      {children}
    </div>
  );
};

const Requirements = ({ children }) => {
  return <div style={{ opacity: 0.6, fontSize: '0.9em', marginTop: 4 }}>{children}</div>;
};

const Emphasis = ({ children }) => {
  return <span style={{ fontWeight: 500 }}>{children}</span>;
};

const Ipv6Support = ({ supported }) => {
  const { t } = useTranslation();

  if (typeof supported !== 'boolean') {
    return null;
  }

  return (
    <div style={{ fontSize: '0.9em', marginTop: 4 }}>
      {supported && (
        <>
          <FontAwesomeIcon icon={faCheckCircle} style={{ color: '#2ecc40', marginRight: 6 }} />
          <span style={{ opacity: 0.6 }}>{t('setup.guide.ipv6.supported')}</span>
        </>
      )}
      {!supported && (
        <>
          <FontAwesomeIcon icon={faTimesCircle} style={{ color: '#ff851b', marginRight: 4 }} />
          <span style={{ opacity: 0.6 }}>{t('setup.guide.ipv6.notSupported')}</span>
        </>
      )}
    </div>
  );
};

const Warning = ({ children }) => {
  return (
    <div className="mt-4" style={{ fontSize: '0.9em', lineHeight: '1.6em', opacity: 0.9 }}>
      <FontAwesomeIcon icon={faExclamationTriangle} style={{ color: '#ffcc00', marginRight: 6 }} />
      {children}
    </div>
  );
};

const Code = ({ children }) => {
  return (
    <div className="mt-2 code-block">
      <div className="notranslate">
        {children}
      </div>
    </div>
  );
};

export default function SetupSetupGuide({ profile, ipv6, linkedIpDNSServers, dnsStamp, ipv6Support }) {
  const { t } = useTranslation();
  const theme = useTheme();

  const [platform, setPlatform] = useState(null);

  useEffect(() => {
    if (window.navigator) {
      if (/CrOS/.test(window.navigator.userAgent)) {
        setPlatform('chromeos');
      } else if (window.navigator.platform === 'MacIntel') {
        setPlatform('macos');
      } else if (['iPhone', 'iPad', 'iPod'].includes(window.navigator.platform)) {
        setPlatform('ios');
      } else if (/android/i.test(window.navigator.userAgent)) {
        setPlatform('android');
      } else if (/linux/i.test(window.navigator.platform)) {
        setPlatform('linux');
      } else {
        setPlatform('windows');
      }

      return;
    }

    setPlatform('android');
  }, []);

  return (
    <Card id="setup-guide">
      <ListGroup variant="flush">
        <ListGroup.Item className="py-3">
          <h5>{t('setup.guide.name')}</h5>
          <div style={{ opacity: 0.6, fontSize: '0.9em' }}>{t('setup.guide.description')}</div>
          <MediaQuery minWidth={992}>
            <Nav variant="pills" className="mx-0 mt-4 notranslate">
              {Object.keys(PLATFORMS).map((_platform) => (
                <Nav.Item key={_platform}>
                  <Nav.Link
                    href={`#${_platform}`}
                    active={platform === _platform}
                    onClick={(event) => {
                      event.preventDefault();
                      setPlatform(_platform);
                    }}
                  >
                    <FontAwesomeIcon icon={PLATFORMS[_platform].icon} style={{ marginRight: 3 }} />
                    <span>{t(`setup.guide.platforms.${_platform}.name`)}</span>
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </MediaQuery>
          <MediaQuery maxWidth={991}>
            <Dropdown className="mt-4 notranslate">
              {Object.keys(PLATFORMS)
                .filter((_platform) => _platform === platform)
                .map((_platform) => (
                  <Dropdown.Toggle style={{ width: "100%" }} key={_platform}>
                    <FontAwesomeIcon icon={PLATFORMS[_platform].icon} style={{ marginRight: 3 }} />
                    <span>{t(`setup.guide.platforms.${_platform}.name`)}</span>
                  </Dropdown.Toggle>
                ))}
              <Dropdown.Menu className="col-lg-12" style={{ width: "100%" }}>
                {Object.keys(PLATFORMS).map((_platform) => (
                  <Dropdown.Item
                    key={_platform}
                    className="text-center"
                    active={platform === _platform}
                    onClick={() => setPlatform(_platform)}
                  >
                    <FontAwesomeIcon icon={PLATFORMS[_platform].icon} style={{ marginRight: 3 }} />
                    <span>{t(`setup.guide.platforms.${_platform}.name`)}</span>
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </MediaQuery>
        </ListGroup.Item>

        {platform === 'android' && (
          <>
            <ListGroup.Item className="py-5 px-md-5">
              <Tag recommended />
              <Title>{t('setup.guide.platforms.android.guides.private.title')}</Title>
              <Requirements>{t('setup.guide.platforms.android.guides.private.requirements')}</Requirements>
              <div className="mt-4">
                <div className="my-2">{t('setup.guide.platforms.android.guides.private.steps.1')}</div>
                <div className="my-2">{t('setup.guide.platforms.android.guides.private.steps.2')}</div>
                <div className="my-2">
                  <Trans i18nKey="setup.guide.platforms.android.guides.private.steps.3">
                    _3. Enter
                    <span className="notranslate" style={{ fontWeight: 'bold' }}>
                      {{ endpoint: `${profile}.dns.nextdns.io` }}
                    </span>
                    and hit Save.
                  </Trans>
                </div>
              </div>
            </ListGroup.Item>
          </>
        )}

        {platform === 'ios' && (
          <>
            <ListGroup.Item className="py-5 px-md-5">
              <Tag recommended />
              <Title>{t('setup.guide.platforms.ios.guides.profile.title')}</Title>
              <Requirements>{t('setup.guide.platforms.ios.guides.profile.requirements')}</Requirements>
              <div className="mt-4">
                <div className="my-2">
                  <Trans i18nKey="setup.guide.platforms.ios.guides.profile.step">
                    _Use our Apple Configuration Profile Generator available at
                    <a href={`https://apple.nextdns.io/?profile=${profile}`}>{{ link: 'apple.nextdns.io' }}</a>.
                  </Trans>
                </div>
              </div>
            </ListGroup.Item>

            <ListGroup.Item className="py-5 px-md-5">
              <Or />
              <Title>{t('setup.guide.platforms.ios.guides.app.title')}</Title>
              <div className="mt-4">
                <a href="https://apps.apple.com/app/nextdns/id1463342498">
                  <img alt="" src={appStore} className="d-inline-block" style={{ height: 46 }} />
                </a>
              </div>
              <div className="mt-4">
                <div className="my-2">{t('setup.guide.platforms.ios.guides.app.steps.1')}</div>
                <div className="my-2">
                  <Trans i18nKey="setup.guide.platforms.ios.guides.app.steps.2">
                    _2. Open the app then go to Settings and toggle "Use Custom Configuration". Enter
                    <span className="notranslate" style={{ fontWeight: 'bold' }}>
                      {{ id: profile }}
                    </span>
                    as Configuration ID.
                  </Trans>
                </div>
                <div className="my-2">{t('setup.guide.platforms.ios.guides.app.steps.3')}</div>
              </div>
            </ListGroup.Item>
          </>
        )}

        {platform === 'windows' && (
          <>
            <ListGroup.Item className="py-5 px-md-5">
              <Tag recommended />
              <Title>{t('setup.guide.platforms.windows.guides.dns-over-https.title')}</Title>
              <Requirements>{t('setup.guide.platforms.windows.guides.dns-over-https.requirements')}</Requirements>
              <ol
                className="mt-4 d-flex flex-column"
                style={{ listStylePosition: 'inside', listStyleType: 'decimal', rowGap: '0.5rem' }}
              >
                <li>
                  <Trans i18nKey="setup.guide.platforms.windows.guides.dns-over-https.steps.0">
                    _Open the <Emphasis>Settings</Emphasis> app.
                  </Trans>
                </li>
                <li>
                  <Trans i18nKey="setup.guide.platforms.windows.guides.dns-over-https.steps.1">
                    _Go to <Emphasis>Network & internet</Emphasis>.
                  </Trans>
                </li>
                <li>
                  <Trans i18nKey="setup.guide.platforms.windows.guides.dns-over-https.steps.2">
                    _Click on <Emphasis>Wi-Fi</Emphasis> (or <Emphasis>Ethernet</Emphasis>).
                  </Trans>
                </li>
                <li>
                  <Trans i18nKey="setup.guide.platforms.windows.guides.dns-over-https.steps.3">
                    _Click on <Emphasis>Hardware properties</Emphasis>, or ignore this step if you clicked on&nbsp;
                    <Emphasis>Ethernet</Emphasis>.
                  </Trans>
                </li>
                <li>
                  <Trans i18nKey="setup.guide.platforms.windows.guides.dns-over-https.steps.4">
                    _Click the <Emphasis>Edit</Emphasis> button next to <Emphasis>DNS server assignment</Emphasis>.
                  </Trans>
                </li>
                <li>
                  <Trans i18nKey="setup.guide.platforms.windows.guides.dns-over-https.steps.5">
                    _Select <Emphasis>Manual</Emphasis>.
                  </Trans>
                </li>
                <li>
                  <Trans i18nKey="setup.guide.platforms.windows.guides.dns-over-https.steps.6">
                    _Enable <Emphasis>IPv{{ version: 4 }}</Emphasis>.
                  </Trans>
                </li>
                <li>
                  <Trans i18nKey="setup.guide.platforms.windows.guides.dns-over-https.steps.7">
                    _Enter&nbsp;
                    <span className="notranslate" style={{ fontWeight: 'bold' }}>
                      {{ ip: '45.90.28.0' }}
                    </span>
                    &nbsp;as <Emphasis>Preferred DNS</Emphasis>, then select <Emphasis>On (manual template)</Emphasis>
                    &nbsp;and enter&nbsp;
                    <span className="notranslate" style={{ fontWeight: 'bold' }}>
                      {{ endpoint: `https://dns.nextdns.io/${profile}` }}
                    </span>
                    .
                  </Trans>
                </li>
                <li>
                  <Trans i18nKey="setup.guide.platforms.windows.guides.dns-over-https.steps.8">
                    _Enter&nbsp;
                    <span className="notranslate" style={{ fontWeight: 'bold' }}>
                      {{ ip: '45.90.30.0' }}
                    </span>
                    &nbsp;as <Emphasis>Alternate DNS</Emphasis>, then select <Emphasis>On (manual template)</Emphasis>
                    &nbsp;and enter&nbsp;
                    <span className="notranslate" style={{ fontWeight: 'bold' }}>
                      {{ endpoint: `https://dns.nextdns.io/${profile}` }}
                    </span>
                    .
                  </Trans>
                </li>
                <li>
                  <Trans i18nKey="setup.guide.platforms.windows.guides.dns-over-https.steps.9">
                    _Click <Emphasis>Save</Emphasis>.
                  </Trans>
                </li>
              </ol>
            </ListGroup.Item>

            <ListGroup.Item className="py-5 px-md-5">
              <Or />
              <Tag recommended />
              <Title>{t('setup.guide.platforms.windows.guides.app.title')}</Title>
              <div className="mt-4">
                <div className="my-2">
                  <Trans i18nKey="setup.guide.platforms.windows.guides.app.steps.1">
                    _1. Download the installer <a href="https://nextdns.io/download/windows/stable">here</a>.
                  </Trans>
                </div>
                <div className="my-2">
                  <Trans i18nKey="setup.guide.platforms.windows.guides.app.steps.2">
                    _2. After installing, right-click on NextDNS icon in the Systray then open the Settings. Set
                    <span className="notranslate" style={{ fontWeight: 'bold' }}>
                      {{ id: profile }}
                    </span>
                    as Configuration ID.
                  </Trans>
                </div>
                <div className="my-2">{t('setup.guide.platforms.windows.guides.app.steps.3')}</div>
              </div>
            </ListGroup.Item>
            <ListGroup.Item className="py-5 px-md-5">
              <Or />
              <Title>IPv6</Title>
              <Ipv6Support supported={ipv6Support} />
              <div className="mt-4">
                <div className="my-2">{t('setup.guide.platforms.windows.guides.ip.steps.1')}</div>
                <div className="my-2">{t('setup.guide.platforms.windows.guides.ip.steps.2')}</div>
                <div className="my-2">{t('setup.guide.platforms.windows.guides.ip.steps.3')}</div>
                <div className="my-2">{t('setup.guide.platforms.windows.guides.ip.steps.4')}</div>
                <div className="my-2">{t('setup.guide.platforms.windows.guides.ip.steps.5', { version: 6 })}</div>
                <div className="my-2">{t('setup.guide.platforms.windows.guides.ip.steps.6')}</div>
                <div className="my-2">{t('setup.guide.platforms.windows.guides.ip.steps.7')}</div>
                <div className="my-2">
                  <Trans i18nKey="setup.guide.platforms.windows.guides.ip.steps.8">
                    _8. Replace the current addresses (if any) with
                    <span className="notranslate" style={{ fontWeight: 'bold' }}>
                      {{ dns1: ipv6[0] }}
                    </span>
                    and
                    <span className="notranslate" style={{ fontWeight: 'bold' }}>
                      {{ dns2: ipv6[1] }}
                    </span>
                    .
                  </Trans>
                </div>
                <div className="my-2">{t('setup.guide.platforms.windows.guides.ip.steps.9')}</div>
              </div>
            </ListGroup.Item>
            <ListGroup.Item className="py-5 px-md-5">
              <Or />
              <Title>{t('setup.guide.platforms.android.guides.ip.ipv4.title')}</Title>
              <div className="mt-4">
                <div className="my-2">{t('setup.guide.platforms.windows.guides.ip.steps.1')}</div>
                <div className="my-2">{t('setup.guide.platforms.windows.guides.ip.steps.2')}</div>
                <div className="my-2">{t('setup.guide.platforms.windows.guides.ip.steps.3')}</div>
                <div className="my-2">{t('setup.guide.platforms.windows.guides.ip.steps.4')}</div>
                <div className="my-2">{t('setup.guide.platforms.windows.guides.ip.steps.5', { version: 4 })}</div>
                <div className="my-2">{t('setup.guide.platforms.windows.guides.ip.steps.6')}</div>
                <div className="my-2">{t('setup.guide.platforms.windows.guides.ip.steps.7')}</div>
                <div className="my-2">
                  <Trans i18nKey="setup.guide.platforms.windows.guides.ip.steps.8">
                    _8. Replace the current addresses (if any) with
                    <span className="notranslate" style={{ fontWeight: 'bold' }}>
                      {{ dns1: linkedIpDNSServers[0] }}
                    </span>
                    and
                    <span className="notranslate" style={{ fontWeight: 'bold' }}>
                      {{ dns2: linkedIpDNSServers[1] }}
                    </span>
                    .
                  </Trans>
                </div>
                <div className="my-2">{t('setup.guide.platforms.windows.guides.ip.steps.9')}</div>
              </div>
            </ListGroup.Item>
            <ListGroup.Item className="py-5 px-md-5">
              <Or />
              <Tag advanced />
              <Title noTranslate>YogaDNS</Title>
              <div className="mt-4">
                <div className="my-2">
                  <Trans i18nKey="setup.guide.platforms.windows.guides.yoga.steps.1">
                    _1. Install YogaDNS from
                    <a className="notranslate" href="https://yogadns.com">
                      {{ link: 'https://yogadns.com' }}
                    </a>
                    .
                  </Trans>
                </div>
                <div className="my-2">
                  <Trans i18nKey="setup.guide.platforms.windows.guides.yoga.steps.2">
                    _2. Follow the instructions for NextDNS at
                    <a className="notranslate" href="https://yogadns.com/docs/nextdns">
                      {{ link: 'https://yogadns.com/docs/nextdns' }}
                    </a>
                    and use
                    <span className="notranslate" style={{ fontWeight: 'bold' }}>
                      {{ id: profile }}
                    </span>
                    as Configuration ID.
                  </Trans>
                </div>
              </div>
            </ListGroup.Item>
          </>
        )}

        {platform === 'macos' && (
          <>
            <ListGroup.Item className="py-5 px-md-5">
              <Tag recommended />
              <Title>{t('setup.guide.platforms.ios.guides.profile.title')}</Title>
              <Requirements>{t('setup.guide.platforms.macos.guides.profile.requirements')}</Requirements>
              <div className="mt-4">
                <div className="my-2">
                  <Trans i18nKey="setup.guide.platforms.ios.guides.profile.step">
                    _Use our Apple Configuration Profile Generator available at
                    <a href={`https://apple.nextdns.io/?profile=${profile}`}>{{ link: 'apple.nextdns.io' }}</a>.
                  </Trans>
                </div>
              </div>
            </ListGroup.Item>

            <ListGroup.Item className="py-5 px-md-5">
              <Or />
              <Title>{t('setup.guide.platforms.macos.guides.app.title')}</Title>
              <div className="mt-4">
                <a href="https://apps.apple.com/app/nextdns/id1464122853">
                  <img alt="" src={macAppStore} className="d-inline-block" style={{ height: 46 }} />
                </a>
              </div>
              <div className="mt-4">
                <div className="my-2">{t('setup.guide.platforms.macos.guides.app.steps.1')}</div>
                <div className="my-2">{t('setup.guide.platforms.macos.guides.app.steps.2')}</div>
                <div className="my-2">
                  <Trans i18nKey="setup.guide.platforms.macos.guides.app.steps.3">
                    _3. Check "Use Custom Configuration" and enter
                    <span className="notranslate" style={{ fontWeight: 'bold' }}>
                      {{ id: profile }}
                    </span>
                    as Configuration ID.
                  </Trans>
                </div>
                <div className="my-2">{t('setup.guide.platforms.macos.guides.app.steps.4')}</div>
              </div>
            </ListGroup.Item>

            <ListGroup.Item className="py-5 px-md-5">
              <Or />
              <Title>IPv6</Title>
              <Ipv6Support supported={ipv6Support} />
              <div className="mt-4">
                <div className="my-2">{t('setup.guide.platforms.macos.guides.ip.steps.1')}</div>
                <div className="my-2">{t('setup.guide.platforms.macos.guides.ip.steps.2')}</div>
                <div className="my-2">{t('setup.guide.platforms.macos.guides.ip.steps.3')}</div>
                <div className="my-2">
                  <Trans i18nKey="setup.guide.platforms.macos.guides.ip.steps.4">
                    _4. In the DNS Servers list, remove all addresses (if any) then add
                    <span className="notranslate" style={{ fontWeight: 'bold' }}>
                      {{ dns1: ipv6[0] }}
                    </span>
                    and
                    <span className="notranslate" style={{ fontWeight: 'bold' }}>
                      {{ dns2: ipv6[1] }}
                    </span>
                    .
                  </Trans>
                </div>
                <div className="my-2">{t('setup.guide.platforms.macos.guides.ip.steps.5')}</div>
              </div>
            </ListGroup.Item>

            <ListGroup.Item className="py-5 px-md-5">
              <Or />
              <Title>{t('setup.guide.platforms.android.guides.ip.ipv4.title')}</Title>
              <div className="mt-4">
                <div className="my-2">{t('setup.guide.platforms.macos.guides.ip.steps.1')}</div>
                <div className="my-2">{t('setup.guide.platforms.macos.guides.ip.steps.2')}</div>
                <div className="my-2">{t('setup.guide.platforms.macos.guides.ip.steps.3')}</div>
                <div className="my-2">
                  <Trans i18nKey="setup.guide.platforms.macos.guides.ip.steps.4">
                    _4. In the DNS Servers list, remove all addresses (if any) then add
                    <span className="notranslate" style={{ fontWeight: 'bold' }}>
                      {{ dns1: linkedIpDNSServers[0] }}
                    </span>
                    and
                    <span className="notranslate" style={{ fontWeight: 'bold' }}>
                      {{ dns2: linkedIpDNSServers[1] }}
                    </span>
                    .
                  </Trans>
                </div>
                <div className="my-2">{t('setup.guide.platforms.macos.guides.ip.steps.5')}</div>
              </div>
            </ListGroup.Item>

            <ListGroup.Item className="py-5 px-md-5">
              <Or />
              <Tag advanced />
              <Title>{t('setup.guide.platforms.macos.guides.cli.title')}</Title>
              <div className="mt-4">
                <div className="my-2">
                  <Trans i18nKey="setup.guide.platforms.macos.guides.cli.steps.1">
                    _1. Run the following command:
                    <span
                      className="notranslate"
                      style={{
                        marginLeft: 4,
                        fontSize: '0.9em',
                        fontFamily: 'SFMono-Regular,Consolas,Liberation Mono,Menlo,monospace',
                      }}
                    >
                      {{ command: 'sh -c "$(curl -sL https://nextdns.io/install)"' }}
                    </span>
                  </Trans>
                </div>
                <div className="my-2">{t('setup.guide.platforms.macos.guides.cli.steps.2')}</div>
              </div>
              <div className="mt-4">
                <div className="my-2">
                  <Trans i18nKey="setup.guide.platforms.macos.guides.cli.manual">
                    _Head over to our open-source repository at
                    <a href="https://github.com/nextdns/nextdns/wiki">
                      {{ link: 'https://github.com/nextdns/nextdns/wiki' }}
                    </a>
                    for manual installation instructions.
                  </Trans>
                </div>
              </div>
            </ListGroup.Item>
          </>
        )}

        {platform === 'linux' && (
          <>
            <ListGroup.Item className="py-5 px-md-5">
              <Tag recommended />
              <Title noTranslate>systemd-resolved</Title>
              <div className="mt-4">
                <div className="my-2">
                  <Trans i18nKey="setup.guide.use">
                    _Use the following in <span className="notranslate">{{ file: '/etc/systemd/resolved.conf' }}</span>:
                  </Trans>
                  <Code>
                    [Resolve]
                    <br />
                    DNS=45.90.28.0#<b>{profile}</b>.dns.nextdns.io
                    <br />
                    DNS=2a07:a8c0::#<b>{profile}</b>.dns.nextdns.io
                    <br />
                    DNS=45.90.30.0#<b>{profile}</b>.dns.nextdns.io
                    <br />
                    DNS=2a07:a8c1::#<b>{profile}</b>.dns.nextdns.io
                    <br />
                    DNSOverTLS=yes
                  </Code>
                </div>
              </div>
            </ListGroup.Item>

            <ListGroup.Item className="py-5 px-md-5">
              <Or />
              <Title>{t('setup.guide.platforms.linux.guides.cli.title')}</Title>
              <div className="mt-4">
                <div className="my-2">
                  <Trans i18nKey="setup.guide.platforms.linux.guides.cli.steps.1">
                    _1. Run the following command:
                    <span
                      className="notranslate"
                      style={{
                        marginLeft: 4,
                        fontSize: '0.9em',
                        fontFamily: 'SFMono-Regular,Consolas,Liberation Mono,Menlo,monospace',
                      }}
                    >
                      {{ command: 'sh -c "$(curl -sL https://nextdns.io/install)"' }}
                    </span>
                  </Trans>
                </div>
                <div className="my-2">{t('setup.guide.platforms.linux.guides.cli.steps.2')}</div>
              </div>
              <div className="mt-4">
                <div className="my-2">
                  <Trans i18nKey="setup.guide.platforms.linux.guides.cli.manual">
                    _Head over to our open-source repository at
                    <a href="https://github.com/nextdns/nextdns/wiki">
                      {{ link: 'https://github.com/nextdns/nextdns/wiki' }}
                    </a>
                    for manual installation instructions.
                  </Trans>
                </div>
              </div>
            </ListGroup.Item>

            <ListGroup.Item className="py-5 px-md-5">
              <Or />
              <Title>IPv6</Title>
              <Ipv6Support supported={ipv6Support} />
              <div className="mt-4">
                <div className="my-2">
                  <Trans i18nKey="setup.guide.platforms.linux.guides.ip.step">
                    _Change your DNS servers to
                    <span className="notranslate" style={{ fontWeight: 'bold' }}>
                      {{ dns1: ipv6[0] }}
                    </span>
                    and
                    <span className="notranslate" style={{ fontWeight: 'bold' }}>
                      {{ dns2: ipv6[1] }}
                    </span>
                    .
                  </Trans>
                </div>
              </div>
            </ListGroup.Item>

            <ListGroup.Item className="py-5 px-md-5">
              <Or />
              <Title>{t('setup.guide.platforms.android.guides.ip.ipv4.title')}</Title>
              <div className="mt-4">
                <div className="my-2">
                  <Trans i18nKey="setup.guide.platforms.linux.guides.ip.step">
                    _Change your DNS servers to
                    <span className="notranslate" style={{ fontWeight: 'bold' }}>
                      {{ dns1: linkedIpDNSServers[0] }}
                    </span>
                    and
                    <span className="notranslate" style={{ fontWeight: 'bold' }}>
                      {{ dns2: linkedIpDNSServers[1] }}
                    </span>
                    .
                  </Trans>
                </div>
              </div>
            </ListGroup.Item>

            <ListGroup.Item className="py-5 px-md-5">
              <Or />
              <Title noTranslate>dnsmasq</Title>
              <div className="mt-4">
                <div className="my-2">
                  <Trans i18nKey="setup.guide.use">
                    _Use the following in <span className="notranslate">{{ file: 'dnsmasq.conf' }}</span>:
                  </Trans>
                  <Code>
                    no-resolv
                    <br />
                    bogus-priv
                    <br />
                    strict-order
                    <br />
                    server=2a07:a8c1::
                    <br />
                    server=45.90.30.0
                    <br />
                    server=2a07:a8c0::
                    <br />
                    server=45.90.28.0
                    <br />
                    add-cpe-id=<b>{profile}</b>
                  </Code>
                </div>
              </div>
            </ListGroup.Item>

            <ListGroup.Item className="py-5 px-md-5">
              <Or />
              <Title noTranslate>Stubby</Title>
              <div className="mt-4">
                <div className="my-2">
                  <Trans i18nKey="setup.guide.use">
                    _Use the following in <span className="notranslate">{{ file: 'stubby.yml' }}</span>:
                  </Trans>
                  <Code>
                    round_robin_upstreams: 1
                    <br />
                    upstream_recursive_servers:
                    <br />
                    &nbsp;&nbsp;- address_data: 45.90.28.0
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;tls_auth_name: "<b>{profile}</b>.dns.nextdns.io"
                    <br />
                    &nbsp;&nbsp;- address_data: 2a07:a8c0::0
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;tls_auth_name: "<b>{profile}</b>.dns.nextdns.io"
                    <br />
                    &nbsp;&nbsp;- address_data: 45.90.30.0
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;tls_auth_name: "<b>{profile}</b>.dns.nextdns.io"
                    <br />
                    &nbsp;&nbsp;- address_data: 2a07:a8c1::0
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;tls_auth_name: "<b>{profile}</b>.dns.nextdns.io"
                  </Code>
                </div>
              </div>
              <Warning>
                <Trans i18nKey="setup.guide.platforms.linux.guides.stubby.warning">
                  _Make sure Stubby is linked with <span style={{ fontWeight: 500 }}>OpenSSL 1.1.1 or higher</span> as
                  previous versions will not work with NextDNS.
                </Trans>
              </Warning>
            </ListGroup.Item>

            <ListGroup.Item className="py-5 px-md-5">
              <Or />
              <Title no Translate>
                DNSCrypt
              </Title>
              <div className="mt-4">
                <div className="my-2">
                  <Trans i18nKey="setup.guide.use">
                    _Use the following in <span className="notranslate">{{ file: 'dnscrypt-proxy.toml' }}</span>:
                  </Trans>
                  <Code>
                    server_names = ['NextDNS-<b>{profile}</b>']
                    <br />
                    <br />
                    [static]
                    <br />
                    &nbsp;&nbsp;[static.'NextDNS-<b>{profile}</b>']
                    <br />
                    &nbsp;&nbsp;stamp = '<b>{dnsStamp}</b>'
                  </Code>
                </div>
              </div>
            </ListGroup.Item>

            <ListGroup.Item className="py-5 px-md-5">
              <Or />
              <Title noTranslate>Knot Resolver</Title>
              <div className="mt-4">
                <div className="my-2">
                  <Trans i18nKey="setup.guide.use">
                    _Use the following in <span className="notranslate">{{ file: '/etc/kresd/custom.conf' }}</span>:
                  </Trans>
                  <Code>
                    policy.add(policy.all(policy.TLS_FORWARD({'{'}
                    <br />
                    &nbsp;&nbsp;{'{'}'45.90.28.0', hostname='<b>{profile}</b>.dns.nextdns.io'{'}'},
                    <br />
                    &nbsp;&nbsp;{'{'}'2a07:a8c0::', hostname='<b>{profile}</b>.dns.nextdns.io'{'}'},
                    <br />
                    &nbsp;&nbsp;{'{'}'45.90.30.0', hostname='<b>{profile}</b>.dns.nextdns.io'{'}'},
                    <br />
                    &nbsp;&nbsp;{'{'}'2a07:a8c1::', hostname='<b>{profile}</b>.dns.nextdns.io'{'}'}
                    <br />
                    {'}'})))
                  </Code>
                </div>
              </div>
            </ListGroup.Item>

            <ListGroup.Item className="py-5 px-md-5">
              <Or />
              <Title noTranslate>cloudflared</Title>
              <div className="mt-4">
                <div className="my-2">
                  <Trans i18nKey="setup.guide.use">
                    _Use the following in
                    <span className="notranslate">{{ file: '/usr/local/etc/cloudflared/config.yml' }}</span>:
                  </Trans>
                  <Code>
                    proxy-dns: true
                    <br />
                    proxy-dns-upstream:
                    <br />
                    &nbsp;- https://dns.nextdns.io/<b>{profile}</b>
                  </Code>
                </div>
              </div>
            </ListGroup.Item>

            <ListGroup.Item className="py-5 px-md-5">
              <Or />
              <Title noTranslate>Unbound</Title>
              <div className="mt-4">
                <div className="my-2">
                  <Trans i18nKey="setup.guide.use">
                    _Use the following in <span className="notranslate">{{ file: 'unbound.conf' }}</span>:
                  </Trans>
                  <Code>
                    forward-zone:
                    <br />
                    &nbsp;&nbsp;name: "."
                    <br />
                    &nbsp;&nbsp;forward-tls-upstream: yes
                    <br />
                    &nbsp;&nbsp;forward-addr: 45.90.28.0#<b>{profile}</b>.dns.nextdns.io
                    <br />
                    &nbsp;&nbsp;forward-addr: 2a07:a8c0::#<b>{profile}</b>.dns.nextdns.io
                    <br />
                    &nbsp;&nbsp;forward-addr: 45.90.30.0#<b>{profile}</b>.dns.nextdns.io
                    <br />
                    &nbsp;&nbsp;forward-addr: 2a07:a8c1::#<b>{profile}</b>.dns.nextdns.io
                  </Code>
                </div>
              </div>
              <Warning>
                <Trans i18nKey="setup.guide.platforms.linux.guides.unbound.warning">
                  _As a recursive resolver, Unbound chases CNAMEs. This may result in
                  <span style={{ fontWeight: 500 }}>unexpected behavior</span> when used in conjunction with a blocking
                  DNS resolver like NextDNS. See
                  <a href="https://github.com/NLnetLabs/unbound/issues/132" target="_blank" rel="noopener noreferrer">
                    {{ link: 'github.com/NLnetLabs/unbound/issues/132' }}
                  </a>
                  .
                </Trans>
              </Warning>
            </ListGroup.Item>
          </>
        )}

        {platform === 'chromeos' && (
          <>
            <ListGroup.Item className="py-5 px-md-5">
              <Tag recommended />
              <Title>{t('setup.guide.platforms.chromeos.guides.secure-dns.title')}</Title>
              <ol
                className="mt-4 d-flex flex-column"
                style={{ listStylePosition: 'inside', listStyleType: 'decimal', rowGap: '0.5rem' }}
              >
                <li>
                  <Trans i18nKey="setup.guide.platforms.chromeos.guides.secure-dns.steps.0">
                    _Open the <Emphasis>Settings</Emphasis> app.
                  </Trans>
                </li>
                <li>
                  <Trans i18nKey="setup.guide.platforms.chromeos.guides.secure-dns.steps.1">
                    _Go to <Emphasis>Security and Privacy</Emphasis>.
                  </Trans>
                </li>
                <li>
                  <Trans i18nKey="setup.guide.platforms.chromeos.guides.secure-dns.steps.2">
                    _Enable <Emphasis>Use secure DNS</Emphasis>.
                  </Trans>
                </li>
                <li>
                  <Trans i18nKey="setup.guide.platforms.chromeos.guides.secure-dns.steps.3">
                    _Select <Emphasis>With: Custom</Emphasis>, then enter&nbsp;
                    <span className="notranslate" style={{ fontWeight: 'bold' }}>
                      {{ endpoint: `https://dns.nextdns.io/${profile}` }}
                    </span>
                    .
                  </Trans>
                </li>
              </ol>
            </ListGroup.Item>

            <ListGroup.Item className="py-5 px-md-5">
              <Or />
              <Title>IPv6</Title>
              <Ipv6Support supported={ipv6Support} />
              <div className="mt-4">
                <div className="my-2">
                  <Trans i18nKey="setup.guide.platforms.chromeos.guides.ip.step">
                    _Change your DNS servers to
                    <span className="notranslate" style={{ fontWeight: 'bold' }}>
                      {{ dns1: ipv6[0] }}
                    </span>
                    and
                    <span className="notranslate" style={{ fontWeight: 'bold' }}>
                      {{ dns2: ipv6[1] }}
                    </span>
                    .
                  </Trans>
                </div>
              </div>
            </ListGroup.Item>

            <ListGroup.Item className="py-5 px-md-5">
              <Or />
              <Title>{t('setup.guide.platforms.android.guides.ip.ipv4.title')}</Title>
              <div className="mt-4">
                <div className="my-2">
                  <Trans i18nKey="setup.guide.platforms.chromeos.guides.ip.step">
                    _Change your DNS servers to
                    <span className="notranslate" style={{ fontWeight: 'bold' }}>
                      {{ dns1: linkedIpDNSServers[0] }}
                    </span>
                    and
                    <span className="notranslate" style={{ fontWeight: 'bold' }}>
                      {{ dns2: linkedIpDNSServers[1] }}
                    </span>
                    .
                  </Trans>
                </div>
              </div>
            </ListGroup.Item>
          </>
        )}

        {platform === 'browsers' && (
          <>
            <ListGroup.Item className="py-5 px-md-5">
              <div
                className="d-flex align-items-center"
                style={{ fontSize: '1.3em', lineHeight: '1.3em', fontWeight: 500 }}
              >
                <img src={GoogleChrome} alt="" className="d-inline-block" style={{ height: 20, marginRight: 6 }} />
                <div>{t('setup.guide.platforms.browsers.guides.chrome.title')}</div>
              </div>
              <div className="mt-4">
                <div className="my-2">{t('setup.guide.platforms.browsers.guides.chrome.steps.1')}</div>
                <div className="my-2">{t('setup.guide.platforms.browsers.guides.chrome.steps.2')}</div>
                <div className="my-2">{t('setup.guide.platforms.browsers.guides.chrome.steps.3')}</div>
                <div className="my-2">
                  <Trans i18nKey="setup.guide.platforms.browsers.guides.chrome.steps.4">
                    _4. Select With: Custom, then enter
                    <span className="notranslate" style={{ fontWeight: 'bold' }}>
                      {{ endpoint: `https://dns.nextdns.io/${profile}` }}
                    </span>
                    .
                  </Trans>
                </div>
              </div>
            </ListGroup.Item>

            <ListGroup.Item className="py-5 px-md-5">
              <div
                className="d-flex align-items-center"
                style={{ fontSize: '1.3em', lineHeight: '1.3em', fontWeight: 500 }}
              >
                <img src={Firefox} alt="" className="d-inline-block" style={{ height: 20, marginRight: 6 }} />
                <div>{t('setup.guide.platforms.browsers.guides.firefox.title')}</div>
              </div>
              <Requirements>{t('setup.guide.platforms.browsers.guides.firefox.requirements')}</Requirements>
              <div className="mt-4">
                <div className="my-2">{t('setup.guide.platforms.browsers.guides.firefox.steps.1')}</div>
                <div className="my-2">{t('setup.guide.platforms.browsers.guides.firefox.steps.2')}</div>
                <div className="my-2">{t('setup.guide.platforms.browsers.guides.firefox.steps.3')}</div>
                <div className="my-2">{t('setup.guide.platforms.browsers.guides.firefox.steps.4')}</div>
                <div className="my-2">{t('setup.guide.platforms.browsers.guides.firefox.steps.5')}</div>
                <div className="my-2">
                  <Trans i18nKey="setup.guide.platforms.browsers.guides.firefox.steps.6">
                    _6. Enter
                    <span className="notranslate" style={{ fontWeight: 'bold' }}>
                      {{ endpoint: `https://dns.nextdns.io/${profile}` }}
                    </span>.
                  </Trans>
                </div>
              </div>
            </ListGroup.Item>

            <ListGroup.Item className="py-5 px-md-5">
              <div
                className="d-flex align-items-center"
                style={{ fontSize: '1.3em', lineHeight: '1.3em', fontWeight: 500 }}
              >
                <img src={Edge} alt="" className="d-inline-block" style={{ height: 20, marginRight: 6 }} />
                <div>{t('setup.guide.platforms.browsers.guides.edge.title')}</div>
              </div>
              <div className="mt-4">
                <div className="my-2">{t('setup.guide.platforms.browsers.guides.edge.steps.1')}</div>
                <div className="my-2">{t('setup.guide.platforms.browsers.guides.edge.steps.2')}</div>
                <div className="my-2">{t('setup.guide.platforms.browsers.guides.edge.steps.3')}</div>
                <div className="my-2">
                  <Trans i18nKey="setup.guide.platforms.browsers.guides.edge.steps.4">
                    _4. Select Choose a service provider, then enter
                    <span className="notranslate" style={{ fontWeight: 'bold' }}>
                      {{ endpoint: `https://dns.nextdns.io/${profile}` }}
                    </span>
                    .
                  </Trans>
                </div>
              </div>
            </ListGroup.Item>

            <ListGroup.Item className="py-5 px-md-5">
              <div
                className="d-flex align-items-center"
                style={{ fontSize: '1.3em', lineHeight: '1.3em', fontWeight: 500 }}
              >
                <img src={Brave} alt="" className="d-inline-block" style={{ height: 20, marginRight: 6 }} />
                <div>{t('setup.guide.platforms.browsers.guides.brave.title')}</div>
              </div>
              <div className="mt-4">
                <div className="my-2">{t('setup.guide.platforms.browsers.guides.brave.steps.1')}</div>
                <div className="my-2">{t('setup.guide.platforms.browsers.guides.brave.steps.2')}</div>
                <div className="my-2">{t('setup.guide.platforms.browsers.guides.brave.steps.3')}</div>
                <div className="my-2">
                  <Trans i18nKey="setup.guide.platforms.browsers.guides.brave.steps.4">
                    _4. Select With: Custom, then enter
                    <span className="notranslate" style={{ fontWeight: 'bold' }}>
                      {{ endpoint: `https://dns.nextdns.io/${profile}` }}
                    </span>
                    .
                  </Trans>
                </div>
              </div>
            </ListGroup.Item>
          </>
        )}

        {platform === 'routers' && (
          <>
            <ListGroup.Item className="py-5 px-md-5">
              <Tag recommended />
              <Title>{t('setup.guide.platforms.routers.guides.cli.title')}</Title>
              <Requirements>{t('setup.guide.platforms.routers.guides.cli.requirements')}</Requirements>
              <div className="mt-4">
                <div className="my-2">
                  <Trans i18nKey="setup.guide.platforms.routers.guides.cli.step">
                    _Head over to our open-source repository at
                    <a href="https://github.com/nextdns/nextdns/wiki">
                      {{ link: 'https://github.com/nextdns/nextdns/wiki' }}
                    </a>
                    for installation instructions.
                  </Trans>
                </div>
              </div>
            </ListGroup.Item>

            <ListGroup.Item className="py-5 px-md-5">
              <Or />
              <Title>IPv6</Title>
              <Ipv6Support supported={ipv6Support} />
              <div className="mt-4">
                <div className="my-2">{t('setup.guide.platforms.routers.guides.ip.steps.1')}</div>
                <div className="my-2">{t('setup.guide.platforms.routers.guides.ip.steps.2')}</div>
                <div className="my-2">
                  <Trans i18nKey="setup.guide.platforms.routers.guides.ip.steps.3">
                    _3. Remove all addresses (if any) then add
                    <span className="notranslate" style={{ fontWeight: 'bold' }}>
                      {{ dns1: ipv6[0] }}
                    </span>
                    and
                    <span className="notranslate" style={{ fontWeight: 'bold' }}>
                      {{ dns2: ipv6[1] }}
                    </span>
                    .
                  </Trans>
                </div>
                <div className="my-2">{t('setup.guide.platforms.routers.guides.ip.steps.4')}</div>
              </div>
              <Warning>
                <Trans i18nKey="setup.guide.platforms.routers.guides.ip.ipv6.warning">
                  _Some routers may not support the IPv6 notation above. In that case, use
                  <span className="notranslate" style={{ fontWeight: 'bold' }}>
                    {{ dns1: expandIpv6(ipv6[0]) }}
                  </span>
                  and
                  <span className="notranslate" style={{ fontWeight: 'bold' }}>
                    {{ dns2: expandIpv6(ipv6[1]) }}
                  </span>
                  .
                </Trans>
              </Warning>
            </ListGroup.Item>

            <ListGroup.Item className="py-5 px-md-5">
              <Or />
              <Title>{t('setup.guide.platforms.android.guides.ip.ipv4.title')}</Title>
              <div className="mt-4">
                <div className="my-2">{t('setup.guide.platforms.routers.guides.ip.steps.1')}</div>
                <div className="my-2">{t('setup.guide.platforms.routers.guides.ip.steps.2')}</div>
                <div className="my-2">
                  <Trans i18nKey="setup.guide.platforms.routers.guides.ip.steps.3">
                    _3. Remove all addresses (if any) then add
                    <span className="notranslate" style={{ fontWeight: 'bold' }}>
                      {{ dns1: linkedIpDNSServers[0] }}
                    </span>
                    and
                    <span className="notranslate" style={{ fontWeight: 'bold' }}>
                      {{ dns2: linkedIpDNSServers[1] }}
                    </span>
                    .
                  </Trans>
                </div>
                <div className="my-2">{t('setup.guide.platforms.routers.guides.ip.steps.4')}</div>
              </div>
            </ListGroup.Item>

            <ListGroup.Item className="py-5 px-md-5">
              <Or />
              <Title noTranslate>dnsmasq</Title>
              <div className="mt-4">
                <div className="my-2">
                  <Trans i18nKey="setup.guide.use">
                    _Use the following in <span className="notranslate">{{ file: 'dnsmasq.conf' }}</span>:
                  </Trans>
                  <Code>
                    no-resolv
                    <br />
                    bogus-priv
                    <br />
                    strict-order
                    <br />
                    server=2a07:a8c1::
                    <br />
                    server=45.90.30.0
                    <br />
                    server=2a07:a8c0::
                    <br />
                    server=45.90.28.0
                    <br />
                    add-cpe-id=<b>{profile}</b>
                  </Code>
                </div>
              </div>
            </ListGroup.Item>

            <ListGroup.Item className="py-5 px-md-5">
              <Or />
              <Title noTranslate>Stubby</Title>
              <div className="mt-4">
                <div className="my-2">
                  <Trans i18nKey="setup.guide.use">
                    _Use the following in <span className="notranslate">{{ file: 'stubby.yml' }}</span>:
                  </Trans>
                  <Code>
                    round_robin_upstreams: 1
                    <br />
                    upstream_recursive_servers:
                    <br />
                    &nbsp;&nbsp;- address_data: 45.90.28.0
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;tls_auth_name: "<b>{profile}</b>.dns.nextdns.io"
                    <br />
                    &nbsp;&nbsp;- address_data: 2a07:a8c0::0
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;tls_auth_name: "<b>{profile}</b>.dns.nextdns.io"
                    <br />
                    &nbsp;&nbsp;- address_data: 45.90.30.0
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;tls_auth_name: "<b>{profile}</b>.dns.nextdns.io"
                    <br />
                    &nbsp;&nbsp;- address_data: 2a07:a8c1::0
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;tls_auth_name: "<b>{profile}</b>.dns.nextdns.io"
                  </Code>
                </div>
              </div>
              <Warning>
                <Trans i18nKey="setup.guide.platforms.linux.guides.stubby.warning">
                  _Make sure Stubby is linked with <span style={{ fontWeight: 500 }}>OpenSSL 1.1.1 or higher</span> as
                  previous versions will not work with NextDNS.
                </Trans>
              </Warning>
            </ListGroup.Item>

            <ListGroup.Item className="py-5 px-md-5">
              <Or />
              <Title noTranslate>pfSense</Title>
              <div className="mt-4">
                <div className="my-2">{t('setup.guide.platforms.routers.guides.pfsense.steps.1')}</div>
                <div className="my-2">
                  {t('setup.guide.platforms.routers.guides.pfsense.steps.2')}
                  <Code>
                    server:
                    <br />
                    &nbsp;&nbsp;forward-zone:
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;name: "."
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;forward-tls-upstream: yes
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;forward-addr: 45.90.28.0#<b>{profile}</b>.dns.nextdns.io
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;forward-addr: 2a07:a8c0::#<b>{profile}</b>.dns.nextdns.io
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;forward-addr: 45.90.30.0#<b>{profile}</b>.dns.nextdns.io
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;forward-addr: 2a07:a8c1::#<b>{profile}</b>.dns.nextdns.io
                  </Code>
                </div>
              </div>
            </ListGroup.Item>

            <ListGroup.Item className="py-5 px-md-5">
              <Or />
              <Title noTranslate>DNSCrypt</Title>
              <div className="mt-4">
                <div className="my-2">
                  <Trans i18nKey="setup.guide.use">
                    _Use the following in <span className="notranslate">{{ file: 'dnscrypt-proxy.toml' }}</span>:
                  </Trans>
                  <Code>
                    server_names = ['NextDNS-<b>{profile}</b>']
                    <br />
                    <br />
                    [static]
                    <br />
                    &nbsp;&nbsp;[static.'NextDNS-<b>{profile}</b>']
                    <br />
                    &nbsp;&nbsp;stamp = '<b>{dnsStamp}</b>'
                  </Code>
                </div>
              </div>
            </ListGroup.Item>

            <ListGroup.Item className="py-5 px-md-5">
              <Or />
              <Title noTranslate>Knot Resolver</Title>
              <div className="mt-4">
                <div className="my-2">
                  <Trans i18nKey="setup.guide.use">
                    _Use the following in <span className="notranslate">{{ file: '/etc/kresd/custom.conf' }}</span>:
                  </Trans>
                  <Code>
                    policy.add(policy.all(policy.TLS_FORWARD({'{'}
                    <br />
                    &nbsp;&nbsp;{'{'}'45.90.28.0', hostname='<b>{profile}</b>.dns.nextdns.io'{'}'},
                    <br />
                    &nbsp;&nbsp;{'{'}'2a07:a8c0::', hostname='<b>{profile}</b>.dns.nextdns.io'{'}'},
                    <br />
                    &nbsp;&nbsp;{'{'}'45.90.30.0', hostname='<b>{profile}</b>.dns.nextdns.io'{'}'},
                    <br />
                    &nbsp;&nbsp;{'{'}'2a07:a8c1::', hostname='<b>{profile}</b>.dns.nextdns.io'{'}'}
                    <br />
                    {'}'})))
                  </Code>
                </div>
              </div>
            </ListGroup.Item>

            <ListGroup.Item className="py-5 px-md-5">
              <Or />
              <Title noTranslate>Unbound</Title>
              <div className="mt-4">
                <div className="my-2">
                  <Trans i18nKey="setup.guide.use">
                    _Use the following in <span className="notranslate">{{ file: 'unbound.conf' }}</span>:
                  </Trans>
                  <Code>
                    forward-zone:
                    <br />
                    &nbsp;&nbsp;name: "."
                    <br />
                    &nbsp;&nbsp;forward-tls-upstream: yes
                    <br />
                    &nbsp;&nbsp;forward-addr: 45.90.28.0#<b>{profile}</b>.dns.nextdns.io
                    <br />
                    &nbsp;&nbsp;forward-addr: 2a07:a8c0::#<b>{profile}</b>.dns.nextdns.io
                    <br />
                    &nbsp;&nbsp;forward-addr: 45.90.30.0#<b>{profile}</b>.dns.nextdns.io
                    <br />
                    &nbsp;&nbsp;forward-addr: 2a07:a8c1::#<b>{profile}</b>.dns.nextdns.io
                  </Code>
                </div>
              </div>
              <Warning>
                <Trans i18nKey="setup.guide.platforms.linux.guides.unbound.warning">
                  _As a recursive resolver, Unbound chases CNAMEs. This may result in
                  <span style={{ fontWeight: 500 }}>unexpected behavior</span> when used in conjunction with a blocking
                  DNS resolver like NextDNS. See
                  <a href="https://github.com/NLnetLabs/unbound/issues/132" target="_blank" rel="noopener noreferrer">
                    {{ link: 'github.com/NLnetLabs/unbound/issues/132' }}
                  </a>
                  .
                </Trans>
              </Warning>
            </ListGroup.Item>

            <ListGroup.Item className="py-5 px-md-5">
              <Or />
              <Title noTranslate>MikroTik</Title>
              <div className="mt-4">
                <div className="my-2">
                  {t('setup.guide.platforms.routers.guides.mikrotik.run')}
                  <Code>
                    /tool fetch url=https://curl.se/ca/cacert.pem
                    <br />
                    /certificate import file-name=cacert.pem
                    <br />
                    /ip dns set servers=""
                    <br />
                    /ip dns static add name=dns.nextdns.io address=45.90.28.0 type=A
                    <br />
                    /ip dns static add name=dns.nextdns.io address=45.90.30.0 type=A
                    <br />
                    /ip dns static add name=dns.nextdns.io address=2a07:a8c0:: type=AAAA
                    <br />
                    /ip dns static add name=dns.nextdns.io address=2a07:a8c1:: type=AAAA
                    <br />
                    /ip dns set use-doh-server=“https://dns.nextdns.io/<b>{profile}</b>” verify-doh-cert=yes
                  </Code>
                </div>
              </div>
            </ListGroup.Item>

            <ListGroup.Item className="py-5 px-md-5">
              <Or />
              <Title>
                <img src={theme === 'dark' ? TailscaleDark : Tailscale} alt="Tailscale" className="d-inline-block" style={{ height: 20 }} />
              </Title>
              <div className="mt-4">
                <div className="my-2">
                  <Trans i18nKey="setup.guide.platforms.vpn.guides.tailscale.manual">
                    _Head over to the Tailscale documentation at{' '}
                    <a href="https://tailscale.com/kb/1218/nextdns">
                      {{ link: 'https://tailscale.com/kb/1218/nextdns' }}
                    </a>{' '}
                    for setup instructions.
                  </Trans>
                </div>
              </div>
            </ListGroup.Item>
          </>
        )}
      </ListGroup>
    </Card>
  );
}
