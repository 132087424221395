const KEY = 'activeProfile';

// TODO: remove activeConfiguration migration (added on June 30, 2022)

const get = () => {
  if (window.localStorage) {
    if (window.localStorage.getItem('activeConfiguration')) {
      window.localStorage.setItem(KEY, window.localStorage.getItem('activeConfiguration'));
    }

    window.localStorage.removeItem('activeConfiguration');

    return window.localStorage.getItem(KEY);
  }

  return null;
};

const save = (profile) => {
  if (window.localStorage) {
    window.localStorage.removeItem('activeConfiguration');
    window.localStorage.setItem(KEY, profile);
  }
};

const clear = () => {
  if (window.localStorage) {
    window.localStorage.removeItem('activeConfiguration');
    window.localStorage.removeItem(KEY);
  }
};

const lastProfileSaver = {
  get,
  save,
  clear,
};

export default lastProfileSaver;
