import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { OverlayTrigger, Tooltip, Modal, Form, Button, Spinner } from 'react-bootstrap';
import axios from 'axios';

import { API_BASE_URL } from '../config';

import PaypalIcon from '../images/payments/paypal-icon.svg';

export default function AccountAffiliationPayOutButton({ email, availableBalance, onPaidOut }) {
  const { t } = useTranslation();
  const source = useRef(null);
  const isModalClosableRef = useRef(true);

  const [showModal, setShowModal] = useState(false);
  const [paypalEmailValue, setPaypalEmailValue] = useState('');
  const [paypalEmailError, setPaypalEmailError] = useState(null);
  const [currentPasswordValue, setCurrentPasswordValue] = useState('');
  const [currentPasswordError, setCurrentPasswordError] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    if (!isModalClosableRef.current) {
      return;
    }

    setShowModal(false);
    setPaypalEmailValue('');
    setPaypalEmailError(null);
    setCurrentPasswordValue('');
    setCurrentPasswordError(null);
    setIsProcessing(false);
  };

  const onPaypalEmailValueChange = (event) => {
    setPaypalEmailValue(event.target.value);
    setPaypalEmailError(null);
  };

  const onCurrentPasswordValueChange = (event) => {
    setCurrentPasswordValue(event.target.value);
    setCurrentPasswordError(null);
  };

  const payout = async (event) => {
    event.preventDefault();

    isModalClosableRef.current = false;
    setIsProcessing(true);

    const { data } = await axios.post(
      `${API_BASE_URL}/accounts/@me/payout`,
      {
        paypalEmail: paypalEmailValue,
        currentPassword: currentPasswordValue,
      },
      {
        cancelToken: source.current.token,
      }
    );

    isModalClosableRef.current = true;
    setIsProcessing(false);

    if (data.errors) {
      setPaypalEmailError(data.errors.paypalEmail);
      setCurrentPasswordValue('');
      setCurrentPasswordError(data.errors.currentPassword);
      return;
    }

    closeModal();
    onPaidOut();
  };

  useEffect(() => {
    source.current = axios.CancelToken.source();
    return () => {
      source.current.cancel();
    };
  }, []);

  return (
    <>
      {availableBalance < 50 ? (
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip>
              <div className="p-2" style={{ fontSize: '0.9em', lineHeight: '1.5em', opacity: 0.9 }}>
                {t('account.affiliation.payout.minimum', { amount: [50, 'USD'] })}
              </div>
            </Tooltip>
          }
        >
          <span className="d-inline-block">
            <Button variant="outline-primary" size="sm" disabled style={{ pointerEvents: 'none' }}>
              <img src={PaypalIcon} alt="" className="d-inline-block" style={{ height: 14, marginRight: 2 }} />
              <span> </span>
              <span>{t('account.affiliation.payout.button')}</span>
            </Button>
          </span>
        </OverlayTrigger>
      ) : (
        <Button variant="outline-primary" size="sm" onClick={openModal}>
          <img src={PaypalIcon} alt="" className="d-inline-block" style={{ height: 14, marginRight: 2 }} />
          <span> </span>
          <span>{t('account.affiliation.payout.button')}</span>
        </Button>
      )}

      <Modal show={showModal} animation={false} onHide={closeModal}>
        <Form onSubmit={payout} action="#submit" noValidate>
          <Modal.Header closeButton>
            <Modal.Title as="h5">{t('account.affiliation.payout.button')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.Label>{t('account.affiliation.payout.form.paypalEmail')}</Form.Label>
              <Form.Control
                type="email"
                autoFocus
                autoCorrect="off"
                autoCapitalize="none"
                autoComplete="username"
                isInvalid={!!paypalEmailError}
                value={paypalEmailValue}
                onChange={onPaypalEmailValueChange}
              />
              {!!paypalEmailError && (
                <Form.Control.Feedback type="invalid">
                  {t(`account.affiliation.payout.form.errors.paypalEmail.${paypalEmailError}`)}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className='mt-3'>
              <Form.Label>{t('account.currentPassword')}</Form.Label>
              <Form.Control
                type="password"
                autoComplete="current-password"
                isInvalid={!!currentPasswordError}
                value={currentPasswordValue}
                onChange={onCurrentPasswordValueChange}
              />
              {!!currentPasswordError && (
                <Form.Control.Feedback type="invalid">
                  {t(`account.errors.currentPassword.${currentPasswordError}`)}
                </Form.Control.Feedback>
              )}
              <div className="mt-1 text-end" style={{ fontSize: '0.8em' }}>
                <Link to={`/reset-password?email=${encodeURIComponent(email)}`}>
                  {t('account.forgotPassword')}
                </Link>
              </div>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-secondary" onClick={closeModal}>
              {t('global.cancel')}
            </Button>

            {!isProcessing && (
              <Button type="submit">{t('account.affiliation.payout.button')}</Button>
            )}
            {isProcessing && (
              <Button disabled>
                <Spinner as="span" animation="border" size="sm" className="me-2" />
                {t('account.affiliation.payout.button')}
              </Button>
            )}
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}