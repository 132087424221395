import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Modal, Form, Button } from 'react-bootstrap';
import axios from 'axios';

import { API_BASE_URL } from '../config';

export default function Account2FARecoveryCodesButton({ email }) {
  const [showModal, setShowModal] = useState(false);
  const [currentPasswordValue, setCurrentPasswordValue] = useState('');
  const [currentPasswordError, setCurrentPasswordError] = useState(null);
  const [currentPassword, setCurrentPassword] = useState(null);
  const [codes, setCodes] = useState(null);
  const { t } = useTranslation();
  const source = useRef(null);

  useEffect(() => {
    source.current = axios.CancelToken.source();
    return () => {
      source.current.cancel();
    };
  }, []);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setCurrentPasswordValue('');
    setCurrentPasswordError(null);
    setCurrentPassword(null);
    setCodes(null);
  };

  const onCurrentPasswordValueChange = (event) => {
    setCurrentPasswordValue(event.target.value);
    setCurrentPasswordError(null);
  };

  const getCodes = async (event, regenerate) => {
    if (event) {
      event.preventDefault();
    }

    const { data } = await axios.post(
      `${API_BASE_URL}/accounts/@me/2fa/recovery`,
      {
        currentPassword: currentPasswordValue,
        regenerate: regenerate,
      },
      {
        cancelToken: source.current.token,
      }
    );

    if (regenerate) {
      setCodes(data.codes);
      return;
    }

    if (data.errors) {
      setCurrentPasswordValue('');
      setCurrentPasswordError(data.errors.currentPassword);
      return;
    }

    setCurrentPassword(currentPasswordValue);
    setCodes(data.codes);
  };

  const generateHref = () => {
    return 'data:text/plain;charset=UTF-8,' + codes.join('%0A');
  };

  return (
    <>
      <Button variant="link" className="p-0" size="sm" onClick={openModal}>
        {t('account.2fa.recovery.button')}
      </Button>

      <Modal show={showModal} animation={false} onHide={closeModal}>
        <Form onSubmit={getCodes} action="#submit" noValidate>
          <Modal.Header closeButton>
            <Modal.Title as="h5">{t('account.2fa.recovery.button')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {!currentPassword && (
              <Form.Group>
                <Form.Label>{t('account.currentPassword')}</Form.Label>
                <Form.Control
                  type="password"
                  autoFocus
                  autoComplete="current-password"
                  isInvalid={!!currentPasswordError}
                  value={currentPasswordValue}
                  onChange={onCurrentPasswordValueChange}
                />
                {!!currentPasswordError && (
                  <Form.Control.Feedback type="invalid">
                    {t(`account.errors.currentPassword.${currentPasswordError}`)}
                  </Form.Control.Feedback>
                )}
                <div className="mt-1 text-end" style={{ fontSize: '0.8em' }}>
                  <Link to={`/reset-password?email=${encodeURIComponent(email)}`}>
                    {t('account.forgotPassword')}
                  </Link>
                </div>
              </Form.Group>
            )}
            {!!codes && (
              <>
                <div className="mb-4" style={{ opacity: 0.6, fontSize: '0.9em' }}>
                  {t('account.2fa.recovery.modal.howto')}
                </div>
                <div className="text-monospace text-center" style={{ fontSize: '1.2em', fontWeight: 500 }}>
                  {codes.map((code) => (
                    <div className="d-inline-block mx-3 my-2" key={code}>
                      {code}
                    </div>
                  ))}
                </div>
                <div className="mt-4 mb-4 text-center">
                  <Button as="a" download="NextDNS 2FA Recovery Codes.txt" href={generateHref()}>
                    {t('account.2fa.recovery.modal.download')}
                  </Button>
                  <Button className="ms-2" variant="outline-primary" onClick={() => getCodes(null, true)}>
                    {t('account.2fa.recovery.modal.regen')}
                  </Button>
                </div>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            {!codes && (
              <>
                <Button variant="outline-secondary" onClick={closeModal}>
                  {t('global.cancel')}
                </Button>
                <Button type="submit">{t('global.continue')}</Button>
              </>
            )}
            {!!codes && (
              <Button variant="outline-secondary" onClick={closeModal}>
                {t('global.close')}
              </Button>
            )}
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}