import React, { useState, useCallback, useEffect, useRef, useContext } from 'react';
import { useCallbackWithCancel } from '../hooks';
import { useTranslation } from 'react-i18next';
import { Card, Form, Button } from 'react-bootstrap';
import { patch } from '../api2';

import { AccountContext } from '../contexts';

export default function Settings({ profile, role }) {
  const { t } = useTranslation();

  const account = useContext(AccountContext);

  const inputRef = useRef();

  const [input, setInput] = useState({
    value: '',
    error: null,
  });

  useEffect(() => {
    setInput({
      value: account.getProfileName(profile) || '',
      error: null,
    });
  }, [account, profile]);

  const onInputChange = useCallback((event) => {
    setInput({
      value: event.target.value,
      error: null,
    });
  }, []);

  const save = useCallbackWithCancel(
    (event) => {
      event.preventDefault();

      if (!input.value.trim()) {
        setInput((input) => {
          return { ...input, error: { code: 'required' } };
        });

        return;
      }

      setInput((input) => {
        return { ...input, value: input.value.trim().normalize() };
      });

      if (input.value === account.getProfileName(profile)) {
        return;
      }

      return patch(`/profiles/${profile}`, { name: input.value }, (data) => {
        if (data && data.errors) {
          setInput((input) => {
            return { ...input, error: data.errors[0] };
          });

          return;
        }

        inputRef.current.blur();

        account.onProfileNameChanged(profile, input.value);
      });
    },
    [account, profile, input.value]
  );

  return (
    <Card body>
      <h5>{t('settings.name.name')}</h5>
      <div style={{ opacity: 0.6, fontSize: '0.9em' }}>{t('settings.name.description')}</div>
      <div className="mt-3">
        <Form onSubmit={save} action="#submit" noValidate>
          <div className="d-flex">
            <div className="flex-grow-1">
              <Form.Group className="mb-0">
                <Form.Control
                  maxLength="255"
                  ref={inputRef}
                  value={input.value}
                  isInvalid={!!input.error}
                  onChange={onInputChange}
                  disabled={role !== 'owner'}
                />
                {!!input.error && (
                  <Form.Control.Feedback type="invalid">
                    {t(
                      `configurationSelector.form.name.errors.${
                        input.error.code === 'duplicate' ? 'taken' : input.error.code
                      }`
                    )}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </div>
            {input.value && input.value.trim().normalize() !== account.getProfileName(profile) && (
              <div className="ms-2">
                <Button type="submit">{t('global.save')}</Button>
              </div>
            )}
          </div>
        </Form>
      </div>
    </Card>
  );
}
