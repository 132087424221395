import React, { memo } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Card, ListGroup } from 'react-bootstrap';

export default memo(function SetupIdentifyDevices({ profile }) {
  const { t } = useTranslation();

  return (
    <Card>
      <ListGroup variant="flush">
        <ListGroup.Item className="py-3">
          <h5>{t('setup.identify.name')}</h5>
          <div style={{ opacity: 0.6, fontSize: '0.9em' }}>{t('setup.identify.description')}</div>
        </ListGroup.Item>
        <ListGroup.Item className="py-3">
          <h6>DNS-over-TLS/QUIC</h6>
          <div className="mt-1" style={{ fontSize: '0.9em', opacity: 0.8 }}>
            <Trans i18nKey="setup.identify.dot.instructions">
              _Prepend the name to the provided domain (the name should only contain
              <span className="notranslate">a-z</span>, <span className="notranslate">A-Z</span>,
              <span className="notranslate">0-9</span> and <span className="notranslate">-</span>). Use
              <span className="notranslate">--</span> for spaces.
            </Trans>
          </div>
          <div className="mt-1" style={{ fontSize: '0.9em', opacity: 0.6 }}>
            <Trans i18nKey="setup.identify.dot.example">
              _For "<span className="notranslate">John Router</span>", you would use
              <span className="notranslate">John--Router-{{ profile }}.dns.nextdns.io</span> as your DNS-over-TLS
              endpoint.
            </Trans>
          </div>
        </ListGroup.Item>
        <ListGroup.Item className="py-3">
          <h6>DNS-over-HTTPS</h6>
          <div className="mt-1" style={{ fontSize: '0.9em', opacity: 0.8 }}>
            {t('setup.identify.doh.instructions')}
          </div>
          <div className="mt-1" style={{ fontSize: '0.9em', opacity: 0.6 }}>
            <Trans i18nKey="setup.identify.doh.example">
              _For "<span className="notranslate">John's Firefox</span>", you would use
              <span className="notranslate">https://dns.nextdns.io/{{ profile }}/John's%20Firefox</span> as your
              DNS-over-HTTPS endpoint.
            </Trans>
          </div>
        </ListGroup.Item>
        <ListGroup.Item className="py-3">
          <h6>{t('setup.identify.apps.name')}</h6>
          <div className="mt-1" style={{ fontSize: '0.9em', opacity: 0.8 }}>
            {t('setup.identify.apps.instructions')}
          </div>
        </ListGroup.Item>
      </ListGroup>
    </Card>
  );
});
