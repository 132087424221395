import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, ListGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

import { AccountContext } from '../contexts';

import Account2FAEnableButton from './Account2FAEnableButton';
import Account2FADisableButton from './Account2FADisableButton';
import Account2FARecoveryCodesButton from './Account2FARecoveryCodesButton';

export default function Account2FA({ twoFactorAuthentication, onChange }) {
  const { t } = useTranslation();

  const account = useContext(AccountContext);

  return (
    <Card>
      <ListGroup variant="flush">
        <ListGroup.Item className="py-3">
          <h5>{t('account.2fa.name')}</h5>
          <div style={{ opacity: 0.6, fontSize: '0.9em' }}>{t('account.2fa.description')}</div>
          <div className="mt-4">
            {twoFactorAuthentication.enabled && (
              <>
                <div style={{ fontSize: '0.9em' }}>
                  <FontAwesomeIcon icon={faCheckCircle} style={{ color: '#2ecc40', marginRight: 6 }} />
                  <span style={{ opacity: 0.8 }}>{t('account.2fa.enabled')}</span>
                </div>
                <div className="mt-3">
                  <Account2FARecoveryCodesButton email={account.account.email} />
                </div>
              </>
            )}
            {!twoFactorAuthentication.enabled && (
              <Account2FAEnableButton
                email={account.account.email}
                secretCandidate={twoFactorAuthentication.secretCandidate}
                onEnabled={onChange}
              />
            )}
          </div>
        </ListGroup.Item>

        {twoFactorAuthentication.enabled && (
          <ListGroup.Item className="py-3">
            <Account2FADisableButton email={account.account.email} onDisabled={onChange} />
          </ListGroup.Item>
        )}
      </ListGroup>
    </Card>
  );
}
