import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

import 'dayjs/locale/fr';
import 'dayjs/locale/es';
import 'dayjs/locale/de';
import 'dayjs/locale/nl';
import 'dayjs/locale/it';
import 'dayjs/locale/pl';
import 'dayjs/locale/sv';
import 'dayjs/locale/fi';
import 'dayjs/locale/tr';
import 'dayjs/locale/pt-br';
import 'dayjs/locale/id';
import 'dayjs/locale/ja';
import 'dayjs/locale/zh-tw';
import 'dayjs/locale/zh';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import updateLocale from 'dayjs/plugin/updateLocale';
import isoWeek from 'dayjs/plugin/isoWeek';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);
dayjs.extend(updateLocale);
dayjs.extend(isoWeek);
dayjs.extend(customParseFormat);

// TODO: uncomment (as is) when below PR is released
// https://github.com/iamkun/dayjs/pull/1122
/*
dayjs.updateLocale('ja', {
  formats: {
    LL: 'YYYY 年 M 月 D 日',
    LLLL: 'YYYY 年 M 月 D 日dddd HH:mm',
  },
  relativeTime: {
    m: '1 分',
    mm: '%d 分',
    h: '1 時間',
    hh: '%d 時間',
    d: '1 日',
    dd: '%d 日',
    M: '1 ヶ月',
    MM: '%d ヶ月',
    y: '1 年',
    yy: '%d 年',
  },
});

dayjs.updateLocale('zh-tw', {
  formats: {
    LL: 'YYYY 年 M 月 D 日',
    LLLL: 'YYYY 年 M 月 D 日dddd HH:mm',
  },
});

dayjs.updateLocale('zh', {
  formats: {
    LL: 'YYYY 年 M 月 D 日',
    LLLL: 'YYYY 年 M 月 D 日ddddA h 点 mm 分',
  },
});
*/

// https://stripe.com/docs/js/appendix/supported_locales

i18n.on('initialized', (lng) => {
  i18n.services.pluralResolver.addRule('pl', {
    numbers: [1, 2], // https://github.com/i18next/i18next/blob/master/src/PluralResolver.js#L9
    plurals: (n) => {
      return Number(n !== 1);
    },
  });
});

const SUPPORTED_LANGUAGES = [
  'fr',
  'es',
  'de',
  'nl',
  'it',
  'pl',
  'sv',
  'fi',
  'tr',
  'pt-BR',
  'id',
  'ja',
  'zh-TW',
  'zh',
  'en',
];

const FALLBACK_LANGUAGES = {
  'zh-HK': 'zh-TW',
  'zh-MO': 'zh-TW',
};

const getLng = () => {
  const languages = [];

  if (typeof navigator !== 'undefined') {
    if (navigator.languages) {
      for (const language of navigator.languages) {
        languages.push(language);
      }
    }

    if (navigator.userLanguage) {
      languages.push(navigator.userLanguage);
    }

    if (navigator.language) {
      languages.push(navigator.language);
    }
  }

  for (const language of languages) {
    for (const fallback in FALLBACK_LANGUAGES) {
      if (language.toLowerCase() === fallback.toLowerCase()) {
        return FALLBACK_LANGUAGES[fallback];
      }
    }

    for (const supported of SUPPORTED_LANGUAGES) {
      if (language.toLowerCase() === supported.toLowerCase()) {
        return supported;
      }
    }

    if (language.includes('-')) {
      for (const supported of SUPPORTED_LANGUAGES) {
        const lng = language.split('-')[0];

        if (lng.toLowerCase() === supported.toLowerCase()) {
          return supported;
        }
      }
    }
  }

  return 'en';
};

const browserLocale = new Intl.NumberFormat().resolvedOptions().locale;

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    ns: 'merged',
    defaultNS: 'merged',
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    lng: getLng(),
    compatibilityJSON: 'v3',
    supportedLngs: SUPPORTED_LANGUAGES,
    fallbackLng: false,
    debug: process.env.NODE_ENV === 'development',
    returnEmptyString: false, // Crowdin returns "" for untranslated strings
    interpolation: {
      escapeValue: false,
      format: (value, format, lng) => {
        if (value === undefined) {
          return value;
        }
        const locale = lng || i18n.language;

        if (format === 'price') {
          const [amount, currency] = value;

          // Intl.NumberFormat().resolvedOptions().locale needed here because we want to represent $ and other multi-currencies signs correctly, even if we don't support the locale yet
          return new Intl.NumberFormat(browserLocale, {
            style: 'currency',
            currency: currency,
          }).format(amount);
        }

        if (format === 'number') {
          return new Intl.NumberFormat(locale).format(value);
        }

        if (format === 'percentage') {
          return new Intl.NumberFormat(locale, {
            style: 'percent',
            maximumFractionDigits: 2,
          }).format(value);
        }

        if (format === 'date') {
          const [source, formatString] = value;

          // TODO: clean up later, only accepts ISO strings or dayjs instances (for optimization purposes)
          let day;
          if (dayjs.isDayjs(source)) {
            day = source;
          } else if (typeof source === 'number' && source <= 2147483647) {
            day = dayjs.unix(source);
          } else {
            day = dayjs(source);
          }

          return day.locale(locale).format(formatString);
        }

        return '???';
      },
    },
  });

export default i18n;
