import React, { useCallback, useRef, useState } from 'react';
import { useCallbackWithCancel } from '../hooks';
import { useTranslation } from 'react-i18next';
import { Modal, Button, Spinner } from 'react-bootstrap';
import { del } from '../api2';

export default function SettingsClearLogsButton({ profile, role }) {
  const { t } = useTranslation();

  const isModalClosable = useRef(true);

  const [state, setState] = useState({
    isClearing: false,
    showModal: false,
  });

  const openModal = useCallback(() => {
    setState({
      showModal: true,
      isClearing: false,
    });
  }, []);

  const closeModal = useCallback(() => {
    if (!isModalClosable.current) {
      return;
    }

    setState({
      showModal: false,
      isClearing: false,
    });
  }, []);

  const clearLogs = useCallbackWithCancel(() => {
    isModalClosable.current = false;

    setState((state) => {
      return { ...state, isClearing: true };
    });

    return del(`/profiles/${profile}/logs`, () => {
      isModalClosable.current = true;
      closeModal();
    });
  }, [profile, closeModal]);

  return (
    <>
      <Button variant="outline-danger" size="sm" onClick={openModal} disabled={role === 'viewer'}>
        {t('settings.logs.clear.button')}
      </Button>

      <Modal show={state.showModal} animation={false} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title as="h5">{t('settings.logs.clear.button')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t('settings.logs.clear.confirm.text')}</Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" disabled={state.isClearing} onClick={closeModal}>
            {t('global.cancel')}
          </Button>

          {!state.isClearing && (
            <Button variant="danger" onClick={clearLogs}>
              {t('settings.logs.clear.confirm.submit')}
            </Button>
          )}
          {state.isClearing && (
            <Button variant="danger" disabled>
              <Spinner as="span" animation="border" size="sm" className="me-2" />
              {t('settings.logs.clear.confirm.submit')}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}
