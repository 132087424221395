import React, { lazy, useState, useEffect, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Card } from 'react-bootstrap';

import { get } from '../api2';

const GeographyMap = lazy(() => import('./GeographyMap'));

export default function AnalyticsTrafficDestinationMap({ profile, device, timeRange }) {
  const { t, i18n } = useTranslation();

  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);

    return get(
      `/profiles/${profile}/analytics/destinations`,
      {
        type: 'countries',
        device,
        ...timeRange,
        limit: 300,
        lang: i18n.language,
      },
      ({ data }) => {
        setData(Object.fromEntries(data.map((item) => [item.code, item])));
        setLoading(false);
      }
    );
  }, [profile, device, timeRange, i18n.language]);

  return (
    <Col lg="12">
      <Card body>
        <h5>{t('analytics.destination.name')}</h5>
        <div style={{ opacity: 0.6, fontSize: '0.9em' }}>{t('analytics.destination.description')}</div>
        <div className="mt-3 mt-md-0">
          <Suspense fallback={<canvas style={{ width: '100%', height: 'auto' }} width={800} height={480} />}>
            <GeographyMap data={data} loading={loading} t={t} />
          </Suspense>
        </div>
      </Card>
    </Col>
  );
}
