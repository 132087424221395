import React, { memo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, ListGroup, Button } from 'react-bootstrap';
import { request } from '../api2';

import { useCallbackWithCancel, usePrevious } from '../hooks';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt, faCheckCircle, faAngleDown } from '@fortawesome/free-solid-svg-icons';

import SetupLinkedIpDDNS from './SetupLinkedIpDDNS';

export default memo(function SetupLinkedIp({
  profile,
  role,
  linkedIpDNSServers,
  initialLinkedIp,
  ddnsHostname,
  linkedIpUpdateToken,
}) {
  const { t } = useTranslation();

  const [showAdvanced, setShowAdvanced] = useState(false);

  const [linkedIp, setLinkedIp] = useState(initialLinkedIp);
  const [clientIp, setClientIp] = useState();

  const prevLinkedIpUpdateToken = usePrevious(linkedIpUpdateToken);

  const link = useCallbackWithCancel(
    (touchOnly) => {
      return request(
        `https://link-ip.nextdns.io/${profile}/${linkedIpUpdateToken}${touchOnly ? '?touchOnly=1' : ''}`,
        { method: 'GET', withCredentials: true, responseType: 'text' },
        ({ response }) => {
          setClientIp(response);

          if (!touchOnly) {
            setLinkedIp(response);
          }
        }
      );
    },
    [profile, linkedIpUpdateToken]
  );

  useEffect(() => {
    if (role === 'viewer') {
      return;
    }

    if (prevLinkedIpUpdateToken !== linkedIpUpdateToken) {
      link(true);
    }
  }, [role, link, prevLinkedIpUpdateToken, linkedIpUpdateToken]);

  return (
    <Card>
      <ListGroup variant="flush">
        <ListGroup.Item className="py-3">
          <h5>{t('setup.linkedIp.name')}</h5>
          <div style={{ opacity: 0.6, fontSize: '0.9em' }}>{t('setup.linkedIp.description')}</div>
        </ListGroup.Item>
        <ListGroup.Item>
          <div className="d-flex justify-content-between gap-2 flex-wrap">
            <div className="flex-grow-1">{t('setup.linkedIp.servers.name')}</div>
            <div className="notranslate" style={{ opacity: 0.6 }}>
              {linkedIpDNSServers.map((server) => (
                <div key={server} className="text-end">
                  {server}
                </div>
              ))}
            </div>
          </div>
        </ListGroup.Item>
        <ListGroup.Item>
          <div className="d-flex justify-content-between gap-2 flex-wrap">
            <div>{t('setup.linkedIp.ip.name')}</div>
            <div className="flex-shrink-0 d-flex">
              {!!linkedIp && (
                <>
                  <span className="notranslate" style={{ opacity: 0.6 }}>
                    {linkedIp}
                  </span>
                  {linkedIp === clientIp && (
                    <FontAwesomeIcon icon={faCheckCircle} style={{ color: '#2ecc40', marginLeft: 8 }} />
                  )}
                </>
              )}
              {(!!clientIp || !linkedIp) && (!linkedIp || linkedIp !== clientIp) && (
                <Button
                  variant="outline-primary"
                  size="sm"
                  style={{ fontSize: '0.7em', marginLeft: 8, marginTop: -3 }}
                  onClick={(event) => {
                    event.preventDefault();
                    link();
                  }}
                  disabled={role === 'viewer'}
                >
                  <>
                    {!!linkedIp && <FontAwesomeIcon icon={faSyncAlt} />}
                    {!linkedIp && (
                      <>
                        <FontAwesomeIcon icon={faSyncAlt} className="me-1" />
                        <b>{t('setup.linkedIp.ip.link')}</b>
                      </>
                    )}
                  </>
                </Button>
              )}
            </div>
          </div>
        </ListGroup.Item>

        {(showAdvanced || !!ddnsHostname) && (
          <SetupLinkedIpDDNS
            profile={profile}
            role={role}
            hostname={ddnsHostname}
            onLinkedIpUpdate={(ip) => setLinkedIp(ip)}
          />
        )}

        {showAdvanced && role !== 'viewer' && (
          <ListGroup.Item style={{ fontSize: '0.8em' }}>
            <div>{t('setup.linkedIp.programmatically')}</div>
            <div className="mt-1 notranslate" style={{ opacity: 0.6, wordBreak: 'break-all' }}>
              https://link-ip.nextdns.io/{profile}/{linkedIpUpdateToken}
            </div>
          </ListGroup.Item>
        )}

        {!showAdvanced && (role !== 'viewer' || !ddnsHostname) && (
          <ListGroup.Item className="text-center bg-2" style={{ paddingTop: 4, paddingBottom: 8 }}>
            <Button
              variant="link"
              className="p-0 m-0"
              size="sm"
              style={{ fontSize: '0.8em' }}
              onClick={(event) => {
                event.preventDefault();
                setShowAdvanced(true);
              }}
            >
              {t('setup.linkedIp.showAdvanced')}
              <FontAwesomeIcon icon={faAngleDown} style={{ marginLeft: 6 }} />
            </Button>
          </ListGroup.Item>
        )}
      </ListGroup>
    </Card>
  );
});
