import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Form } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

import { post } from '../api2';
import { puny } from '../helpers';
import { useCallbackWithCancel } from '../hooks';

export default function XlistAdd({ listType, profile, role, onAdded }) {
  const { t } = useTranslation();
  const location = useLocation();

  const inputRef = useRef();

  const [input, setInput] = useState({
    value: '',
    error: null,
  });

  // reset input value on route change
  useEffect(() => {
    const domain = location.state?.domain ?? '';
    if (domain) {
      inputRef.current.focus();
    }
    setInput({
      value: domain,
      error: null,
    });
  }, [profile, listType, location.state]);

  const onInputChange = useCallback((event) => {
    setInput({
      value: event.target.value.toLowerCase().trim(),
      error: null,
    });
  }, []);

  const add = useCallbackWithCancel(() => {
    if (input.value.length === 0) {
      return;
    }

    let value = input.value;

    if (value.startsWith('*.')) {
      value = value.slice(2);
    }

    const payload = { id: puny(value), active: true };

    return post(`/profiles/${profile}/${listType}`, payload, (data) => {
      if (data && data.errors) {
        setInput({
          value: input.value,
          error: data.errors[0],
        });
        return;
      }

      if (inputRef.current !== null) {
        inputRef.current.blur();
      }

      setInput({
        value: '',
        error: null,
      });

      onAdded(payload);
    });
  }, [profile, listType, input.value, onAdded]);

  if (process.env.REACT_APP_TAILWIND) {
    return (
      <form
        action="#submit"
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
          add();
        }}
      >
        <input
          ref={input}
          type="text"
          spellCheck="false"
          autoCorrect="off"
          autoComplete="off"
          autoCapitalize="off"
          className="tw-block tw-w-full tw-rounded-md tw-border tw-border-slate-800/50 tw-bg-slate-800/50 tw-p-2.5 tw-font-mono tw-text-[13px] tw-text-slate-200 focus:tw-border-nextdnsblue focus:tw-outline-none mouse:hover:tw-bg-slate-800/60"
          value={input.value}
          placeholder={t('xlist.placeholder')}
          onChange={onInputChange}
          disabled={role === 'viewer'}
        />
      </form>
    );
  }

  return (
    <Card.Header style={{ padding: 12 }}>
      <Form
        onSubmit={(event) => {
          event.preventDefault();
          add();
        }}
        action="#submit"
        noValidate
      >
        <Form.Group className="mb-0">
          <Form.Control
            ref={inputRef}
            spellCheck="false"
            autoCorrect="off"
            autoComplete="off"
            autoCapitalize="off"
            isInvalid={!!input.error}
            value={input.value}
            placeholder={t('xlist.placeholder')}
            onChange={onInputChange}
            disabled={role === 'viewer'}
          />
          {input.error && (
            <Form.Control.Feedback type="invalid">
              {t(`xlist.errors.${input.error.code === 'duplicate' ? 'exists' : input.error.code}`)}
            </Form.Control.Feedback>
          )}
        </Form.Group>
      </Form>
    </Card.Header>
  );
}
