import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { Container, Row, Col, Modal, Button } from 'react-bootstrap';
import { CancelToken } from 'axios';
import classnames from 'classnames';
import { useTheme } from '../theme';

import { api } from '../api';
import { AppContext, AccountContext } from '../contexts';

import HeaderProfileSelector from './HeaderProfileSelector';
import ThemeSwitcherButton from '../ThemeSwitcherButton';
import HeaderAccount from './HeaderAccount';
import HeaderMenu from './HeaderMenu';
import RelativeTime from '../Components/RelativeTime';

import './Header.scss';
import logo from '../images/logo@2x.png';
import logoDark from '../images/logo-dark@2x.png';
import LogoIcon from '../images/logo-icon@2x.png';

export default function Header({ profile, simple }) {
  const { t } = useTranslation();
  const theme = useTheme();

  const app = useContext(AppContext);
  const account = useContext(AccountContext);

  const [queries, setQueries] = useState(0);

  useEffect(() => {
    if (!account.account.email) {
      return;
    }

    let source = CancelToken.source();

    const fetchQueries = async () => {
      let data;
      try {
        ({ data } = await api.get('/accounts/@me/usage', {
          cancelToken: source.token,
        }));
      } catch (error) {
        if (error.isAxiosError) {
          app.handleNetworkError(error);
          return;
        }
        throw error;
      }

      setQueries(data.monthlyQueries);
    };

    fetchQueries();

    return () => {
      source.cancel();
    };
  }, [app, account.account.email]);

  if (process.env.REACT_APP_TAILWIND) {
    return (
      <>
        {queries > 250000 && queries <= 300000 && (
          <div className="HeaderMessage" style={{ background: '#ff851b' }}>
            <Container>
              <Row>
                <Col className="text-center p-3">
                  <div style={{ fontSize: '0.9em', lineHeight: '1.5em' }}>
                    <Trans i18nKey="banners.nearQuota">
                      _You have made more than {{ queriesNearQuota: 250000 }} queries this month, getting close to the
                      free monthly quota of {{ queriesQuota: 300000 }} queries.
                      <Link to="/account" style={{ color: 'white', fontWeight: 600 }}>
                        Subscribe now
                      </Link>
                      to continue using NextDNS.
                    </Trans>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        )}

        {queries > 300000 && (
          <div className="HeaderMessage" style={{ background: '#ff4136' }}>
            <Container>
              <Row>
                <Col className="text-center p-3">
                  <div style={{ fontSize: '0.9em', lineHeight: '1.5em' }}>
                    <Trans i18nKey="banners.overQuota">
                      _You have made more than {{ queriesQuota: 300000 }} DNS queries this month and all features have
                      been disabled.
                      <Link to="/account" style={{ color: 'white', fontWeight: 600 }}>
                        Subscribe now
                      </Link>
                      to continue using NextDNS.
                    </Trans>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        )}

        {!!account.account.expired && (
          <Modal show={true} animation={false}>
            <Modal.Header closeButton>
              <Modal.Title as="h5">{t('account.temporary.expired.modal.title')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{t('account.temporary.expired.modal.text')}</Modal.Body>
            <Modal.Footer>
              <Button as={Link} to="/signup">
                {t('account.signup')}
              </Button>
            </Modal.Footer>
          </Modal>
        )}

        {!!account.account.expiresOn && (
          <div className="HeaderMessage">
            <Container>
              <Row>
                <Col className="text-center p-3">
                  <div style={{ fontSize: '0.9em', lineHeight: '1.5em' }}>
                    <Trans i18nKey="banners.temporary">
                      _This temporary account will expire in
                      <b>
                        <RelativeTime date={account.account.expiresOn} />
                      </b>
                      and is only accessible from this browser. Sign up now for free to save your settings.
                    </Trans>
                  </div>
                  <div className="my-2">
                    <Button as={Link} variant="light" to="/signup">
                      {t('account.signup')}
                    </Button>
                  </div>
                  <div style={{ fontSize: '0.8em', lineHeight: '1.5em' }}>
                    <Trans i18nKey="account.alreadyHaveAnAccount">
                      _Already have an account?
                      <Link style={{ color: 'white', fontWeight: 600 }} to="/logout">
                        Log in
                      </Link>
                    </Trans>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        )}

        <div className="tw-border-b tw-bg-gray-50 tw-pt-2 dark:tw-border-slate-800 dark:tw-bg-slate-900">
          <Container>
            <Row>
              <Col>
                <div className="tw-mb-2 tw-flex tw-items-center">
                  <div className="tw-flex tw-grow tw-items-center">
                    <img src={LogoIcon} alt="" className="tw--mb-0.5 tw-me-1 tw-h-7 tw-object-contain" />
                    <HeaderProfileSelector profile={profile} />
                  </div>
                  <div>
                    <ThemeSwitcherButton />
                  </div>
                </div>
                {!simple && !!profile && <HeaderMenu profile={profile} />}
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }

  return (
    <>
      {queries > 250000 && queries <= 300000 && (
        <div className="HeaderMessage near-quota">
          <Container>
            <Row>
              <Col className="text-center p-3">
                <div style={{ fontSize: '0.9em', lineHeight: '1.5em' }}>
                  <Trans i18nKey="banners.nearQuota">
                    _You have made more than {{ queriesNearQuota: 250000 }} queries this month, getting close to the
                    free monthly quota of {{ queriesQuota: 300000 }} queries.
                    <Link to="/account" style={{ color: 'white', fontWeight: 600 }}>
                      Subscribe now
                    </Link>
                    to continue using NextDNS.
                  </Trans>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      )}

      {queries > 300000 && (
        <div className="HeaderMessage over-quota">
          <Container>
            <Row>
              <Col className="text-center p-3">
                <div style={{ fontSize: '0.9em', lineHeight: '1.5em' }}>
                  <Trans i18nKey="banners.overQuota">
                    _You have made more than {{ queriesQuota: 300000 }} DNS queries this month and all features have
                    been disabled.
                    <Link to="/account" style={{ color: 'white', fontWeight: 600 }}>
                      Subscribe now
                    </Link>
                    to continue using NextDNS.
                  </Trans>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      )}

      {!!account.account.expired && (
        <Modal show={true} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title as="h5">{t('account.temporary.expired.modal.title')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{t('account.temporary.expired.modal.text')}</Modal.Body>
          <Modal.Footer>
            <Button as={Link} to="/signup">
              {t('account.signup')}
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {!!account.account.expiresOn && (
        <div className="HeaderMessage">
          <Container>
            <Row>
              <Col className="text-center p-3">
                <div style={{ fontSize: '0.9em', lineHeight: '1.5em' }}>
                  <Trans i18nKey="banners.temporary">
                    _This temporary account will expire in
                    <b>
                      <RelativeTime date={account.account.expiresOn} />
                    </b>
                    and is only accessible from this browser. Sign up now for free to save your settings.
                  </Trans>
                </div>
                <div className="my-2">
                  <Button as={Link} variant="light" to="/signup">
                    {t('account.signup')}
                  </Button>
                </div>
                <div style={{ fontSize: '0.8em', lineHeight: '1.5em' }}>
                  <Trans i18nKey="account.alreadyHaveAnAccount">
                    _Already have an account?
                    <Link style={{ color: 'white', fontWeight: 600 }} to="/logout">
                      Log in
                    </Link>
                  </Trans>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      )}

      <div className="Header">
        <Container>
          <Row>
            <Col>
              <div className="d-flex" style={{ marginBottom: 16 }}>
                <div className="flex-grow-1">
                  <div className="d-block d-md-flex">
                    <Link
                      to="/"
                      className={classnames('d-md-inline', { 'd-none': !simple })}
                      style={{ marginRight: !simple ? 15 : 0 }}
                    >
                      <img
                        className="d-inline"
                        src={theme === 'dark' ? logoDark : logo}
                        alt=""
                        style={{ width: 137, height: 38 }}
                      />
                    </Link>
                    {!simple && <HeaderProfileSelector profile={profile} />}
                  </div>
                </div>
                {!!account.account.email && (
                  <div>
                    <HeaderAccount />
                  </div>
                )}
              </div>
              {!simple && !!profile && <HeaderMenu profile={profile} />}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
